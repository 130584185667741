import * as React from "react";
import {Route, useNavigate} from "react-router-dom";
import {RouteProps} from "react-router";
import {useRecoilValue} from "recoil";

//
import {userState} from "../store/atoms";

interface Props extends RouteProps{
  redirect?: string
}

export default function PrivateRoute({children, redirect, ...rest}: Props) {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  React.useEffect(()=> {
    if (user.status === "USER_NOT_LOGGED_IN") navigate(redirect ?? "/sign-up");
  }, [user]);
  switch (user.status){
    case "USER_LOADING":
      return <div/>
    case "USER_LOGGED_IN":
      return (
        <Route {...rest}>
          {children}
        </Route>
      );
    case "USER_NOT_LOGGED_IN":
      return <div/>
    default: return <div/>
  }
}
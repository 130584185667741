import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

// Local
import {Strings} from "../constants";

interface Props {
  dark?: boolean
}

export default function Copyright({dark = false}: Props) {
  return (
    <Typography style={{color: dark ? "#f5f5f5" : "#333"}} variant="body2" align="center">
      {"Copyright © "}
      <Link color="inherit" href={Strings.url}>
        {Strings.company}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
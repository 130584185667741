import * as React from "react";
import { StyleSheet, View } from "react-native";
import { DefaultTheme, Provider as PaperProvider } from "react-native-paper";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { RecoilRoot, useSetRecoilState } from "recoil";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
// @ts-ignore
import ScriptTag from "react-script-tag";

// Local
import Navigator from "./navigation/Navigator";
import Loader from "./components/Loader";
import { Colors, Strings } from "./constants";
import { auth } from "./firebase";
import { userState } from "./store/atoms";

const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.primary,
    accent: Colors.accent,
  },
};

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.accent,
    },
  },
});

function StatefulApp() {
  console.log("Version 2021.4.23.1")
  const setUser = useSetRecoilState(userState);
  const [isReady, setIsReady] = React.useState(false);
  const styles = StyleSheet.create({
    container: {},
  });
  
  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in
        setUser({
          uid: user.uid,
          email: user.email,
          status: "USER_LOGGED_IN",
        });
        // ...
      } else {
        // User is signed out
        // ...
        setUser({
          uid: null,
          email: null,
          status: "USER_NOT_LOGGED_IN",
        });
        console.log("signed out");
      }
      setIsReady(true);
    });
  }, []);
  
  return (
    <>
      <ScriptTag
        type="text/javascript"
        src="https://api.mmoneyapp.com/merchant/js/mmoney-payment.js"
      />
      {isReady ? (
        <View style={styles.container}>
          <Navigator />
        </View>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default function App() {
  return (
    <RecoilRoot>
      <ThemeProvider theme={outerTheme}>
        <PaperProvider theme={theme}>
          <PayPalScriptProvider options={{ "client-id": Strings.payPalClientIDLive }}>
            <StatefulApp />
          </PayPalScriptProvider>
        </PaperProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
}

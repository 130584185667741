import React from "react";
import {makeStyles, Theme, createStyles} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useParams} from "react-router";

//
import Page from "../components/Page";
import {unhyphenateSearchQuery} from "../utils";
import BookGrid from "../components/BookGrid";
import {EndPoints} from "../constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 1024,
      margin: "auto",
      backgroundColor: theme.palette.common.white,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(4),
    },
    heading: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(2),
      },
    },
    grid: {
      width: "100%",
    },
    book: {},
    imageContainer: {
      backgroundColor: "#f2f2f2",
    },
    detailsContainer: {
      padding: theme.spacing(2),
    },
    title: {
      fontSize: 15,
      fontWeight: "bolder",
      letterSpacing: 0.25,
      color: theme.palette.common.black,
    },
    details: {
      fontSize: 14,
      lineHeight: 1.5,
      color: "#717171",
    },
    price: {
      fontSize: 16,
      color: theme.palette.common.black,
      marginTop: theme.spacing(1),
    },
    noResultsContainer: {
      padding: theme.spacing(4),
    },
  })
);

export default function SearchResults() {
  const classes = useStyles();
  const {query} = useParams();
  const perPage = 24
  const [books, setBooks] = React.useState({
    results: null,
    filteredResults: null,
    loading: true,
    error: null,
    totalNumOfBooks: 0,
    numOfBooksShown: perPage
  });
  const {numOfBooksShown, results, filteredResults, loading, error, totalNumOfBooks} = books;
  
  React.useEffect(()=> {
    if (!query) return
    const url = EndPoints.findBook
    const body = JSON.stringify({
      searchQuery: unhyphenateSearchQuery(query)
    });
    fetch(url, {
      method: "POST",
      body,
    })
      .then(response => response.text())
      .then(result => {
        const allBooks = JSON.parse(result)
        setBooks({
          ...books,
          results: allBooks,
          filteredResults: allBooks.slice(0, numOfBooksShown),
          loading: false,
          error: null,
          totalNumOfBooks: allBooks.length
        })
      })
      .catch((err: any) => {
      });
  }, [])
  
  if (loading) return <LinearProgress/>;
  const errorComp = (
    <div style={{marginTop: 40}}>
      <Typography variant="h5" align="center">
        Oops, an error occurred, please reload the page
      </Typography>
    </div>
  );
  return (
    <Page loading={filteredResults === null || filteredResults === undefined}>
      <div style={{minHeight: 600, paddingBottom: 120}}>
        <Typography className={classes.heading} variant="h6">
          Search results for{" "}
          <strong>&quot;{unhyphenateSearchQuery(query)}&quot;</strong>
        </Typography>
        <BookGrid docs={filteredResults ?? []}/>
        {numOfBooksShown < totalNumOfBooks && (<Button
          color="primary"
          variant="contained"
          onClick={() => {
            const numberOfBooksToShow = numOfBooksShown + perPage
            setBooks({
              ...books,
              filteredResults: results.slice(0, numberOfBooksToShow),
              numOfBooksShown: numberOfBooksToShow
            })
          }}>
          Load more
        </Button>)}
      </div>
    </Page>
  );
}

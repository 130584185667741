import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import SentimentVeryDissatisfiedTwoToneIcon from '@material-ui/icons/SentimentVeryDissatisfiedTwoTone';
import Typography from "@material-ui/core/Typography";

// Local
import Page from "../components/Page";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 792,
      margin: "auto",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(20),
      display: "flex",
      flexDirection: "column",
      justifyContent:" center",
      alignItems: "center",
    },
    icon: {
      width: 120,
      height: 120,
      marginBottom: 24,
      color: theme.palette.secondary.main
    },
  }),
);

const NotFound = () => {
  const classes = useStyles();
  return (
    <Page>
      <div className={classes.root}>
          <SentimentVeryDissatisfiedTwoToneIcon className={classes.icon}/>
        <Typography align="center">We're sorry</Typography>
        <Typography align="center" variant="h5">This page was not found</Typography>
      </div>
    </Page>
  );
};

export default NotFound;
import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";

// Local
import Page from "../components/Page";
import BR from "../components/BR";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 792,
      margin: "auto",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  }),
);

const Privacy = () => {
  const classes = useStyles();
  return (
    <Page>
      <div className={classes.root}>
        <Typography variant="h4">Privacy Policy</Typography>
        <br/>
        <Typography>
          This web site ("Site") is owned and operated by CSERS, a division of Datalore Inc. Registered Office: #10
          Waterhall Terrace #2, St James, Barbados, Registered in Barbados. (“Datalore Inc.,” “we,” “us” or “our”).
          We are committed to maintaining your confidence and trust with respect to the information we collect from you.
          This privacy policy sets forth what information we collect about you, how we use your information, and the
          choices you have about how we use your information.
        </Typography>
        <br/>
        <Typography>WHAT INFORMATION DO WE COLLECT?</Typography>
        <br/>
        <Typography>
          We collect information from you when you register on our site, subscribe to our newsletter or fill out a form.
        </Typography>
        <BR/>
        <Typography>
          When ordering or registering on our site, as appropriate, you may be asked to enter your name, e-mail address,
          phone number or credit card information. You may, however, visit our site anonymously.
        </Typography>
        <BR/>
        <Typography>
          This Site automatically collects information on our server logs from your web browser regarding your use of
          the Site. Examples of information we collect automatically from all users include the Internet Protocol ("IP")
          address used to connect your computer to the Internet; computer and connection information such as browser
          type and version, operating system, and platform; and full Uniform Resource Locator ("URL") click stream data,
          including date and time, and content you viewed or searched for on the Site.
        </Typography>
        <BR/>
        <Typography>
          This Site also collects information through the use of "cookies." Cookies are anonymous, unique alphanumeric
          identifiers sent to your browser (if you allow) from a web site’s computers and stored on your computer's hard
          drive. The type of information we collect as a result of a cookie being stored on your computer is specific to
          your computer. We use "session" cookies to collect information about your use of the Site (e.g., whether you
          are logged in) and so that you may view articles, abstracts or other records and browse from page to page.
          These cookies are temporary and are deleted when you close your browser. We use "persistent" cookies to give
          you a more personalized browsing experience and help you navigate the Site more efficiently.
        </Typography>
        <BR/>
        <Typography>
          We collect and use, and may from time to time supply third parties with this information only as anonymous,
          aggregated user data for the purposes of usage analysis, quality control and administering and improving the
          Site.
        </Typography>
        <BR/>
        <Typography>
          You can prevent your browser from accepting new cookies, have the browser notify you when you receive a new
          cookie, or disable cookies altogether by accessing your browser’s preferences menu. If you choose to disable
          cookies altogether, you can use the Site but will not be able to register, login to the Site with a personal
          User Name and Password, or make use of the advanced personalization features of the Site, although the basic
          features and functionality offered to unregistered users of the Site will be possible to use.
        </Typography>
        <br/>
        <br/>
        <Typography>
          HOW YOUR PERSONAL INFORMATION IS USED
        </Typography>
        <br/>
        <Typography>
          Any of the information we collect from you may be used in one of the following ways:
        </Typography>
        <BR/>
        <Typography>
          <ul>
            <li>
              Completion and support of the activity for which the information was provided, such as allowing access to
              or delivery of our products or services, processing or fulfilling an order, or responding to your requests
              or inquiries
            </li>
            <li>
              Website and system administration, such as for the technical support of the Site and its computer system,
              including processing computer account information, information used in the course of securing and
              maintaining the Site, and verification of Site activity by the Site or its agents
            </li>
            <li>
              Research and development to enhance, evaluate and improve the Site and our services
            </li>
            <li>
              Tailoring or customizing content or design of the Site during a single visit to the Site and
              individualized personalization of the Site on repeat visits
            </li>
            <li>
              Pseudonymous analysis to create a profile used to determine the habits, interests or other characteristics
              of users for purposes of research, analysis and anonymous reporting
            </li>
            <li>
              Communicating with you about changes or updates to the Site and our services and, with your consent,
              special offers, promotions or market research surveys
            </li>
          </ul>
        </Typography>
        <BR/>
        <Typography>
          Note: If at any time you would like to unsubscribe from receiving future emails you may do so by using the
          “opt-out” or unsubscribe option or other means provided within the communications you receive. We reserve the
          right to notify you of changes or updates to the Site whenever necessary.
        </Typography>
        <br/>
        <br/>
        <Typography>
          RETENTION AND PROTECTION OF YOUR INFORMATION
        </Typography>
        <br/>
        <Typography>
          We implement a variety of security measures to maintain the safety of your personal information when you
          access your personal information.
        </Typography>
        <BR/>
        <Typography>
          We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure
          Socket Layer (SSL) technology and then encrypted into our Payment Gateway providers database only to be
          accessible by those authorized with special access rights to such systems, and are required to keep the
          information confidential.
        </Typography>
        <BR/>
        <Typography>
          After a transaction, your private information (credit cards, social security numbers, financials, etc.) will
          not be stored on our servers.
        </Typography>
        <BR/>
        <Typography>
          However, we do retain the personal and non-financial information we collect from you for as long as the
          information is relevant to our business purposes or until you request that we remove the data by contacting
          our Customer Service Department at the address or telephone number detailed below.
        </Typography>
        <br/>
        <br/>
        <Typography>
          DO WE SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <br/>
        <Typography>
          We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This
          does not include trusted third parties who assist us in operating our website, conducting our business, or
          servicing you, so long as those parties agree to keep this information confidential. We may also release your
          information when we believe release is appropriate to comply with the law, enforce our site policies, or
          protect ours or others’ rights, property, or safety. However, non-personally identifiable visitor information
          may be provided to other parties for marketing, advertising, or other uses.
        </Typography>
        <br/>
        <br/>
        <Typography>
          ACCESS AND CHANGES TO REGISTRATION INFO
        </Typography>
        <br/>
        <Typography>
          Registered Users may access their identified contact information and correct any discrepancies or update
          information through their profile page. The accuracy of such information is solely the responsibility of the
          user. No access is given to other data that may have been collected about users.
        </Typography>
        <br/>
        <br/>
        <Typography>
          TERMS AND CONDITIONS
        </Typography>
        <br/>
        <Typography>
          Please also visit our <Link to="/terms">Terms and Conditions</Link> page.
          </Typography>
        <br/>
        <Typography>
          ABOUT THIS POLICY
        </Typography>
        <br/>
        <Typography>
          The foregoing is the current privacy policy of the Site. We reserve the right to change this policy at any
          time without notice. Any changes to this policy will be posted on this webpage. Our policy does not create a
          contract or any legal rights.
        </Typography>
        <BR/>
        <Typography>
          This privacy policy applies solely to the information you provide while visiting this Site. The terms of this
          privacy policy do not govern personal information furnished through any means other than this Site (such as
          via telephone or postal mail).
        </Typography>
        <BR/>
        <Typography>
          This Site may contain links to other websites that are beyond our control. Other websites to which you link
          may contain privacy policies that are different from this privacy policy. We encourage you to check the
          privacy policy of each website you visit before submitting any information to them.
        </Typography>
        <br/>
        <Typography>
          By using our site you consent to our website privacy policy.
        </Typography>
        <br/>
        <br/>
        <Typography>
          QUESTIONS
        </Typography>
        <br/>
        <Typography>
          If you have any questions or comments regarding this privacy policy, please contact us at:
        </Typography>
        <br/>
        <Typography>
          info@csers.info
        </Typography>
        <BR/>
        <Typography>
          PO Box 964E, St. Michael, Barbados
        </Typography>
        <BR/>
        <Typography>
          Or
        </Typography>
        <BR/>
        <Typography>
          #10 Waterhall Terrace #2, St James, Barabados
        </Typography>
        <BR/>
        <Typography>
          Tel: (246) 429-2805
        </Typography>
      </div>
    </Page>
  );
};

export default Privacy;
const subjects = [
  {
    name: "ANTIQUES & COLLECTIBLES",
    shortcode: "ANT",
  },
  {
    name: "ARCHITECTURE",
    shortcode: "ARC",
  },
  {
    name: "Art",
    shortcode: "ART",
  },
  {
    name: "Bibles",
    shortcode: "BIB",
  },
  {
    name: "BIOGRAPHY & AUTOBIOGRAPHY",
    shortcode: "BIO",
  },
  {
    name: "BODY, MIND & SPIRIT",
    shortcode: "OCC",
  },
  {
    name: "BUSINESS & ECONOMICS",
    shortcode: "BUS",
  },
  {
    name: "COMICS & GRAPHIC NOVELS",
    shortcode: "CGN",
  },
  {
    name: "Computers",
    shortcode: "COM",
  },
  {
    name: "Cooking",
    shortcode: "CKB",
  },
  {
    name: "CRAFTS & HOBBIES",
    shortcode: "CRA",
  },
  {
    name: "Design",
    shortcode: "DES",
  },
  {
    name: "Drama",
    shortcode: "DRA",
  },
  {
    name: "Education",
    shortcode: "EDU",
  },
  {
    name: "Family & Relationships",
    shortcode: "FAM",
  },
  {
    name: "Fiction",
    shortcode: "FIC",
  },
  {
    name: "Foreign Language Study",
    shortcode: "FOR",
  },
  {
    name: "Games & Activities",
    shortcode: "GAM",
  },
  {
    name: "Gardening",
    shortcode: "GAR",
  },
  {
    name: "Health & Fitness",
    shortcode: "HEA",
  },
  {
    name: "History",
    shortcode: "HIS",
  },
  {
    name: "House & Home",
    shortcode: "HOM",
  },
  {
    name: "Humor",
    shortcode: "HUM",
  },
  {
    name: "Juvenile Fiction",
    shortcode: "JUV",
  },
  {
    name: "Juvenile Non-Fiction",
    shortcode: "JNF",
  },
  {
    name: "Language Arts & Disciplines",
    shortcode: "LAN",
  },
  {
    name: "Law",
    shortcode: "LAW",
  },
  {
    name: "LITERARY COLLECTIONS",
    shortcode: "LCO",
  },
  {
    name: "LITERARY CRITICISM",
    shortcode: "LIT",
  },
  {
    name: "MATHEMATICS",
    shortcode: "MAT",
  },
  {
    name: "MEDICAL",
    shortcode: "MED",
  },
  {
    name: "MUSIC",
    shortcode: "MUS",
  },
  {
    name: "NATURE",
    shortcode: "NAT",
  },
  {
    name: "PERFORMING ARTS",
    shortcode: "PER",
  },
  {
    name: "PETS",
    shortcode: "PET",
  },
  {
    name: "PHILOSOPHY",
    shortcode: "PHI",
  },
  {
    name: "PHOTOGRAPHY",
    shortcode: "PHO",
  },
  {
    name: "POETRY",
    shortcode: "POE",
  },
  {
    name: "POLITICAL SCIENCE",
    shortcode: "POL",
  },
  {
    name: "PSYCHOLOGY",
    shortcode: "PSY",
  },
  {
    name: "REFERENCE",
    shortcode: "REF",
  },
  {
    name: "RELIGION",
    shortcode: "REL",
  },
  {
    name: "SCIENCE",
    shortcode: "SCI",
  },
  {
    name: "SELF-HELP",
    shortcode: "SEL",
  },
  {
    name: "SOCIAL SCIENCE",
    shortcode: "SOC",
  },
  {
    name: "SPORTS & RECREATION",
    shortcode: "SPO",
  },
  {
    name: "STUDY AIDS",
    shortcode: "STU",
  },
  {
    name: "TECHNOLOGY & ENGINEERING",
    shortcode: "TEC",
  },
  {
    name: "TRANSPORTATION",
    shortcode: "TRA",
  },
  {
    name: "TRAVEL",
    shortcode: "TRV",
  },
  {
    name: "TRUE CRIME",
    shortcode: "TRU",
  },
  {
    name: "YOUNG ADULT FICTION",
    shortcode: "YAF",
  },
  {
    name: "YOUNG ADULT NON-FICTION",
    shortcode: "YAN",
  },
];

export default subjects;

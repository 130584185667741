import xml2js from "xml2js";

// Local
import {Strings} from "../constants";
import {BookVariantType} from "../types";

export const sanitizeHtml = (html) => {
  //-- remove BR tags and replace them with line break
  html = html.replace(/<br>/gi, "");
  html = html.replace(/<br\s\/>/gi, "");
  html = html.replace(/<br\/>/gi, "");
  
  //-- remove P and A tags but preserve what's inside of them
  html = html.replace(/<p.*?>/gi, "\n");
  html = html.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 ($1)");
  
  //-- remove all inside SCRIPT and STYLE tags
  html = html.replace(/<script.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/script>/gi, "");
  html = html.replace(/<style.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/style>/gi, "");
  //-- remove all else
  html = html.replace(/<(?:.|\s)*?>/g, "");
  
  //-- get rid of more than 2 multiple line breaks:
  html = html.replace(/(?:(?:\r\n|\r|\n)\s*){2,}/gim, "\n\n");
  
  //-- get rid of more than 2 spaces:
  html = html.replace(/ +(?= )/g, "");
  
  //-- get rid of html-encoded characters:
  html = html.replace(/&nbsp;/gi, " ");
  html = html.replace(/&amp;/gi, "&");
  html = html.replace(/&quot;/gi, '"');
  html = html.replace(/&lt;/gi, "<");
  html = html.replace(/&gt;/gi, ">");
  html = html.replace(/&#x2013;/gi, "-");
  html = html.replace(/&#x2019;/gi, "'");
  html = html.replace(/&#xA9;/gi, "Copyright");
  html = html.replace(/&reg;/gi, " (Registered)");
  
  return html;
};

export const hyphenateSearchQuery = (str: string) => {
  const searchRegExp = /\s/g
  const replaceWith = '+';
  return str.toLowerCase().replace(searchRegExp, replaceWith)
};

export const unhyphenateSearchQuery = (str: string) => {
  const searchRegExp = /\+/g
  const replaceWith = ' ';
  return str.toLowerCase().replace(searchRegExp, replaceWith)
};

export function getUSDPrice(prices) {
  let price = 0;
  for (let i = 0; i < prices?.length; i++) {
    const currentPrice = prices[i];
    if (currentPrice?.currency === "USD" && currentPrice?.type === "digital-list-price") {
      price = currentPrice?.value;
      break;
    }
  }
  return price;
}

export function getVariantIndexes(variants: BookVariantType[]) {
  const flag = -1;
  let indexes = {
    perpetual: flag,
    180: flag,
    120: flag,
    90: flag,
    60: flag,
    30: flag,
  };
  
  variants.forEach((variant, index) => {
    switch (variant?.duration) {
      case "365":
        indexes["365"] = index;
        break;
      case "180":
        indexes["180"] = index;
        break;
      case "120":
        indexes["120"] = index;
        break;
      case "90":
        indexes["90"] = index;
        break;
      case "60":
        indexes["60"] = index;
        break;
      case "30":
        indexes["30"] = index;
        break;
      case "perpetual":
        indexes.perpetual = index;
        break;
      default:
        break;
    }
  });
  
  return {
    flag,
    indexes
  }
}

export function sortVariants(variants: BookVariantType[]) {
  const sortedVariants = [];
  if (variants) {
    const {flag, indexes} = getVariantIndexes(variants);
    
    if (indexes.perpetual !== flag) sortedVariants.push(variants[indexes.perpetual]);
    if (indexes["180"] !== flag) sortedVariants.push(variants[indexes["180"]]);
    if (indexes["120"] !== flag) sortedVariants.push(variants[indexes["120"]]);
    if (indexes["90"] !== flag) sortedVariants.push(variants[indexes["90"]]);
    if (indexes["60"] !== flag) sortedVariants.push(variants[indexes["60"]]);
    if (indexes["30"] !== flag) sortedVariants.push(variants[indexes["30"]]);
  }
  return sortedVariants;
}

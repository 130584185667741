import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBGyGCNUbxyq9NsGhP7EVzzbW5K6sbn8XU",
  authDomain: "sharecaribbean.firebaseapp.com",
  projectId: "sharecaribbean",
  storageBucket: "sharecaribbean.appspot.com",
  messagingSenderId: "497460183802",
  appId: "1:497460183802:web:722b7fe81ebe991c3cbf43",
  measurementId: "G-1F0HK0W48R"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();

if (location.hostname === "localhost") {
  //auth.useEmulator("http://localhost:9099");
  //firestore.useEmulator("localhost", 8080);
  functions.useEmulator("localhost", 5001);
}

export {
  auth,
  firestore,
  functions,
  storage,
}
export default firebase;

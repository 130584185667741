import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

// Local
import Page from "../components/Page";
import BR from "../components/BR";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 792,
      margin: "auto",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  }),
);

const FAQ = () => {
  const classes = useStyles();
  return (
    <Page>
      <div className={classes.root}>
        <Typography variant="h4">Student ePortal FAQs</Typography>
        <br/>
        <br/>
        <Typography><strong>What is an eTextbook?</strong></Typography>
        <Typography>
          An eTextbook is a digital, downloadable version of a printed paper textbook. eTextbooks have become a more
          affordable and practical option over the heavy and more expensive printed textbooks. eTextbook readers have
          interactive features that allow for a more engaging reading experience than with print textbooks.
        </Typography>
        <br/>
        <br/>
        <Typography>
          <strong>How do I access my eTextbook after purchase?</strong>
        </Typography>
        <Typography>
          Your eTextbooks are available immediately to you following your purchase.
        </Typography>
        <BR/>
        <Typography>
          If you have purchased on studenteportal.com, you can access your books by clicking Read Now on the Order
          Confirmation page or by visiting the Bookshelf site. You may access your books through Bookshelf Online
          without downloading the Bookshelf application if you have an internet connection. If you wish to access your
          books offline, you will need to download the Bookshelf application.
        </Typography>
        <BR/>
        <Typography>
          Note that your login/account for studenteportal.com will be used to access your books in Bookshelf, you should
          not create a separate account.
        </Typography>
        <br/>
        <br/>
        <Typography>
          <strong>What devices can I read an eTextbook on?</strong>
        </Typography>
        <Typography>
          Read your textbooks on any computer, tablet or mobile phone. Bookshelf is compatible with both Macs and PCs as
          well as iOS, Android, Kindle Fire, and Chromebook devices.
        </Typography>
        <br/>
        <br/>
        <Typography>
          <strong>Can I access my eTextbook on more than one device?</strong>
        </Typography>
        <Typography>
          Yes. You will be able to download and access your eTextbook onto 2 computers AND 2 mobile devices using the
          Bookshelf app.
        </Typography>
        <br/>
        <br/>
        <Typography>
          <strong>Can I print my eTextbook?</strong>
        </Typography>
        <Typography>
          Restrictions around the use of copy & paste and printing are set by the publisher on a title by title basis.
          You can view the restrictions on the Product Details page for the title purchased.
        </Typography>
        <br/>
        <br/>
        <Typography>
          <strong>Do I have to be connected to the internet to read my eTextbook?</strong>
        </Typography>
        <Typography>
          No. You can access 100% of your eTextbooks without an internet connection. To access your eTextbooks offline,
          you will need to download the Bookshelf app onto your computer, tablet or mobile device. To download
          Bookshelf, visit our Apps page. (Add Link)
        </Typography>
        <br/>
        <br/>
        <Typography>
          <strong>What eTextbook formats are available?</strong>
        </Typography>
        <Typography>
          There are two different types of electronic textbooks sold in the Student ePortal store:
        </Typography>
        <Typography>
          Reflowable Text and Page-Fidelity.
        </Typography>
        <br/>
        <Typography>
          Reflowable Textbook (EPUB)
        </Typography>
        <Typography>
          Reflowable textbooks do not maintain the layout of a traditional bound book. The text is responsive, so the
          font size can be easily adjusted no matter the size of the screen. These books conform to EPUB 3 industry
          standards.
        </Typography>
        <br/>
        <Typography>
          Page-Fidelity Textbook (PDF)
        </Typography>
        <Typography>
          The Page-Fidelity textbook maintains the look and feel of a bound book—the text is fixed and the screen size
          determines the size of the text.
        </Typography>
        <br/>
        <Typography>
          Both types of eBooks support a wide range of features, including but not limited to: notes, highlights, text
          to speech, printing, syncing across devices, and more.
        </Typography>
        <br/>
        <Typography>
          Information about the book's format is available on the Product Details page for that title in the Student
          ePortal store. You may locate a title using our global search feature.
        </Typography>
        <br/>
        <br/>
        <Typography>
          <strong>Do I have to create an account to purchase an eTextbook?</strong>
        </Typography>
        <Typography>
          Your account credentials will be used to log into Bookshelf to access your purchased eTextbooks. If you choose
          to skip creating an account, you will need to return to the product page each time you wish to read your
          eTextbook. An account also allows you to access your eBook offline on one of our Mobile Bookshelf apps.
        </Typography>
        <br/>
        <br/>
        <Typography>
          <strong>Can I extend my eTextbook rental?</strong>
        </Typography>
        <Typography>
          Unfortunately, extensions are not available for eTextbook rentals. If you wish to have access to your
          eTextbook longer, you need to re-rent it by reordering the eTextbook.
        </Typography>
        <br/>
        <br/>
        <Typography>
          <strong>How do I redeem a code?</strong>
        </Typography>
        <Typography>
          Often, when purchasing a book from a third party, you will be provided with a 20-character registration code.
          These can be redeemed directly into Bookshelf Online. Please note that codes longer or shorter than 20
          characters are not valid redemption codes, and will need to be redeemed with the publisher/bookseller first.
        </Typography>
      </div>
    </Page>
  );
};

export default FAQ;
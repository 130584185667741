import React from "react";
import {Image} from "react-native";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSetRecoilState } from "recoil";

//
import { auth } from "../firebase";
import Copyright from "../components/Copyright";
import { userState } from "../store/atoms";

let SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required(),
});

async function resetPassword(email: string) {
  return auth.sendPasswordResetEmail(email.toLowerCase()).then(() => {
      return {
        status: "success",
        error: {
          code: "none",
          message: "none"
        }
      }
    })
    .catch(function (error) {
      // Handle Errors here.
      const errorCode: string = error.code;
      const errorMessage: string = error.message;
      switch (errorCode){
        case "auth/invalid-email":
          return {
            status: "failure",
            error: {
              code: errorCode,
              message: "The email address you entered is not valid."
            }
          }
        case "auth/user-not-found":
          return {
            status: "failure",
            error: {
              code: errorCode,
              message: "There is no user with the email address that you entered."
            }
          }
        default: return {
          status: "failure",
          error: {
            code: errorCode,
            message: errorMessage
          }
        }
      }
    });
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  progressContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  error: {
    color: theme.palette.error.main,
    marginTop: 2,
  },
  success: {
    color: theme.palette.success.main,
    marginTop: 2,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Link to="/">
          <Image style={{width: 80, height: 80, margin: 8}} source={require("../assets/icon.png")}/>
        </Link>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Typography variant="body2" align="center">
          An email will be sent to you with instructions on how to reset your password
        </Typography>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={SignupSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting }) => {
            resetPassword(values.email.trim()).then(
              response => {
                if (response.status === 'failure') {
                  setError(response.error.message);
                  setSubmitting(false);
                } else {
                  setSuccess("Reset password instructions sent to " + values.email)
                  setSubmitting(false);
                }
              }
            );
          }}
        >
          {({ errors, handleChange, handleSubmit, isSubmitting, values }) => (
            <form className={classes.form} onSubmit={handleSubmit}>
              {isSubmitting && (
                <Box className={classes.progressContainer}>
                  <LinearProgress />
                </Box>
              )}
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={values["email"]}
                onChange={handleChange}
              />
              <Typography className={classes.error} variant="body2">
                {errors["email"]}
              </Typography>
              <Button
                className={classes.submit}
                disabled={isSubmitting || !!success}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Reset
              </Button>
              <Typography className={classes.error} variant="body1">
                {error}
              </Typography>
              <Typography className={classes.success} variant="body1">
                {success}
              </Typography>
            </form>
          )}
        </Formik>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

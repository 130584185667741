import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { View, useWindowDimensions } from "react-native";
import { Searchbar } from "react-native-paper";
import { useNavigate } from "react-router-dom";

//
import { hyphenateSearchQuery } from "../utils";
import { Strings } from "../constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      //color: theme.palette.primary.dark,
      //color: "white",
      fontWeight: 600,
    },
    body: {
      //color: "white",
      //fontWeight: 300,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    button: {
      width: theme.spacing(13),
      alignSelf: "flex-end",
      marginTop: theme.spacing(2),
      //textTransform: "capitalize",
    },
  })
);

export default function SearchBox() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dimensions = useWindowDimensions();
  const [searchQuery, setSearchQuery] = React.useState("");

  const onChangeSearch = (query) => setSearchQuery(query);
  return (
    <View
      style={{
        width: dimensions.width > 480 ? "100%" : "90%",
        maxWidth: 560,
        height: "100%",
        maxHeight: dimensions.width > 480 ? 328 : 368,
        marginHorizontal: theme.spacing(2),
        padding: theme.spacing(4),
        borderRadius: theme.spacing(1),
        // @ts-ignore
        //background: `linear-gradient(0deg, ${theme.palette.primary.light}, ${theme.palette.primary.main}, ${theme.palette.primary.dark}`,
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        //opacity: 0.5,
      }}
    >
      <Typography className={classes.heading} variant="h5">
        Rent or buy eTextbooks and start studying in minutes
      </Typography>
      <Typography className={classes.body}>
        Read anytime, anywhere online or offline.
      </Typography>
      <Typography className={classes.body}>
        Shop our catalogue of 700,000+ titles, covering primary, secondary and tertiary levels.
      </Typography>
      <div style={{height: 8}}/>
      <Searchbar
        placeholder={Strings.searchPlaceholder}
        onChangeText={onChangeSearch}
        value={searchQuery}
        onSubmitEditing={() => {
          if (searchQuery.length > 0) {
            const query = hyphenateSearchQuery(searchQuery.trim());
            navigate("/search/" + query);
          }
        }}
      />
      <View style={{ width: "100%", alignItems: "flex-end" }}>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={() => {
            if (searchQuery.length > 0) {
              const query = hyphenateSearchQuery(searchQuery.trim());
              navigate("/search/" + query);
            }
          }}
        >
          Search
        </Button>
      </View>
    </View>
  );
}

import {atom} from 'recoil';
//
import {CartItemType, FirebaseUserType} from "../types"

const cartState = atom<CartItemType[]>({
  key: 'cartState',
  default: null
});

const userState = atom<FirebaseUserType>({
  key: 'userState',
  default: {
    uid: null,
    email: null,
    status: "USER_LOADING"
  }
});

export {
  cartState,
  userState
}

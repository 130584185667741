import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { capitalize } from "@material-ui/core";

//

interface Props {
  book: {
    title: string;
    duration: string;
    price: number;
  };
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review(props: Props) {
  const { book } = props;
  const classes = useStyles();
  const title = book.title;
  const duration = book.duration === "perpetual" ? "Lifetime" : "Rental duration: " + capitalize(book.duration) + " days";
  const total = Number(book.price).toFixed(2);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={title}
            secondary={duration}
          />
          <Typography variant="body2">
            {total}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            USD ${total}
          </Typography>
        </ListItem>
      </List>
    </React.Fragment>
  );
}

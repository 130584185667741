import * as React from "react";
import {Route, useNavigate} from "react-router-dom";
import {RouteProps} from "react-router";
import {useRecoilValue} from "recoil";
import LinearProgress from "@material-ui/core/LinearProgress";

//
import {userState} from "../store/atoms";

export default function AuthenticatingRoute({children, ...rest}: RouteProps) {
  const [initialLoad, setInitialLoad] = React.useState(true);
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  React.useEffect(()=> {
    if (initialLoad){
      if (user.status === "USER_LOGGED_IN") navigate("/");
      setInitialLoad(false);
    }
  }, [user, initialLoad]);
  if (initialLoad) return <LinearProgress/>;
  return (
    <Route {...rest}>
      {children}
    </Route>
  );
}
import React from "react";
import {Image, View} from "react-native";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";

// Local
import Page from "../components/Page";
import Subjects from "../data/subjects"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerPaper: {
      position: "relative",
      height: 200,
      margin: "auto",
      marginTop: 20,
      marginBottom: 80,
      [theme.breakpoints.up("md")]: {
        marginTop: 40,
        marginBottom: 40,
        height: 240,
      },
    },
    root: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
      //maxWidth: 600,
      margin: "auto",
      marginTop: 20,
      marginBottom: 80,
      [theme.breakpoints.up("md")]: {
        marginTop: 40,
        marginBottom: 40,
      },
      padding: theme.spacing(),
    },
    title: {
      color: "white",
      fontWeight: "bold",
    },
    hr: {
      width: 144,
      height: 4,
      backgroundColor: "#fff",
      marginTop: theme.spacing(3),
      borderBottomLeftRadius: 2,
      borderBottomRightRadius: 2,
    },
    item: {
      [theme.breakpoints.up("md")]: {
        marginTop: -8,
        marginBottom: -8,
      },
    },
    subject: {
      textTransform: "capitalize"
    }
  })
);

const Browse = () => {
  const classes = useStyles();
  const [segmentedSubjects, setSegmentedSubjects] = React.useState({
    column1: [],
    column2: [],
    column3: [],
  });
  
  React.useEffect(() => {
    if (segmentedSubjects.column1.length === 0){
      const threePartIndex = Math.ceil(Subjects.length / 3);
      const column1 = [];
      const column2 = [];
      const column3 = [];
      Subjects.forEach(subject => {
        if (column1.length < threePartIndex) {
          column1.push(subject);
        } else if (column2.length < threePartIndex) {
          column2.push(subject);
        } else {
          column3.push(subject);
        }
      })
  
      setSegmentedSubjects({
        column1,
        column2,
        column3,
      });
    }
  });
  
  return (
    <Page>
      <Paper className={classes.bannerPaper} variant="outlined">
        <Image
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 4
          }}
          source={require("../assets/browse.jpg")}
          resizeMode="cover"/>
        <View
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "black",
            opacity: 0.4,
            borderRadius: 4
          }}/>
        <View
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 4
          }}>
          <Typography className={classes.title} variant="h4" align="center">
            Browse Subjects
          </Typography>
          <div className={classes.hr}/>
        </View>
      </Paper>
      <Paper className={classes.root} variant="outlined">
        <Grid container>
          <Grid item xs={12} md={4}>
            {segmentedSubjects.column1.map((subject) => {
              const code = subject.shortcode.toLowerCase();
              const to = `/browse/${code}`;
              return (
                <List key={subject.shortcode}>
                  <ListItem className={classes.item} button component={Link} to={to}>
                    <ListItemText
                      primaryTypographyProps={{className: classes.subject}}
                      primary={subject.name.toLowerCase()}/>
                  </ListItem>
                </List>
              );
            })}
          </Grid>
          <Grid item xs={12} md={4}>
            {segmentedSubjects.column2.map((subject) => {
              const code = subject.shortcode.toLowerCase();
              const to = `/browse/${code}`;
              return (
                <List key={subject.shortcode}>
                  <ListItem className={classes.item} button component={Link} to={to}>
                    <ListItemText
                      primaryTypographyProps={{className: classes.subject}}
                      primary={subject.name.toLowerCase()}/>
                  </ListItem>
                </List>
              );
            })}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {segmentedSubjects.column3.map((subject) => {
              const code = subject.shortcode.toLowerCase();
              const to = `/browse/${code}`;
              return (
                <List key={subject.shortcode}>
                  <ListItem className={classes.item} button component={Link} to={to}>
                    <ListItemText
                      primaryTypographyProps={{className: classes.subject}}
                      primary={subject.name.toLowerCase()}/>
                  </ListItem>
                </List>
              );
            })}
          </Grid>
        </Grid>
      </Paper>
    </Page>
  );
};

export default Browse;

import React from "react";
import { Image } from "react-native";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { capitalize } from "@material-ui/core";
import { useDocumentOnce, useDocumentDataOnce } from "react-firebase-hooks/firestore";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useNavigate, useParams } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useRecoilValue } from "recoil";
import parseHtml from 'html-react-parser';

//
import Page from "../components/Page";
import { BookType } from "../types";
import { userState } from "../store/atoms";
import { firestore } from "../firebase";
import { getUSDPrice, getVariantIndexes, sortVariants } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 1024,
      margin: "auto",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(4),
    },
    overviewPaper: {
      flexGrow: 1,
    },
    selectionPaper: {
      flexGrow: 1,
      padding: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
      },
    },
    infoPaper: {
      flexGrow: 1,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
      },
    },
    infoContainer: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    heading: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(2),
      },
    },
    book: {},
    imageContainer: {
      backgroundColor: "#f2f2f2",
    },
    detailsContainer: {
      padding: theme.spacing(2),
    },
    title: {
      fontSize: 16,
      fontWeight: "bolder",
      letterSpacing: 0.25,
      color: theme.palette.common.black,
    },
    details: {
      fontSize: 14,
      lineHeight: 1.75,
      color: "#656565",
    },
    price: {
      fontSize: 32,
      fontWeight: 600,
      textAlign: "center",
      color: theme.palette.common.black,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    input: {
      width: "100%",
    },
    button: {
      width: "100%",
    },
  })
);

export default function Product() {
  const classes = useStyles();
  // @ts-ignore
  const smDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { vbid = "" } = useParams();
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const userBookRef = user?.uid
    ? firestore.collection("users").doc(user?.uid).collection("books").doc(vbid)
    : null;
  const bookRef = firestore.collection("books").doc(vbid);
  const [userBookSnapshot, loadingUserBook, userBookError] = useDocumentOnce(
    userBookRef
  );
  const [book, loadingBook, bookError] = useDocumentDataOnce<BookType>(bookRef);
  const [variant, setVariant] = React.useState(null);
  const [variants, setVariants] = React.useState([]);
  
  React.useEffect(()=> {
    if (book){
      const variants = sortVariants(book?.variants);
      setVariants(variants);
      const {flag, indexes} = getVariantIndexes(variants);
  
      if (indexes["180"] !== flag) setVariant(indexes["180"])
      else if (indexes["120"] !== flag) setVariant(indexes["120"])
      else if (indexes["90"] !== flag) setVariant(indexes["90"])
      else if (indexes["60"] !== flag) setVariant(indexes["60"])
      else if (indexes["30"] !== flag) setVariant(indexes["30"])
      else if (indexes.perpetual !== flag) setVariant(indexes.perpetual)
    }
  }, [loadingBook])
  
  React.useEffect(()=> console.log(variants[variant]), [variant])
  
  if (loadingUserBook || loadingBook || variant === null) return <LinearProgress />;
  //return <div />;
  return (
    <Page>
      <Typography className={classes.heading} variant="h6">
        {book ? book.title : "Sorry we don't have a book with that code"}
      </Typography>
      {book && (
        <Box className={classes.root}>
          <Grid container direction="row" spacing={smDown ? 0 : 4}>
            <Grid item xs={12} sm={8}>
              <Paper className={classes.overviewPaper} variant="outlined">
                <Grid container>
                  <Grid item xs={4}>
                    <Box className={classes.imageContainer}>
                      <Image
                        resizeMode="contain"
                        style={{ width: "100%", height: 252, margin: "auto" }}
                        source={{ uri: book.resource_links.cover_image }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box
                      className={classes.detailsContainer}
                      borderColor="#e5e5e5"
                    >
                      <Typography className={classes.title}>
                        {book.title}
                      </Typography>
                      <Typography className={classes.details} variant="body2">
                        by {book.authors}
                      </Typography>
                      <div style={{height: 8}}/>
                      {Number(book.edition) > 0 && <Typography className={classes.details} variant="body2">
                        Edition: {book.edition}
                      </Typography>}
                      <Typography className={classes.details} variant="body2">
                        Print ISBN: {book.identifiers.print_isbn_canonical}
                      </Typography>
                      <Typography className={classes.details} variant="body2">
                        eISBN: {book.identifiers.eisbn_canonical}
                      </Typography>
                      <Typography className={classes.details} variant="body2">
                        Format: {book?.format === "Page Fidelity" ? "PDF" : book?.format}
                      </Typography>
                      <Typography className={classes.details} variant="body2">
                        Publisher: {book.publisher}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.selectionPaper} variant="outlined">
                <TextField
                  className={classes.input}
                  variant="outlined"
                  select
                  label="License Duration"
                  value={capitalize(variants[variant]?.duration)}
                  helperText="Please select your duration"
                >
                  {variants?.map((option, index) => (
                    <MenuItem
                      key={index.toString()}
                      value={capitalize(option?.duration)}
                      onClick={() => {
                        setVariant(index);
                      }}
                    >
                      {option?.duration === "perpetual" ? "Lifetime" : capitalize(option?.duration) + " days"}
                    </MenuItem>
                  ))}
                </TextField>
                <Box className={classes.detailsContainer}>
                  <Typography className={classes.price}>
                    ${getUSDPrice(variants[variant].prices)} USD
                  </Typography>
                </Box>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (!!user?.uid) {
                      if (userBookSnapshot && userBookSnapshot.exist) {
                        navigate(`/bookshelf/${vbid}`);
                      } else {
                        navigate(`/checkout/${vbid}/${variant}`);
                      }
                    } else {
                      navigate(`/sign-up/product/${vbid}`);
                    }
                  }}
                >
                  {!!user?.uid
                    ? userBookSnapshot?.exists
                      ? "View on bookshelf"
                      : "Buy now"
                    : "Sign up to buy"}
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.infoPaper} variant="outlined">
                <Toolbar>
                  <Typography variant="h6">Details</Typography>
                </Toolbar>
                <Box className={classes.infoContainer}>
                  <Typography variant="body2">
                    {parseHtml(book?.description ?? "")}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
    </Page>
  );
}

import React from 'react';
import LinearProgress from "@material-ui/core/LinearProgress";

const Loader = () => {
  return (
    <LinearProgress/>
  );
};

export default Loader;

import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Image, View, useWindowDimensions} from "react-native";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Link} from "react-router-dom";

//
import Page from "../components/Page";
import SearchBox from "../components/SearchBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    ctaContainer: {
      minHeight: 200,
      maxWidth: 640,
      margin: "auto",
      padding: theme.spacing(10),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      marginBottom: theme.spacing(2)
    },
    text: {
      color: "#424242",
      marginBottom: theme.spacing(2)
    }
  }),
);

export default function Home() {
  const classes = useStyles();
  const dimensions = useWindowDimensions();
  // @ts-ignore
  const smDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <Page fullWidth={true}>
      <Box className={classes.root}>
        <View style={{flex: 1, minHeight: smDown ? dimensions.height/ 1.5 :  (dimensions.height)}}>
          <Image
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0
            }}
            source={require('../assets/girl.jpg')}/>
          <View
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              justifyContent: "center",
              alignItems: "center"
            }}>
          <View
            style={{
              position: "absolute",
              top: 80,
              left: 20,
              bottom: 0,
              right: 0,
            }}>
            <SearchBox/>
          </View>
          </View>
        </View>
        <Box
          className={classes.ctaContainer}
          borderTop={1}
          borderColor="#e5e5e5">
          <Typography className={classes.title} variant="h4" align="center">
            Have Questions?
          </Typography>
          <Typography className={classes.text} variant="body1" align="center">
            Are you interested in buying an eTextbook but want to know more? Check out our FAQ page to have any of your questions and queries answered.
          </Typography>
          <Button component={Link} to="/faq" variant="outlined" color="primary" size="large">
            Learn more
          </Button>
        </Box>
      </Box>
    </Page>
  );
}

import React from "react";
import {Image} from "react-native";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";
import {useSetRecoilState} from "recoil";

//
import {auth, firestore} from "../firebase";
import Copyright from "../components/Copyright";
import {userState} from "../store/atoms";
import {EndPoints, Strings} from "../constants";

let SignupSchema = Yup.object().shape({
  firstname: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required(),
  lastname: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required(),
  email: Yup.string().email("Invalid email").required(),
  password: Yup.string().min(7, "Too Short!").max(16, "Too Long!").required(),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  progressContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  error: {
    color: theme.palette.error.main,
    marginTop: 2,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const navigate = useNavigate();
  const {redirect, redirect1} = useParams();
  const setUser = useSetRecoilState(userState);
  const [signInError, setSignInError] = React.useState("");
  
  function _handleRedirect() {
    if (!!redirect1) {
      switch (redirect) {
        case "product":
          navigate(`/product/${redirect1}`);
          break;
        default:
          navigate("/");
      }
    } else {
      switch (redirect) {
        case "bookshelf":
          navigate("/bookshelf");
          break;
        case "checkout":
          navigate("/browse");
          break;
        default:
          navigate("/");
      }
    }
  }
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <Link to="/">
          <Image style={{width: 80, height: 80, margin: 8}} source={require("../assets/icon.png")}/>
        </Link>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Formik
          initialValues={{
            email: "",
            firstname: "",
            lastname: "",
            password: "",
          }}
          validationSchema={SignupSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, {setSubmitting}) => {
            auth
              .createUserWithEmailAndPassword(
                values.email.trim(),
                values.password.trim()
              )
              .then(({user}) => {
                // Signed in
                // ...
                setUser({
                  uid: null,
                  email: null,
                  status: "USER_LOADING",
                });
                if (user.uid) {
                  const userRef = firestore.collection("users").doc(user.uid);
                  userRef
                    .set({
                      firstname: values.firstname,
                      lastname: values.lastname,
                      name: `${values.firstname} ${values.lastname}`,
                      email: values.email,
                      guid: null,
                      token: null,
                    })
                    .then(() => {
                      const body = JSON.stringify({
                        apiKey: Strings.apiKey,
                        uid: user.uid,
                        firstname: values.firstname,
                        lastname: values.lastname,
                      });
                      fetch(EndPoints.getVitalSourceCredentials, {
                        method: "POST",
                        body,
                      })
                        .then((response: any) => {
                          console.log({response});
                          //return response.text();
                          setSubmitting(false);
                          _handleRedirect();
                        })
                        /*.then((result: any) => { // This was for if we wanted to use the token or guid
                          console.log({result: JSON.parse(result)});
                        })*/
                        .catch((err: any) => {
                          console.log(err)
                        });
                    })
                    .catch(() => {
                      setSubmitting(false);
                    });
                }
              })
              .catch((error) => {
                console.log({error})
                const errorCode = error.code;
                switch (errorCode) {
                  case "auth/wrong-password":
                    setSignInError("The password you entered does not match your email address")
                    break;
                  case "auth/too-many-requests":
                    setSignInError("Too many attempts have been made from this device, please try again later")
                    break;
                  case "auth/user-disabled":
                    setSignInError("This account has been disabled, please contact support")
                    break;
                  case "auth/network-request-failed":
                    setSignInError("Your network connection was disconnected, please try again")
                    break;
                  default:
                    setSignInError("Something went wrong while trying to sign in, please try again later")
                }
                setSubmitting(false);
              });
          }}
        >
          {({errors, handleChange, handleSubmit, isSubmitting, values}) => (
            <form className={classes.form} onSubmit={handleSubmit}>
              {isSubmitting && (
                <Box className={classes.progressContainer}>
                  <LinearProgress/>
                </Box>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={isSubmitting}
                    autoComplete="fname"
                    name="firstname"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstname"
                    label="First Name"
                    autoFocus
                    value={values["firstname"]}
                    onChange={handleChange}
                  />
                  <Typography className={classes.error} variant="body2">
                    {errors["firstname"]}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={isSubmitting}
                    variant="outlined"
                    required
                    fullWidth
                    id="lastname"
                    label="Last Name"
                    name="lastname"
                    autoComplete="lname"
                    value={values["lastname"]}
                    onChange={handleChange}
                  />
                  <Typography className={classes.error} variant="body2">
                    {errors["lastname"]}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={isSubmitting}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={values["email"]}
                    onChange={handleChange}
                  />
                  <Typography className={classes.error} variant="body2">
                    {errors["email"]}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={isSubmitting}
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={values["password"]}
                    onChange={handleChange}
                  />
                  <Typography className={classes.error} variant="body2">
                    {errors["password"]}
                  </Typography>
                </Grid>
              </Grid>
              <Button
                className={classes.submit}
                disabled={isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Sign Up
              </Button>
              <Typography className={classes.error} variant="body1">
                {signInError}
              </Typography>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link
                    style={{textDecoration: "none"}}
                    to={
                      redirect
                        ? redirect1
                        ? `/sign-in/${redirect}/${redirect1}`
                        : `/sign-in/${redirect}`
                        : "/sign-in"
                    }
                  >
                    <Typography variant="body2">
                      Already have an account? Sign in
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
      <Box mt={5}>
        <Copyright/>
      </Box>
    </Container>
  );
}

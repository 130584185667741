import React from "react";
import {Image} from "react-native";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";

//
import {BookType} from "../types";
import {getUSDPrice, getVariantIndexes, sortVariants} from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 1024,
      margin: "auto",
      backgroundColor: theme.palette.common.white,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(10),
      },
    },
    heading: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(2),
      },
    },
    grid: {
      width: "100%",
    },
    book: {
      height: "100%"
    },
    imageContainer: {
      backgroundColor: "#f2f2f2",
    },
    detailsContainer: {
      padding: theme.spacing(2),
    },
    title: {
      fontSize: 15,
      fontWeight: "bolder",
      letterSpacing: 0.25,
      color: theme.palette.common.black,
    },
    details: {
      fontSize: 14,
      lineHeight: 1.5,
      color: "#717171",
    },
    price: {
      fontSize: 16,
      color: theme.palette.common.black,
      marginTop: theme.spacing(1),
    },
    noResultsContainer: {
      padding: theme.spacing(4),
    },
  })
);

interface Props {
  docs: any[];
  noItemsMsg?: string;
}

function getDisplayVariant(book) {
  const variants = sortVariants(book?.variants);
  const {flag, indexes} = getVariantIndexes(variants);
  let displayVariant;
  if (indexes["180"] !== flag) displayVariant = variants[indexes["180"]];
  else if (indexes["120"] !== flag) displayVariant = variants[indexes["120"]];
  else if (indexes["90"] !== flag) displayVariant = variants[indexes["90"]];
  else if (indexes["60"] !== flag) displayVariant = variants[indexes["60"]];
  else if (indexes["30"] !== flag) displayVariant = variants[indexes["30"]];
  else if (indexes.perpetual !== flag) displayVariant = variants[indexes.perpetual];
  else displayVariant = variants[indexes["180"]];
  return displayVariant;
}

export default function BookGrid({docs, noItemsMsg}: Props) {
  const classes = useStyles();
  //console.log(docs.slice(0, 10))
  //return null
  return (
    <Paper className={classes.root} variant="outlined">
      {docs?.length > 0 ? (
        <div>
          <Grid className={classes.grid} container direction="row">
            {docs?.map((doc, index) => {
              // TODO: Find out why one of the books being returned is null
              if (doc === null) return null
              const book: BookType = doc;
              let displayVariant = getDisplayVariant(book);
              return (
                <Grid item xs={6} md={4} lg={3} key={index.toString()}>
                  {/* @ts-ignore */}
                  <Link
                    style={{textDecoration: "none"}}
                    to={"/product/" + book?.vbid}
                  >
                    <Box
                      className={classes.book}
                      border={0.5}
                      borderBottom={0}
                      borderColor="#e5e5e5"
                    >
                      <Box className={classes.imageContainer}>
                        <Image
                          resizeMode="contain"
                          style={{width: "100%", height: 228, margin: "auto"}}
                          source={{uri: book?.resource_links?.cover_image}}
                        />
                      </Box>
                      <Box
                        className={classes.detailsContainer}
                        borderTop={0.5}
                        borderColor="#e5e5e5"
                      >
                        <Typography className={classes.title}>
                          {book?.title}
                        </Typography>
                        <Typography className={classes.details} variant="body2">
                          by {book?.authors}
                        </Typography>
                        {Number(book?.edition) > 0 && <Typography className={classes.details} variant="body2">
                          Edition: {book?.edition}
                        </Typography>}
                        <Typography className={classes.price}>
                          ${getUSDPrice(displayVariant?.prices)}
                        </Typography>
                        <Typography className={classes.details} variant="body2">
                          {displayVariant?.duration === "perpetual" ? "" : "Rent for " + displayVariant?.duration + " days" + (book?.variants?.length === 1 ? "" : " (More options)")}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        <Box className={classes.noResultsContainer}>
          <Typography variant="h6">
            {noItemsMsg ?? "Results not found"}
          </Typography>
        </Box>
      )}
    </Paper>
  );
}

import React from 'react';
import {Image, StyleSheet} from "react-native";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useRecoilValue} from "recoil";

// Local
import Page from "../components/Page";
import BR from "../components/BR";
import {userState} from "../store/atoms";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 792,
      margin: "auto",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
    caption: {
      color: "#424242"
    }
  }),
);

const Redeem = () => {
  const classes = useStyles();
  const user = useRecoilValue(userState);
  // @ts-ignore
  const smDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  
  const styles = StyleSheet.create({
    images: {
      height: smDown ? 240 : 400,
      width: "100%",
      maxWidth: 600,
      //marginTop: 16,
      //marginBottom: 16,
    }
  });
  return (
    <Page>
      <div className={classes.root}>
        <Typography variant="h4">Redeeming Codes</Typography>
        <BR/>
        <Typography>
          Student ePortal codes purchased from 3rd party retailers can be redeemed directly through Bookshelf Online.
        </Typography>
        <br/>
        <Typography><strong>Step 1</strong></Typography>
        <Typography>
          Go to <Link to={user.status === "USER_LOGGED_IN" ? "/bookshelf" : "/sign-up/bookshelf"}>Bookshelf</Link>.
        </Typography>
        <br/>
        <Typography><strong>Step 2</strong></Typography>
        <Typography>
          <Link to="/sign-in">Log in</Link>, or <Link to="/sign-up">sign up</Link> if you do not already have an
          account.
        </Typography>
        <Image style={styles.images} resizeMode="contain" source={require("../assets/redeem1.png")}/>
        <Image style={styles.images} resizeMode="contain" source={require("../assets/redeem2.png")}/>
        <br/>
        <Typography><strong>Step 3</strong></Typography>
        <Typography>
          Click on Redeem Codes.
        </Typography>
        <br/>
        <Image style={styles.images} resizeMode="contain" source={require("../assets/redeem3.png")}/>
        <br/>
        <Typography>
          Enter your code/codes. Your book(s) will appear in your Bookshelf account.
        </Typography>
        <br/>
        <Image style={styles.images} resizeMode="contain" source={require("../assets/redeem4.png")}/>
        <br/>
        <Typography><strong>Step 4</strong></Typography>
        <Typography>
          Download our Bookshelf Mobile app for offline access to your books.
        </Typography>
        <br/>
        <Typography className={classes.caption} variant="caption">
          *Bookshelf Online is available for one year from the date of redemption. You will need to download the mobile
          app to retain access to your books beyond that point.
        </Typography>
      </div>
    </Page>
  );
};

export default Redeem;

export default [
  {
    "Code": "ANT000000",
    "Literal": "ANTIQUES & COLLECTIBLES / General"
  },
  {
    "Code": "ANT056000",
    "Literal": "ANTIQUES & COLLECTIBLES / Advertising"
  },
  {
    "Code": "ANT001000",
    "Literal": "ANTIQUES & COLLECTIBLES / Americana"
  },
  {
    "Code": "ANT002000",
    "Literal": "ANTIQUES & COLLECTIBLES / Art"
  },
  {
    "Code": "ANT003000",
    "Literal": "ANTIQUES & COLLECTIBLES / Autographs"
  },
  {
    "Code": "ANT005000",
    "Literal": "ANTIQUES & COLLECTIBLES / Books"
  },
  {
    "Code": "ANT006000",
    "Literal": "ANTIQUES & COLLECTIBLES / Bottles"
  },
  {
    "Code": "ANT007000",
    "Literal": "ANTIQUES & COLLECTIBLES / Buttons & Pins"
  },
  {
    "Code": "ANT054000",
    "Literal": "ANTIQUES & COLLECTIBLES / Canadiana"
  },
  {
    "Code": "ANT008000",
    "Literal": "ANTIQUES & COLLECTIBLES / Care & Restoration"
  },
  {
    "Code": "ANT010000",
    "Literal": "ANTIQUES & COLLECTIBLES / Clocks & Watches"
  },
  {
    "Code": "ANT011000",
    "Literal": "ANTIQUES & COLLECTIBLES / Coins, Currency & Medals"
  },
  {
    "Code": "ANT012000",
    "Literal": "ANTIQUES & COLLECTIBLES / Comics"
  },
  {
    "Code": "ANT015000",
    "Literal": "ANTIQUES & COLLECTIBLES / Dolls"
  },
  {
    "Code": "ANT053000",
    "Literal": "ANTIQUES & COLLECTIBLES / Figurines"
  },
  {
    "Code": "ANT016000",
    "Literal": "ANTIQUES & COLLECTIBLES / Firearms & Weapons"
  },
  {
    "Code": "ANT017000",
    "Literal": "ANTIQUES & COLLECTIBLES / Furniture"
  },
  {
    "Code": "ANT018000",
    "Literal": "ANTIQUES & COLLECTIBLES / Glass & Glassware"
  },
  {
    "Code": "ANT021000",
    "Literal": "ANTIQUES & COLLECTIBLES / Jewelry"
  },
  {
    "Code": "ANT022000",
    "Literal": "ANTIQUES & COLLECTIBLES / Kitchenware"
  },
  {
    "Code": "ANT023000",
    "Literal": "ANTIQUES & COLLECTIBLES / Magazines & Newspapers"
  },
  {
    "Code": "ANT024000",
    "Literal": "ANTIQUES & COLLECTIBLES / Military"
  },
  {
    "Code": "ANT029000",
    "Literal": "ANTIQUES & COLLECTIBLES / Paper Ephemera"
  },
  {
    "Code": "ANT025000",
    "Literal": "ANTIQUES & COLLECTIBLES / Performing Arts"
  },
  {
    "Code": "ANT031000",
    "Literal": "ANTIQUES & COLLECTIBLES / Political"
  },
  {
    "Code": "ANT052000",
    "Literal": "ANTIQUES & COLLECTIBLES / Popular Culture"
  },
  {
    "Code": "ANT032000",
    "Literal": "ANTIQUES & COLLECTIBLES / Porcelain & China"
  },
  {
    "Code": "ANT033000",
    "Literal": "ANTIQUES & COLLECTIBLES / Postcards"
  },
  {
    "Code": "ANT034000",
    "Literal": "ANTIQUES & COLLECTIBLES / Posters"
  },
  {
    "Code": "ANT035000",
    "Literal": "ANTIQUES & COLLECTIBLES / Pottery & Ceramics"
  },
  {
    "Code": "ANT036000",
    "Literal": "ANTIQUES & COLLECTIBLES / Radios & Televisions (see also Performing Arts)"
  },
  {
    "Code": "ANT037000",
    "Literal": "ANTIQUES & COLLECTIBLES / Records"
  },
  {
    "Code": "ANT038000",
    "Literal": "ANTIQUES & COLLECTIBLES / Reference"
  },
  {
    "Code": "ANT040000",
    "Literal": "ANTIQUES & COLLECTIBLES / Rugs"
  },
  {
    "Code": "ANT041000",
    "Literal": "ANTIQUES & COLLECTIBLES / Silver, Gold & Other Metals"
  },
  {
    "Code": "ANT043000",
    "Literal": "ANTIQUES & COLLECTIBLES / Sports (see also headings under Sports Cards)"
  },
  {
    "Code": "ANT042000",
    "Literal": "ANTIQUES & COLLECTIBLES / Sports Cards / General"
  },
  {
    "Code": "ANT042010",
    "Literal": "ANTIQUES & COLLECTIBLES / Sports Cards / Baseball"
  },
  {
    "Code": "ANT044000",
    "Literal": "ANTIQUES & COLLECTIBLES / Stamps"
  },
  {
    "Code": "ANT045000",
    "Literal": "ANTIQUES & COLLECTIBLES / Teddy Bears & Stuffed Animals"
  },
  {
    "Code": "ANT047000",
    "Literal": "ANTIQUES & COLLECTIBLES / Textiles & Costume"
  },
  {
    "Code": "ANT055000",
    "Literal": "ANTIQUES & COLLECTIBLES / Tobacco-Related"
  },
  {
    "Code": "ANT050000",
    "Literal": "ANTIQUES & COLLECTIBLES / Toys"
  },
  {
    "Code": "ANT009000",
    "Literal": "ANTIQUES & COLLECTIBLES / Transportation"
  },
  {
    "Code": "ANT051000",
    "Literal": "ANTIQUES & COLLECTIBLES / Wine"
  },
  {
    "Code": "ARC000000",
    "Literal": "ARCHITECTURE / General"
  },
  {
    "Code": "ARC022000",
    "Literal": "ARCHITECTURE / Adaptive Reuse & Renovation"
  },
  {
    "Code": "ARC023000",
    "Literal": "ARCHITECTURE / Annuals"
  },
  {
    "Code": "ARC024000",
    "Literal": "ARCHITECTURE / Buildings / General"
  },
  {
    "Code": "ARC024010",
    "Literal": "ARCHITECTURE / Buildings / Landmarks & Monuments"
  },
  {
    "Code": "ARC011000",
    "Literal": "ARCHITECTURE / Buildings / Public, Commercial & Industrial"
  },
  {
    "Code": "ARC016000",
    "Literal": "ARCHITECTURE / Buildings / Religious"
  },
  {
    "Code": "ARC003000",
    "Literal": "ARCHITECTURE / Buildings / Residential"
  },
  {
    "Code": "ARC019000",
    "Literal": "ARCHITECTURE / Codes & Standards"
  },
  {
    "Code": "ARC001000",
    "Literal": "ARCHITECTURE / Criticism"
  },
  {
    "Code": "ARC002000",
    "Literal": "ARCHITECTURE / Decoration & Ornament"
  },
  {
    "Code": "ARC004000",
    "Literal": "ARCHITECTURE / Design, Drafting, Drawing & Presentation"
  },
  {
    "Code": "ARC014000",
    "Literal": "ARCHITECTURE / Historic Preservation / General"
  },
  {
    "Code": "ARC014010",
    "Literal": "ARCHITECTURE / Historic Preservation / Restoration Techniques"
  },
  {
    "Code": "ARC005000",
    "Literal": "ARCHITECTURE / History / General"
  },
  {
    "Code": "ARC005010",
    "Literal": "ARCHITECTURE / History / Prehistoric & Primitive"
  },
  {
    "Code": "ARC005020",
    "Literal": "ARCHITECTURE / History / Ancient & Classical"
  },
  {
    "Code": "ARC005030",
    "Literal": "ARCHITECTURE / History / Medieval"
  },
  {
    "Code": "ARC005040",
    "Literal": "ARCHITECTURE / History / Renaissance"
  },
  {
    "Code": "ARC005050",
    "Literal": "ARCHITECTURE / History / Baroque & Rococo"
  },
  {
    "Code": "ARC005060",
    "Literal": "ARCHITECTURE / History / Romanticism"
  },
  {
    "Code": "ARC005070",
    "Literal": "ARCHITECTURE / History / Modern (late 19th Century to 1945)"
  },
  {
    "Code": "ARC005080",
    "Literal": "ARCHITECTURE / History / Contemporary (1945-)"
  },
  {
    "Code": "ARC006000",
    "Literal": "ARCHITECTURE / Individual Architects & Firms / General"
  },
  {
    "Code": "ARC006010",
    "Literal": "ARCHITECTURE / Individual Architects & Firms / Essays"
  },
  {
    "Code": "ARC006020",
    "Literal": "ARCHITECTURE / Individual Architects & Firms / Monographs"
  },
  {
    "Code": "ARC007000",
    "Literal": "ARCHITECTURE / Interior Design / General"
  },
  {
    "Code": "ARC007010",
    "Literal": "ARCHITECTURE / Interior Design / Lighting"
  },
  {
    "Code": "ARC008000",
    "Literal": "ARCHITECTURE / Landscape"
  },
  {
    "Code": "ARC009000",
    "Literal": "ARCHITECTURE / Methods & Materials"
  },
  {
    "Code": "ARC015000",
    "Literal": "ARCHITECTURE / Professional Practice"
  },
  {
    "Code": "ARC017000",
    "Literal": "ARCHITECTURE / Project Management"
  },
  {
    "Code": "ARC012000",
    "Literal": "ARCHITECTURE / Reference"
  },
  {
    "Code": "ARC020000",
    "Literal": "ARCHITECTURE / Regional"
  },
  {
    "Code": "ARC021000",
    "Literal": "ARCHITECTURE / Security Design"
  },
  {
    "Code": "ARC013000",
    "Literal": "ARCHITECTURE / Study & Teaching"
  },
  {
    "Code": "ARC018000",
    "Literal": "ARCHITECTURE / Sustainability & Green Design"
  },
  {
    "Code": "ARC010000",
    "Literal": "ARCHITECTURE / Urban & Land Use Planning"
  },
  {
    "Code": "ARC025000",
    "Literal": "ARCHITECTURE / Vernacular"
  },
  {
    "Code": "ART000000",
    "Literal": "ART / General"
  },
  {
    "Code": "ART015010",
    "Literal": "ART / African"
  },
  {
    "Code": "ART015020",
    "Literal": "ART / American / General"
  },
  {
    "Code": "ART038000",
    "Literal": "ART / American / African American"
  },
  {
    "Code": "ART039000",
    "Literal": "ART / American / Asian American"
  },
  {
    "Code": "ART040000",
    "Literal": "ART / American / Hispanic American"
  },
  {
    "Code": "ART054000",
    "Literal": "ART / Annuals"
  },
  {
    "Code": "ART037000",
    "Literal": "ART / Art & Politics"
  },
  {
    "Code": "ART019000",
    "Literal": "ART / Asian / General"
  },
  {
    "Code": "ART019010",
    "Literal": "ART / Asian / Chinese"
  },
  {
    "Code": "ART019020",
    "Literal": "ART / Asian / Indian & South Asian"
  },
  {
    "Code": "ART019030",
    "Literal": "ART / Asian / Japanese"
  },
  {
    "Code": "ART042000",
    "Literal": "ART / Australian & Oceanian"
  },
  {
    "Code": "ART055000",
    "Literal": "ART / Body Art & Tattooing"
  },
  {
    "Code": "ART043000",
    "Literal": "ART / Business Aspects"
  },
  {
    "Code": "ART015040",
    "Literal": "ART / Canadian"
  },
  {
    "Code": "ART044000",
    "Literal": "ART / Caribbean & Latin American"
  },
  {
    "Code": "ART045000",
    "Literal": "ART / Ceramics"
  },
  {
    "Code": "ART006000",
    "Literal": "ART / Collections, Catalogs, Exhibitions / General"
  },
  {
    "Code": "ART006010",
    "Literal": "ART / Collections, Catalogs, Exhibitions / Group Shows"
  },
  {
    "Code": "ART006020",
    "Literal": "ART / Collections, Catalogs, Exhibitions / Permanent Collections"
  },
  {
    "Code": "ART007000",
    "Literal": "ART / Color Theory"
  },
  {
    "Code": "ART008000",
    "Literal": "ART / Conceptual"
  },
  {
    "Code": "ART056000",
    "Literal": "ART / Conservation & Preservation"
  },
  {
    "Code": "ART009000",
    "Literal": "ART / Criticism & Theory"
  },
  {
    "Code": "ART046000",
    "Literal": "ART / Digital"
  },
  {
    "Code": "ART063000",
    "Literal": "ART / Environmental & Land Art"
  },
  {
    "Code": "ART015030",
    "Literal": "ART / European"
  },
  {
    "Code": "ART057000",
    "Literal": "ART / Film & Video"
  },
  {
    "Code": "ART013000",
    "Literal": "ART / Folk & Outsider Art"
  },
  {
    "Code": "ART061000",
    "Literal": "ART / Glass"
  },
  {
    "Code": "ART058000",
    "Literal": "ART / Graffiti & Street Art"
  },
  {
    "Code": "ART015000",
    "Literal": "ART / History / General"
  },
  {
    "Code": "ART015050",
    "Literal": "ART / History / Prehistoric & Primitive"
  },
  {
    "Code": "ART015060",
    "Literal": "ART / History / Ancient & Classical"
  },
  {
    "Code": "ART015070",
    "Literal": "ART / History / Medieval"
  },
  {
    "Code": "ART015080",
    "Literal": "ART / History / Renaissance"
  },
  {
    "Code": "ART015090",
    "Literal": "ART / History / Baroque & Rococo"
  },
  {
    "Code": "ART015120",
    "Literal": "ART / History / Romanticism"
  },
  {
    "Code": "ART015100",
    "Literal": "ART / History / Modern (late 19th Century to 1945)"
  },
  {
    "Code": "ART015110",
    "Literal": "ART / History / Contemporary (1945-)"
  },
  {
    "Code": "ART016000",
    "Literal": "ART / Individual Artists / General"
  },
  {
    "Code": "ART016010",
    "Literal": "ART / Individual Artists / Artists' Books"
  },
  {
    "Code": "ART016020",
    "Literal": "ART / Individual Artists / Essays"
  },
  {
    "Code": "ART016030",
    "Literal": "ART / Individual Artists / Monographs"
  },
  {
    "Code": "ART047000",
    "Literal": "ART / Middle Eastern"
  },
  {
    "Code": "ART017000",
    "Literal": "ART / Mixed Media"
  },
  {
    "Code": "ART059000",
    "Literal": "ART / Museum Studies"
  },
  {
    "Code": "ART041000",
    "Literal": "ART / Native American"
  },
  {
    "Code": "ART060000",
    "Literal": "ART / Performance"
  },
  {
    "Code": "ART023000",
    "Literal": "ART / Popular Culture"
  },
  {
    "Code": "ART048000",
    "Literal": "ART / Prints"
  },
  {
    "Code": "ART062000",
    "Literal": "ART / Public Art"
  },
  {
    "Code": "ART025000",
    "Literal": "ART / Reference"
  },
  {
    "Code": "ART049000",
    "Literal": "ART / Russian & Former Soviet Union"
  },
  {
    "Code": "ART026000",
    "Literal": "ART / Sculpture & Installation"
  },
  {
    "Code": "ART027000",
    "Literal": "ART / Study & Teaching"
  },
  {
    "Code": "ART050000",
    "Literal": "ART / Subjects & Themes / General"
  },
  {
    "Code": "ART050050",
    "Literal": "ART / Subjects & Themes / Erotica"
  },
  {
    "Code": "ART050010",
    "Literal": "ART / Subjects & Themes / Human Figure"
  },
  {
    "Code": "ART050020",
    "Literal": "ART / Subjects & Themes / Landscapes & Seascapes"
  },
  {
    "Code": "ART050030",
    "Literal": "ART / Subjects & Themes / Plants & Animals"
  },
  {
    "Code": "ART050040",
    "Literal": "ART / Subjects & Themes / Portraits"
  },
  {
    "Code": "ART035000",
    "Literal": "ART / Subjects & Themes / Religious"
  },
  {
    "Code": "ART050060",
    "Literal": "ART / Subjects & Themes / Science Fiction & Fantasy"
  },
  {
    "Code": "ART028000",
    "Literal": "ART / Techniques / General"
  },
  {
    "Code": "ART031000",
    "Literal": "ART / Techniques / Acrylic Painting"
  },
  {
    "Code": "ART002000",
    "Literal": "ART / Techniques / Airbrush"
  },
  {
    "Code": "ART003000",
    "Literal": "ART / Techniques / Calligraphy"
  },
  {
    "Code": "ART004000",
    "Literal": "ART / Techniques / Cartooning"
  },
  {
    "Code": "ART051000",
    "Literal": "ART / Techniques / Color"
  },
  {
    "Code": "ART010000",
    "Literal": "ART / Techniques / Drawing"
  },
  {
    "Code": "ART052000",
    "Literal": "ART / Techniques / Life Drawing"
  },
  {
    "Code": "ART018000",
    "Literal": "ART / Techniques / Oil Painting"
  },
  {
    "Code": "ART020000",
    "Literal": "ART / Techniques / Painting"
  },
  {
    "Code": "ART021000",
    "Literal": "ART / Techniques / Pastel Drawing"
  },
  {
    "Code": "ART033000",
    "Literal": "ART / Techniques / Pen & Ink Drawing"
  },
  {
    "Code": "ART034000",
    "Literal": "ART / Techniques / Pencil Drawing"
  },
  {
    "Code": "ART024000",
    "Literal": "ART / Techniques / Printmaking"
  },
  {
    "Code": "ART053000",
    "Literal": "ART / Techniques / Sculpting"
  },
  {
    "Code": "ART029000",
    "Literal": "ART / Techniques / Watercolor Painting"
  },
  {
    "Code": "ART064000",
    "Literal": "ART / Video Game Art"
  },
  {
    "Code": "ART065000",
    "Literal": "ART / Women Artists"
  },
  {
    "Code": "BIB000000",
    "Literal": "BIBLES / General"
  },
  {
    "Code": "BIB001000",
    "Literal": "BIBLES / Christian Standard Bible / General"
  },
  {
    "Code": "BIB001010",
    "Literal": "BIBLES / Christian Standard Bible / Children"
  },
  {
    "Code": "BIB001020",
    "Literal": "BIBLES / Christian Standard Bible / Devotional"
  },
  {
    "Code": "BIB001080",
    "Literal": "BIBLES / Christian Standard Bible / Dramatized"
  },
  {
    "Code": "BIB001090",
    "Literal": "BIBLES / Christian Standard Bible / Journaling"
  },
  {
    "Code": "BIB001030",
    "Literal": "BIBLES / Christian Standard Bible / New Testament & Portions"
  },
  {
    "Code": "BIB001100",
    "Literal": "BIBLES / Christian Standard Bible / Outreach"
  },
  {
    "Code": "BIB001110",
    "Literal": "BIBLES / Christian Standard Bible / Reading"
  },
  {
    "Code": "BIB001040",
    "Literal": "BIBLES / Christian Standard Bible / Reference"
  },
  {
    "Code": "BIB001050",
    "Literal": "BIBLES / Christian Standard Bible / Study"
  },
  {
    "Code": "BIB001060",
    "Literal": "BIBLES / Christian Standard Bible / Text"
  },
  {
    "Code": "BIB001070",
    "Literal": "BIBLES / Christian Standard Bible / Youth & Teen"
  },
  {
    "Code": "BIB022000",
    "Literal": "BIBLES / Common English Bible / General"
  },
  {
    "Code": "BIB022010",
    "Literal": "BIBLES / Common English Bible / Children"
  },
  {
    "Code": "BIB022020",
    "Literal": "BIBLES / Common English Bible / Devotional"
  },
  {
    "Code": "BIB022080",
    "Literal": "BIBLES / Common English Bible / Dramatized"
  },
  {
    "Code": "BIB022090",
    "Literal": "BIBLES / Common English Bible / Journaling"
  },
  {
    "Code": "BIB022030",
    "Literal": "BIBLES / Common English Bible / New Testament & Portions"
  },
  {
    "Code": "BIB022100",
    "Literal": "BIBLES / Common English Bible / Outreach"
  },
  {
    "Code": "BIB022110",
    "Literal": "BIBLES / Common English Bible / Reading"
  },
  {
    "Code": "BIB022040",
    "Literal": "BIBLES / Common English Bible / Reference"
  },
  {
    "Code": "BIB022050",
    "Literal": "BIBLES / Common English Bible / Study"
  },
  {
    "Code": "BIB022060",
    "Literal": "BIBLES / Common English Bible / Text"
  },
  {
    "Code": "BIB022070",
    "Literal": "BIBLES / Common English Bible / Youth & Teen"
  },
  {
    "Code": "BIB002000",
    "Literal": "BIBLES / Contemporary English Version / General"
  },
  {
    "Code": "BIB002010",
    "Literal": "BIBLES / Contemporary English Version / Children"
  },
  {
    "Code": "BIB002020",
    "Literal": "BIBLES / Contemporary English Version / Devotional"
  },
  {
    "Code": "BIB002080",
    "Literal": "BIBLES / Contemporary English Version / Dramatized"
  },
  {
    "Code": "BIB002090",
    "Literal": "BIBLES / Contemporary English Version / Journaling"
  },
  {
    "Code": "BIB002030",
    "Literal": "BIBLES / Contemporary English Version / New Testament & Portions"
  },
  {
    "Code": "BIB002100",
    "Literal": "BIBLES / Contemporary English Version / Outreach"
  },
  {
    "Code": "BIB002110",
    "Literal": "BIBLES / Contemporary English Version / Reading"
  },
  {
    "Code": "BIB002040",
    "Literal": "BIBLES / Contemporary English Version / Reference"
  },
  {
    "Code": "BIB002050",
    "Literal": "BIBLES / Contemporary English Version / Study"
  },
  {
    "Code": "BIB002060",
    "Literal": "BIBLES / Contemporary English Version / Text"
  },
  {
    "Code": "BIB002070",
    "Literal": "BIBLES / Contemporary English Version / Youth & Teen"
  },
  {
    "Code": "BIB003000",
    "Literal": "BIBLES / English Standard Version / General"
  },
  {
    "Code": "BIB003010",
    "Literal": "BIBLES / English Standard Version / Children"
  },
  {
    "Code": "BIB003020",
    "Literal": "BIBLES / English Standard Version / Devotional"
  },
  {
    "Code": "BIB003080",
    "Literal": "BIBLES / English Standard Version / Dramatized"
  },
  {
    "Code": "BIB003090",
    "Literal": "BIBLES / English Standard Version / Journaling"
  },
  {
    "Code": "BIB003030",
    "Literal": "BIBLES / English Standard Version / New Testament & Portions"
  },
  {
    "Code": "BIB003100",
    "Literal": "BIBLES / English Standard Version / Outreach"
  },
  {
    "Code": "BIB003110",
    "Literal": "BIBLES / English Standard Version / Reading"
  },
  {
    "Code": "BIB003040",
    "Literal": "BIBLES / English Standard Version / Reference"
  },
  {
    "Code": "BIB003050",
    "Literal": "BIBLES / English Standard Version / Study"
  },
  {
    "Code": "BIB003060",
    "Literal": "BIBLES / English Standard Version / Text"
  },
  {
    "Code": "BIB003070",
    "Literal": "BIBLES / English Standard Version / Youth & Teen"
  },
  {
    "Code": "BIB004000",
    "Literal": "BIBLES / God's Word / General"
  },
  {
    "Code": "BIB004010",
    "Literal": "BIBLES / God's Word / Children"
  },
  {
    "Code": "BIB004020",
    "Literal": "BIBLES / God's Word / Devotional"
  },
  {
    "Code": "BIB004080",
    "Literal": "BIBLES / God's Word / Dramatized"
  },
  {
    "Code": "BIB004090",
    "Literal": "BIBLES / God's Word / Journaling"
  },
  {
    "Code": "BIB004030",
    "Literal": "BIBLES / God's Word / New Testament & Portions"
  },
  {
    "Code": "BIB004100",
    "Literal": "BIBLES / God's Word / Outreach"
  },
  {
    "Code": "BIB004110",
    "Literal": "BIBLES / God's Word / Reading"
  },
  {
    "Code": "BIB004040",
    "Literal": "BIBLES / God's Word / Reference"
  },
  {
    "Code": "BIB004050",
    "Literal": "BIBLES / God's Word / Study"
  },
  {
    "Code": "BIB004060",
    "Literal": "BIBLES / God's Word / Text"
  },
  {
    "Code": "BIB004070",
    "Literal": "BIBLES / God's Word / Youth & Teen"
  },
  {
    "Code": "BIB005000",
    "Literal": "BIBLES / International Children's Bible / General"
  },
  {
    "Code": "BIB005010",
    "Literal": "BIBLES / International Children's Bible / Children"
  },
  {
    "Code": "BIB005020",
    "Literal": "BIBLES / International Children's Bible / Devotional"
  },
  {
    "Code": "BIB005080",
    "Literal": "BIBLES / International Children's Bible / Dramatized"
  },
  {
    "Code": "BIB005090",
    "Literal": "BIBLES / International Children's Bible / Journaling"
  },
  {
    "Code": "BIB005030",
    "Literal": "BIBLES / International Children's Bible / New Testament & Portions"
  },
  {
    "Code": "BIB005100",
    "Literal": "BIBLES / International Children's Bible / Outreach"
  },
  {
    "Code": "BIB005110",
    "Literal": "BIBLES / International Children's Bible / Reading"
  },
  {
    "Code": "BIB005040",
    "Literal": "BIBLES / International Children's Bible / Reference"
  },
  {
    "Code": "BIB005050",
    "Literal": "BIBLES / International Children's Bible / Study"
  },
  {
    "Code": "BIB005060",
    "Literal": "BIBLES / International Children's Bible / Text"
  },
  {
    "Code": "BIB005070",
    "Literal": "BIBLES / International Children's Bible / Youth & Teen"
  },
  {
    "Code": "BIB006000",
    "Literal": "BIBLES / King James Version / General"
  },
  {
    "Code": "BIB006010",
    "Literal": "BIBLES / King James Version / Children"
  },
  {
    "Code": "BIB006020",
    "Literal": "BIBLES / King James Version / Devotional"
  },
  {
    "Code": "BIB006080",
    "Literal": "BIBLES / King James Version / Dramatized"
  },
  {
    "Code": "BIB006090",
    "Literal": "BIBLES / King James Version / Journaling"
  },
  {
    "Code": "BIB006030",
    "Literal": "BIBLES / King James Version / New Testament & Portions"
  },
  {
    "Code": "BIB006100",
    "Literal": "BIBLES / King James Version / Outreach"
  },
  {
    "Code": "BIB006110",
    "Literal": "BIBLES / King James Version / Reading"
  },
  {
    "Code": "BIB006040",
    "Literal": "BIBLES / King James Version / Reference"
  },
  {
    "Code": "BIB006050",
    "Literal": "BIBLES / King James Version / Study"
  },
  {
    "Code": "BIB006060",
    "Literal": "BIBLES / King James Version / Text"
  },
  {
    "Code": "BIB006070",
    "Literal": "BIBLES / King James Version / Youth & Teen"
  },
  {
    "Code": "BIB007000",
    "Literal": "BIBLES / La Biblia de las Americas / General"
  },
  {
    "Code": "BIB007010",
    "Literal": "BIBLES / La Biblia de las Americas / Children"
  },
  {
    "Code": "BIB007020",
    "Literal": "BIBLES / La Biblia de las Americas / Devotional"
  },
  {
    "Code": "BIB007080",
    "Literal": "BIBLES / La Biblia de las Americas / Dramatized"
  },
  {
    "Code": "BIB007090",
    "Literal": "BIBLES / La Biblia de las Americas / Journaling"
  },
  {
    "Code": "BIB007030",
    "Literal": "BIBLES / La Biblia de las Americas / New Testament & Portions"
  },
  {
    "Code": "BIB007100",
    "Literal": "BIBLES / La Biblia de las Americas / Outreach"
  },
  {
    "Code": "BIB007110",
    "Literal": "BIBLES / La Biblia de las Americas / Reading"
  },
  {
    "Code": "BIB007040",
    "Literal": "BIBLES / La Biblia de las Americas / Reference"
  },
  {
    "Code": "BIB007050",
    "Literal": "BIBLES / La Biblia de las Americas / Study"
  },
  {
    "Code": "BIB007060",
    "Literal": "BIBLES / La Biblia de las Americas / Text"
  },
  {
    "Code": "BIB007070",
    "Literal": "BIBLES / La Biblia de las Americas / Youth & Teen"
  },
  {
    "Code": "BIB008000",
    "Literal": "BIBLES / Multiple Translations / General"
  },
  {
    "Code": "BIB008010",
    "Literal": "BIBLES / Multiple Translations / Children"
  },
  {
    "Code": "BIB008020",
    "Literal": "BIBLES / Multiple Translations / Devotional"
  },
  {
    "Code": "BIB008080",
    "Literal": "BIBLES / Multiple Translations / Dramatized"
  },
  {
    "Code": "BIB008090",
    "Literal": "BIBLES / Multiple Translations / Journaling"
  },
  {
    "Code": "BIB008030",
    "Literal": "BIBLES / Multiple Translations / New Testament & Portions"
  },
  {
    "Code": "BIB008100",
    "Literal": "BIBLES / Multiple Translations / Outreach"
  },
  {
    "Code": "BIB008110",
    "Literal": "BIBLES / Multiple Translations / Reading"
  },
  {
    "Code": "BIB008040",
    "Literal": "BIBLES / Multiple Translations / Reference"
  },
  {
    "Code": "BIB008050",
    "Literal": "BIBLES / Multiple Translations / Study"
  },
  {
    "Code": "BIB008060",
    "Literal": "BIBLES / Multiple Translations / Text"
  },
  {
    "Code": "BIB008070",
    "Literal": "BIBLES / Multiple Translations / Youth & Teen"
  },
  {
    "Code": "BIB009000",
    "Literal": "BIBLES / New American Bible / General"
  },
  {
    "Code": "BIB009010",
    "Literal": "BIBLES / New American Bible / Children"
  },
  {
    "Code": "BIB009020",
    "Literal": "BIBLES / New American Bible / Devotional"
  },
  {
    "Code": "BIB009080",
    "Literal": "BIBLES / New American Bible / Dramatized"
  },
  {
    "Code": "BIB009090",
    "Literal": "BIBLES / New American Bible / Journaling"
  },
  {
    "Code": "BIB009030",
    "Literal": "BIBLES / New American Bible / New Testament & Portions"
  },
  {
    "Code": "BIB009100",
    "Literal": "BIBLES / New American Bible / Outreach"
  },
  {
    "Code": "BIB009110",
    "Literal": "BIBLES / New American Bible / Reading"
  },
  {
    "Code": "BIB009040",
    "Literal": "BIBLES / New American Bible / Reference"
  },
  {
    "Code": "BIB009050",
    "Literal": "BIBLES / New American Bible / Study"
  },
  {
    "Code": "BIB009060",
    "Literal": "BIBLES / New American Bible / Text"
  },
  {
    "Code": "BIB009070",
    "Literal": "BIBLES / New American Bible / Youth & Teen"
  },
  {
    "Code": "BIB010000",
    "Literal": "BIBLES / New American Standard Bible / General"
  },
  {
    "Code": "BIB010010",
    "Literal": "BIBLES / New American Standard Bible / Children"
  },
  {
    "Code": "BIB010020",
    "Literal": "BIBLES / New American Standard Bible / Devotional"
  },
  {
    "Code": "BIB010080",
    "Literal": "BIBLES / New American Standard Bible / Dramatized"
  },
  {
    "Code": "BIB010090",
    "Literal": "BIBLES / New American Standard Bible / Journaling"
  },
  {
    "Code": "BIB010030",
    "Literal": "BIBLES / New American Standard Bible / New Testament & Portions"
  },
  {
    "Code": "BIB010100",
    "Literal": "BIBLES / New American Standard Bible / Outreach"
  },
  {
    "Code": "BIB010110",
    "Literal": "BIBLES / New American Standard Bible / Reading"
  },
  {
    "Code": "BIB010040",
    "Literal": "BIBLES / New American Standard Bible / Reference"
  },
  {
    "Code": "BIB010050",
    "Literal": "BIBLES / New American Standard Bible / Study"
  },
  {
    "Code": "BIB010060",
    "Literal": "BIBLES / New American Standard Bible / Text"
  },
  {
    "Code": "BIB010070",
    "Literal": "BIBLES / New American Standard Bible / Youth & Teen"
  },
  {
    "Code": "BIB011000",
    "Literal": "BIBLES / New Century Version / General"
  },
  {
    "Code": "BIB011010",
    "Literal": "BIBLES / New Century Version / Children"
  },
  {
    "Code": "BIB011020",
    "Literal": "BIBLES / New Century Version / Devotional"
  },
  {
    "Code": "BIB011080",
    "Literal": "BIBLES / New Century Version / Dramatized"
  },
  {
    "Code": "BIB011090",
    "Literal": "BIBLES / New Century Version / Journaling"
  },
  {
    "Code": "BIB011030",
    "Literal": "BIBLES / New Century Version / New Testament & Portions"
  },
  {
    "Code": "BIB011100",
    "Literal": "BIBLES / New Century Version / Outreach"
  },
  {
    "Code": "BIB011110",
    "Literal": "BIBLES / New Century Version / Reading"
  },
  {
    "Code": "BIB011040",
    "Literal": "BIBLES / New Century Version / Reference"
  },
  {
    "Code": "BIB011050",
    "Literal": "BIBLES / New Century Version / Study"
  },
  {
    "Code": "BIB011060",
    "Literal": "BIBLES / New Century Version / Text"
  },
  {
    "Code": "BIB011070",
    "Literal": "BIBLES / New Century Version / Youth & Teen"
  },
  {
    "Code": "BIB012000",
    "Literal": "BIBLES / New International Reader's Version / General"
  },
  {
    "Code": "BIB012010",
    "Literal": "BIBLES / New International Reader's Version / Children"
  },
  {
    "Code": "BIB012020",
    "Literal": "BIBLES / New International Reader's Version / Devotional"
  },
  {
    "Code": "BIB012080",
    "Literal": "BIBLES / New International Reader's Version / Dramatized"
  },
  {
    "Code": "BIB012090",
    "Literal": "BIBLES / New International Reader's Version / Journaling"
  },
  {
    "Code": "BIB012030",
    "Literal": "BIBLES / New International Reader's Version / New Testament & Portions"
  },
  {
    "Code": "BIB012100",
    "Literal": "BIBLES / New International Reader's Version / Outreach"
  },
  {
    "Code": "BIB012110",
    "Literal": "BIBLES / New International Reader's Version / Reading"
  },
  {
    "Code": "BIB012040",
    "Literal": "BIBLES / New International Reader's Version / Reference"
  },
  {
    "Code": "BIB012050",
    "Literal": "BIBLES / New International Reader's Version / Study"
  },
  {
    "Code": "BIB012060",
    "Literal": "BIBLES / New International Reader's Version / Text"
  },
  {
    "Code": "BIB012070",
    "Literal": "BIBLES / New International Reader's Version / Youth & Teen"
  },
  {
    "Code": "BIB013000",
    "Literal": "BIBLES / New International Version / General"
  },
  {
    "Code": "BIB013010",
    "Literal": "BIBLES / New International Version / Children"
  },
  {
    "Code": "BIB013020",
    "Literal": "BIBLES / New International Version / Devotional"
  },
  {
    "Code": "BIB013080",
    "Literal": "BIBLES / New International Version / Dramatized"
  },
  {
    "Code": "BIB013090",
    "Literal": "BIBLES / New International Version / Journaling"
  },
  {
    "Code": "BIB013030",
    "Literal": "BIBLES / New International Version / New Testament & Portions"
  },
  {
    "Code": "BIB013100",
    "Literal": "BIBLES / New International Version / Outreach"
  },
  {
    "Code": "BIB013110",
    "Literal": "BIBLES / New International Version / Reading"
  },
  {
    "Code": "BIB013040",
    "Literal": "BIBLES / New International Version / Reference"
  },
  {
    "Code": "BIB013050",
    "Literal": "BIBLES / New International Version / Study"
  },
  {
    "Code": "BIB013060",
    "Literal": "BIBLES / New International Version / Text"
  },
  {
    "Code": "BIB013070",
    "Literal": "BIBLES / New International Version / Youth & Teen"
  },
  {
    "Code": "BIB014000",
    "Literal": "BIBLES / New King James Version / General"
  },
  {
    "Code": "BIB014010",
    "Literal": "BIBLES / New King James Version / Children"
  },
  {
    "Code": "BIB014020",
    "Literal": "BIBLES / New King James Version / Devotional"
  },
  {
    "Code": "BIB014080",
    "Literal": "BIBLES / New King James Version / Dramatized"
  },
  {
    "Code": "BIB014090",
    "Literal": "BIBLES / New King James Version / Journaling"
  },
  {
    "Code": "BIB014030",
    "Literal": "BIBLES / New King James Version / New Testament & Portions"
  },
  {
    "Code": "BIB014100",
    "Literal": "BIBLES / New King James Version / Outreach"
  },
  {
    "Code": "BIB014110",
    "Literal": "BIBLES / New King James Version / Reading"
  },
  {
    "Code": "BIB014040",
    "Literal": "BIBLES / New King James Version / Reference"
  },
  {
    "Code": "BIB014050",
    "Literal": "BIBLES / New King James Version / Study"
  },
  {
    "Code": "BIB014060",
    "Literal": "BIBLES / New King James Version / Text"
  },
  {
    "Code": "BIB014070",
    "Literal": "BIBLES / New King James Version / Youth & Teen"
  },
  {
    "Code": "BIB015000",
    "Literal": "BIBLES / New Living Translation / General"
  },
  {
    "Code": "BIB015010",
    "Literal": "BIBLES / New Living Translation / Children"
  },
  {
    "Code": "BIB015020",
    "Literal": "BIBLES / New Living Translation / Devotional"
  },
  {
    "Code": "BIB015080",
    "Literal": "BIBLES / New Living Translation / Dramatized"
  },
  {
    "Code": "BIB015090",
    "Literal": "BIBLES / New Living Translation / Journaling"
  },
  {
    "Code": "BIB015030",
    "Literal": "BIBLES / New Living Translation / New Testament & Portions"
  },
  {
    "Code": "BIB015100",
    "Literal": "BIBLES / New Living Translation / Outreach"
  },
  {
    "Code": "BIB015110",
    "Literal": "BIBLES / New Living Translation / Reading"
  },
  {
    "Code": "BIB015040",
    "Literal": "BIBLES / New Living Translation / Reference"
  },
  {
    "Code": "BIB015050",
    "Literal": "BIBLES / New Living Translation / Study"
  },
  {
    "Code": "BIB015060",
    "Literal": "BIBLES / New Living Translation / Text"
  },
  {
    "Code": "BIB015070",
    "Literal": "BIBLES / New Living Translation / Youth & Teen"
  },
  {
    "Code": "BIB016000",
    "Literal": "BIBLES / New Revised Standard Version / General"
  },
  {
    "Code": "BIB016010",
    "Literal": "BIBLES / New Revised Standard Version / Children"
  },
  {
    "Code": "BIB016020",
    "Literal": "BIBLES / New Revised Standard Version / Devotional"
  },
  {
    "Code": "BIB016080",
    "Literal": "BIBLES / New Revised Standard Version / Dramatized"
  },
  {
    "Code": "BIB016090",
    "Literal": "BIBLES / New Revised Standard Version / Journaling"
  },
  {
    "Code": "BIB016030",
    "Literal": "BIBLES / New Revised Standard Version / New Testament & Portions"
  },
  {
    "Code": "BIB016100",
    "Literal": "BIBLES / New Revised Standard Version / Outreach"
  },
  {
    "Code": "BIB016110",
    "Literal": "BIBLES / New Revised Standard Version / Reading"
  },
  {
    "Code": "BIB016040",
    "Literal": "BIBLES / New Revised Standard Version / Reference"
  },
  {
    "Code": "BIB016050",
    "Literal": "BIBLES / New Revised Standard Version / Study"
  },
  {
    "Code": "BIB016060",
    "Literal": "BIBLES / New Revised Standard Version / Text"
  },
  {
    "Code": "BIB016070",
    "Literal": "BIBLES / New Revised Standard Version / Youth & Teen"
  },
  {
    "Code": "BIB024000",
    "Literal": "BIBLES / Nueva Traduccion Viviente / General"
  },
  {
    "Code": "BIB024010",
    "Literal": "BIBLES / Nueva Traduccion Viviente / Children"
  },
  {
    "Code": "BIB024020",
    "Literal": "BIBLES / Nueva Traduccion Viviente / Devotional"
  },
  {
    "Code": "BIB024030",
    "Literal": "BIBLES / Nueva Traduccion Viviente / Dramatized"
  },
  {
    "Code": "BIB024040",
    "Literal": "BIBLES / Nueva Traduccion Viviente / Journaling"
  },
  {
    "Code": "BIB024050",
    "Literal": "BIBLES / Nueva Traduccion Viviente / New Testament & Portions"
  },
  {
    "Code": "BIB024060",
    "Literal": "BIBLES / Nueva Traduccion Viviente / Outreach"
  },
  {
    "Code": "BIB024070",
    "Literal": "BIBLES / Nueva Traduccion Viviente / Reading"
  },
  {
    "Code": "BIB024080",
    "Literal": "BIBLES / Nueva Traduccion Viviente / Reference"
  },
  {
    "Code": "BIB024090",
    "Literal": "BIBLES / Nueva Traduccion Viviente / Study"
  },
  {
    "Code": "BIB024100",
    "Literal": "BIBLES / Nueva Traduccion Viviente / Text"
  },
  {
    "Code": "BIB024110",
    "Literal": "BIBLES / Nueva Traduccion Viviente / Youth & Teen"
  },
  {
    "Code": "BIB017000",
    "Literal": "BIBLES / Nueva Version International / General"
  },
  {
    "Code": "BIB017010",
    "Literal": "BIBLES / Nueva Version International / Children"
  },
  {
    "Code": "BIB017020",
    "Literal": "BIBLES / Nueva Version International / Devotional"
  },
  {
    "Code": "BIB017080",
    "Literal": "BIBLES / Nueva Version International / Dramatized"
  },
  {
    "Code": "BIB017090",
    "Literal": "BIBLES / Nueva Version International / Journaling"
  },
  {
    "Code": "BIB017030",
    "Literal": "BIBLES / Nueva Version International / New Testament & Portions"
  },
  {
    "Code": "BIB017100",
    "Literal": "BIBLES / Nueva Version International / Outreach"
  },
  {
    "Code": "BIB017110",
    "Literal": "BIBLES / Nueva Version International / Reading"
  },
  {
    "Code": "BIB017040",
    "Literal": "BIBLES / Nueva Version International / Reference"
  },
  {
    "Code": "BIB017050",
    "Literal": "BIBLES / Nueva Version International / Study"
  },
  {
    "Code": "BIB017060",
    "Literal": "BIBLES / Nueva Version International / Text"
  },
  {
    "Code": "BIB017070",
    "Literal": "BIBLES / Nueva Version International / Youth & Teen"
  },
  {
    "Code": "BIB018000",
    "Literal": "BIBLES / Other English Translations / General"
  },
  {
    "Code": "BIB018010",
    "Literal": "BIBLES / Other English Translations / Children"
  },
  {
    "Code": "BIB018020",
    "Literal": "BIBLES / Other English Translations / Devotional"
  },
  {
    "Code": "BIB018080",
    "Literal": "BIBLES / Other English Translations / Dramatized"
  },
  {
    "Code": "BIB018090",
    "Literal": "BIBLES / Other English Translations / Journaling"
  },
  {
    "Code": "BIB018030",
    "Literal": "BIBLES / Other English Translations / New Testament & Portions"
  },
  {
    "Code": "BIB018100",
    "Literal": "BIBLES / Other English Translations / Outreach"
  },
  {
    "Code": "BIB018110",
    "Literal": "BIBLES / Other English Translations / Reading"
  },
  {
    "Code": "BIB018040",
    "Literal": "BIBLES / Other English Translations / Reference"
  },
  {
    "Code": "BIB018050",
    "Literal": "BIBLES / Other English Translations / Study"
  },
  {
    "Code": "BIB018060",
    "Literal": "BIBLES / Other English Translations / Text"
  },
  {
    "Code": "BIB018070",
    "Literal": "BIBLES / Other English Translations / Youth & Teen"
  },
  {
    "Code": "BIB025000",
    "Literal": "BIBLES / Other Non-English Translations / General"
  },
  {
    "Code": "BIB025010",
    "Literal": "BIBLES / Other Non-English Translations / Children"
  },
  {
    "Code": "BIB025020",
    "Literal": "BIBLES / Other Non-English Translations / Devotional"
  },
  {
    "Code": "BIB025030",
    "Literal": "BIBLES / Other Non-English Translations / Dramatized"
  },
  {
    "Code": "BIB025040",
    "Literal": "BIBLES / Other Non-English Translations / Journaling"
  },
  {
    "Code": "BIB025050",
    "Literal": "BIBLES / Other Non-English Translations / New Testament & Portions"
  },
  {
    "Code": "BIB025060",
    "Literal": "BIBLES / Other Non-English Translations / Outreach"
  },
  {
    "Code": "BIB025070",
    "Literal": "BIBLES / Other Non-English Translations / Reading"
  },
  {
    "Code": "BIB025080",
    "Literal": "BIBLES / Other Non-English Translations / Reference"
  },
  {
    "Code": "BIB025090",
    "Literal": "BIBLES / Other Non-English Translations / Study"
  },
  {
    "Code": "BIB025100",
    "Literal": "BIBLES / Other Non-English Translations / Text"
  },
  {
    "Code": "BIB025110",
    "Literal": "BIBLES / Other Non-English Translations / Youth & Teen"
  },
  {
    "Code": "BIB019000",
    "Literal": "BIBLES / Reina Valera / General"
  },
  {
    "Code": "BIB019010",
    "Literal": "BIBLES / Reina Valera / Children"
  },
  {
    "Code": "BIB019020",
    "Literal": "BIBLES / Reina Valera / Devotional"
  },
  {
    "Code": "BIB019080",
    "Literal": "BIBLES / Reina Valera / Dramatized"
  },
  {
    "Code": "BIB019090",
    "Literal": "BIBLES / Reina Valera / Journaling"
  },
  {
    "Code": "BIB019030",
    "Literal": "BIBLES / Reina Valera / New Testament & Portions"
  },
  {
    "Code": "BIB019100",
    "Literal": "BIBLES / Reina Valera / Outreach"
  },
  {
    "Code": "BIB019110",
    "Literal": "BIBLES / Reina Valera / Reading"
  },
  {
    "Code": "BIB019040",
    "Literal": "BIBLES / Reina Valera / Reference"
  },
  {
    "Code": "BIB019050",
    "Literal": "BIBLES / Reina Valera / Study"
  },
  {
    "Code": "BIB019060",
    "Literal": "BIBLES / Reina Valera / Text"
  },
  {
    "Code": "BIB019070",
    "Literal": "BIBLES / Reina Valera / Youth & Teen"
  },
  {
    "Code": "BIB023000",
    "Literal": "BIBLES / The Amplified Bible / General"
  },
  {
    "Code": "BIB023010",
    "Literal": "BIBLES / The Amplified Bible / Children"
  },
  {
    "Code": "BIB023020",
    "Literal": "BIBLES / The Amplified Bible / Devotional"
  },
  {
    "Code": "BIB023080",
    "Literal": "BIBLES / The Amplified Bible / Dramatized"
  },
  {
    "Code": "BIB023090",
    "Literal": "BIBLES / The Amplified Bible / Journaling"
  },
  {
    "Code": "BIB023030",
    "Literal": "BIBLES / The Amplified Bible / New Testament & Portions"
  },
  {
    "Code": "BIB023100",
    "Literal": "BIBLES / The Amplified Bible / Outreach"
  },
  {
    "Code": "BIB023110",
    "Literal": "BIBLES / The Amplified Bible / Reading"
  },
  {
    "Code": "BIB023040",
    "Literal": "BIBLES / The Amplified Bible / Reference"
  },
  {
    "Code": "BIB023050",
    "Literal": "BIBLES / The Amplified Bible / Study"
  },
  {
    "Code": "BIB023060",
    "Literal": "BIBLES / The Amplified Bible / Text"
  },
  {
    "Code": "BIB023070",
    "Literal": "BIBLES / The Amplified Bible / Youth & Teen"
  },
  {
    "Code": "BIB020000",
    "Literal": "BIBLES / The Message / General"
  },
  {
    "Code": "BIB020010",
    "Literal": "BIBLES / The Message / Children"
  },
  {
    "Code": "BIB020020",
    "Literal": "BIBLES / The Message / Devotional"
  },
  {
    "Code": "BIB020080",
    "Literal": "BIBLES / The Message / Dramatized"
  },
  {
    "Code": "BIB020090",
    "Literal": "BIBLES / The Message / Journaling"
  },
  {
    "Code": "BIB020030",
    "Literal": "BIBLES / The Message / New Testament & Portions"
  },
  {
    "Code": "BIB020100",
    "Literal": "BIBLES / The Message / Outreach"
  },
  {
    "Code": "BIB020110",
    "Literal": "BIBLES / The Message / Reading"
  },
  {
    "Code": "BIB020040",
    "Literal": "BIBLES / The Message / Reference"
  },
  {
    "Code": "BIB020050",
    "Literal": "BIBLES / The Message / Study"
  },
  {
    "Code": "BIB020060",
    "Literal": "BIBLES / The Message / Text"
  },
  {
    "Code": "BIB020070",
    "Literal": "BIBLES / The Message / Youth & Teen"
  },
  {
    "Code": "BIO000000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / General"
  },
  {
    "Code": "BIO023000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Adventurers & Explorers"
  },
  {
    "Code": "BIO001000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Artists, Architects, Photographers"
  },
  {
    "Code": "BIO034000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Aviation & Nautical"
  },
  {
    "Code": "BIO003000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Business"
  },
  {
    "Code": "BIO024000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Criminals & Outlaws"
  },
  {
    "Code": "BIO029000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Culinary"
  },
  {
    "Code": "BIO002000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Cultural, Ethnic & Regional / General"
  },
  {
    "Code": "BIO002010",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Cultural, Ethnic & Regional / African American & Black"
  },
  {
    "Code": "BIO002020",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Cultural, Ethnic & Regional / Asian & Asian American"
  },
  {
    "Code": "BIO002030",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Cultural, Ethnic & Regional / Hispanic & Latino"
  },
  {
    "Code": "BIO028000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Cultural, Ethnic & Regional / Native American & Aboriginal"
  },
  {
    "Code": "BIO025000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Editors, Journalists, Publishers"
  },
  {
    "Code": "BIO019000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Educators"
  },
  {
    "Code": "BIO005000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Entertainment & Performing Arts"
  },
  {
    "Code": "BIO030000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Environmentalists & Naturalists"
  },
  {
    "Code": "BIO035000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Fashion"
  },
  {
    "Code": "BIO036000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Fire & Emergency Services"
  },
  {
    "Code": "BIO006000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Historical"
  },
  {
    "Code": "BIO037000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Jewish"
  },
  {
    "Code": "BIO027000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Law Enforcement"
  },
  {
    "Code": "BIO020000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Lawyers & Judges"
  },
  {
    "Code": "BIO031000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / LGBT"
  },
  {
    "Code": "BIO007000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Literary Figures"
  },
  {
    "Code": "BIO017000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Medical (incl. Patients)"
  },
  {
    "Code": "BIO008000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Military"
  },
  {
    "Code": "BIO004000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Music"
  },
  {
    "Code": "BIO033000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / People with Disabilities"
  },
  {
    "Code": "BIO026000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Personal Memoirs"
  },
  {
    "Code": "BIO009000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Philosophers"
  },
  {
    "Code": "BIO010000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Political"
  },
  {
    "Code": "BIO011000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Presidents & Heads of State"
  },
  {
    "Code": "BIO012000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Reference"
  },
  {
    "Code": "BIO018000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Religious"
  },
  {
    "Code": "BIO013000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Rich & Famous"
  },
  {
    "Code": "BIO014000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Royalty"
  },
  {
    "Code": "BIO015000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Science & Technology"
  },
  {
    "Code": "BIO032000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Social Activists"
  },
  {
    "Code": "BIO021000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Social Scientists & Psychologists"
  },
  {
    "Code": "BIO016000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Sports"
  },
  {
    "Code": "BIO038000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Survival"
  },
  {
    "Code": "BIO022000",
    "Literal": "BIOGRAPHY & AUTOBIOGRAPHY / Women"
  },
  {
    "Code": "OCC000000",
    "Literal": "BODY, MIND & SPIRIT / General"
  },
  {
    "Code": "OCC022000",
    "Literal": "BODY, MIND & SPIRIT / Afterlife & Reincarnation"
  },
  {
    "Code": "OCC042000",
    "Literal": "BODY, MIND & SPIRIT / Alchemy"
  },
  {
    "Code": "OCC031000",
    "Literal": "BODY, MIND & SPIRIT / Ancient Mysteries & Controversial Knowledge"
  },
  {
    "Code": "OCC032000",
    "Literal": "BODY, MIND & SPIRIT / Angels & Spirit Guides"
  },
  {
    "Code": "OCC002000",
    "Literal": "BODY, MIND & SPIRIT / Astrology / General"
  },
  {
    "Code": "OCC030000",
    "Literal": "BODY, MIND & SPIRIT / Astrology / Eastern"
  },
  {
    "Code": "OCC009000",
    "Literal": "BODY, MIND & SPIRIT / Astrology / Horoscopes"
  },
  {
    "Code": "OCC036010",
    "Literal": "BODY, MIND & SPIRIT / Celtic Spirituality"
  },
  {
    "Code": "OCC003000",
    "Literal": "BODY, MIND & SPIRIT / Channeling & Mediumship"
  },
  {
    "Code": "OCC004000",
    "Literal": "BODY, MIND & SPIRIT / Crystals"
  },
  {
    "Code": "OCC005000",
    "Literal": "BODY, MIND & SPIRIT / Divination / General"
  },
  {
    "Code": "OCC008000",
    "Literal": "BODY, MIND & SPIRIT / Divination / Fortune Telling"
  },
  {
    "Code": "OCC017000",
    "Literal": "BODY, MIND & SPIRIT / Divination / Palmistry"
  },
  {
    "Code": "OCC024000",
    "Literal": "BODY, MIND & SPIRIT / Divination / Tarot"
  },
  {
    "Code": "OCC006000",
    "Literal": "BODY, MIND & SPIRIT / Dreams"
  },
  {
    "Code": "OCC039000",
    "Literal": "BODY, MIND & SPIRIT / Entheogens & Visionary Substances"
  },
  {
    "Code": "OCC037000",
    "Literal": "BODY, MIND & SPIRIT / Feng Shui"
  },
  {
    "Code": "OCC033000",
    "Literal": "BODY, MIND & SPIRIT / Gaia & Earth Energies"
  },
  {
    "Code": "OCC036050",
    "Literal": "BODY, MIND & SPIRIT / Goddess Worship"
  },
  {
    "Code": "OCC011000",
    "Literal": "BODY, MIND & SPIRIT / Healing / General"
  },
  {
    "Code": "OCC011010",
    "Literal": "BODY, MIND & SPIRIT / Healing / Energy (Qigong, Reiki, Polarity)"
  },
  {
    "Code": "OCC011020",
    "Literal": "BODY, MIND & SPIRIT / Healing / Prayer & Spiritual"
  },
  {
    "Code": "OCC040000",
    "Literal": "BODY, MIND & SPIRIT / Hermetism & Rosicrucianism"
  },
  {
    "Code": "OCC038000",
    "Literal": "BODY, MIND & SPIRIT / I Ching"
  },
  {
    "Code": "OCC019000",
    "Literal": "BODY, MIND & SPIRIT / Inspiration & Personal Growth"
  },
  {
    "Code": "OCC028000",
    "Literal": "BODY, MIND & SPIRIT / Magick Studies"
  },
  {
    "Code": "OCC010000",
    "Literal": "BODY, MIND & SPIRIT / Mindfulness & Meditation"
  },
  {
    "Code": "OCC012000",
    "Literal": "BODY, MIND & SPIRIT / Mysticism"
  },
  {
    "Code": "OCC014000",
    "Literal": "BODY, MIND & SPIRIT / New Thought"
  },
  {
    "Code": "OCC015000",
    "Literal": "BODY, MIND & SPIRIT / Numerology"
  },
  {
    "Code": "OCC016000",
    "Literal": "BODY, MIND & SPIRIT / Occultism"
  },
  {
    "Code": "OCC018000",
    "Literal": "BODY, MIND & SPIRIT / Parapsychology / General"
  },
  {
    "Code": "OCC007000",
    "Literal": "BODY, MIND & SPIRIT / Parapsychology / ESP (Clairvoyance, Precognition, Telepathy)"
  },
  {
    "Code": "OCC034000",
    "Literal": "BODY, MIND & SPIRIT / Parapsychology / Near-Death Experience"
  },
  {
    "Code": "OCC035000",
    "Literal": "BODY, MIND & SPIRIT / Parapsychology / Out-of-Body Experience"
  },
  {
    "Code": "OCC020000",
    "Literal": "BODY, MIND & SPIRIT / Prophecy"
  },
  {
    "Code": "OCC021000",
    "Literal": "BODY, MIND & SPIRIT / Reference"
  },
  {
    "Code": "OCC041000",
    "Literal": "BODY, MIND & SPIRIT / Sacred Sexuality"
  },
  {
    "Code": "OCC036030",
    "Literal": "BODY, MIND & SPIRIT / Shamanism"
  },
  {
    "Code": "OCC027000",
    "Literal": "BODY, MIND & SPIRIT / Spiritualism"
  },
  {
    "Code": "OCC023000",
    "Literal": "BODY, MIND & SPIRIT / Supernatural (incl. Ghosts)"
  },
  {
    "Code": "OCC025000",
    "Literal": "BODY, MIND & SPIRIT / UFOs & Extraterrestrials"
  },
  {
    "Code": "OCC029000",
    "Literal": "BODY, MIND & SPIRIT / Unexplained Phenomena"
  },
  {
    "Code": "OCC026000",
    "Literal": "BODY, MIND & SPIRIT / Witchcraft (see also RELIGION / Wicca)"
  },
  {
    "Code": "BUS000000",
    "Literal": "BUSINESS & ECONOMICS / General"
  },
  {
    "Code": "BUS001000",
    "Literal": "BUSINESS & ECONOMICS / Accounting / General"
  },
  {
    "Code": "BUS001010",
    "Literal": "BUSINESS & ECONOMICS / Accounting / Financial"
  },
  {
    "Code": "BUS001020",
    "Literal": "BUSINESS & ECONOMICS / Accounting / Governmental"
  },
  {
    "Code": "BUS001040",
    "Literal": "BUSINESS & ECONOMICS / Accounting / Managerial"
  },
  {
    "Code": "BUS001050",
    "Literal": "BUSINESS & ECONOMICS / Accounting / Standards (GAAP, IFRS, etc.)"
  },
  {
    "Code": "BUS002000",
    "Literal": "BUSINESS & ECONOMICS / Advertising & Promotion"
  },
  {
    "Code": "BUS003000",
    "Literal": "BUSINESS & ECONOMICS / Auditing"
  },
  {
    "Code": "BUS004000",
    "Literal": "BUSINESS & ECONOMICS / Banks & Banking"
  },
  {
    "Code": "BUS005000",
    "Literal": "BUSINESS & ECONOMICS / Bookkeeping"
  },
  {
    "Code": "BUS006000",
    "Literal": "BUSINESS & ECONOMICS / Budgeting"
  },
  {
    "Code": "BUS007000",
    "Literal": "BUSINESS & ECONOMICS / Business Communication / General"
  },
  {
    "Code": "BUS007010",
    "Literal": "BUSINESS & ECONOMICS / Business Communication / Meetings & Presentations"
  },
  {
    "Code": "BUS008000",
    "Literal": "BUSINESS & ECONOMICS / Business Ethics"
  },
  {
    "Code": "BUS009000",
    "Literal": "BUSINESS & ECONOMICS / Business Etiquette"
  },
  {
    "Code": "BUS010000",
    "Literal": "BUSINESS & ECONOMICS / Business Law"
  },
  {
    "Code": "BUS091000",
    "Literal": "BUSINESS & ECONOMICS / Business Mathematics"
  },
  {
    "Code": "BUS011000",
    "Literal": "BUSINESS & ECONOMICS / Business Writing"
  },
  {
    "Code": "BUS012000",
    "Literal": "BUSINESS & ECONOMICS / Careers / General"
  },
  {
    "Code": "BUS012010",
    "Literal": "BUSINESS & ECONOMICS / Careers / Internships"
  },
  {
    "Code": "BUS037020",
    "Literal": "BUSINESS & ECONOMICS / Careers / Job Hunting"
  },
  {
    "Code": "BUS056030",
    "Literal": "BUSINESS & ECONOMICS / Careers / Resumes"
  },
  {
    "Code": "BUS073000",
    "Literal": "BUSINESS & ECONOMICS / Commerce"
  },
  {
    "Code": "BUS013000",
    "Literal": "BUSINESS & ECONOMICS / Commercial Policy"
  },
  {
    "Code": "BUS110000",
    "Literal": "BUSINESS & ECONOMICS / Conflict Resolution & Mediation"
  },
  {
    "Code": "BUS075000",
    "Literal": "BUSINESS & ECONOMICS / Consulting"
  },
  {
    "Code": "BUS016000",
    "Literal": "BUSINESS & ECONOMICS / Consumer Behavior"
  },
  {
    "Code": "BUS077000",
    "Literal": "BUSINESS & ECONOMICS / Corporate & Business History"
  },
  {
    "Code": "BUS017000",
    "Literal": "BUSINESS & ECONOMICS / Corporate Finance / General"
  },
  {
    "Code": "BUS017010",
    "Literal": "BUSINESS & ECONOMICS / Corporate Finance / Private Equity"
  },
  {
    "Code": "BUS017020",
    "Literal": "BUSINESS & ECONOMICS / Corporate Finance / Valuation"
  },
  {
    "Code": "BUS017030",
    "Literal": "BUSINESS & ECONOMICS / Corporate Finance / Venture Capital"
  },
  {
    "Code": "BUS104000",
    "Literal": "BUSINESS & ECONOMICS / Corporate Governance"
  },
  {
    "Code": "BUS111000",
    "Literal": "BUSINESS & ECONOMICS / Crowdfunding"
  },
  {
    "Code": "BUS018000",
    "Literal": "BUSINESS & ECONOMICS / Customer Relations"
  },
  {
    "Code": "BUS019000",
    "Literal": "BUSINESS & ECONOMICS / Decision-Making & Problem Solving"
  },
  {
    "Code": "BUS092000",
    "Literal": "BUSINESS & ECONOMICS / Development / General"
  },
  {
    "Code": "BUS020000",
    "Literal": "BUSINESS & ECONOMICS / Development / Business Development"
  },
  {
    "Code": "BUS068000",
    "Literal": "BUSINESS & ECONOMICS / Development / Economic Development"
  },
  {
    "Code": "BUS072000",
    "Literal": "BUSINESS & ECONOMICS / Development / Sustainable Development"
  },
  {
    "Code": "BUS078000",
    "Literal": "BUSINESS & ECONOMICS / Distribution"
  },
  {
    "Code": "BUS090000",
    "Literal": "BUSINESS & ECONOMICS / E-Commerce / General (see also COMPUTERS / Electronic Commerce)"
  },
  {
    "Code": "BUS090040",
    "Literal": "BUSINESS & ECONOMICS / E-Commerce / Auctions & Small Business"
  },
  {
    "Code": "BUS090010",
    "Literal": "BUSINESS & ECONOMICS / E-Commerce / Internet Marketing"
  },
  {
    "Code": "BUS090030",
    "Literal": "BUSINESS & ECONOMICS / E-Commerce / Online Trading"
  },
  {
    "Code": "BUS021000",
    "Literal": "BUSINESS & ECONOMICS / Econometrics"
  },
  {
    "Code": "BUS022000",
    "Literal": "BUSINESS & ECONOMICS / Economic Conditions"
  },
  {
    "Code": "BUS023000",
    "Literal": "BUSINESS & ECONOMICS / Economic History"
  },
  {
    "Code": "BUS069000",
    "Literal": "BUSINESS & ECONOMICS / Economics / General"
  },
  {
    "Code": "BUS069010",
    "Literal": "BUSINESS & ECONOMICS / Economics / Comparative"
  },
  {
    "Code": "BUS039000",
    "Literal": "BUSINESS & ECONOMICS / Economics / Macroeconomics"
  },
  {
    "Code": "BUS044000",
    "Literal": "BUSINESS & ECONOMICS / Economics / Microeconomics"
  },
  {
    "Code": "BUS069030",
    "Literal": "BUSINESS & ECONOMICS / Economics / Theory"
  },
  {
    "Code": "BUS024000",
    "Literal": "BUSINESS & ECONOMICS / Education"
  },
  {
    "Code": "BUS025000",
    "Literal": "BUSINESS & ECONOMICS / Entrepreneurship"
  },
  {
    "Code": "BUS099000",
    "Literal": "BUSINESS & ECONOMICS / Environmental Economics"
  },
  {
    "Code": "BUS026000",
    "Literal": "BUSINESS & ECONOMICS / Exports & Imports"
  },
  {
    "Code": "BUS093000",
    "Literal": "BUSINESS & ECONOMICS / Facility Management"
  },
  {
    "Code": "BUS027000",
    "Literal": "BUSINESS & ECONOMICS / Finance / General"
  },
  {
    "Code": "BUS027010",
    "Literal": "BUSINESS & ECONOMICS / Finance / Financial Engineering"
  },
  {
    "Code": "BUS027020",
    "Literal": "BUSINESS & ECONOMICS / Finance / Financial Risk Management"
  },
  {
    "Code": "BUS027030",
    "Literal": "BUSINESS & ECONOMICS / Finance / Wealth Management"
  },
  {
    "Code": "BUS086000",
    "Literal": "BUSINESS & ECONOMICS / Forecasting"
  },
  {
    "Code": "BUS028000",
    "Literal": "BUSINESS & ECONOMICS / Foreign Exchange"
  },
  {
    "Code": "BUS105000",
    "Literal": "BUSINESS & ECONOMICS / Franchises"
  },
  {
    "Code": "BUS029000",
    "Literal": "BUSINESS & ECONOMICS / Free Enterprise & Capitalism"
  },
  {
    "Code": "BUS113000",
    "Literal": "BUSINESS & ECONOMICS / Globalization"
  },
  {
    "Code": "BUS079000",
    "Literal": "BUSINESS & ECONOMICS / Government & Business"
  },
  {
    "Code": "BUS094000",
    "Literal": "BUSINESS & ECONOMICS / Green Business"
  },
  {
    "Code": "BUS080000",
    "Literal": "BUSINESS & ECONOMICS / Home-Based Businesses"
  },
  {
    "Code": "BUS030000",
    "Literal": "BUSINESS & ECONOMICS / Human Resources & Personnel Management"
  },
  {
    "Code": "BUS082000",
    "Literal": "BUSINESS & ECONOMICS / Industrial Management"
  },
  {
    "Code": "BUS070000",
    "Literal": "BUSINESS & ECONOMICS / Industries / General"
  },
  {
    "Code": "BUS070010",
    "Literal": "BUSINESS & ECONOMICS / Industries / Agribusiness"
  },
  {
    "Code": "BUS070020",
    "Literal": "BUSINESS & ECONOMICS / Industries / Automobile Industry"
  },
  {
    "Code": "BUS070030",
    "Literal": "BUSINESS & ECONOMICS / Industries / Computers & Information Technology"
  },
  {
    "Code": "BUS070160",
    "Literal": "BUSINESS & ECONOMICS / Industries / Construction"
  },
  {
    "Code": "BUS070040",
    "Literal": "BUSINESS & ECONOMICS / Industries / Energy"
  },
  {
    "Code": "BUS070110",
    "Literal": "BUSINESS & ECONOMICS / Industries / Entertainment"
  },
  {
    "Code": "BUS070090",
    "Literal": "BUSINESS & ECONOMICS / Industries / Fashion & Textile Industry"
  },
  {
    "Code": "BUS070140",
    "Literal": "BUSINESS & ECONOMICS / Industries / Financial Services"
  },
  {
    "Code": "BUS070120",
    "Literal": "BUSINESS & ECONOMICS / Industries / Food Industry"
  },
  {
    "Code": "BUS070170",
    "Literal": "BUSINESS & ECONOMICS / Industries / Healthcare"
  },
  {
    "Code": "BUS081000",
    "Literal": "BUSINESS & ECONOMICS / Industries / Hospitality, Travel & Tourism"
  },
  {
    "Code": "BUS070050",
    "Literal": "BUSINESS & ECONOMICS / Industries / Manufacturing"
  },
  {
    "Code": "BUS070060",
    "Literal": "BUSINESS & ECONOMICS / Industries / Media & Communications"
  },
  {
    "Code": "BUS070150",
    "Literal": "BUSINESS & ECONOMICS / Industries / Natural Resource Extraction"
  },
  {
    "Code": "BUS070070",
    "Literal": "BUSINESS & ECONOMICS / Industries / Park & Recreation Management"
  },
  {
    "Code": "BUS070130",
    "Literal": "BUSINESS & ECONOMICS / Industries / Pharmaceutical & Biotechnology"
  },
  {
    "Code": "BUS057000",
    "Literal": "BUSINESS & ECONOMICS / Industries / Retailing"
  },
  {
    "Code": "BUS070080",
    "Literal": "BUSINESS & ECONOMICS / Industries / Service"
  },
  {
    "Code": "BUS070100",
    "Literal": "BUSINESS & ECONOMICS / Industries / Transportation"
  },
  {
    "Code": "BUS031000",
    "Literal": "BUSINESS & ECONOMICS / Inflation"
  },
  {
    "Code": "BUS083000",
    "Literal": "BUSINESS & ECONOMICS / Information Management"
  },
  {
    "Code": "BUS032000",
    "Literal": "BUSINESS & ECONOMICS / Infrastructure"
  },
  {
    "Code": "BUS033000",
    "Literal": "BUSINESS & ECONOMICS / Insurance / General"
  },
  {
    "Code": "BUS033010",
    "Literal": "BUSINESS & ECONOMICS / Insurance / Automobile"
  },
  {
    "Code": "BUS033020",
    "Literal": "BUSINESS & ECONOMICS / Insurance / Casualty"
  },
  {
    "Code": "BUS033040",
    "Literal": "BUSINESS & ECONOMICS / Insurance / Health"
  },
  {
    "Code": "BUS033050",
    "Literal": "BUSINESS & ECONOMICS / Insurance / Liability"
  },
  {
    "Code": "BUS033060",
    "Literal": "BUSINESS & ECONOMICS / Insurance / Life"
  },
  {
    "Code": "BUS033080",
    "Literal": "BUSINESS & ECONOMICS / Insurance / Property"
  },
  {
    "Code": "BUS033070",
    "Literal": "BUSINESS & ECONOMICS / Insurance / Risk Assessment & Management"
  },
  {
    "Code": "BUS034000",
    "Literal": "BUSINESS & ECONOMICS / Interest"
  },
  {
    "Code": "BUS035000",
    "Literal": "BUSINESS & ECONOMICS / International / General"
  },
  {
    "Code": "BUS001030",
    "Literal": "BUSINESS & ECONOMICS / International / Accounting"
  },
  {
    "Code": "BUS069020",
    "Literal": "BUSINESS & ECONOMICS / International / Economics"
  },
  {
    "Code": "BUS043030",
    "Literal": "BUSINESS & ECONOMICS / International / Marketing"
  },
  {
    "Code": "BUS064020",
    "Literal": "BUSINESS & ECONOMICS / International / Taxation"
  },
  {
    "Code": "BUS036000",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / General"
  },
  {
    "Code": "BUS036070",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Analysis & Trading Strategies"
  },
  {
    "Code": "BUS036010",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Bonds"
  },
  {
    "Code": "BUS014000",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Commodities / General"
  },
  {
    "Code": "BUS014010",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Commodities / Energy"
  },
  {
    "Code": "BUS014020",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Commodities / Metals"
  },
  {
    "Code": "BUS036080",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Derivatives"
  },
  {
    "Code": "BUS036020",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Futures"
  },
  {
    "Code": "BUS036030",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Mutual Funds"
  },
  {
    "Code": "BUS036040",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Options"
  },
  {
    "Code": "BUS036090",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Portfolio Management"
  },
  {
    "Code": "BUS036050",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Real Estate"
  },
  {
    "Code": "BUS036060",
    "Literal": "BUSINESS & ECONOMICS / Investments & Securities / Stocks"
  },
  {
    "Code": "BUS112000",
    "Literal": "BUSINESS & ECONOMICS / Islamic Banking & Finance"
  },
  {
    "Code": "BUS098000",
    "Literal": "BUSINESS & ECONOMICS / Knowledge Capital"
  },
  {
    "Code": "BUS038000",
    "Literal": "BUSINESS & ECONOMICS / Labor"
  },
  {
    "Code": "BUS071000",
    "Literal": "BUSINESS & ECONOMICS / Leadership"
  },
  {
    "Code": "BUS040000",
    "Literal": "BUSINESS & ECONOMICS / Mail Order"
  },
  {
    "Code": "BUS041000",
    "Literal": "BUSINESS & ECONOMICS / Management"
  },
  {
    "Code": "BUS042000",
    "Literal": "BUSINESS & ECONOMICS / Management Science"
  },
  {
    "Code": "BUS043000",
    "Literal": "BUSINESS & ECONOMICS / Marketing / General"
  },
  {
    "Code": "BUS043010",
    "Literal": "BUSINESS & ECONOMICS / Marketing / Direct"
  },
  {
    "Code": "BUS043020",
    "Literal": "BUSINESS & ECONOMICS / Marketing / Industrial"
  },
  {
    "Code": "BUS043040",
    "Literal": "BUSINESS & ECONOMICS / Marketing / Multilevel"
  },
  {
    "Code": "BUS043060",
    "Literal": "BUSINESS & ECONOMICS / Marketing / Research"
  },
  {
    "Code": "BUS043050",
    "Literal": "BUSINESS & ECONOMICS / Marketing / Telemarketing"
  },
  {
    "Code": "BUS106000",
    "Literal": "BUSINESS & ECONOMICS / Mentoring & Coaching"
  },
  {
    "Code": "BUS015000",
    "Literal": "BUSINESS & ECONOMICS / Mergers & Acquisitions"
  },
  {
    "Code": "BUS045000",
    "Literal": "BUSINESS & ECONOMICS / Money & Monetary Policy"
  },
  {
    "Code": "BUS046000",
    "Literal": "BUSINESS & ECONOMICS / Motivational"
  },
  {
    "Code": "BUS100000",
    "Literal": "BUSINESS & ECONOMICS / Museum Administration & Museology"
  },
  {
    "Code": "BUS047000",
    "Literal": "BUSINESS & ECONOMICS / Negotiating"
  },
  {
    "Code": "BUS048000",
    "Literal": "BUSINESS & ECONOMICS / New Business Enterprises"
  },
  {
    "Code": "BUS074000",
    "Literal": "BUSINESS & ECONOMICS / Nonprofit Organizations & Charities / General"
  },
  {
    "Code": "BUS074010",
    "Literal": "BUSINESS & ECONOMICS / Nonprofit Organizations & Charities / Finance & Accounting"
  },
  {
    "Code": "BUS074020",
    "Literal": "BUSINESS & ECONOMICS / Nonprofit Organizations & Charities / Fundraising & Grants"
  },
  {
    "Code": "BUS074030",
    "Literal": "BUSINESS & ECONOMICS / Nonprofit Organizations & Charities / Management & Leadership"
  },
  {
    "Code": "BUS074040",
    "Literal": "BUSINESS & ECONOMICS / Nonprofit Organizations & Charities / Marketing & Communications"
  },
  {
    "Code": "BUS084000",
    "Literal": "BUSINESS & ECONOMICS / Office Automation"
  },
  {
    "Code": "BUS095000",
    "Literal": "BUSINESS & ECONOMICS / Office Equipment & Supplies"
  },
  {
    "Code": "BUS096000",
    "Literal": "BUSINESS & ECONOMICS / Office Management"
  },
  {
    "Code": "BUS049000",
    "Literal": "BUSINESS & ECONOMICS / Operations Research"
  },
  {
    "Code": "BUS085000",
    "Literal": "BUSINESS & ECONOMICS / Organizational Behavior"
  },
  {
    "Code": "BUS103000",
    "Literal": "BUSINESS & ECONOMICS / Organizational Development"
  },
  {
    "Code": "BUS102000",
    "Literal": "BUSINESS & ECONOMICS / Outsourcing"
  },
  {
    "Code": "BUS050000",
    "Literal": "BUSINESS & ECONOMICS / Personal Finance / General"
  },
  {
    "Code": "BUS050010",
    "Literal": "BUSINESS & ECONOMICS / Personal Finance / Budgeting"
  },
  {
    "Code": "BUS050020",
    "Literal": "BUSINESS & ECONOMICS / Personal Finance / Investing"
  },
  {
    "Code": "BUS050030",
    "Literal": "BUSINESS & ECONOMICS / Personal Finance / Money Management"
  },
  {
    "Code": "BUS050040",
    "Literal": "BUSINESS & ECONOMICS / Personal Finance / Retirement Planning"
  },
  {
    "Code": "BUS050050",
    "Literal": "BUSINESS & ECONOMICS / Personal Finance / Taxation"
  },
  {
    "Code": "BUS107000",
    "Literal": "BUSINESS & ECONOMICS / Personal Success"
  },
  {
    "Code": "BUS087000",
    "Literal": "BUSINESS & ECONOMICS / Production & Operations Management"
  },
  {
    "Code": "BUS101000",
    "Literal": "BUSINESS & ECONOMICS / Project Management"
  },
  {
    "Code": "BUS051000",
    "Literal": "BUSINESS & ECONOMICS / Public Finance"
  },
  {
    "Code": "BUS052000",
    "Literal": "BUSINESS & ECONOMICS / Public Relations"
  },
  {
    "Code": "BUS076000",
    "Literal": "BUSINESS & ECONOMICS / Purchasing & Buying"
  },
  {
    "Code": "BUS053000",
    "Literal": "BUSINESS & ECONOMICS / Quality Control"
  },
  {
    "Code": "BUS054000",
    "Literal": "BUSINESS & ECONOMICS / Real Estate / General"
  },
  {
    "Code": "BUS054010",
    "Literal": "BUSINESS & ECONOMICS / Real Estate / Buying & Selling Homes"
  },
  {
    "Code": "BUS054020",
    "Literal": "BUSINESS & ECONOMICS / Real Estate / Commercial"
  },
  {
    "Code": "BUS054030",
    "Literal": "BUSINESS & ECONOMICS / Real Estate / Mortgages"
  },
  {
    "Code": "BUS055000",
    "Literal": "BUSINESS & ECONOMICS / Reference"
  },
  {
    "Code": "BUS108000",
    "Literal": "BUSINESS & ECONOMICS / Research & Development"
  },
  {
    "Code": "BUS058000",
    "Literal": "BUSINESS & ECONOMICS / Sales & Selling / General"
  },
  {
    "Code": "BUS058010",
    "Literal": "BUSINESS & ECONOMICS / Sales & Selling / Management"
  },
  {
    "Code": "BUS089000",
    "Literal": "BUSINESS & ECONOMICS / Secretarial Aids & Training"
  },
  {
    "Code": "BUS059000",
    "Literal": "BUSINESS & ECONOMICS / Skills"
  },
  {
    "Code": "BUS060000",
    "Literal": "BUSINESS & ECONOMICS / Small Business"
  },
  {
    "Code": "BUS061000",
    "Literal": "BUSINESS & ECONOMICS / Statistics"
  },
  {
    "Code": "BUS063000",
    "Literal": "BUSINESS & ECONOMICS / Strategic Planning"
  },
  {
    "Code": "BUS062000",
    "Literal": "BUSINESS & ECONOMICS / Structural Adjustment"
  },
  {
    "Code": "BUS064000",
    "Literal": "BUSINESS & ECONOMICS / Taxation / General"
  },
  {
    "Code": "BUS064010",
    "Literal": "BUSINESS & ECONOMICS / Taxation / Corporate"
  },
  {
    "Code": "BUS064030",
    "Literal": "BUSINESS & ECONOMICS / Taxation / Small Business"
  },
  {
    "Code": "BUS088000",
    "Literal": "BUSINESS & ECONOMICS / Time Management"
  },
  {
    "Code": "BUS065000",
    "Literal": "BUSINESS & ECONOMICS / Total Quality Management"
  },
  {
    "Code": "BUS066000",
    "Literal": "BUSINESS & ECONOMICS / Training"
  },
  {
    "Code": "BUS067000",
    "Literal": "BUSINESS & ECONOMICS / Urban & Regional"
  },
  {
    "Code": "BUS109000",
    "Literal": "BUSINESS & ECONOMICS / Women in Business"
  },
  {
    "Code": "BUS097000",
    "Literal": "BUSINESS & ECONOMICS / Workplace Culture"
  },
  {
    "Code": "CGN000000",
    "Literal": "COMICS & GRAPHIC NOVELS / General"
  },
  {
    "Code": "CGN012000",
    "Literal": "COMICS & GRAPHIC NOVELS / Adaptations"
  },
  {
    "Code": "CGN001000",
    "Literal": "COMICS & GRAPHIC NOVELS / Anthologies"
  },
  {
    "Code": "CGN008000",
    "Literal": "COMICS & GRAPHIC NOVELS / Contemporary Women"
  },
  {
    "Code": "CGN004010",
    "Literal": "COMICS & GRAPHIC NOVELS / Crime & Mystery"
  },
  {
    "Code": "CGN013000",
    "Literal": "COMICS & GRAPHIC NOVELS / Dystopian"
  },
  {
    "Code": "CGN004020",
    "Literal": "COMICS & GRAPHIC NOVELS / Erotica"
  },
  {
    "Code": "CGN004030",
    "Literal": "COMICS & GRAPHIC NOVELS / Fantasy"
  },
  {
    "Code": "CGN010000",
    "Literal": "COMICS & GRAPHIC NOVELS / Historical Fiction"
  },
  {
    "Code": "CGN004040",
    "Literal": "COMICS & GRAPHIC NOVELS / Horror"
  },
  {
    "Code": "CGN014000",
    "Literal": "COMICS & GRAPHIC NOVELS / Humorous"
  },
  {
    "Code": "CGN009000",
    "Literal": "COMICS & GRAPHIC NOVELS / LGBT"
  },
  {
    "Code": "CGN006000",
    "Literal": "COMICS & GRAPHIC NOVELS / Literary"
  },
  {
    "Code": "CGN004050",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / General"
  },
  {
    "Code": "CGN004240",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Action & Adventure"
  },
  {
    "Code": "CGN004100",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Crime & Mystery"
  },
  {
    "Code": "CGN004230",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Dystopian"
  },
  {
    "Code": "CGN004110",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Erotica & Hentai"
  },
  {
    "Code": "CGN004120",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Fantasy"
  },
  {
    "Code": "CGN004140",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Historical Fiction"
  },
  {
    "Code": "CGN004150",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Horror"
  },
  {
    "Code": "CGN004250",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Humorous"
  },
  {
    "Code": "CGN004300",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Isekai"
  },
  {
    "Code": "CGN004130",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / LGBT"
  },
  {
    "Code": "CGN004260",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Magical Girl"
  },
  {
    "Code": "CGN004270",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Mecha"
  },
  {
    "Code": "CGN004160",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Media Tie-In"
  },
  {
    "Code": "CGN004170",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Nonfiction"
  },
  {
    "Code": "CGN004180",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Romance"
  },
  {
    "Code": "CGN004280",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / School Life"
  },
  {
    "Code": "CGN004190",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Science Fiction"
  },
  {
    "Code": "CGN004200",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Sports"
  },
  {
    "Code": "CGN004290",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Supernatural"
  },
  {
    "Code": "CGN004210",
    "Literal": "COMICS & GRAPHIC NOVELS / Manga / Yaoi"
  },
  {
    "Code": "CGN004060",
    "Literal": "COMICS & GRAPHIC NOVELS / Media Tie-In"
  },
  {
    "Code": "CGN007000",
    "Literal": "COMICS & GRAPHIC NOVELS / Nonfiction / General"
  },
  {
    "Code": "CGN007010",
    "Literal": "COMICS & GRAPHIC NOVELS / Nonfiction / Biography & Memoir"
  },
  {
    "Code": "CGN015000",
    "Literal": "COMICS & GRAPHIC NOVELS / Reference"
  },
  {
    "Code": "CGN011000",
    "Literal": "COMICS & GRAPHIC NOVELS / Religious"
  },
  {
    "Code": "CGN004090",
    "Literal": "COMICS & GRAPHIC NOVELS / Romance"
  },
  {
    "Code": "CGN004070",
    "Literal": "COMICS & GRAPHIC NOVELS / Science Fiction"
  },
  {
    "Code": "CGN004080",
    "Literal": "COMICS & GRAPHIC NOVELS / Superheroes (see also FICTION / Superheroes)"
  },
  {
    "Code": "CGN016000",
    "Literal": "COMICS & GRAPHIC NOVELS / Supernatural"
  },
  {
    "Code": "CGN017000",
    "Literal": "COMICS & GRAPHIC NOVELS / Supervillains"
  },
  {
    "Code": "COM000000",
    "Literal": "COMPUTERS / General"
  },
  {
    "Code": "COM082000",
    "Literal": "COMPUTERS / Bioinformatics"
  },
  {
    "Code": "COM006000",
    "Literal": "COMPUTERS / Buyer's Guides"
  },
  {
    "Code": "COM007000",
    "Literal": "COMPUTERS / CAD-CAM"
  },
  {
    "Code": "COM008000",
    "Literal": "COMPUTERS / Calculators"
  },
  {
    "Code": "COM009000",
    "Literal": "COMPUTERS / CD-DVD Technology"
  },
  {
    "Code": "COM055000",
    "Literal": "COMPUTERS / Certification Guides / General"
  },
  {
    "Code": "COM055010",
    "Literal": "COMPUTERS / Certification Guides / A+"
  },
  {
    "Code": "COM055020",
    "Literal": "COMPUTERS / Certification Guides / MCSE"
  },
  {
    "Code": "COM061000",
    "Literal": "COMPUTERS / Client-Server Computing"
  },
  {
    "Code": "COM091000",
    "Literal": "COMPUTERS / Cloud Computing"
  },
  {
    "Code": "COM010000",
    "Literal": "COMPUTERS / Compilers"
  },
  {
    "Code": "COM059000",
    "Literal": "COMPUTERS / Computer Engineering"
  },
  {
    "Code": "COM012000",
    "Literal": "COMPUTERS / Computer Graphics"
  },
  {
    "Code": "COM013000",
    "Literal": "COMPUTERS / Computer Literacy"
  },
  {
    "Code": "COM014000",
    "Literal": "COMPUTERS / Computer Science"
  },
  {
    "Code": "COM072000",
    "Literal": "COMPUTERS / Computer Simulation"
  },
  {
    "Code": "COM016000",
    "Literal": "COMPUTERS / Computer Vision & Pattern Recognition"
  },
  {
    "Code": "COM086000",
    "Literal": "COMPUTERS / Computerized Home & Entertainment"
  },
  {
    "Code": "COM017000",
    "Literal": "COMPUTERS / Cybernetics"
  },
  {
    "Code": "COM062000",
    "Literal": "COMPUTERS / Data Modeling & Design"
  },
  {
    "Code": "COM018000",
    "Literal": "COMPUTERS / Data Processing"
  },
  {
    "Code": "COM020000",
    "Literal": "COMPUTERS / Data Transmission Systems / General"
  },
  {
    "Code": "COM020050",
    "Literal": "COMPUTERS / Data Transmission Systems / Broadband"
  },
  {
    "Code": "COM020010",
    "Literal": "COMPUTERS / Data Transmission Systems / Electronic Data Interchange"
  },
  {
    "Code": "COM020090",
    "Literal": "COMPUTERS / Data Transmission Systems / Wireless"
  },
  {
    "Code": "COM089000",
    "Literal": "COMPUTERS / Data Visualization"
  },
  {
    "Code": "COM021000",
    "Literal": "COMPUTERS / Databases / General"
  },
  {
    "Code": "COM021030",
    "Literal": "COMPUTERS / Databases / Data Mining"
  },
  {
    "Code": "COM021040",
    "Literal": "COMPUTERS / Databases / Data Warehousing"
  },
  {
    "Code": "COM021050",
    "Literal": "COMPUTERS / Databases / Servers"
  },
  {
    "Code": "COM084000",
    "Literal": "COMPUTERS / Desktop Applications / General"
  },
  {
    "Code": "COM084010",
    "Literal": "COMPUTERS / Desktop Applications / Databases"
  },
  {
    "Code": "COM087020",
    "Literal": "COMPUTERS / Desktop Applications / Design & Graphics"
  },
  {
    "Code": "COM022000",
    "Literal": "COMPUTERS / Desktop Applications / Desktop Publishing"
  },
  {
    "Code": "COM084020",
    "Literal": "COMPUTERS / Desktop Applications / Email Clients"
  },
  {
    "Code": "COM027000",
    "Literal": "COMPUTERS / Desktop Applications / Personal Finance Applications"
  },
  {
    "Code": "COM078000",
    "Literal": "COMPUTERS / Desktop Applications / Presentation Software"
  },
  {
    "Code": "COM081000",
    "Literal": "COMPUTERS / Desktop Applications / Project Management Software"
  },
  {
    "Code": "COM054000",
    "Literal": "COMPUTERS / Desktop Applications / Spreadsheets"
  },
  {
    "Code": "COM084030",
    "Literal": "COMPUTERS / Desktop Applications / Suites"
  },
  {
    "Code": "COM058000",
    "Literal": "COMPUTERS / Desktop Applications / Word Processing"
  },
  {
    "Code": "COM087000",
    "Literal": "COMPUTERS / Digital Media / General"
  },
  {
    "Code": "COM087010",
    "Literal": "COMPUTERS / Digital Media / Audio"
  },
  {
    "Code": "COM087030",
    "Literal": "COMPUTERS / Digital Media / Photography (see also PHOTOGRAPHY / Techniques / Digital)"
  },
  {
    "Code": "COM071000",
    "Literal": "COMPUTERS / Digital Media / Video & Animation"
  },
  {
    "Code": "COM063000",
    "Literal": "COMPUTERS / Document Management"
  },
  {
    "Code": "COM085000",
    "Literal": "COMPUTERS / Documentation & Technical Writing"
  },
  {
    "Code": "COM023000",
    "Literal": "COMPUTERS / Educational Software"
  },
  {
    "Code": "COM064000",
    "Literal": "COMPUTERS / Electronic Commerce (see also headings under BUSINESS & ECONOMICS / E-Commerce)"
  },
  {
    "Code": "COM065000",
    "Literal": "COMPUTERS / Electronic Publishing"
  },
  {
    "Code": "COM092000",
    "Literal": "COMPUTERS / Embedded Computer Systems"
  },
  {
    "Code": "COM005000",
    "Literal": "COMPUTERS / Enterprise Applications / General"
  },
  {
    "Code": "COM005030",
    "Literal": "COMPUTERS / Enterprise Applications / Business Intelligence Tools"
  },
  {
    "Code": "COM066000",
    "Literal": "COMPUTERS / Enterprise Applications / Collaboration Software"
  },
  {
    "Code": "COM025000",
    "Literal": "COMPUTERS / Expert Systems"
  },
  {
    "Code": "COM067000",
    "Literal": "COMPUTERS / Hardware / General"
  },
  {
    "Code": "COM038000",
    "Literal": "COMPUTERS / Hardware / Mainframes & Minicomputers"
  },
  {
    "Code": "COM074000",
    "Literal": "COMPUTERS / Hardware / Mobile Devices"
  },
  {
    "Code": "COM049000",
    "Literal": "COMPUTERS / Hardware / Peripherals"
  },
  {
    "Code": "COM050000",
    "Literal": "COMPUTERS / Hardware / Personal Computers / General"
  },
  {
    "Code": "COM050020",
    "Literal": "COMPUTERS / Hardware / Personal Computers / Macintosh"
  },
  {
    "Code": "COM050010",
    "Literal": "COMPUTERS / Hardware / Personal Computers / PCs"
  },
  {
    "Code": "COM090000",
    "Literal": "COMPUTERS / Hardware / Tablets"
  },
  {
    "Code": "COM080000",
    "Literal": "COMPUTERS / History"
  },
  {
    "Code": "COM079010",
    "Literal": "COMPUTERS / Human-Computer Interaction (HCI)"
  },
  {
    "Code": "COM012050",
    "Literal": "COMPUTERS / Image Processing"
  },
  {
    "Code": "COM032000",
    "Literal": "COMPUTERS / Information Technology"
  },
  {
    "Code": "COM031000",
    "Literal": "COMPUTERS / Information Theory"
  },
  {
    "Code": "COM004000",
    "Literal": "COMPUTERS / Intelligence (AI) & Semantics"
  },
  {
    "Code": "COM034000",
    "Literal": "COMPUTERS / Interactive & Multimedia"
  },
  {
    "Code": "COM060000",
    "Literal": "COMPUTERS / Internet / General"
  },
  {
    "Code": "COM060090",
    "Literal": "COMPUTERS / Internet / Application Development"
  },
  {
    "Code": "COM035000",
    "Literal": "COMPUTERS / Keyboarding"
  },
  {
    "Code": "COM036000",
    "Literal": "COMPUTERS / Logic Design"
  },
  {
    "Code": "COM037000",
    "Literal": "COMPUTERS / Machine Theory"
  },
  {
    "Code": "COM039000",
    "Literal": "COMPUTERS / Management Information Systems"
  },
  {
    "Code": "COM077000",
    "Literal": "COMPUTERS / Mathematical & Statistical Software"
  },
  {
    "Code": "COM041000",
    "Literal": "COMPUTERS / Microprocessors"
  },
  {
    "Code": "COM042000",
    "Literal": "COMPUTERS / Natural Language Processing"
  },
  {
    "Code": "COM043000",
    "Literal": "COMPUTERS / Networking / General"
  },
  {
    "Code": "COM075000",
    "Literal": "COMPUTERS / Networking / Hardware"
  },
  {
    "Code": "COM060030",
    "Literal": "COMPUTERS / Networking / Intranets & Extranets"
  },
  {
    "Code": "COM043020",
    "Literal": "COMPUTERS / Networking / Local Area Networks (LANs)"
  },
  {
    "Code": "COM043040",
    "Literal": "COMPUTERS / Networking / Network Protocols"
  },
  {
    "Code": "COM043060",
    "Literal": "COMPUTERS / Networking / Vendor Specific"
  },
  {
    "Code": "COM044000",
    "Literal": "COMPUTERS / Neural Networks"
  },
  {
    "Code": "COM069000",
    "Literal": "COMPUTERS / Online Services"
  },
  {
    "Code": "COM046000",
    "Literal": "COMPUTERS / Operating Systems / General"
  },
  {
    "Code": "COM046100",
    "Literal": "COMPUTERS / Operating Systems / Android"
  },
  {
    "Code": "COM046020",
    "Literal": "COMPUTERS / Operating Systems / Apple (Mac OS & iOS)"
  },
  {
    "Code": "COM046060",
    "Literal": "COMPUTERS / Operating Systems / DOS"
  },
  {
    "Code": "COM046070",
    "Literal": "COMPUTERS / Operating Systems / Linux"
  },
  {
    "Code": "COM046080",
    "Literal": "COMPUTERS / Operating Systems / Mainframe & Midrange"
  },
  {
    "Code": "COM046030",
    "Literal": "COMPUTERS / Operating Systems / UNIX"
  },
  {
    "Code": "COM046090",
    "Literal": "COMPUTERS / Operating Systems / Virtualization"
  },
  {
    "Code": "COM046040",
    "Literal": "COMPUTERS / Operating Systems / Windows Desktop"
  },
  {
    "Code": "COM046050",
    "Literal": "COMPUTERS / Operating Systems / Windows Server"
  },
  {
    "Code": "COM047000",
    "Literal": "COMPUTERS / Optical Data Processing"
  },
  {
    "Code": "COM051000",
    "Literal": "COMPUTERS / Programming / General"
  },
  {
    "Code": "COM051300",
    "Literal": "COMPUTERS / Programming / Algorithms"
  },
  {
    "Code": "COM012040",
    "Literal": "COMPUTERS / Programming / Games"
  },
  {
    "Code": "COM051370",
    "Literal": "COMPUTERS / Programming / Macintosh"
  },
  {
    "Code": "COM051380",
    "Literal": "COMPUTERS / Programming / Microsoft"
  },
  {
    "Code": "COM051460",
    "Literal": "COMPUTERS / Programming / Mobile Devices"
  },
  {
    "Code": "COM051210",
    "Literal": "COMPUTERS / Programming / Object Oriented"
  },
  {
    "Code": "COM051390",
    "Literal": "COMPUTERS / Programming / Open Source"
  },
  {
    "Code": "COM051220",
    "Literal": "COMPUTERS / Programming / Parallel"
  },
  {
    "Code": "COM051010",
    "Literal": "COMPUTERS / Programming Languages / General"
  },
  {
    "Code": "COM051020",
    "Literal": "COMPUTERS / Programming Languages / Ada"
  },
  {
    "Code": "COM051470",
    "Literal": "COMPUTERS / Programming Languages / ASP.NET"
  },
  {
    "Code": "COM051040",
    "Literal": "COMPUTERS / Programming Languages / Assembly Language"
  },
  {
    "Code": "COM051050",
    "Literal": "COMPUTERS / Programming Languages / BASIC"
  },
  {
    "Code": "COM051060",
    "Literal": "COMPUTERS / Programming Languages / C"
  },
  {
    "Code": "COM051070",
    "Literal": "COMPUTERS / Programming Languages / C++"
  },
  {
    "Code": "COM051310",
    "Literal": "COMPUTERS / Programming Languages / C#"
  },
  {
    "Code": "COM051080",
    "Literal": "COMPUTERS / Programming Languages / COBOL"
  },
  {
    "Code": "COM051090",
    "Literal": "COMPUTERS / Programming Languages / FORTRAN"
  },
  {
    "Code": "COM051270",
    "Literal": "COMPUTERS / Programming Languages / HTML"
  },
  {
    "Code": "COM051280",
    "Literal": "COMPUTERS / Programming Languages / Java"
  },
  {
    "Code": "COM051260",
    "Literal": "COMPUTERS / Programming Languages / JavaScript"
  },
  {
    "Code": "COM051100",
    "Literal": "COMPUTERS / Programming Languages / LISP"
  },
  {
    "Code": "COM051130",
    "Literal": "COMPUTERS / Programming Languages / Pascal"
  },
  {
    "Code": "COM051350",
    "Literal": "COMPUTERS / Programming Languages / Perl"
  },
  {
    "Code": "COM051400",
    "Literal": "COMPUTERS / Programming Languages / PHP"
  },
  {
    "Code": "COM051140",
    "Literal": "COMPUTERS / Programming Languages / Prolog"
  },
  {
    "Code": "COM051360",
    "Literal": "COMPUTERS / Programming Languages / Python"
  },
  {
    "Code": "COM051290",
    "Literal": "COMPUTERS / Programming Languages / RPG"
  },
  {
    "Code": "COM051410",
    "Literal": "COMPUTERS / Programming Languages / Ruby"
  },
  {
    "Code": "COM051170",
    "Literal": "COMPUTERS / Programming Languages / SQL"
  },
  {
    "Code": "COM051450",
    "Literal": "COMPUTERS / Programming Languages / UML"
  },
  {
    "Code": "COM051420",
    "Literal": "COMPUTERS / Programming Languages / VBScript"
  },
  {
    "Code": "COM051200",
    "Literal": "COMPUTERS / Programming Languages / Visual BASIC"
  },
  {
    "Code": "COM051320",
    "Literal": "COMPUTERS / Programming Languages / XML"
  },
  {
    "Code": "COM052000",
    "Literal": "COMPUTERS / Reference"
  },
  {
    "Code": "COM053000",
    "Literal": "COMPUTERS / Security / General"
  },
  {
    "Code": "COM083000",
    "Literal": "COMPUTERS / Security / Cryptography"
  },
  {
    "Code": "COM043050",
    "Literal": "COMPUTERS / Security / Networking"
  },
  {
    "Code": "COM060040",
    "Literal": "COMPUTERS / Security / Online Safety & Privacy"
  },
  {
    "Code": "COM015000",
    "Literal": "COMPUTERS / Security / Viruses & Malware"
  },
  {
    "Code": "COM079000",
    "Literal": "COMPUTERS / Social Aspects"
  },
  {
    "Code": "COM051230",
    "Literal": "COMPUTERS / Software Development & Engineering / General"
  },
  {
    "Code": "COM051430",
    "Literal": "COMPUTERS / Software Development & Engineering / Project Management"
  },
  {
    "Code": "COM051330",
    "Literal": "COMPUTERS / Software Development & Engineering / Quality Assurance & Testing"
  },
  {
    "Code": "COM051240",
    "Literal": "COMPUTERS / Software Development & Engineering / Systems Analysis & Design"
  },
  {
    "Code": "COM051440",
    "Literal": "COMPUTERS / Software Development & Engineering / Tools"
  },
  {
    "Code": "COM073000",
    "Literal": "COMPUTERS / Speech & Audio Processing"
  },
  {
    "Code": "COM088000",
    "Literal": "COMPUTERS / System Administration / General"
  },
  {
    "Code": "COM019000",
    "Literal": "COMPUTERS / System Administration / Disaster & Recovery"
  },
  {
    "Code": "COM020020",
    "Literal": "COMPUTERS / System Administration / Email Administration"
  },
  {
    "Code": "COM088010",
    "Literal": "COMPUTERS / System Administration / Linux & UNIX Administration"
  },
  {
    "Code": "COM030000",
    "Literal": "COMPUTERS / System Administration / Storage & Retrieval"
  },
  {
    "Code": "COM088020",
    "Literal": "COMPUTERS / System Administration / Windows Administration"
  },
  {
    "Code": "COM011000",
    "Literal": "COMPUTERS / Systems Architecture / General"
  },
  {
    "Code": "COM048000",
    "Literal": "COMPUTERS / Systems Architecture / Distributed Systems & Computing"
  },
  {
    "Code": "COM070000",
    "Literal": "COMPUTERS / User Interfaces"
  },
  {
    "Code": "COM056000",
    "Literal": "COMPUTERS / Utilities"
  },
  {
    "Code": "COM057000",
    "Literal": "COMPUTERS / Virtual Worlds"
  },
  {
    "Code": "COM060080",
    "Literal": "COMPUTERS / Web / General"
  },
  {
    "Code": "COM060100",
    "Literal": "COMPUTERS / Web / Blogging"
  },
  {
    "Code": "COM060010",
    "Literal": "COMPUTERS / Web / Browsers"
  },
  {
    "Code": "COM060170",
    "Literal": "COMPUTERS / Web / Content Management Systems"
  },
  {
    "Code": "COM060130",
    "Literal": "COMPUTERS / Web / Design"
  },
  {
    "Code": "COM060110",
    "Literal": "COMPUTERS / Web / Podcasting & Webcasting"
  },
  {
    "Code": "COM060120",
    "Literal": "COMPUTERS / Web / Search Engines"
  },
  {
    "Code": "COM060070",
    "Literal": "COMPUTERS / Web / Site Directories"
  },
  {
    "Code": "COM060140",
    "Literal": "COMPUTERS / Web / Social Media"
  },
  {
    "Code": "COM060150",
    "Literal": "COMPUTERS / Web / User-Generated Content"
  },
  {
    "Code": "COM060160",
    "Literal": "COMPUTERS / Web / Web Programming"
  },
  {
    "Code": "COM060180",
    "Literal": "COMPUTERS / Web / Web Services & APIs"
  },
  {
    "Code": "CKB000000",
    "Literal": "COOKING / General"
  },
  {
    "Code": "CKB107000",
    "Literal": "COOKING / Baby Food"
  },
  {
    "Code": "CKB100000",
    "Literal": "COOKING / Beverages / General"
  },
  {
    "Code": "CKB088000",
    "Literal": "COOKING / Beverages / Alcoholic / General"
  },
  {
    "Code": "CKB006000",
    "Literal": "COOKING / Beverages / Alcoholic / Bartending"
  },
  {
    "Code": "CKB007000",
    "Literal": "COOKING / Beverages / Alcoholic / Beer"
  },
  {
    "Code": "CKB126000",
    "Literal": "COOKING / Beverages / Alcoholic / Wine"
  },
  {
    "Code": "CKB019000",
    "Literal": "COOKING / Beverages / Coffee & Tea"
  },
  {
    "Code": "CKB118000",
    "Literal": "COOKING / Beverages / Juices & Smoothies"
  },
  {
    "Code": "CKB008000",
    "Literal": "COOKING / Beverages / Non-Alcoholic"
  },
  {
    "Code": "CKB127000",
    "Literal": "COOKING / Comfort Food"
  },
  {
    "Code": "CKB119000",
    "Literal": "COOKING / Cooking for Kids"
  },
  {
    "Code": "CKB120000",
    "Literal": "COOKING / Cooking with Kids"
  },
  {
    "Code": "CKB101000",
    "Literal": "COOKING / Courses & Dishes / General"
  },
  {
    "Code": "CKB003000",
    "Literal": "COOKING / Courses & Dishes / Appetizers"
  },
  {
    "Code": "CKB009000",
    "Literal": "COOKING / Courses & Dishes / Bread"
  },
  {
    "Code": "CKB010000",
    "Literal": "COOKING / Courses & Dishes / Breakfast"
  },
  {
    "Code": "CKB012000",
    "Literal": "COOKING / Courses & Dishes / Brunch & Tea Time"
  },
  {
    "Code": "CKB014000",
    "Literal": "COOKING / Courses & Dishes / Cakes"
  },
  {
    "Code": "CKB112000",
    "Literal": "COOKING / Courses & Dishes / Casseroles"
  },
  {
    "Code": "CKB095000",
    "Literal": "COOKING / Courses & Dishes / Confectionery"
  },
  {
    "Code": "CKB021000",
    "Literal": "COOKING / Courses & Dishes / Cookies"
  },
  {
    "Code": "CKB024000",
    "Literal": "COOKING / Courses & Dishes / Desserts"
  },
  {
    "Code": "CKB122000",
    "Literal": "COOKING / Courses & Dishes / Ice Cream, Ices, etc."
  },
  {
    "Code": "CKB062000",
    "Literal": "COOKING / Courses & Dishes / Pastry"
  },
  {
    "Code": "CKB063000",
    "Literal": "COOKING / Courses & Dishes / Pies"
  },
  {
    "Code": "CKB064000",
    "Literal": "COOKING / Courses & Dishes / Pizza"
  },
  {
    "Code": "CKB073000",
    "Literal": "COOKING / Courses & Dishes / Salads"
  },
  {
    "Code": "CKB121000",
    "Literal": "COOKING / Courses & Dishes / Sandwiches"
  },
  {
    "Code": "CKB102000",
    "Literal": "COOKING / Courses & Dishes / Sauces & Dressings"
  },
  {
    "Code": "CKB079000",
    "Literal": "COOKING / Courses & Dishes / Soups & Stews"
  },
  {
    "Code": "CKB029000",
    "Literal": "COOKING / Entertaining"
  },
  {
    "Code": "CKB030000",
    "Literal": "COOKING / Essays & Narratives"
  },
  {
    "Code": "CKB039000",
    "Literal": "COOKING / Health & Healing / General"
  },
  {
    "Code": "CKB106000",
    "Literal": "COOKING / Health & Healing / Allergy"
  },
  {
    "Code": "CKB103000",
    "Literal": "COOKING / Health & Healing / Cancer"
  },
  {
    "Code": "CKB025000",
    "Literal": "COOKING / Health & Healing / Diabetic & Sugar-Free"
  },
  {
    "Code": "CKB111000",
    "Literal": "COOKING / Health & Healing / Gluten-Free"
  },
  {
    "Code": "CKB104000",
    "Literal": "COOKING / Health & Healing / Heart"
  },
  {
    "Code": "CKB114000",
    "Literal": "COOKING / Health & Healing / High Protein"
  },
  {
    "Code": "CKB108000",
    "Literal": "COOKING / Health & Healing / Low Carbohydrate"
  },
  {
    "Code": "CKB050000",
    "Literal": "COOKING / Health & Healing / Low Cholesterol"
  },
  {
    "Code": "CKB051000",
    "Literal": "COOKING / Health & Healing / Low Fat"
  },
  {
    "Code": "CKB052000",
    "Literal": "COOKING / Health & Healing / Low Salt"
  },
  {
    "Code": "CKB026000",
    "Literal": "COOKING / Health & Healing / Weight Control"
  },
  {
    "Code": "CKB041000",
    "Literal": "COOKING / History"
  },
  {
    "Code": "CKB042000",
    "Literal": "COOKING / Holiday"
  },
  {
    "Code": "CKB115000",
    "Literal": "COOKING / Individual Chefs & Restaurants"
  },
  {
    "Code": "CKB128000",
    "Literal": "COOKING / Media Tie-In"
  },
  {
    "Code": "CKB023000",
    "Literal": "COOKING / Methods / General"
  },
  {
    "Code": "CKB004000",
    "Literal": "COOKING / Methods / Baking"
  },
  {
    "Code": "CKB005000",
    "Literal": "COOKING / Methods / Barbecue & Grilling"
  },
  {
    "Code": "CKB015000",
    "Literal": "COOKING / Methods / Canning & Preserving"
  },
  {
    "Code": "CKB020000",
    "Literal": "COOKING / Methods / Cookery for One"
  },
  {
    "Code": "CKB116000",
    "Literal": "COOKING / Methods / Frying"
  },
  {
    "Code": "CKB033000",
    "Literal": "COOKING / Methods / Garnishing & Food Presentation"
  },
  {
    "Code": "CKB037000",
    "Literal": "COOKING / Methods / Gourmet"
  },
  {
    "Code": "CKB113000",
    "Literal": "COOKING / Methods / Low Budget"
  },
  {
    "Code": "CKB057000",
    "Literal": "COOKING / Methods / Microwave"
  },
  {
    "Code": "CKB060000",
    "Literal": "COOKING / Methods / Outdoor"
  },
  {
    "Code": "CKB129000",
    "Literal": "COOKING / Methods / Pressure Cooking"
  },
  {
    "Code": "CKB068000",
    "Literal": "COOKING / Methods / Professional"
  },
  {
    "Code": "CKB069000",
    "Literal": "COOKING / Methods / Quantity"
  },
  {
    "Code": "CKB070000",
    "Literal": "COOKING / Methods / Quick & Easy"
  },
  {
    "Code": "CKB110000",
    "Literal": "COOKING / Methods / Raw Food"
  },
  {
    "Code": "CKB109000",
    "Literal": "COOKING / Methods / Slow Cooking"
  },
  {
    "Code": "CKB081000",
    "Literal": "COOKING / Methods / Special Appliances"
  },
  {
    "Code": "CKB089000",
    "Literal": "COOKING / Methods / Wok"
  },
  {
    "Code": "CKB117000",
    "Literal": "COOKING / Pet Food"
  },
  {
    "Code": "CKB071000",
    "Literal": "COOKING / Reference"
  },
  {
    "Code": "CKB031000",
    "Literal": "COOKING / Regional & Ethnic / General"
  },
  {
    "Code": "CKB001000",
    "Literal": "COOKING / Regional & Ethnic / African"
  },
  {
    "Code": "CKB002000",
    "Literal": "COOKING / Regional & Ethnic / American / General"
  },
  {
    "Code": "CKB002010",
    "Literal": "COOKING / Regional & Ethnic / American / California Style"
  },
  {
    "Code": "CKB002020",
    "Literal": "COOKING / Regional & Ethnic / American / Middle Atlantic States"
  },
  {
    "Code": "CKB002030",
    "Literal": "COOKING / Regional & Ethnic / American / Middle Western States"
  },
  {
    "Code": "CKB002040",
    "Literal": "COOKING / Regional & Ethnic / American / New England"
  },
  {
    "Code": "CKB002050",
    "Literal": "COOKING / Regional & Ethnic / American / Northwestern States"
  },
  {
    "Code": "CKB002060",
    "Literal": "COOKING / Regional & Ethnic / American / Southern States"
  },
  {
    "Code": "CKB002070",
    "Literal": "COOKING / Regional & Ethnic / American / Southwestern States"
  },
  {
    "Code": "CKB002080",
    "Literal": "COOKING / Regional & Ethnic / American / Western States"
  },
  {
    "Code": "CKB090000",
    "Literal": "COOKING / Regional & Ethnic / Asian"
  },
  {
    "Code": "CKB097000",
    "Literal": "COOKING / Regional & Ethnic / Australian & Oceanian"
  },
  {
    "Code": "CKB013000",
    "Literal": "COOKING / Regional & Ethnic / Cajun & Creole"
  },
  {
    "Code": "CKB091000",
    "Literal": "COOKING / Regional & Ethnic / Canadian"
  },
  {
    "Code": "CKB016000",
    "Literal": "COOKING / Regional & Ethnic / Caribbean & West Indian"
  },
  {
    "Code": "CKB099000",
    "Literal": "COOKING / Regional & Ethnic / Central American & South American"
  },
  {
    "Code": "CKB017000",
    "Literal": "COOKING / Regional & Ethnic / Chinese"
  },
  {
    "Code": "CKB011000",
    "Literal": "COOKING / Regional & Ethnic / English, Scottish & Welsh"
  },
  {
    "Code": "CKB092000",
    "Literal": "COOKING / Regional & Ethnic / European"
  },
  {
    "Code": "CKB034000",
    "Literal": "COOKING / Regional & Ethnic / French"
  },
  {
    "Code": "CKB036000",
    "Literal": "COOKING / Regional & Ethnic / German"
  },
  {
    "Code": "CKB038000",
    "Literal": "COOKING / Regional & Ethnic / Greek"
  },
  {
    "Code": "CKB043000",
    "Literal": "COOKING / Regional & Ethnic / Hungarian"
  },
  {
    "Code": "CKB044000",
    "Literal": "COOKING / Regional & Ethnic / Indian & South Asian"
  },
  {
    "Code": "CKB045000",
    "Literal": "COOKING / Regional & Ethnic / International"
  },
  {
    "Code": "CKB046000",
    "Literal": "COOKING / Regional & Ethnic / Irish"
  },
  {
    "Code": "CKB047000",
    "Literal": "COOKING / Regional & Ethnic / Italian"
  },
  {
    "Code": "CKB048000",
    "Literal": "COOKING / Regional & Ethnic / Japanese"
  },
  {
    "Code": "CKB049000",
    "Literal": "COOKING / Regional & Ethnic / Jewish & Kosher"
  },
  {
    "Code": "CKB123000",
    "Literal": "COOKING / Regional & Ethnic / Korean"
  },
  {
    "Code": "CKB055000",
    "Literal": "COOKING / Regional & Ethnic / Mediterranean"
  },
  {
    "Code": "CKB056000",
    "Literal": "COOKING / Regional & Ethnic / Mexican"
  },
  {
    "Code": "CKB093000",
    "Literal": "COOKING / Regional & Ethnic / Middle Eastern"
  },
  {
    "Code": "CKB058000",
    "Literal": "COOKING / Regional & Ethnic / Native American"
  },
  {
    "Code": "CKB065000",
    "Literal": "COOKING / Regional & Ethnic / Polish"
  },
  {
    "Code": "CKB066000",
    "Literal": "COOKING / Regional & Ethnic / Portuguese"
  },
  {
    "Code": "CKB072000",
    "Literal": "COOKING / Regional & Ethnic / Russian"
  },
  {
    "Code": "CKB074000",
    "Literal": "COOKING / Regional & Ethnic / Scandinavian"
  },
  {
    "Code": "CKB078000",
    "Literal": "COOKING / Regional & Ethnic / Soul Food"
  },
  {
    "Code": "CKB124000",
    "Literal": "COOKING / Regional & Ethnic / Southeast Asian"
  },
  {
    "Code": "CKB080000",
    "Literal": "COOKING / Regional & Ethnic / Spanish"
  },
  {
    "Code": "CKB083000",
    "Literal": "COOKING / Regional & Ethnic / Thai"
  },
  {
    "Code": "CKB084000",
    "Literal": "COOKING / Regional & Ethnic / Turkish"
  },
  {
    "Code": "CKB094000",
    "Literal": "COOKING / Regional & Ethnic / Vietnamese"
  },
  {
    "Code": "CKB077000",
    "Literal": "COOKING / Seasonal"
  },
  {
    "Code": "CKB105000",
    "Literal": "COOKING / Specific Ingredients / General"
  },
  {
    "Code": "CKB018000",
    "Literal": "COOKING / Specific Ingredients / Chocolate"
  },
  {
    "Code": "CKB096000",
    "Literal": "COOKING / Specific Ingredients / Dairy"
  },
  {
    "Code": "CKB035000",
    "Literal": "COOKING / Specific Ingredients / Fruit"
  },
  {
    "Code": "CKB032000",
    "Literal": "COOKING / Specific Ingredients / Game"
  },
  {
    "Code": "CKB040000",
    "Literal": "COOKING / Specific Ingredients / Herbs, Spices, Condiments"
  },
  {
    "Code": "CKB054000",
    "Literal": "COOKING / Specific Ingredients / Meat"
  },
  {
    "Code": "CKB059000",
    "Literal": "COOKING / Specific Ingredients / Natural Foods"
  },
  {
    "Code": "CKB061000",
    "Literal": "COOKING / Specific Ingredients / Pasta"
  },
  {
    "Code": "CKB067000",
    "Literal": "COOKING / Specific Ingredients / Poultry"
  },
  {
    "Code": "CKB098000",
    "Literal": "COOKING / Specific Ingredients / Rice & Grains"
  },
  {
    "Code": "CKB076000",
    "Literal": "COOKING / Specific Ingredients / Seafood"
  },
  {
    "Code": "CKB085000",
    "Literal": "COOKING / Specific Ingredients / Vegetables"
  },
  {
    "Code": "CKB082000",
    "Literal": "COOKING / Tablesetting"
  },
  {
    "Code": "CKB125000",
    "Literal": "COOKING / Vegan"
  },
  {
    "Code": "CKB086000",
    "Literal": "COOKING / Vegetarian"
  },
  {
    "Code": "CRA000000",
    "Literal": "CRAFTS & HOBBIES / General"
  },
  {
    "Code": "CRA001000",
    "Literal": "CRAFTS & HOBBIES / Applique"
  },
  {
    "Code": "CRA002000",
    "Literal": "CRAFTS & HOBBIES / Baskets"
  },
  {
    "Code": "CRA048000",
    "Literal": "CRAFTS & HOBBIES / Beadwork"
  },
  {
    "Code": "CRA046000",
    "Literal": "CRAFTS & HOBBIES / Book Printing & Binding"
  },
  {
    "Code": "CRA049000",
    "Literal": "CRAFTS & HOBBIES / Candle Making"
  },
  {
    "Code": "CRA003000",
    "Literal": "CRAFTS & HOBBIES / Carving"
  },
  {
    "Code": "CRA043000",
    "Literal": "CRAFTS & HOBBIES / Crafts for Children"
  },
  {
    "Code": "CRA005000",
    "Literal": "CRAFTS & HOBBIES / Decorating"
  },
  {
    "Code": "CRA056000",
    "Literal": "CRAFTS & HOBBIES / Dollhouses"
  },
  {
    "Code": "CRA057000",
    "Literal": "CRAFTS & HOBBIES / Dolls & Doll Clothing"
  },
  {
    "Code": "CRA006000",
    "Literal": "CRAFTS & HOBBIES / Dough"
  },
  {
    "Code": "CRA007000",
    "Literal": "CRAFTS & HOBBIES / Dye"
  },
  {
    "Code": "CRA009000",
    "Literal": "CRAFTS & HOBBIES / Fashion"
  },
  {
    "Code": "CRA060000",
    "Literal": "CRAFTS & HOBBIES / Felting"
  },
  {
    "Code": "CRA061000",
    "Literal": "CRAFTS & HOBBIES / Fiber Arts & Textiles"
  },
  {
    "Code": "CRA010000",
    "Literal": "CRAFTS & HOBBIES / Flower Arranging"
  },
  {
    "Code": "CRA047000",
    "Literal": "CRAFTS & HOBBIES / Folkcrafts"
  },
  {
    "Code": "CRA011000",
    "Literal": "CRAFTS & HOBBIES / Framing"
  },
  {
    "Code": "CRA062000",
    "Literal": "CRAFTS & HOBBIES / Furniture & Cabinetry"
  },
  {
    "Code": "CRA012000",
    "Literal": "CRAFTS & HOBBIES / Glass & Glassware"
  },
  {
    "Code": "CRA034000",
    "Literal": "CRAFTS & HOBBIES / Holiday & Seasonal"
  },
  {
    "Code": "CRA014000",
    "Literal": "CRAFTS & HOBBIES / Jewelry"
  },
  {
    "Code": "CRA055000",
    "Literal": "CRAFTS & HOBBIES / Knots, Macrame & Rope Work"
  },
  {
    "Code": "CRA050000",
    "Literal": "CRAFTS & HOBBIES / Leatherwork"
  },
  {
    "Code": "CRA017000",
    "Literal": "CRAFTS & HOBBIES / Metal Work"
  },
  {
    "Code": "CRA018000",
    "Literal": "CRAFTS & HOBBIES / Miniatures"
  },
  {
    "Code": "CRA054000",
    "Literal": "CRAFTS & HOBBIES / Mixed Media"
  },
  {
    "Code": "CRA045000",
    "Literal": "CRAFTS & HOBBIES / Model Railroading"
  },
  {
    "Code": "CRA020000",
    "Literal": "CRAFTS & HOBBIES / Models"
  },
  {
    "Code": "CRA053000",
    "Literal": "CRAFTS & HOBBIES / Nature Crafts"
  },
  {
    "Code": "CRA022000",
    "Literal": "CRAFTS & HOBBIES / Needlework / General"
  },
  {
    "Code": "CRA004000",
    "Literal": "CRAFTS & HOBBIES / Needlework / Crocheting"
  },
  {
    "Code": "CRA044000",
    "Literal": "CRAFTS & HOBBIES / Needlework / Cross-Stitch"
  },
  {
    "Code": "CRA008000",
    "Literal": "CRAFTS & HOBBIES / Needlework / Embroidery"
  },
  {
    "Code": "CRA015000",
    "Literal": "CRAFTS & HOBBIES / Needlework / Knitting"
  },
  {
    "Code": "CRA016000",
    "Literal": "CRAFTS & HOBBIES / Needlework / Lace & Tatting"
  },
  {
    "Code": "CRA021000",
    "Literal": "CRAFTS & HOBBIES / Needlework / Needlepoint"
  },
  {
    "Code": "CRA023000",
    "Literal": "CRAFTS & HOBBIES / Origami"
  },
  {
    "Code": "CRA024000",
    "Literal": "CRAFTS & HOBBIES / Painting"
  },
  {
    "Code": "CRA025000",
    "Literal": "CRAFTS & HOBBIES / Papercrafts"
  },
  {
    "Code": "CRA026000",
    "Literal": "CRAFTS & HOBBIES / Patchwork"
  },
  {
    "Code": "CRA051000",
    "Literal": "CRAFTS & HOBBIES / Polymer Clay"
  },
  {
    "Code": "CRA027000",
    "Literal": "CRAFTS & HOBBIES / Potpourri & Fragrances"
  },
  {
    "Code": "CRA028000",
    "Literal": "CRAFTS & HOBBIES / Pottery & Ceramics"
  },
  {
    "Code": "CRA029000",
    "Literal": "CRAFTS & HOBBIES / Printmaking & Stamping"
  },
  {
    "Code": "CRA030000",
    "Literal": "CRAFTS & HOBBIES / Puppets & Puppetry"
  },
  {
    "Code": "CRA031000",
    "Literal": "CRAFTS & HOBBIES / Quilts & Quilting"
  },
  {
    "Code": "CRA032000",
    "Literal": "CRAFTS & HOBBIES / Reference"
  },
  {
    "Code": "CRA058000",
    "Literal": "CRAFTS & HOBBIES / Ribbon Work"
  },
  {
    "Code": "CRA033000",
    "Literal": "CRAFTS & HOBBIES / Rugs"
  },
  {
    "Code": "CRA052000",
    "Literal": "CRAFTS & HOBBIES / Scrapbooking"
  },
  {
    "Code": "CRA035000",
    "Literal": "CRAFTS & HOBBIES / Sewing"
  },
  {
    "Code": "CRA064000",
    "Literal": "CRAFTS & HOBBIES / Soap Making"
  },
  {
    "Code": "CRA036000",
    "Literal": "CRAFTS & HOBBIES / Stenciling"
  },
  {
    "Code": "CRA037000",
    "Literal": "CRAFTS & HOBBIES / Stuffed Animals"
  },
  {
    "Code": "CRA039000",
    "Literal": "CRAFTS & HOBBIES / Toymaking"
  },
  {
    "Code": "CRA063000",
    "Literal": "CRAFTS & HOBBIES / Upcycling & Repurposing"
  },
  {
    "Code": "CRA040000",
    "Literal": "CRAFTS & HOBBIES / Weaving & Spinning"
  },
  {
    "Code": "CRA059000",
    "Literal": "CRAFTS & HOBBIES / Wirework"
  },
  {
    "Code": "CRA041000",
    "Literal": "CRAFTS & HOBBIES / Wood Toys"
  },
  {
    "Code": "CRA042000",
    "Literal": "CRAFTS & HOBBIES / Woodwork"
  },
  {
    "Code": "DES000000",
    "Literal": "DESIGN / General"
  },
  {
    "Code": "DES001000",
    "Literal": "DESIGN / Book"
  },
  {
    "Code": "DES002000",
    "Literal": "DESIGN / Clip Art"
  },
  {
    "Code": "DES003000",
    "Literal": "DESIGN / Decorative Arts"
  },
  {
    "Code": "DES004000",
    "Literal": "DESIGN / Essays"
  },
  {
    "Code": "DES005000",
    "Literal": "DESIGN / Fashion & Accessories"
  },
  {
    "Code": "DES006000",
    "Literal": "DESIGN / Furniture"
  },
  {
    "Code": "DES007000",
    "Literal": "DESIGN / Graphic Arts / General"
  },
  {
    "Code": "DES007010",
    "Literal": "DESIGN / Graphic Arts / Advertising"
  },
  {
    "Code": "DES007020",
    "Literal": "DESIGN / Graphic Arts / Branding & Logo Design"
  },
  {
    "Code": "DES007030",
    "Literal": "DESIGN / Graphic Arts / Commercial & Corporate"
  },
  {
    "Code": "DES007040",
    "Literal": "DESIGN / Graphic Arts / Illustration"
  },
  {
    "Code": "DES007050",
    "Literal": "DESIGN / Graphic Arts / Typography"
  },
  {
    "Code": "DES008000",
    "Literal": "DESIGN / History & Criticism"
  },
  {
    "Code": "DES009000",
    "Literal": "DESIGN / Industrial"
  },
  {
    "Code": "DES014000",
    "Literal": "DESIGN / Jewelry"
  },
  {
    "Code": "DES011000",
    "Literal": "DESIGN / Product"
  },
  {
    "Code": "DES012000",
    "Literal": "DESIGN / Reference"
  },
  {
    "Code": "DES013000",
    "Literal": "DESIGN / Textile & Costume"
  },
  {
    "Code": "DRA000000",
    "Literal": "DRAMA / General"
  },
  {
    "Code": "DRA011000",
    "Literal": "DRAMA / African"
  },
  {
    "Code": "DRA001000",
    "Literal": "DRAMA / American / General"
  },
  {
    "Code": "DRA001010",
    "Literal": "DRAMA / American / African American"
  },
  {
    "Code": "DRA006000",
    "Literal": "DRAMA / Ancient & Classical"
  },
  {
    "Code": "DRA002000",
    "Literal": "DRAMA / Anthologies (multiple authors)"
  },
  {
    "Code": "DRA005000",
    "Literal": "DRAMA / Asian / General"
  },
  {
    "Code": "DRA005010",
    "Literal": "DRAMA / Asian / Japanese"
  },
  {
    "Code": "DRA012000",
    "Literal": "DRAMA / Australian & Oceanian"
  },
  {
    "Code": "DRA013000",
    "Literal": "DRAMA / Canadian"
  },
  {
    "Code": "DRA014000",
    "Literal": "DRAMA / Caribbean & Latin American"
  },
  {
    "Code": "DRA004000",
    "Literal": "DRAMA / European / General"
  },
  {
    "Code": "DRA003000",
    "Literal": "DRAMA / European / English, Irish, Scottish, Welsh"
  },
  {
    "Code": "DRA004010",
    "Literal": "DRAMA / European / French"
  },
  {
    "Code": "DRA004020",
    "Literal": "DRAMA / European / German"
  },
  {
    "Code": "DRA004030",
    "Literal": "DRAMA / European / Italian"
  },
  {
    "Code": "DRA004040",
    "Literal": "DRAMA / European / Spanish & Portuguese"
  },
  {
    "Code": "DRA017000",
    "Literal": "DRAMA / LGBT"
  },
  {
    "Code": "DRA018000",
    "Literal": "DRAMA / Medieval"
  },
  {
    "Code": "DRA015000",
    "Literal": "DRAMA / Middle Eastern"
  },
  {
    "Code": "DRA008000",
    "Literal": "DRAMA / Religious & Liturgical"
  },
  {
    "Code": "DRA016000",
    "Literal": "DRAMA / Russian & Former Soviet Union"
  },
  {
    "Code": "DRA010000",
    "Literal": "DRAMA / Shakespeare"
  },
  {
    "Code": "DRA019000",
    "Literal": "DRAMA / Women Authors"
  },
  {
    "Code": "EDU000000",
    "Literal": "EDUCATION / General"
  },
  {
    "Code": "EDU001000",
    "Literal": "EDUCATION / Administration / General"
  },
  {
    "Code": "EDU001020",
    "Literal": "EDUCATION / Administration / Elementary & Secondary"
  },
  {
    "Code": "EDU001010",
    "Literal": "EDUCATION / Administration / Facility Management"
  },
  {
    "Code": "EDU001030",
    "Literal": "EDUCATION / Administration / Higher"
  },
  {
    "Code": "EDU001040",
    "Literal": "EDUCATION / Administration / School Superintendents & Principals"
  },
  {
    "Code": "EDU002000",
    "Literal": "EDUCATION / Adult & Continuing Education"
  },
  {
    "Code": "EDU003000",
    "Literal": "EDUCATION / Aims & Objectives"
  },
  {
    "Code": "EDU057000",
    "Literal": "EDUCATION / Arts in Education"
  },
  {
    "Code": "EDU049000",
    "Literal": "EDUCATION / Behavioral Management"
  },
  {
    "Code": "EDU005000",
    "Literal": "EDUCATION / Bilingual Education"
  },
  {
    "Code": "EDU044000",
    "Literal": "EDUCATION / Classroom Management"
  },
  {
    "Code": "EDU050000",
    "Literal": "EDUCATION / Collaborative & Team Teaching"
  },
  {
    "Code": "EDU043000",
    "Literal": "EDUCATION / Comparative"
  },
  {
    "Code": "EDU039000",
    "Literal": "EDUCATION / Computers & Technology"
  },
  {
    "Code": "EDU006000",
    "Literal": "EDUCATION / Counseling / General"
  },
  {
    "Code": "EDU014000",
    "Literal": "EDUCATION / Counseling / Academic Development"
  },
  {
    "Code": "EDU031000",
    "Literal": "EDUCATION / Counseling / Career Development"
  },
  {
    "Code": "EDU045000",
    "Literal": "EDUCATION / Counseling / Crisis Management"
  },
  {
    "Code": "EDU007000",
    "Literal": "EDUCATION / Curricula"
  },
  {
    "Code": "EDU008000",
    "Literal": "EDUCATION / Decision-Making & Problem Solving"
  },
  {
    "Code": "EDU041000",
    "Literal": "EDUCATION / Distance, Open & Online Education"
  },
  {
    "Code": "EDU023000",
    "Literal": "EDUCATION / Early Childhood (incl. Preschool & Kindergarten)"
  },
  {
    "Code": "EDU034000",
    "Literal": "EDUCATION / Educational Policy & Reform / General"
  },
  {
    "Code": "EDU034020",
    "Literal": "EDUCATION / Educational Policy & Reform / Charter Schools"
  },
  {
    "Code": "EDU034030",
    "Literal": "EDUCATION / Educational Policy & Reform / Federal Legislation"
  },
  {
    "Code": "EDU034010",
    "Literal": "EDUCATION / Educational Policy & Reform / School Safety"
  },
  {
    "Code": "EDU009000",
    "Literal": "EDUCATION / Educational Psychology"
  },
  {
    "Code": "EDU010000",
    "Literal": "EDUCATION / Elementary"
  },
  {
    "Code": "EDU042000",
    "Literal": "EDUCATION / Essays"
  },
  {
    "Code": "EDU011000",
    "Literal": "EDUCATION / Evaluation & Assessment"
  },
  {
    "Code": "EDU012000",
    "Literal": "EDUCATION / Experimental Methods"
  },
  {
    "Code": "EDU013000",
    "Literal": "EDUCATION / Finance"
  },
  {
    "Code": "EDU015000",
    "Literal": "EDUCATION / Higher"
  },
  {
    "Code": "EDU016000",
    "Literal": "EDUCATION / History"
  },
  {
    "Code": "EDU017000",
    "Literal": "EDUCATION / Home Schooling"
  },
  {
    "Code": "EDU048000",
    "Literal": "EDUCATION / Inclusive Education"
  },
  {
    "Code": "EDU018000",
    "Literal": "EDUCATION / Language Experience Approach"
  },
  {
    "Code": "EDU032000",
    "Literal": "EDUCATION / Leadership"
  },
  {
    "Code": "EDU051000",
    "Literal": "EDUCATION / Learning Styles"
  },
  {
    "Code": "EDU020000",
    "Literal": "EDUCATION / Multicultural Education"
  },
  {
    "Code": "EDU021000",
    "Literal": "EDUCATION / Non-Formal Education"
  },
  {
    "Code": "EDU036000",
    "Literal": "EDUCATION / Organizations & Institutions"
  },
  {
    "Code": "EDU022000",
    "Literal": "EDUCATION / Parent Participation"
  },
  {
    "Code": "EDU040000",
    "Literal": "EDUCATION / Philosophy, Theory & Social Aspects"
  },
  {
    "Code": "EDU033000",
    "Literal": "EDUCATION / Physical Education"
  },
  {
    "Code": "EDU046000",
    "Literal": "EDUCATION / Professional Development"
  },
  {
    "Code": "EDU024000",
    "Literal": "EDUCATION / Reference"
  },
  {
    "Code": "EDU037000",
    "Literal": "EDUCATION / Research"
  },
  {
    "Code": "EDU052000",
    "Literal": "EDUCATION / Rural"
  },
  {
    "Code": "EDU025000",
    "Literal": "EDUCATION / Secondary"
  },
  {
    "Code": "EDU026000",
    "Literal": "EDUCATION / Special Education / General"
  },
  {
    "Code": "EDU026050",
    "Literal": "EDUCATION / Special Education / Behavioral, Emotional & Social Disabilities"
  },
  {
    "Code": "EDU026010",
    "Literal": "EDUCATION / Special Education / Communicative Disorders"
  },
  {
    "Code": "EDU026030",
    "Literal": "EDUCATION / Special Education / Developmental & Intellectual Disabilities"
  },
  {
    "Code": "EDU026060",
    "Literal": "EDUCATION / Special Education / Gifted"
  },
  {
    "Code": "EDU026020",
    "Literal": "EDUCATION / Special Education / Learning Disabilities"
  },
  {
    "Code": "EDU026040",
    "Literal": "EDUCATION / Special Education / Physical Disabilities"
  },
  {
    "Code": "EDU058000",
    "Literal": "EDUCATION / Standards (incl. Common Core)"
  },
  {
    "Code": "EDU027000",
    "Literal": "EDUCATION / Statistics"
  },
  {
    "Code": "EDU038000",
    "Literal": "EDUCATION / Student Life & Student Affairs"
  },
  {
    "Code": "EDU059000",
    "Literal": "EDUCATION / Teacher & Student Mentoring"
  },
  {
    "Code": "EDU053000",
    "Literal": "EDUCATION / Teacher Training & Certification"
  },
  {
    "Code": "EDU029000",
    "Literal": "EDUCATION / Teaching Methods & Materials / General"
  },
  {
    "Code": "EDU029050",
    "Literal": "EDUCATION / Teaching Methods & Materials / Arts & Humanities"
  },
  {
    "Code": "EDU029070",
    "Literal": "EDUCATION / Teaching Methods & Materials / Health & Sexuality"
  },
  {
    "Code": "EDU029080",
    "Literal": "EDUCATION / Teaching Methods & Materials / Language Arts"
  },
  {
    "Code": "EDU029060",
    "Literal": "EDUCATION / Teaching Methods & Materials / Library Skills"
  },
  {
    "Code": "EDU029010",
    "Literal": "EDUCATION / Teaching Methods & Materials / Mathematics"
  },
  {
    "Code": "EDU029020",
    "Literal": "EDUCATION / Teaching Methods & Materials / Reading & Phonics"
  },
  {
    "Code": "EDU029030",
    "Literal": "EDUCATION / Teaching Methods & Materials / Science & Technology"
  },
  {
    "Code": "EDU029040",
    "Literal": "EDUCATION / Teaching Methods & Materials / Social Science"
  },
  {
    "Code": "EDU030000",
    "Literal": "EDUCATION / Testing & Measurement"
  },
  {
    "Code": "EDU054000",
    "Literal": "EDUCATION / Urban"
  },
  {
    "Code": "EDU055000",
    "Literal": "EDUCATION / Violence & Harassment"
  },
  {
    "Code": "EDU056000",
    "Literal": "EDUCATION / Vocational"
  },
  {
    "Code": "FAM000000",
    "Literal": "FAMILY & RELATIONSHIPS / General"
  },
  {
    "Code": "FAM001000",
    "Literal": "FAMILY & RELATIONSHIPS / Abuse / General"
  },
  {
    "Code": "FAM001010",
    "Literal": "FAMILY & RELATIONSHIPS / Abuse / Child Abuse"
  },
  {
    "Code": "FAM001030",
    "Literal": "FAMILY & RELATIONSHIPS / Abuse / Domestic Partner Abuse"
  },
  {
    "Code": "FAM001020",
    "Literal": "FAMILY & RELATIONSHIPS / Abuse / Elder Abuse"
  },
  {
    "Code": "FAM002000",
    "Literal": "FAMILY & RELATIONSHIPS / Activities"
  },
  {
    "Code": "FAM004000",
    "Literal": "FAMILY & RELATIONSHIPS / Adoption & Fostering"
  },
  {
    "Code": "FAM006000",
    "Literal": "FAMILY & RELATIONSHIPS / Alternative Family"
  },
  {
    "Code": "FAM007000",
    "Literal": "FAMILY & RELATIONSHIPS / Anger (see also SELF-HELP / Self-Management / Anger Management)"
  },
  {
    "Code": "FAM047000",
    "Literal": "FAMILY & RELATIONSHIPS / Attention Deficit Disorder (ADD-ADHD)"
  },
  {
    "Code": "FAM048000",
    "Literal": "FAMILY & RELATIONSHIPS / Autism Spectrum Disorders"
  },
  {
    "Code": "FAM008000",
    "Literal": "FAMILY & RELATIONSHIPS / Baby Names"
  },
  {
    "Code": "FAM050000",
    "Literal": "FAMILY & RELATIONSHIPS / Babysitting, Day Care & Child Care"
  },
  {
    "Code": "FAM049000",
    "Literal": "FAMILY & RELATIONSHIPS / Bullying"
  },
  {
    "Code": "FAM012000",
    "Literal": "FAMILY & RELATIONSHIPS / Children with Special Needs"
  },
  {
    "Code": "FAM013000",
    "Literal": "FAMILY & RELATIONSHIPS / Conflict Resolution"
  },
  {
    "Code": "FAM051000",
    "Literal": "FAMILY & RELATIONSHIPS / Dating"
  },
  {
    "Code": "FAM014000",
    "Literal": "FAMILY & RELATIONSHIPS / Death, Grief, Bereavement"
  },
  {
    "Code": "FAM015000",
    "Literal": "FAMILY & RELATIONSHIPS / Divorce & Separation"
  },
  {
    "Code": "FAM052000",
    "Literal": "FAMILY & RELATIONSHIPS / Dysfunctional Families"
  },
  {
    "Code": "FAM016000",
    "Literal": "FAMILY & RELATIONSHIPS / Education"
  },
  {
    "Code": "FAM017000",
    "Literal": "FAMILY & RELATIONSHIPS / Eldercare"
  },
  {
    "Code": "FAM053000",
    "Literal": "FAMILY & RELATIONSHIPS / Extended Family"
  },
  {
    "Code": "FAM021000",
    "Literal": "FAMILY & RELATIONSHIPS / Friendship"
  },
  {
    "Code": "FAM028000",
    "Literal": "FAMILY & RELATIONSHIPS / Learning Disabilities"
  },
  {
    "Code": "FAM056000",
    "Literal": "FAMILY & RELATIONSHIPS / LGBT"
  },
  {
    "Code": "FAM046000",
    "Literal": "FAMILY & RELATIONSHIPS / Life Stages / General"
  },
  {
    "Code": "FAM003000",
    "Literal": "FAMILY & RELATIONSHIPS / Life Stages / Adolescence"
  },
  {
    "Code": "FAM025000",
    "Literal": "FAMILY & RELATIONSHIPS / Life Stages / Infants & Toddlers"
  },
  {
    "Code": "FAM005000",
    "Literal": "FAMILY & RELATIONSHIPS / Life Stages / Later Years"
  },
  {
    "Code": "FAM054000",
    "Literal": "FAMILY & RELATIONSHIPS / Life Stages / Mid-Life"
  },
  {
    "Code": "FAM039000",
    "Literal": "FAMILY & RELATIONSHIPS / Life Stages / School Age"
  },
  {
    "Code": "FAM043000",
    "Literal": "FAMILY & RELATIONSHIPS / Life Stages / Teenagers"
  },
  {
    "Code": "FAM029000",
    "Literal": "FAMILY & RELATIONSHIPS / Love & Romance"
  },
  {
    "Code": "FAM030000",
    "Literal": "FAMILY & RELATIONSHIPS / Marriage & Long-Term Relationships"
  },
  {
    "Code": "FAM055000",
    "Literal": "FAMILY & RELATIONSHIPS / Military Families"
  },
  {
    "Code": "FAM034000",
    "Literal": "FAMILY & RELATIONSHIPS / Parenting / General"
  },
  {
    "Code": "FAM020000",
    "Literal": "FAMILY & RELATIONSHIPS / Parenting / Fatherhood"
  },
  {
    "Code": "FAM022000",
    "Literal": "FAMILY & RELATIONSHIPS / Parenting / Grandparenting"
  },
  {
    "Code": "FAM032000",
    "Literal": "FAMILY & RELATIONSHIPS / Parenting / Motherhood"
  },
  {
    "Code": "FAM033000",
    "Literal": "FAMILY & RELATIONSHIPS / Parenting / Parent & Adult Child"
  },
  {
    "Code": "FAM034010",
    "Literal": "FAMILY & RELATIONSHIPS / Parenting / Single Parent"
  },
  {
    "Code": "FAM042000",
    "Literal": "FAMILY & RELATIONSHIPS / Parenting / Stepparenting"
  },
  {
    "Code": "FAM035000",
    "Literal": "FAMILY & RELATIONSHIPS / Peer Pressure"
  },
  {
    "Code": "FAM037000",
    "Literal": "FAMILY & RELATIONSHIPS / Prejudice"
  },
  {
    "Code": "FAM038000",
    "Literal": "FAMILY & RELATIONSHIPS / Reference"
  },
  {
    "Code": "FAM041000",
    "Literal": "FAMILY & RELATIONSHIPS / Siblings"
  },
  {
    "Code": "FAM044000",
    "Literal": "FAMILY & RELATIONSHIPS / Toilet Training"
  },
  {
    "Code": "FIC000000",
    "Literal": "FICTION / General"
  },
  {
    "Code": "FIC064000",
    "Literal": "FICTION / Absurdist"
  },
  {
    "Code": "FIC002000",
    "Literal": "FICTION / Action & Adventure"
  },
  {
    "Code": "FIC075000",
    "Literal": "FICTION / Adaptations & Pastiche"
  },
  {
    "Code": "FIC049000",
    "Literal": "FICTION / African American / General"
  },
  {
    "Code": "FIC049010",
    "Literal": "FICTION / African American / Christian"
  },
  {
    "Code": "FIC049030",
    "Literal": "FICTION / African American / Erotica"
  },
  {
    "Code": "FIC049040",
    "Literal": "FICTION / African American / Historical"
  },
  {
    "Code": "FIC049050",
    "Literal": "FICTION / African American / Mystery & Detective"
  },
  {
    "Code": "FIC049070",
    "Literal": "FICTION / African American / Urban"
  },
  {
    "Code": "FIC049020",
    "Literal": "FICTION / African American / Women"
  },
  {
    "Code": "FIC040000",
    "Literal": "FICTION / Alternative History"
  },
  {
    "Code": "FIC053000",
    "Literal": "FICTION / Amish & Mennonite"
  },
  {
    "Code": "FIC067000",
    "Literal": "FICTION / Animals"
  },
  {
    "Code": "FIC003000",
    "Literal": "FICTION / Anthologies (multiple authors)"
  },
  {
    "Code": "FIC054000",
    "Literal": "FICTION / Asian American"
  },
  {
    "Code": "FIC041000",
    "Literal": "FICTION / Biographical"
  },
  {
    "Code": "FIC042000",
    "Literal": "FICTION / Christian / General"
  },
  {
    "Code": "FIC042090",
    "Literal": "FICTION / Christian / Biblical"
  },
  {
    "Code": "FIC042010",
    "Literal": "FICTION / Christian / Classic & Allegory"
  },
  {
    "Code": "FIC042050",
    "Literal": "FICTION / Christian / Collections & Anthologies"
  },
  {
    "Code": "FIC042100",
    "Literal": "FICTION / Christian / Contemporary"
  },
  {
    "Code": "FIC042080",
    "Literal": "FICTION / Christian / Fantasy"
  },
  {
    "Code": "FIC042020",
    "Literal": "FICTION / Christian / Futuristic"
  },
  {
    "Code": "FIC042030",
    "Literal": "FICTION / Christian / Historical"
  },
  {
    "Code": "FIC042040",
    "Literal": "FICTION / Christian / Romance / General"
  },
  {
    "Code": "FIC042110",
    "Literal": "FICTION / Christian / Romance / Historical"
  },
  {
    "Code": "FIC042120",
    "Literal": "FICTION / Christian / Romance / Suspense"
  },
  {
    "Code": "FIC042060",
    "Literal": "FICTION / Christian / Suspense"
  },
  {
    "Code": "FIC042070",
    "Literal": "FICTION / Christian / Western"
  },
  {
    "Code": "FIC069000",
    "Literal": "FICTION / City Life"
  },
  {
    "Code": "FIC004000",
    "Literal": "FICTION / Classics"
  },
  {
    "Code": "FIC043000",
    "Literal": "FICTION / Coming of Age"
  },
  {
    "Code": "FIC050000",
    "Literal": "FICTION / Crime"
  },
  {
    "Code": "FIC051000",
    "Literal": "FICTION / Cultural Heritage"
  },
  {
    "Code": "FIC070000",
    "Literal": "FICTION / Disaster"
  },
  {
    "Code": "FIC055000",
    "Literal": "FICTION / Dystopian"
  },
  {
    "Code": "FIC065000",
    "Literal": "FICTION / Epistolary"
  },
  {
    "Code": "FIC005000",
    "Literal": "FICTION / Erotica / General"
  },
  {
    "Code": "FIC005010",
    "Literal": "FICTION / Erotica / BDSM"
  },
  {
    "Code": "FIC005020",
    "Literal": "FICTION / Erotica / Collections & Anthologies"
  },
  {
    "Code": "FIC005060",
    "Literal": "FICTION / Erotica / Historical"
  },
  {
    "Code": "FIC005070",
    "Literal": "FICTION / Erotica / LGBT / General"
  },
  {
    "Code": "FIC005080",
    "Literal": "FICTION / Erotica / LGBT / Bisexual"
  },
  {
    "Code": "FIC005030",
    "Literal": "FICTION / Erotica / LGBT / Gay"
  },
  {
    "Code": "FIC005040",
    "Literal": "FICTION / Erotica / LGBT / Lesbian"
  },
  {
    "Code": "FIC005090",
    "Literal": "FICTION / Erotica / LGBT / Transgender"
  },
  {
    "Code": "FIC005050",
    "Literal": "FICTION / Erotica / Science Fiction, Fantasy & Horror"
  },
  {
    "Code": "FIC010000",
    "Literal": "FICTION / Fairy Tales, Folk Tales, Legends & Mythology"
  },
  {
    "Code": "FIC045000",
    "Literal": "FICTION / Family Life / General"
  },
  {
    "Code": "FIC045010",
    "Literal": "FICTION / Family Life / Marriage & Divorce"
  },
  {
    "Code": "FIC045020",
    "Literal": "FICTION / Family Life / Siblings"
  },
  {
    "Code": "FIC009000",
    "Literal": "FICTION / Fantasy / General"
  },
  {
    "Code": "FIC009100",
    "Literal": "FICTION / Fantasy / Action & Adventure"
  },
  {
    "Code": "FIC009110",
    "Literal": "FICTION / Fantasy / Arthurian"
  },
  {
    "Code": "FIC009040",
    "Literal": "FICTION / Fantasy / Collections & Anthologies"
  },
  {
    "Code": "FIC009010",
    "Literal": "FICTION / Fantasy / Contemporary"
  },
  {
    "Code": "FIC009070",
    "Literal": "FICTION / Fantasy / Dark Fantasy"
  },
  {
    "Code": "FIC009120",
    "Literal": "FICTION / Fantasy / Dragons & Mythical Creatures"
  },
  {
    "Code": "FIC009020",
    "Literal": "FICTION / Fantasy / Epic"
  },
  {
    "Code": "FIC009130",
    "Literal": "FICTION / Fantasy / Gaslamp"
  },
  {
    "Code": "FIC009030",
    "Literal": "FICTION / Fantasy / Historical"
  },
  {
    "Code": "FIC009080",
    "Literal": "FICTION / Fantasy / Humorous"
  },
  {
    "Code": "FIC009140",
    "Literal": "FICTION / Fantasy / Military"
  },
  {
    "Code": "FIC009050",
    "Literal": "FICTION / Fantasy / Paranormal"
  },
  {
    "Code": "FIC009090",
    "Literal": "FICTION / Fantasy / Romantic"
  },
  {
    "Code": "FIC009060",
    "Literal": "FICTION / Fantasy / Urban"
  },
  {
    "Code": "FIC071000",
    "Literal": "FICTION / Friendship"
  },
  {
    "Code": "FIC012000",
    "Literal": "FICTION / Ghost"
  },
  {
    "Code": "FIC027040",
    "Literal": "FICTION / Gothic"
  },
  {
    "Code": "FIC056000",
    "Literal": "FICTION / Hispanic & Latino"
  },
  {
    "Code": "FIC014000",
    "Literal": "FICTION / Historical / General"
  },
  {
    "Code": "FIC014010",
    "Literal": "FICTION / Historical / Ancient"
  },
  {
    "Code": "FIC014020",
    "Literal": "FICTION / Historical / Medieval"
  },
  {
    "Code": "FIC014030",
    "Literal": "FICTION / Historical / Renaissance"
  },
  {
    "Code": "FIC014040",
    "Literal": "FICTION / Historical / World War I"
  },
  {
    "Code": "FIC014050",
    "Literal": "FICTION / Historical / World War II"
  },
  {
    "Code": "FIC058000",
    "Literal": "FICTION / Holidays"
  },
  {
    "Code": "FIC015000",
    "Literal": "FICTION / Horror"
  },
  {
    "Code": "FIC016000",
    "Literal": "FICTION / Humorous / General"
  },
  {
    "Code": "FIC060000",
    "Literal": "FICTION / Humorous / Black Humor"
  },
  {
    "Code": "FIC046000",
    "Literal": "FICTION / Jewish"
  },
  {
    "Code": "FIC034000",
    "Literal": "FICTION / Legal"
  },
  {
    "Code": "FIC068000",
    "Literal": "FICTION / LGBT / General"
  },
  {
    "Code": "FIC072000",
    "Literal": "FICTION / LGBT / Bisexual"
  },
  {
    "Code": "FIC011000",
    "Literal": "FICTION / LGBT / Gay"
  },
  {
    "Code": "FIC018000",
    "Literal": "FICTION / LGBT / Lesbian"
  },
  {
    "Code": "FIC073000",
    "Literal": "FICTION / LGBT / Transgender"
  },
  {
    "Code": "FIC019000",
    "Literal": "FICTION / Literary"
  },
  {
    "Code": "FIC061000",
    "Literal": "FICTION / Magical Realism"
  },
  {
    "Code": "FIC057000",
    "Literal": "FICTION / Mashups"
  },
  {
    "Code": "FIC021000",
    "Literal": "FICTION / Media Tie-In"
  },
  {
    "Code": "FIC035000",
    "Literal": "FICTION / Medical"
  },
  {
    "Code": "FIC022000",
    "Literal": "FICTION / Mystery & Detective / General"
  },
  {
    "Code": "FIC022100",
    "Literal": "FICTION / Mystery & Detective / Amateur Sleuth"
  },
  {
    "Code": "FIC022050",
    "Literal": "FICTION / Mystery & Detective / Collections & Anthologies"
  },
  {
    "Code": "FIC022070",
    "Literal": "FICTION / Mystery & Detective / Cozy / General"
  },
  {
    "Code": "FIC022110",
    "Literal": "FICTION / Mystery & Detective / Cozy / Cats & Dogs"
  },
  {
    "Code": "FIC022120",
    "Literal": "FICTION / Mystery & Detective / Cozy / Crafts"
  },
  {
    "Code": "FIC022130",
    "Literal": "FICTION / Mystery & Detective / Cozy / Culinary"
  },
  {
    "Code": "FIC022010",
    "Literal": "FICTION / Mystery & Detective / Hard-Boiled"
  },
  {
    "Code": "FIC022060",
    "Literal": "FICTION / Mystery & Detective / Historical"
  },
  {
    "Code": "FIC022080",
    "Literal": "FICTION / Mystery & Detective / International Crime & Mystery"
  },
  {
    "Code": "FIC022020",
    "Literal": "FICTION / Mystery & Detective / Police Procedural"
  },
  {
    "Code": "FIC022090",
    "Literal": "FICTION / Mystery & Detective / Private Investigators"
  },
  {
    "Code": "FIC022030",
    "Literal": "FICTION / Mystery & Detective / Traditional"
  },
  {
    "Code": "FIC022040",
    "Literal": "FICTION / Mystery & Detective / Women Sleuths"
  },
  {
    "Code": "FIC059000",
    "Literal": "FICTION / Native American & Aboriginal"
  },
  {
    "Code": "FIC062000",
    "Literal": "FICTION / Noir"
  },
  {
    "Code": "FIC024000",
    "Literal": "FICTION / Occult & Supernatural"
  },
  {
    "Code": "FIC037000",
    "Literal": "FICTION / Political"
  },
  {
    "Code": "FIC025000",
    "Literal": "FICTION / Psychological"
  },
  {
    "Code": "FIC026000",
    "Literal": "FICTION / Religious"
  },
  {
    "Code": "FIC027000",
    "Literal": "FICTION / Romance / General"
  },
  {
    "Code": "FIC027260",
    "Literal": "FICTION / Romance / Action & Adventure"
  },
  {
    "Code": "FIC049060",
    "Literal": "FICTION / Romance / African American"
  },
  {
    "Code": "FIC027340",
    "Literal": "FICTION / Romance / Billionaires"
  },
  {
    "Code": "FIC027270",
    "Literal": "FICTION / Romance / Clean & Wholesome"
  },
  {
    "Code": "FIC027080",
    "Literal": "FICTION / Romance / Collections & Anthologies"
  },
  {
    "Code": "FIC027020",
    "Literal": "FICTION / Romance / Contemporary"
  },
  {
    "Code": "FIC027010",
    "Literal": "FICTION / Romance / Erotica"
  },
  {
    "Code": "FIC027030",
    "Literal": "FICTION / Romance / Fantasy"
  },
  {
    "Code": "FIC027350",
    "Literal": "FICTION / Romance / Firefighters"
  },
  {
    "Code": "FIC027050",
    "Literal": "FICTION / Romance / Historical / General"
  },
  {
    "Code": "FIC027360",
    "Literal": "FICTION / Romance / Historical / American"
  },
  {
    "Code": "FIC027140",
    "Literal": "FICTION / Romance / Historical / Ancient World"
  },
  {
    "Code": "FIC027150",
    "Literal": "FICTION / Romance / Historical / Medieval"
  },
  {
    "Code": "FIC027070",
    "Literal": "FICTION / Romance / Historical / Regency"
  },
  {
    "Code": "FIC027370",
    "Literal": "FICTION / Romance / Historical / Renaissance"
  },
  {
    "Code": "FIC027160",
    "Literal": "FICTION / Romance / Historical / Scottish"
  },
  {
    "Code": "FIC027280",
    "Literal": "FICTION / Romance / Historical / Tudor"
  },
  {
    "Code": "FIC027200",
    "Literal": "FICTION / Romance / Historical / 20th Century"
  },
  {
    "Code": "FIC027170",
    "Literal": "FICTION / Romance / Historical / Victorian"
  },
  {
    "Code": "FIC027180",
    "Literal": "FICTION / Romance / Historical / Viking"
  },
  {
    "Code": "FIC027290",
    "Literal": "FICTION / Romance / Holiday"
  },
  {
    "Code": "FIC027380",
    "Literal": "FICTION / Romance / Later in Life"
  },
  {
    "Code": "FIC027300",
    "Literal": "FICTION / Romance / LGBT / General"
  },
  {
    "Code": "FIC027390",
    "Literal": "FICTION / Romance / LGBT / Bisexual"
  },
  {
    "Code": "FIC027190",
    "Literal": "FICTION / Romance / LGBT / Gay"
  },
  {
    "Code": "FIC027210",
    "Literal": "FICTION / Romance / LGBT / Lesbian"
  },
  {
    "Code": "FIC027400",
    "Literal": "FICTION / Romance / LGBT / Transgender"
  },
  {
    "Code": "FIC027410",
    "Literal": "FICTION / Romance / Medical"
  },
  {
    "Code": "FIC027220",
    "Literal": "FICTION / Romance / Military"
  },
  {
    "Code": "FIC027230",
    "Literal": "FICTION / Romance / Multicultural & Interracial"
  },
  {
    "Code": "FIC027240",
    "Literal": "FICTION / Romance / New Adult"
  },
  {
    "Code": "FIC027120",
    "Literal": "FICTION / Romance / Paranormal / General"
  },
  {
    "Code": "FIC027310",
    "Literal": "FICTION / Romance / Paranormal / Shifters"
  },
  {
    "Code": "FIC027320",
    "Literal": "FICTION / Romance / Paranormal / Vampires"
  },
  {
    "Code": "FIC027440",
    "Literal": "FICTION / Romance / Paranormal / Witches"
  },
  {
    "Code": "FIC027420",
    "Literal": "FICTION / Romance / Police & Law Enforcement"
  },
  {
    "Code": "FIC027250",
    "Literal": "FICTION / Romance / Romantic Comedy"
  },
  {
    "Code": "FIC027450",
    "Literal": "FICTION / Romance / Royalty"
  },
  {
    "Code": "FIC027130",
    "Literal": "FICTION / Romance / Science Fiction"
  },
  {
    "Code": "FIC027330",
    "Literal": "FICTION / Romance / Sports"
  },
  {
    "Code": "FIC027110",
    "Literal": "FICTION / Romance / Suspense"
  },
  {
    "Code": "FIC027090",
    "Literal": "FICTION / Romance / Time Travel"
  },
  {
    "Code": "FIC027100",
    "Literal": "FICTION / Romance / Western"
  },
  {
    "Code": "FIC027430",
    "Literal": "FICTION / Romance / Workplace"
  },
  {
    "Code": "FIC008000",
    "Literal": "FICTION / Sagas"
  },
  {
    "Code": "FIC052000",
    "Literal": "FICTION / Satire"
  },
  {
    "Code": "FIC028000",
    "Literal": "FICTION / Science Fiction / General"
  },
  {
    "Code": "FIC028010",
    "Literal": "FICTION / Science Fiction / Action & Adventure"
  },
  {
    "Code": "FIC028090",
    "Literal": "FICTION / Science Fiction / Alien Contact"
  },
  {
    "Code": "FIC028070",
    "Literal": "FICTION / Science Fiction / Apocalyptic & Post-Apocalyptic"
  },
  {
    "Code": "FIC028040",
    "Literal": "FICTION / Science Fiction / Collections & Anthologies"
  },
  {
    "Code": "FIC028140",
    "Literal": "FICTION / Science Fiction / Crime & Mystery"
  },
  {
    "Code": "FIC028100",
    "Literal": "FICTION / Science Fiction / Cyberpunk"
  },
  {
    "Code": "FIC028110",
    "Literal": "FICTION / Science Fiction / Genetic Engineering"
  },
  {
    "Code": "FIC028020",
    "Literal": "FICTION / Science Fiction / Hard Science Fiction"
  },
  {
    "Code": "FIC028120",
    "Literal": "FICTION / Science Fiction / Humorous"
  },
  {
    "Code": "FIC028050",
    "Literal": "FICTION / Science Fiction / Military"
  },
  {
    "Code": "FIC028130",
    "Literal": "FICTION / Science Fiction / Space Exploration"
  },
  {
    "Code": "FIC028030",
    "Literal": "FICTION / Science Fiction / Space Opera"
  },
  {
    "Code": "FIC028060",
    "Literal": "FICTION / Science Fiction / Steampunk"
  },
  {
    "Code": "FIC028080",
    "Literal": "FICTION / Science Fiction / Time Travel"
  },
  {
    "Code": "FIC047000",
    "Literal": "FICTION / Sea Stories"
  },
  {
    "Code": "FIC029000",
    "Literal": "FICTION / Short Stories (single author)"
  },
  {
    "Code": "FIC066000",
    "Literal": "FICTION / Small Town & Rural"
  },
  {
    "Code": "FIC074000",
    "Literal": "FICTION / Southern"
  },
  {
    "Code": "FIC038000",
    "Literal": "FICTION / Sports"
  },
  {
    "Code": "FIC063000",
    "Literal": "FICTION / Superheroes (see also COMICS & GRAPHIC NOVELS / Superheroes)"
  },
  {
    "Code": "FIC031000",
    "Literal": "FICTION / Thrillers / General"
  },
  {
    "Code": "FIC031010",
    "Literal": "FICTION / Thrillers / Crime"
  },
  {
    "Code": "FIC031100",
    "Literal": "FICTION / Thrillers / Domestic"
  },
  {
    "Code": "FIC006000",
    "Literal": "FICTION / Thrillers / Espionage"
  },
  {
    "Code": "FIC031020",
    "Literal": "FICTION / Thrillers / Historical"
  },
  {
    "Code": "FIC031030",
    "Literal": "FICTION / Thrillers / Legal"
  },
  {
    "Code": "FIC031040",
    "Literal": "FICTION / Thrillers / Medical"
  },
  {
    "Code": "FIC031050",
    "Literal": "FICTION / Thrillers / Military"
  },
  {
    "Code": "FIC031060",
    "Literal": "FICTION / Thrillers / Political"
  },
  {
    "Code": "FIC031080",
    "Literal": "FICTION / Thrillers / Psychological"
  },
  {
    "Code": "FIC031070",
    "Literal": "FICTION / Thrillers / Supernatural"
  },
  {
    "Code": "FIC030000",
    "Literal": "FICTION / Thrillers / Suspense"
  },
  {
    "Code": "FIC036000",
    "Literal": "FICTION / Thrillers / Technological"
  },
  {
    "Code": "FIC031090",
    "Literal": "FICTION / Thrillers / Terrorism"
  },
  {
    "Code": "FIC048000",
    "Literal": "FICTION / Urban"
  },
  {
    "Code": "FIC039000",
    "Literal": "FICTION / Visionary & Metaphysical"
  },
  {
    "Code": "FIC032000",
    "Literal": "FICTION / War & Military"
  },
  {
    "Code": "FIC033000",
    "Literal": "FICTION / Westerns"
  },
  {
    "Code": "FIC044000",
    "Literal": "FICTION / Women"
  },
  {
    "Code": "FOR000000",
    "Literal": "FOREIGN LANGUAGE STUDY / General"
  },
  {
    "Code": "FOR001000",
    "Literal": "FOREIGN LANGUAGE STUDY / African Languages (see also Swahili)"
  },
  {
    "Code": "FOR033000",
    "Literal": "FOREIGN LANGUAGE STUDY / Ancient Languages (see also Latin)"
  },
  {
    "Code": "FOR002000",
    "Literal": "FOREIGN LANGUAGE STUDY / Arabic"
  },
  {
    "Code": "FOR034000",
    "Literal": "FOREIGN LANGUAGE STUDY / Baltic Languages"
  },
  {
    "Code": "FOR029000",
    "Literal": "FOREIGN LANGUAGE STUDY / Celtic Languages"
  },
  {
    "Code": "FOR003000",
    "Literal": "FOREIGN LANGUAGE STUDY / Chinese"
  },
  {
    "Code": "FOR035000",
    "Literal": "FOREIGN LANGUAGE STUDY / Creole Languages"
  },
  {
    "Code": "FOR036000",
    "Literal": "FOREIGN LANGUAGE STUDY / Czech"
  },
  {
    "Code": "FOR004000",
    "Literal": "FOREIGN LANGUAGE STUDY / Danish"
  },
  {
    "Code": "FOR006000",
    "Literal": "FOREIGN LANGUAGE STUDY / Dutch"
  },
  {
    "Code": "FOR007000",
    "Literal": "FOREIGN LANGUAGE STUDY / English as a Second Language"
  },
  {
    "Code": "FOR037000",
    "Literal": "FOREIGN LANGUAGE STUDY / Finnish"
  },
  {
    "Code": "FOR008000",
    "Literal": "FOREIGN LANGUAGE STUDY / French"
  },
  {
    "Code": "FOR009000",
    "Literal": "FOREIGN LANGUAGE STUDY / German"
  },
  {
    "Code": "FOR010000",
    "Literal": "FOREIGN LANGUAGE STUDY / Greek (Modern)"
  },
  {
    "Code": "FOR011000",
    "Literal": "FOREIGN LANGUAGE STUDY / Hebrew"
  },
  {
    "Code": "FOR038000",
    "Literal": "FOREIGN LANGUAGE STUDY / Hindi"
  },
  {
    "Code": "FOR012000",
    "Literal": "FOREIGN LANGUAGE STUDY / Hungarian"
  },
  {
    "Code": "FOR030000",
    "Literal": "FOREIGN LANGUAGE STUDY / Indic Languages"
  },
  {
    "Code": "FOR013000",
    "Literal": "FOREIGN LANGUAGE STUDY / Italian"
  },
  {
    "Code": "FOR014000",
    "Literal": "FOREIGN LANGUAGE STUDY / Japanese"
  },
  {
    "Code": "FOR015000",
    "Literal": "FOREIGN LANGUAGE STUDY / Korean"
  },
  {
    "Code": "FOR016000",
    "Literal": "FOREIGN LANGUAGE STUDY / Latin"
  },
  {
    "Code": "FOR017000",
    "Literal": "FOREIGN LANGUAGE STUDY / Miscellaneous"
  },
  {
    "Code": "FOR005000",
    "Literal": "FOREIGN LANGUAGE STUDY / Multi-Language Dictionaries"
  },
  {
    "Code": "FOR018000",
    "Literal": "FOREIGN LANGUAGE STUDY / Multi-Language Phrasebooks"
  },
  {
    "Code": "FOR031000",
    "Literal": "FOREIGN LANGUAGE STUDY / Native American Languages"
  },
  {
    "Code": "FOR039000",
    "Literal": "FOREIGN LANGUAGE STUDY / Norwegian"
  },
  {
    "Code": "FOR032000",
    "Literal": "FOREIGN LANGUAGE STUDY / Oceanic & Australian Languages"
  },
  {
    "Code": "FOR045000",
    "Literal": "FOREIGN LANGUAGE STUDY / Old & Middle English"
  },
  {
    "Code": "FOR040000",
    "Literal": "FOREIGN LANGUAGE STUDY / Persian"
  },
  {
    "Code": "FOR019000",
    "Literal": "FOREIGN LANGUAGE STUDY / Polish"
  },
  {
    "Code": "FOR020000",
    "Literal": "FOREIGN LANGUAGE STUDY / Portuguese"
  },
  {
    "Code": "FOR041000",
    "Literal": "FOREIGN LANGUAGE STUDY / Romance Languages (Other)"
  },
  {
    "Code": "FOR021000",
    "Literal": "FOREIGN LANGUAGE STUDY / Russian"
  },
  {
    "Code": "FOR022000",
    "Literal": "FOREIGN LANGUAGE STUDY / Scandinavian Languages (Other)"
  },
  {
    "Code": "FOR023000",
    "Literal": "FOREIGN LANGUAGE STUDY / Serbian & Croatian"
  },
  {
    "Code": "FOR024000",
    "Literal": "FOREIGN LANGUAGE STUDY / Slavic Languages (Other)"
  },
  {
    "Code": "FOR025000",
    "Literal": "FOREIGN LANGUAGE STUDY / Southeast Asian Languages (see also Vietnamese)"
  },
  {
    "Code": "FOR026000",
    "Literal": "FOREIGN LANGUAGE STUDY / Spanish"
  },
  {
    "Code": "FOR042000",
    "Literal": "FOREIGN LANGUAGE STUDY / Swahili"
  },
  {
    "Code": "FOR043000",
    "Literal": "FOREIGN LANGUAGE STUDY / Swedish"
  },
  {
    "Code": "FOR027000",
    "Literal": "FOREIGN LANGUAGE STUDY / Turkish & Turkic Languages"
  },
  {
    "Code": "FOR044000",
    "Literal": "FOREIGN LANGUAGE STUDY / Vietnamese"
  },
  {
    "Code": "FOR028000",
    "Literal": "FOREIGN LANGUAGE STUDY / Yiddish"
  },
  {
    "Code": "GAM000000",
    "Literal": "GAMES & ACTIVITIES / General"
  },
  {
    "Code": "GAM020000",
    "Literal": "GAMES & ACTIVITIES / Activity Books"
  },
  {
    "Code": "GAM001010",
    "Literal": "GAMES & ACTIVITIES / Backgammon"
  },
  {
    "Code": "GAM001000",
    "Literal": "GAMES & ACTIVITIES / Board Games"
  },
  {
    "Code": "GAM002000",
    "Literal": "GAMES & ACTIVITIES / Card Games / General"
  },
  {
    "Code": "GAM002030",
    "Literal": "GAMES & ACTIVITIES / Card Games / Blackjack"
  },
  {
    "Code": "GAM002010",
    "Literal": "GAMES & ACTIVITIES / Card Games / Bridge"
  },
  {
    "Code": "GAM002040",
    "Literal": "GAMES & ACTIVITIES / Card Games / Poker"
  },
  {
    "Code": "GAM001030",
    "Literal": "GAMES & ACTIVITIES / Chess"
  },
  {
    "Code": "GAM019000",
    "Literal": "GAMES & ACTIVITIES / Coloring Books"
  },
  {
    "Code": "GAM003000",
    "Literal": "GAMES & ACTIVITIES / Crosswords / General"
  },
  {
    "Code": "GAM003040",
    "Literal": "GAMES & ACTIVITIES / Crosswords / Dictionaries"
  },
  {
    "Code": "GAM016000",
    "Literal": "GAMES & ACTIVITIES / Fantasy Sports"
  },
  {
    "Code": "GAM004000",
    "Literal": "GAMES & ACTIVITIES / Gambling / General (see also SELF-HELP / Compulsive Behavior / Gambling)"
  },
  {
    "Code": "GAM004020",
    "Literal": "GAMES & ACTIVITIES / Gambling / Lotteries"
  },
  {
    "Code": "GAM004050",
    "Literal": "GAMES & ACTIVITIES / Gambling / Sports"
  },
  {
    "Code": "GAM004030",
    "Literal": "GAMES & ACTIVITIES / Gambling / Table"
  },
  {
    "Code": "GAM004040",
    "Literal": "GAMES & ACTIVITIES / Gambling / Track Betting"
  },
  {
    "Code": "GAM021000",
    "Literal": "GAMES & ACTIVITIES / Guided Journals"
  },
  {
    "Code": "GAM005000",
    "Literal": "GAMES & ACTIVITIES / Logic & Brain Teasers"
  },
  {
    "Code": "GAM006000",
    "Literal": "GAMES & ACTIVITIES / Magic"
  },
  {
    "Code": "GAM018000",
    "Literal": "GAMES & ACTIVITIES / Optical Illusions"
  },
  {
    "Code": "GAM007000",
    "Literal": "GAMES & ACTIVITIES / Puzzles"
  },
  {
    "Code": "GAM008000",
    "Literal": "GAMES & ACTIVITIES / Quizzes"
  },
  {
    "Code": "GAM009000",
    "Literal": "GAMES & ACTIVITIES / Reference"
  },
  {
    "Code": "GAM010000",
    "Literal": "GAMES & ACTIVITIES / Role Playing & Fantasy"
  },
  {
    "Code": "GAM017000",
    "Literal": "GAMES & ACTIVITIES / Sudoku"
  },
  {
    "Code": "GAM011000",
    "Literal": "GAMES & ACTIVITIES / Travel Games"
  },
  {
    "Code": "GAM012000",
    "Literal": "GAMES & ACTIVITIES / Trivia"
  },
  {
    "Code": "GAM013000",
    "Literal": "GAMES & ACTIVITIES / Video & Electronic"
  },
  {
    "Code": "GAM014000",
    "Literal": "GAMES & ACTIVITIES / Word & Word Search"
  },
  {
    "Code": "GAR000000",
    "Literal": "GARDENING / General"
  },
  {
    "Code": "GAR027000",
    "Literal": "GARDENING / Climatic / General"
  },
  {
    "Code": "GAR027010",
    "Literal": "GARDENING / Climatic / Desert"
  },
  {
    "Code": "GAR027020",
    "Literal": "GARDENING / Climatic / Temperate"
  },
  {
    "Code": "GAR027030",
    "Literal": "GARDENING / Climatic / Tropical"
  },
  {
    "Code": "GAR001000",
    "Literal": "GARDENING / Container"
  },
  {
    "Code": "GAR002000",
    "Literal": "GARDENING / Essays & Narratives"
  },
  {
    "Code": "GAR004000",
    "Literal": "GARDENING / Flowers / General"
  },
  {
    "Code": "GAR004010",
    "Literal": "GARDENING / Flowers / Annuals"
  },
  {
    "Code": "GAR004030",
    "Literal": "GARDENING / Flowers / Bulbs"
  },
  {
    "Code": "GAR004040",
    "Literal": "GARDENING / Flowers / Orchids"
  },
  {
    "Code": "GAR004050",
    "Literal": "GARDENING / Flowers / Perennials"
  },
  {
    "Code": "GAR004060",
    "Literal": "GARDENING / Flowers / Roses"
  },
  {
    "Code": "GAR004080",
    "Literal": "GARDENING / Flowers / Wildflowers"
  },
  {
    "Code": "GAR005000",
    "Literal": "GARDENING / Fruit"
  },
  {
    "Code": "GAR006000",
    "Literal": "GARDENING / Garden Design"
  },
  {
    "Code": "GAR007000",
    "Literal": "GARDENING / Garden Furnishings"
  },
  {
    "Code": "GAR008000",
    "Literal": "GARDENING / Greenhouses"
  },
  {
    "Code": "GAR009000",
    "Literal": "GARDENING / Herbs"
  },
  {
    "Code": "GAR010000",
    "Literal": "GARDENING / House Plants & Indoor"
  },
  {
    "Code": "GAR011000",
    "Literal": "GARDENING / Hydroponics"
  },
  {
    "Code": "GAR013000",
    "Literal": "GARDENING / Japanese Gardens"
  },
  {
    "Code": "GAR014000",
    "Literal": "GARDENING / Landscape"
  },
  {
    "Code": "GAR015000",
    "Literal": "GARDENING / Lawns"
  },
  {
    "Code": "GAR031000",
    "Literal": "GARDENING / Marijuana Cultivation"
  },
  {
    "Code": "GAR016000",
    "Literal": "GARDENING / Organic"
  },
  {
    "Code": "GAR017000",
    "Literal": "GARDENING / Ornamental Plants"
  },
  {
    "Code": "GAR030000",
    "Literal": "GARDENING / Pictorial"
  },
  {
    "Code": "GAR018000",
    "Literal": "GARDENING / Reference"
  },
  {
    "Code": "GAR019000",
    "Literal": "GARDENING / Regional / General"
  },
  {
    "Code": "GAR019010",
    "Literal": "GARDENING / Regional / Canada"
  },
  {
    "Code": "GAR019020",
    "Literal": "GARDENING / Regional / Middle Atlantic (DC, DE, MD, NJ, NY, PA)"
  },
  {
    "Code": "GAR019030",
    "Literal": "GARDENING / Regional / Midwest (IA, IL, IN, KS, MI, MN, MO, ND, NE, OH, SD, WI)"
  },
  {
    "Code": "GAR019040",
    "Literal": "GARDENING / Regional / New England (CT, MA, ME, NH, RI, VT)"
  },
  {
    "Code": "GAR019050",
    "Literal": "GARDENING / Regional / Pacific Northwest (OR, WA)"
  },
  {
    "Code": "GAR019060",
    "Literal": "GARDENING / Regional / South (AL, AR, FL, GA, KY, LA, MS, NC, SC, TN, VA, WV)"
  },
  {
    "Code": "GAR019070",
    "Literal": "GARDENING / Regional / Southwest (AZ, NM, OK, TX)"
  },
  {
    "Code": "GAR019080",
    "Literal": "GARDENING / Regional / West (AK, CA, CO, HI, ID, MT, NV, UT, WY)"
  },
  {
    "Code": "GAR020000",
    "Literal": "GARDENING / Shade"
  },
  {
    "Code": "GAR021000",
    "Literal": "GARDENING / Shrubs"
  },
  {
    "Code": "GAR022000",
    "Literal": "GARDENING / Techniques"
  },
  {
    "Code": "GAR023000",
    "Literal": "GARDENING / Topiary"
  },
  {
    "Code": "GAR024000",
    "Literal": "GARDENING / Trees"
  },
  {
    "Code": "GAR028000",
    "Literal": "GARDENING / Urban"
  },
  {
    "Code": "GAR025000",
    "Literal": "GARDENING / Vegetables"
  },
  {
    "Code": "GAR029000",
    "Literal": "GARDENING / Water Gardens"
  },
  {
    "Code": "HEA000000",
    "Literal": "HEALTH & FITNESS / General"
  },
  {
    "Code": "HEA001000",
    "Literal": "HEALTH & FITNESS / Acupressure & Acupuncture (see also MEDICAL / Acupuncture)"
  },
  {
    "Code": "HEA027000",
    "Literal": "HEALTH & FITNESS / Allergies"
  },
  {
    "Code": "HEA032000",
    "Literal": "HEALTH & FITNESS / Alternative Therapies"
  },
  {
    "Code": "HEA029000",
    "Literal": "HEALTH & FITNESS / Aromatherapy"
  },
  {
    "Code": "HEA003000",
    "Literal": "HEALTH & FITNESS / Beauty & Grooming"
  },
  {
    "Code": "HEA047000",
    "Literal": "HEALTH & FITNESS / Body Cleansing & Detoxification"
  },
  {
    "Code": "HEA044000",
    "Literal": "HEALTH & FITNESS / Breastfeeding"
  },
  {
    "Code": "HEA046000",
    "Literal": "HEALTH & FITNESS / Children's Health"
  },
  {
    "Code": "HEA048000",
    "Literal": "HEALTH & FITNESS / Diet & Nutrition / General"
  },
  {
    "Code": "HEA006000",
    "Literal": "HEALTH & FITNESS / Diet & Nutrition / Diets"
  },
  {
    "Code": "HEA034000",
    "Literal": "HEALTH & FITNESS / Diet & Nutrition / Food Content Guides"
  },
  {
    "Code": "HEA013000",
    "Literal": "HEALTH & FITNESS / Diet & Nutrition / Macrobiotics"
  },
  {
    "Code": "HEA017000",
    "Literal": "HEALTH & FITNESS / Diet & Nutrition / Nutrition"
  },
  {
    "Code": "HEA023000",
    "Literal": "HEALTH & FITNESS / Diet & Nutrition / Vitamins"
  },
  {
    "Code": "HEA019000",
    "Literal": "HEALTH & FITNESS / Diet & Nutrition / Weight Loss"
  },
  {
    "Code": "HEA039000",
    "Literal": "HEALTH & FITNESS / Diseases / General"
  },
  {
    "Code": "HEA039020",
    "Literal": "HEALTH & FITNESS / Diseases / AIDS & HIV"
  },
  {
    "Code": "HEA039140",
    "Literal": "HEALTH & FITNESS / Diseases / Alzheimer's & Dementia"
  },
  {
    "Code": "HEA039030",
    "Literal": "HEALTH & FITNESS / Diseases / Cancer"
  },
  {
    "Code": "HEA039150",
    "Literal": "HEALTH & FITNESS / Diseases / Chronic Fatigue Syndrome"
  },
  {
    "Code": "HEA039040",
    "Literal": "HEALTH & FITNESS / Diseases / Contagious"
  },
  {
    "Code": "HEA039050",
    "Literal": "HEALTH & FITNESS / Diseases / Diabetes"
  },
  {
    "Code": "HEA039160",
    "Literal": "HEALTH & FITNESS / Diseases / Endocrine System"
  },
  {
    "Code": "HEA039010",
    "Literal": "HEALTH & FITNESS / Diseases / Gastrointestinal"
  },
  {
    "Code": "HEA039060",
    "Literal": "HEALTH & FITNESS / Diseases / Genetic"
  },
  {
    "Code": "HEA039070",
    "Literal": "HEALTH & FITNESS / Diseases / Genitourinary & STDs"
  },
  {
    "Code": "HEA039080",
    "Literal": "HEALTH & FITNESS / Diseases / Heart"
  },
  {
    "Code": "HEA039090",
    "Literal": "HEALTH & FITNESS / Diseases / Immune & Autoimmune"
  },
  {
    "Code": "HEA039100",
    "Literal": "HEALTH & FITNESS / Diseases / Musculoskeletal"
  },
  {
    "Code": "HEA039110",
    "Literal": "HEALTH & FITNESS / Diseases / Nervous System (incl. Brain)"
  },
  {
    "Code": "HEA039120",
    "Literal": "HEALTH & FITNESS / Diseases / Respiratory"
  },
  {
    "Code": "HEA039130",
    "Literal": "HEALTH & FITNESS / Diseases / Skin"
  },
  {
    "Code": "HEA007000",
    "Literal": "HEALTH & FITNESS / Exercise / General"
  },
  {
    "Code": "HEA002000",
    "Literal": "HEALTH & FITNESS / Exercise / Aerobics"
  },
  {
    "Code": "HEA007010",
    "Literal": "HEALTH & FITNESS / Exercise / Aquatic"
  },
  {
    "Code": "HEA007020",
    "Literal": "HEALTH & FITNESS / Exercise / Dance"
  },
  {
    "Code": "HEA007030",
    "Literal": "HEALTH & FITNESS / Exercise / Pilates"
  },
  {
    "Code": "HEA022000",
    "Literal": "HEALTH & FITNESS / Exercise / Stretching"
  },
  {
    "Code": "HEA045000",
    "Literal": "HEALTH & FITNESS / Fertility & Infertility"
  },
  {
    "Code": "HEA033000",
    "Literal": "HEALTH & FITNESS / First Aid"
  },
  {
    "Code": "HEA009000",
    "Literal": "HEALTH & FITNESS / Healing"
  },
  {
    "Code": "HEA028000",
    "Literal": "HEALTH & FITNESS / Health Care Issues"
  },
  {
    "Code": "HEA010000",
    "Literal": "HEALTH & FITNESS / Healthy Living"
  },
  {
    "Code": "HEA035000",
    "Literal": "HEALTH & FITNESS / Hearing & Speech"
  },
  {
    "Code": "HEA011000",
    "Literal": "HEALTH & FITNESS / Herbal Medications"
  },
  {
    "Code": "HEA012000",
    "Literal": "HEALTH & FITNESS / Holism"
  },
  {
    "Code": "HEA030000",
    "Literal": "HEALTH & FITNESS / Homeopathy"
  },
  {
    "Code": "HEA049000",
    "Literal": "HEALTH & FITNESS / Longevity"
  },
  {
    "Code": "HEA014000",
    "Literal": "HEALTH & FITNESS / Massage & Reflexology"
  },
  {
    "Code": "HEA015000",
    "Literal": "HEALTH & FITNESS / Men's Health"
  },
  {
    "Code": "HEA051000",
    "Literal": "HEALTH & FITNESS / Menopause"
  },
  {
    "Code": "HEA016000",
    "Literal": "HEALTH & FITNESS / Naturopathy"
  },
  {
    "Code": "HEA040000",
    "Literal": "HEALTH & FITNESS / Oral Health"
  },
  {
    "Code": "HEA036000",
    "Literal": "HEALTH & FITNESS / Pain Management"
  },
  {
    "Code": "HEA018000",
    "Literal": "HEALTH & FITNESS / Physical Impairments"
  },
  {
    "Code": "HEA041000",
    "Literal": "HEALTH & FITNESS / Pregnancy & Childbirth"
  },
  {
    "Code": "HEA020000",
    "Literal": "HEALTH & FITNESS / Reference"
  },
  {
    "Code": "HEA021000",
    "Literal": "HEALTH & FITNESS / Safety"
  },
  {
    "Code": "HEA042000",
    "Literal": "HEALTH & FITNESS / Sexuality"
  },
  {
    "Code": "HEA043000",
    "Literal": "HEALTH & FITNESS / Sleep"
  },
  {
    "Code": "HEA052000",
    "Literal": "HEALTH & FITNESS / Tai Chi"
  },
  {
    "Code": "HEA050000",
    "Literal": "HEALTH & FITNESS / Vaccinations"
  },
  {
    "Code": "HEA037000",
    "Literal": "HEALTH & FITNESS / Vision"
  },
  {
    "Code": "HEA024000",
    "Literal": "HEALTH & FITNESS / Women's Health"
  },
  {
    "Code": "HEA038000",
    "Literal": "HEALTH & FITNESS / Work-Related Health"
  },
  {
    "Code": "HEA025000",
    "Literal": "HEALTH & FITNESS / Yoga"
  },
  {
    "Code": "HIS000000",
    "Literal": "HISTORY / General"
  },
  {
    "Code": "HIS001000",
    "Literal": "HISTORY / Africa / General"
  },
  {
    "Code": "HIS001010",
    "Literal": "HISTORY / Africa / Central"
  },
  {
    "Code": "HIS001020",
    "Literal": "HISTORY / Africa / East"
  },
  {
    "Code": "HIS001030",
    "Literal": "HISTORY / Africa / North"
  },
  {
    "Code": "HIS001040",
    "Literal": "HISTORY / Africa / South / General"
  },
  {
    "Code": "HIS047000",
    "Literal": "HISTORY / Africa / South / Republic of South Africa"
  },
  {
    "Code": "HIS001050",
    "Literal": "HISTORY / Africa / West"
  },
  {
    "Code": "HIS056000",
    "Literal": "HISTORY / African American"
  },
  {
    "Code": "HIS038000",
    "Literal": "HISTORY / Americas (North, Central, South, West Indies)"
  },
  {
    "Code": "HIS002000",
    "Literal": "HISTORY / Ancient / General"
  },
  {
    "Code": "HIS002030",
    "Literal": "HISTORY / Ancient / Egypt"
  },
  {
    "Code": "HIS002010",
    "Literal": "HISTORY / Ancient / Greece"
  },
  {
    "Code": "HIS002020",
    "Literal": "HISTORY / Ancient / Rome"
  },
  {
    "Code": "HIS003000",
    "Literal": "HISTORY / Asia / General"
  },
  {
    "Code": "HIS050000",
    "Literal": "HISTORY / Asia / Central Asia"
  },
  {
    "Code": "HIS008000",
    "Literal": "HISTORY / Asia / China"
  },
  {
    "Code": "HIS017000",
    "Literal": "HISTORY / Asia / India & South Asia"
  },
  {
    "Code": "HIS021000",
    "Literal": "HISTORY / Asia / Japan"
  },
  {
    "Code": "HIS023000",
    "Literal": "HISTORY / Asia / Korea"
  },
  {
    "Code": "HIS048000",
    "Literal": "HISTORY / Asia / Southeast Asia"
  },
  {
    "Code": "HIS004000",
    "Literal": "HISTORY / Australia & New Zealand"
  },
  {
    "Code": "HIS059000",
    "Literal": "HISTORY / Byzantine Empire"
  },
  {
    "Code": "HIS006000",
    "Literal": "HISTORY / Canada / General"
  },
  {
    "Code": "HIS006010",
    "Literal": "HISTORY / Canada / Pre-Confederation (to 1867)"
  },
  {
    "Code": "HIS006020",
    "Literal": "HISTORY / Canada / Post-Confederation (1867-)"
  },
  {
    "Code": "HIS041000",
    "Literal": "HISTORY / Caribbean & West Indies / General"
  },
  {
    "Code": "HIS041010",
    "Literal": "HISTORY / Caribbean & West Indies / Cuba"
  },
  {
    "Code": "HIS039000",
    "Literal": "HISTORY / Civilization"
  },
  {
    "Code": "HIS049000",
    "Literal": "HISTORY / Essays"
  },
  {
    "Code": "HIS010000",
    "Literal": "HISTORY / Europe / General"
  },
  {
    "Code": "HIS037010",
    "Literal": "HISTORY / Europe / Medieval"
  },
  {
    "Code": "HIS037020",
    "Literal": "HISTORY / Europe / Renaissance"
  },
  {
    "Code": "HIS040000",
    "Literal": "HISTORY / Europe / Austria & Hungary"
  },
  {
    "Code": "HIS005000",
    "Literal": "HISTORY / Europe / Baltic States"
  },
  {
    "Code": "HIS010010",
    "Literal": "HISTORY / Europe / Eastern"
  },
  {
    "Code": "HIS013000",
    "Literal": "HISTORY / Europe / France"
  },
  {
    "Code": "HIS014000",
    "Literal": "HISTORY / Europe / Germany"
  },
  {
    "Code": "HIS015000",
    "Literal": "HISTORY / Europe / Great Britain / General"
  },
  {
    "Code": "HIS015010",
    "Literal": "HISTORY / Europe / Great Britain / Middle Ages (449-1066)"
  },
  {
    "Code": "HIS015020",
    "Literal": "HISTORY / Europe / Great Britain / Norman Conquest to Late Medieval (1066-1485)"
  },
  {
    "Code": "HIS015030",
    "Literal": "HISTORY / Europe / Great Britain / Tudor & Elizabethan Era (1485-1603)"
  },
  {
    "Code": "HIS015040",
    "Literal": "HISTORY / Europe / Great Britain / Stuart Era (1603-1714)"
  },
  {
    "Code": "HIS015050",
    "Literal": "HISTORY / Europe / Great Britain / Georgian Era (1714-1837)"
  },
  {
    "Code": "HIS015060",
    "Literal": "HISTORY / Europe / Great Britain / Victorian Era (1837-1901)"
  },
  {
    "Code": "HIS015070",
    "Literal": "HISTORY / Europe / Great Britain / 20th Century"
  },
  {
    "Code": "HIS015080",
    "Literal": "HISTORY / Europe / Great Britain / 21st Century"
  },
  {
    "Code": "HIS015090",
    "Literal": "HISTORY / Europe / Great Britain / Scotland"
  },
  {
    "Code": "HIS015100",
    "Literal": "HISTORY / Europe / Great Britain / Wales"
  },
  {
    "Code": "HIS042000",
    "Literal": "HISTORY / Europe / Greece (see also Ancient / Greece)"
  },
  {
    "Code": "HIS018000",
    "Literal": "HISTORY / Europe / Ireland"
  },
  {
    "Code": "HIS020000",
    "Literal": "HISTORY / Europe / Italy"
  },
  {
    "Code": "HIS060000",
    "Literal": "HISTORY / Europe / Poland"
  },
  {
    "Code": "HIS044000",
    "Literal": "HISTORY / Europe / Scandinavia"
  },
  {
    "Code": "HIS045000",
    "Literal": "HISTORY / Europe / Spain & Portugal"
  },
  {
    "Code": "HIS010020",
    "Literal": "HISTORY / Europe / Western"
  },
  {
    "Code": "HIS051000",
    "Literal": "HISTORY / Expeditions & Discoveries"
  },
  {
    "Code": "HIS052000",
    "Literal": "HISTORY / Historical Geography"
  },
  {
    "Code": "HIS016000",
    "Literal": "HISTORY / Historiography"
  },
  {
    "Code": "HIS043000",
    "Literal": "HISTORY / Holocaust"
  },
  {
    "Code": "HIS022000",
    "Literal": "HISTORY / Jewish"
  },
  {
    "Code": "HIS024000",
    "Literal": "HISTORY / Latin America / General"
  },
  {
    "Code": "HIS061000",
    "Literal": "HISTORY / Latin America / Pre-Columbian Era"
  },
  {
    "Code": "HIS007000",
    "Literal": "HISTORY / Latin America / Central America"
  },
  {
    "Code": "HIS025000",
    "Literal": "HISTORY / Latin America / Mexico"
  },
  {
    "Code": "HIS033000",
    "Literal": "HISTORY / Latin America / South America"
  },
  {
    "Code": "HIS057000",
    "Literal": "HISTORY / Maritime History & Piracy"
  },
  {
    "Code": "HIS026000",
    "Literal": "HISTORY / Middle East / General"
  },
  {
    "Code": "HIS026010",
    "Literal": "HISTORY / Middle East / Arabian Peninsula"
  },
  {
    "Code": "HIS009000",
    "Literal": "HISTORY / Middle East / Egypt (see also Ancient / Egypt)"
  },
  {
    "Code": "HIS026020",
    "Literal": "HISTORY / Middle East / Iran"
  },
  {
    "Code": "HIS026030",
    "Literal": "HISTORY / Middle East / Iraq"
  },
  {
    "Code": "HIS019000",
    "Literal": "HISTORY / Middle East / Israel & Palestine"
  },
  {
    "Code": "HIS055000",
    "Literal": "HISTORY / Middle East / Turkey & Ottoman Empire"
  },
  {
    "Code": "HIS027000",
    "Literal": "HISTORY / Military / General"
  },
  {
    "Code": "HIS027190",
    "Literal": "HISTORY / Military / Afghan War (2001-)"
  },
  {
    "Code": "HIS027220",
    "Literal": "HISTORY / Military / Ancient"
  },
  {
    "Code": "HIS027140",
    "Literal": "HISTORY / Military / Aviation"
  },
  {
    "Code": "HIS027010",
    "Literal": "HISTORY / Military / Biological & Chemical Warfare"
  },
  {
    "Code": "HIS027160",
    "Literal": "HISTORY / Military / Canada"
  },
  {
    "Code": "HIS027170",
    "Literal": "HISTORY / Military / Iraq War (2003-2011)"
  },
  {
    "Code": "HIS027020",
    "Literal": "HISTORY / Military / Korean War"
  },
  {
    "Code": "HIS027230",
    "Literal": "HISTORY / Military / Medieval"
  },
  {
    "Code": "HIS027200",
    "Literal": "HISTORY / Military / Napoleonic Wars"
  },
  {
    "Code": "HIS027150",
    "Literal": "HISTORY / Military / Naval"
  },
  {
    "Code": "HIS027030",
    "Literal": "HISTORY / Military / Nuclear Warfare"
  },
  {
    "Code": "HIS027040",
    "Literal": "HISTORY / Military / Persian Gulf War (1991)"
  },
  {
    "Code": "HIS027050",
    "Literal": "HISTORY / Military / Pictorial"
  },
  {
    "Code": "HIS027180",
    "Literal": "HISTORY / Military / Special Forces"
  },
  {
    "Code": "HIS027060",
    "Literal": "HISTORY / Military / Strategy"
  },
  {
    "Code": "HIS027110",
    "Literal": "HISTORY / Military / United States"
  },
  {
    "Code": "HIS027240",
    "Literal": "HISTORY / Military / Vehicles"
  },
  {
    "Code": "HIS027120",
    "Literal": "HISTORY / Military / Veterans"
  },
  {
    "Code": "HIS027070",
    "Literal": "HISTORY / Military / Vietnam War"
  },
  {
    "Code": "HIS027210",
    "Literal": "HISTORY / Military / War of 1812"
  },
  {
    "Code": "HIS027130",
    "Literal": "HISTORY / Military / Wars & Conflicts (Other)"
  },
  {
    "Code": "HIS027080",
    "Literal": "HISTORY / Military / Weapons"
  },
  {
    "Code": "HIS027090",
    "Literal": "HISTORY / Military / World War I"
  },
  {
    "Code": "HIS027100",
    "Literal": "HISTORY / Military / World War II"
  },
  {
    "Code": "HIS037030",
    "Literal": "HISTORY / Modern / General"
  },
  {
    "Code": "HIS037090",
    "Literal": "HISTORY / Modern / 16th Century"
  },
  {
    "Code": "HIS037040",
    "Literal": "HISTORY / Modern / 17th Century"
  },
  {
    "Code": "HIS037050",
    "Literal": "HISTORY / Modern / 18th Century"
  },
  {
    "Code": "HIS037060",
    "Literal": "HISTORY / Modern / 19th Century"
  },
  {
    "Code": "HIS037070",
    "Literal": "HISTORY / Modern / 20th Century"
  },
  {
    "Code": "HIS037080",
    "Literal": "HISTORY / Modern / 21st Century"
  },
  {
    "Code": "HIS028000",
    "Literal": "HISTORY / Native American"
  },
  {
    "Code": "HIS029000",
    "Literal": "HISTORY / North America"
  },
  {
    "Code": "HIS053000",
    "Literal": "HISTORY / Oceania"
  },
  {
    "Code": "HIS046000",
    "Literal": "HISTORY / Polar Regions"
  },
  {
    "Code": "HIS030000",
    "Literal": "HISTORY / Reference"
  },
  {
    "Code": "HIS031000",
    "Literal": "HISTORY / Revolutionary"
  },
  {
    "Code": "HIS032000",
    "Literal": "HISTORY / Russia & the Former Soviet Union"
  },
  {
    "Code": "HIS054000",
    "Literal": "HISTORY / Social History"
  },
  {
    "Code": "HIS035000",
    "Literal": "HISTORY / Study & Teaching"
  },
  {
    "Code": "HIS036000",
    "Literal": "HISTORY / United States / General"
  },
  {
    "Code": "HIS036020",
    "Literal": "HISTORY / United States / Colonial Period (1600-1775)"
  },
  {
    "Code": "HIS036030",
    "Literal": "HISTORY / United States / Revolutionary Period (1775-1800)"
  },
  {
    "Code": "HIS036040",
    "Literal": "HISTORY / United States / 19th Century"
  },
  {
    "Code": "HIS036050",
    "Literal": "HISTORY / United States / Civil War Period (1850-1877)"
  },
  {
    "Code": "HIS036060",
    "Literal": "HISTORY / United States / 20th Century"
  },
  {
    "Code": "HIS036070",
    "Literal": "HISTORY / United States / 21st Century"
  },
  {
    "Code": "HIS036010",
    "Literal": "HISTORY / United States / State & Local / General"
  },
  {
    "Code": "HIS036080",
    "Literal": "HISTORY / United States / State & Local / Middle Atlantic (DC, DE, MD, NJ, NY, PA)"
  },
  {
    "Code": "HIS036090",
    "Literal": "HISTORY / United States / State & Local / Midwest (IA, IL, IN, KS, MI, MN, MO, ND, NE, OH, SD, WI)"
  },
  {
    "Code": "HIS036100",
    "Literal": "HISTORY / United States / State & Local / New England (CT, MA, ME, NH, RI, VT)"
  },
  {
    "Code": "HIS036110",
    "Literal": "HISTORY / United States / State & Local / Pacific Northwest (OR, WA)"
  },
  {
    "Code": "HIS036120",
    "Literal": "HISTORY / United States / State & Local / South (AL, AR, FL, GA, KY, LA, MS, NC, SC, TN, VA, WV)"
  },
  {
    "Code": "HIS036130",
    "Literal": "HISTORY / United States / State & Local / Southwest (AZ, NM, OK, TX)"
  },
  {
    "Code": "HIS036140",
    "Literal": "HISTORY / United States / State & Local / West (AK, CA, CO, HI, ID, MT, NV, UT, WY)"
  },
  {
    "Code": "HIS058000",
    "Literal": "HISTORY / Women"
  },
  {
    "Code": "HIS037000",
    "Literal": "HISTORY / World"
  },
  {
    "Code": "HOM000000",
    "Literal": "HOUSE & HOME / General"
  },
  {
    "Code": "HOM019000",
    "Literal": "HOUSE & HOME / Cleaning, Caretaking & Organizing"
  },
  {
    "Code": "HOM003000",
    "Literal": "HOUSE & HOME / Decorating & Furnishings"
  },
  {
    "Code": "HOM004000",
    "Literal": "HOUSE & HOME / Design & Construction"
  },
  {
    "Code": "HOM005000",
    "Literal": "HOUSE & HOME / Do-It-Yourself / General"
  },
  {
    "Code": "HOM001000",
    "Literal": "HOUSE & HOME / Do-It-Yourself / Carpentry"
  },
  {
    "Code": "HOM006000",
    "Literal": "HOUSE & HOME / Do-It-Yourself / Electrical"
  },
  {
    "Code": "HOM012000",
    "Literal": "HOUSE & HOME / Do-It-Yourself / Masonry"
  },
  {
    "Code": "HOM014000",
    "Literal": "HOUSE & HOME / Do-It-Yourself / Plumbing"
  },
  {
    "Code": "HOM024000",
    "Literal": "HOUSE & HOME / Energy"
  },
  {
    "Code": "HOM020000",
    "Literal": "HOUSE & HOME / Equipment, Appliances & Supplies"
  },
  {
    "Code": "HOM009000",
    "Literal": "HOUSE & HOME / Hand Tools"
  },
  {
    "Code": "HOM011000",
    "Literal": "HOUSE & HOME / House Plans"
  },
  {
    "Code": "HOM010000",
    "Literal": "HOUSE & HOME / Maintenance & Repair"
  },
  {
    "Code": "HOM025000",
    "Literal": "HOUSE & HOME / Moving & Relocation"
  },
  {
    "Code": "HOM013000",
    "Literal": "HOUSE & HOME / Outdoor & Recreational Areas"
  },
  {
    "Code": "HOM015000",
    "Literal": "HOUSE & HOME / Power Tools"
  },
  {
    "Code": "HOM016000",
    "Literal": "HOUSE & HOME / Reference"
  },
  {
    "Code": "HOM017000",
    "Literal": "HOUSE & HOME / Remodeling & Renovation"
  },
  {
    "Code": "HOM021000",
    "Literal": "HOUSE & HOME / Security"
  },
  {
    "Code": "HOM023000",
    "Literal": "HOUSE & HOME / Small Spaces"
  },
  {
    "Code": "HOM022000",
    "Literal": "HOUSE & HOME / Sustainable Living"
  },
  {
    "Code": "HUM000000",
    "Literal": "HUMOR / General"
  },
  {
    "Code": "HUM015000",
    "Literal": "HUMOR / Form / Anecdotes & Quotations"
  },
  {
    "Code": "HUM001000",
    "Literal": "HUMOR / Form / Comic Strips & Cartoons"
  },
  {
    "Code": "HUM003000",
    "Literal": "HUMOR / Form / Essays"
  },
  {
    "Code": "HUM004000",
    "Literal": "HUMOR / Form / Jokes & Riddles"
  },
  {
    "Code": "HUM005000",
    "Literal": "HUMOR / Form / Limericks & Verse"
  },
  {
    "Code": "HUM007000",
    "Literal": "HUMOR / Form / Parodies"
  },
  {
    "Code": "HUM017000",
    "Literal": "HUMOR / Form / Pictorial"
  },
  {
    "Code": "HUM018000",
    "Literal": "HUMOR / Form / Puns & Wordplay"
  },
  {
    "Code": "HUM016000",
    "Literal": "HUMOR / Form / Trivia"
  },
  {
    "Code": "HUM008000",
    "Literal": "HUMOR / Topic / Adult"
  },
  {
    "Code": "HUM009000",
    "Literal": "HUMOR / Topic / Animals"
  },
  {
    "Code": "HUM010000",
    "Literal": "HUMOR / Topic / Business & Professional"
  },
  {
    "Code": "HUM020000",
    "Literal": "HUMOR / Topic / Celebrity & Popular Culture"
  },
  {
    "Code": "HUM021000",
    "Literal": "HUMOR / Topic / Cultural, Ethnic & Regional"
  },
  {
    "Code": "HUM022000",
    "Literal": "HUMOR / Topic / History"
  },
  {
    "Code": "HUM023000",
    "Literal": "HUMOR / Topic / Internet & Social Media"
  },
  {
    "Code": "HUM019000",
    "Literal": "HUMOR / Topic / Language"
  },
  {
    "Code": "HUM024000",
    "Literal": "HUMOR / Topic / LGBT"
  },
  {
    "Code": "HUM011000",
    "Literal": "HUMOR / Topic / Marriage & Family"
  },
  {
    "Code": "HUM012000",
    "Literal": "HUMOR / Topic / Men, Women & Relationships"
  },
  {
    "Code": "HUM006000",
    "Literal": "HUMOR / Topic / Politics"
  },
  {
    "Code": "HUM014000",
    "Literal": "HUMOR / Topic / Religion"
  },
  {
    "Code": "HUM025000",
    "Literal": "HUMOR / Topic / School & Education"
  },
  {
    "Code": "HUM013000",
    "Literal": "HUMOR / Topic / Sports"
  },
  {
    "Code": "HUM026000",
    "Literal": "HUMOR / Topic / Travel"
  },
  {
    "Code": "JUV000000",
    "Literal": "JUVENILE FICTION / General"
  },
  {
    "Code": "JUV001000",
    "Literal": "JUVENILE FICTION / Action & Adventure / General"
  },
  {
    "Code": "JUV001020",
    "Literal": "JUVENILE FICTION / Action & Adventure / Pirates"
  },
  {
    "Code": "JUV001010",
    "Literal": "JUVENILE FICTION / Action & Adventure / Survival Stories"
  },
  {
    "Code": "JUV054000",
    "Literal": "JUVENILE FICTION / Activity Books / General"
  },
  {
    "Code": "JUV054010",
    "Literal": "JUVENILE FICTION / Activity Books / Coloring"
  },
  {
    "Code": "JUV054020",
    "Literal": "JUVENILE FICTION / Activity Books / Sticker"
  },
  {
    "Code": "JUV002000",
    "Literal": "JUVENILE FICTION / Animals / General"
  },
  {
    "Code": "JUV002010",
    "Literal": "JUVENILE FICTION / Animals / Alligators & Crocodiles"
  },
  {
    "Code": "JUV002020",
    "Literal": "JUVENILE FICTION / Animals / Apes, Monkeys, etc."
  },
  {
    "Code": "JUV002370",
    "Literal": "JUVENILE FICTION / Animals / Baby Animals"
  },
  {
    "Code": "JUV002030",
    "Literal": "JUVENILE FICTION / Animals / Bears"
  },
  {
    "Code": "JUV002040",
    "Literal": "JUVENILE FICTION / Animals / Birds"
  },
  {
    "Code": "JUV002300",
    "Literal": "JUVENILE FICTION / Animals / Butterflies, Moths & Caterpillars"
  },
  {
    "Code": "JUV002050",
    "Literal": "JUVENILE FICTION / Animals / Cats"
  },
  {
    "Code": "JUV002310",
    "Literal": "JUVENILE FICTION / Animals / Cows"
  },
  {
    "Code": "JUV002290",
    "Literal": "JUVENILE FICTION / Animals / Deer, Moose & Caribou"
  },
  {
    "Code": "JUV002060",
    "Literal": "JUVENILE FICTION / Animals / Dinosaurs & Prehistoric Creatures"
  },
  {
    "Code": "JUV002070",
    "Literal": "JUVENILE FICTION / Animals / Dogs"
  },
  {
    "Code": "JUV002270",
    "Literal": "JUVENILE FICTION / Animals / Dragons, Unicorns & Mythical"
  },
  {
    "Code": "JUV002280",
    "Literal": "JUVENILE FICTION / Animals / Ducks, Geese, etc."
  },
  {
    "Code": "JUV002080",
    "Literal": "JUVENILE FICTION / Animals / Elephants"
  },
  {
    "Code": "JUV002090",
    "Literal": "JUVENILE FICTION / Animals / Farm Animals"
  },
  {
    "Code": "JUV002100",
    "Literal": "JUVENILE FICTION / Animals / Fish"
  },
  {
    "Code": "JUV002110",
    "Literal": "JUVENILE FICTION / Animals / Foxes"
  },
  {
    "Code": "JUV002120",
    "Literal": "JUVENILE FICTION / Animals / Frogs & Toads"
  },
  {
    "Code": "JUV002320",
    "Literal": "JUVENILE FICTION / Animals / Giraffes"
  },
  {
    "Code": "JUV002330",
    "Literal": "JUVENILE FICTION / Animals / Hippos & Rhinos"
  },
  {
    "Code": "JUV002130",
    "Literal": "JUVENILE FICTION / Animals / Horses"
  },
  {
    "Code": "JUV002140",
    "Literal": "JUVENILE FICTION / Animals / Insects, Spiders, etc."
  },
  {
    "Code": "JUV002340",
    "Literal": "JUVENILE FICTION / Animals / Jungle Animals"
  },
  {
    "Code": "JUV002350",
    "Literal": "JUVENILE FICTION / Animals / Kangaroos"
  },
  {
    "Code": "JUV002150",
    "Literal": "JUVENILE FICTION / Animals / Lions, Tigers, Leopards, etc."
  },
  {
    "Code": "JUV002160",
    "Literal": "JUVENILE FICTION / Animals / Mammals"
  },
  {
    "Code": "JUV002170",
    "Literal": "JUVENILE FICTION / Animals / Marine Life"
  },
  {
    "Code": "JUV002180",
    "Literal": "JUVENILE FICTION / Animals / Mice, Hamsters, Guinea Pigs, etc."
  },
  {
    "Code": "JUV002360",
    "Literal": "JUVENILE FICTION / Animals / Nocturnal"
  },
  {
    "Code": "JUV002380",
    "Literal": "JUVENILE FICTION / Animals / Penguins"
  },
  {
    "Code": "JUV002190",
    "Literal": "JUVENILE FICTION / Animals / Pets"
  },
  {
    "Code": "JUV002200",
    "Literal": "JUVENILE FICTION / Animals / Pigs"
  },
  {
    "Code": "JUV002210",
    "Literal": "JUVENILE FICTION / Animals / Rabbits"
  },
  {
    "Code": "JUV002220",
    "Literal": "JUVENILE FICTION / Animals / Reptiles & Amphibians"
  },
  {
    "Code": "JUV002230",
    "Literal": "JUVENILE FICTION / Animals / Squirrels"
  },
  {
    "Code": "JUV002240",
    "Literal": "JUVENILE FICTION / Animals / Turtles"
  },
  {
    "Code": "JUV002250",
    "Literal": "JUVENILE FICTION / Animals / Wolves, Coyotes & Wild Dogs"
  },
  {
    "Code": "JUV002260",
    "Literal": "JUVENILE FICTION / Animals / Zoos"
  },
  {
    "Code": "JUV073000",
    "Literal": "JUVENILE FICTION / Architecture"
  },
  {
    "Code": "JUV003000",
    "Literal": "JUVENILE FICTION / Art"
  },
  {
    "Code": "JUV010000",
    "Literal": "JUVENILE FICTION / Bedtime & Dreams"
  },
  {
    "Code": "JUV004000",
    "Literal": "JUVENILE FICTION / Biographical / General"
  },
  {
    "Code": "JUV004050",
    "Literal": "JUVENILE FICTION / Biographical / Africa"
  },
  {
    "Code": "JUV004060",
    "Literal": "JUVENILE FICTION / Biographical / Asia"
  },
  {
    "Code": "JUV004040",
    "Literal": "JUVENILE FICTION / Biographical / Canada"
  },
  {
    "Code": "JUV004010",
    "Literal": "JUVENILE FICTION / Biographical / Europe"
  },
  {
    "Code": "JUV004070",
    "Literal": "JUVENILE FICTION / Biographical / Latin America"
  },
  {
    "Code": "JUV004020",
    "Literal": "JUVENILE FICTION / Biographical / United States"
  },
  {
    "Code": "JUV047000",
    "Literal": "JUVENILE FICTION / Books & Libraries"
  },
  {
    "Code": "JUV005000",
    "Literal": "JUVENILE FICTION / Boys & Men"
  },
  {
    "Code": "JUV006000",
    "Literal": "JUVENILE FICTION / Business, Careers, Occupations"
  },
  {
    "Code": "JUV007000",
    "Literal": "JUVENILE FICTION / Classics"
  },
  {
    "Code": "JUV048000",
    "Literal": "JUVENILE FICTION / Clothing & Dress"
  },
  {
    "Code": "JUV008000",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / General"
  },
  {
    "Code": "JUV008040",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Action & Adventure"
  },
  {
    "Code": "JUV008050",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Animals"
  },
  {
    "Code": "JUV008060",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Classic Adaptation"
  },
  {
    "Code": "JUV008070",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Fairy Tales, Folklore, Legends & Mythology"
  },
  {
    "Code": "JUV008080",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Fantasy"
  },
  {
    "Code": "JUV008090",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Historical"
  },
  {
    "Code": "JUV008100",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Horror"
  },
  {
    "Code": "JUV008110",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Humorous"
  },
  {
    "Code": "JUV008010",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Manga"
  },
  {
    "Code": "JUV008030",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Media Tie-In"
  },
  {
    "Code": "JUV008120",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Mystery & Detective"
  },
  {
    "Code": "JUV008130",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Paranormal"
  },
  {
    "Code": "JUV008140",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Science Fiction"
  },
  {
    "Code": "JUV008020",
    "Literal": "JUVENILE FICTION / Comics & Graphic Novels / Superheroes"
  },
  {
    "Code": "JUV049000",
    "Literal": "JUVENILE FICTION / Computers & Digital Media"
  },
  {
    "Code": "JUV009000",
    "Literal": "JUVENILE FICTION / Concepts / General"
  },
  {
    "Code": "JUV009010",
    "Literal": "JUVENILE FICTION / Concepts / Alphabet"
  },
  {
    "Code": "JUV009120",
    "Literal": "JUVENILE FICTION / Concepts / Body"
  },
  {
    "Code": "JUV009020",
    "Literal": "JUVENILE FICTION / Concepts / Colors"
  },
  {
    "Code": "JUV009030",
    "Literal": "JUVENILE FICTION / Concepts / Counting & Numbers"
  },
  {
    "Code": "JUV009070",
    "Literal": "JUVENILE FICTION / Concepts / Date & Time"
  },
  {
    "Code": "JUV009090",
    "Literal": "JUVENILE FICTION / Concepts / Money"
  },
  {
    "Code": "JUV009040",
    "Literal": "JUVENILE FICTION / Concepts / Opposites"
  },
  {
    "Code": "JUV009100",
    "Literal": "JUVENILE FICTION / Concepts / Seasons"
  },
  {
    "Code": "JUV009050",
    "Literal": "JUVENILE FICTION / Concepts / Senses & Sensation"
  },
  {
    "Code": "JUV009060",
    "Literal": "JUVENILE FICTION / Concepts / Size & Shape"
  },
  {
    "Code": "JUV009110",
    "Literal": "JUVENILE FICTION / Concepts / Sounds"
  },
  {
    "Code": "JUV009080",
    "Literal": "JUVENILE FICTION / Concepts / Words"
  },
  {
    "Code": "JUV050000",
    "Literal": "JUVENILE FICTION / Cooking & Food"
  },
  {
    "Code": "JUV039150",
    "Literal": "JUVENILE FICTION / Disabilities & Special Needs"
  },
  {
    "Code": "JUV074000",
    "Literal": "JUVENILE FICTION / Diversity & Multicultural"
  },
  {
    "Code": "JUV059000",
    "Literal": "JUVENILE FICTION / Dystopian"
  },
  {
    "Code": "JUV012030",
    "Literal": "JUVENILE FICTION / Fairy Tales & Folklore / General"
  },
  {
    "Code": "JUV012040",
    "Literal": "JUVENILE FICTION / Fairy Tales & Folklore / Adaptations"
  },
  {
    "Code": "JUV012000",
    "Literal": "JUVENILE FICTION / Fairy Tales & Folklore / Anthologies"
  },
  {
    "Code": "JUV012020",
    "Literal": "JUVENILE FICTION / Fairy Tales & Folklore / Country & Ethnic"
  },
  {
    "Code": "JUV013000",
    "Literal": "JUVENILE FICTION / Family / General (see also headings under Social Themes)"
  },
  {
    "Code": "JUV013010",
    "Literal": "JUVENILE FICTION / Family / Adoption"
  },
  {
    "Code": "JUV013090",
    "Literal": "JUVENILE FICTION / Family / Alternative Family"
  },
  {
    "Code": "JUV013080",
    "Literal": "JUVENILE FICTION / Family / Blended Families"
  },
  {
    "Code": "JUV013020",
    "Literal": "JUVENILE FICTION / Family / Marriage & Divorce"
  },
  {
    "Code": "JUV013030",
    "Literal": "JUVENILE FICTION / Family / Multigenerational"
  },
  {
    "Code": "JUV013040",
    "Literal": "JUVENILE FICTION / Family / New Baby"
  },
  {
    "Code": "JUV013050",
    "Literal": "JUVENILE FICTION / Family / Orphans & Foster Homes"
  },
  {
    "Code": "JUV013060",
    "Literal": "JUVENILE FICTION / Family / Parents"
  },
  {
    "Code": "JUV013070",
    "Literal": "JUVENILE FICTION / Family / Siblings"
  },
  {
    "Code": "JUV037000",
    "Literal": "JUVENILE FICTION / Fantasy & Magic"
  },
  {
    "Code": "JUV069000",
    "Literal": "JUVENILE FICTION / Ghost Stories"
  },
  {
    "Code": "JUV014000",
    "Literal": "JUVENILE FICTION / Girls & Women"
  },
  {
    "Code": "JUV015000",
    "Literal": "JUVENILE FICTION / Health & Daily Living / General"
  },
  {
    "Code": "JUV015010",
    "Literal": "JUVENILE FICTION / Health & Daily Living / Daily Activities"
  },
  {
    "Code": "JUV015020",
    "Literal": "JUVENILE FICTION / Health & Daily Living / Diseases, Illnesses & Injuries"
  },
  {
    "Code": "JUV039170",
    "Literal": "JUVENILE FICTION / Health & Daily Living / Toilet Training"
  },
  {
    "Code": "JUV016000",
    "Literal": "JUVENILE FICTION / Historical / General"
  },
  {
    "Code": "JUV016010",
    "Literal": "JUVENILE FICTION / Historical / Africa"
  },
  {
    "Code": "JUV016020",
    "Literal": "JUVENILE FICTION / Historical / Ancient Civilizations"
  },
  {
    "Code": "JUV016030",
    "Literal": "JUVENILE FICTION / Historical / Asia"
  },
  {
    "Code": "JUV016160",
    "Literal": "JUVENILE FICTION / Historical / Canada / General"
  },
  {
    "Code": "JUV016170",
    "Literal": "JUVENILE FICTION / Historical / Canada / Pre-Confederation (to 1867)"
  },
  {
    "Code": "JUV016180",
    "Literal": "JUVENILE FICTION / Historical / Canada / Post-Confederation (1867-)"
  },
  {
    "Code": "JUV016040",
    "Literal": "JUVENILE FICTION / Historical / Europe"
  },
  {
    "Code": "JUV016050",
    "Literal": "JUVENILE FICTION / Historical / Exploration & Discovery"
  },
  {
    "Code": "JUV016060",
    "Literal": "JUVENILE FICTION / Historical / Holocaust"
  },
  {
    "Code": "JUV016070",
    "Literal": "JUVENILE FICTION / Historical / Medieval"
  },
  {
    "Code": "JUV016210",
    "Literal": "JUVENILE FICTION / Historical / Middle East"
  },
  {
    "Code": "JUV016080",
    "Literal": "JUVENILE FICTION / Historical / Military & Wars"
  },
  {
    "Code": "JUV016090",
    "Literal": "JUVENILE FICTION / Historical / Prehistory"
  },
  {
    "Code": "JUV016100",
    "Literal": "JUVENILE FICTION / Historical / Renaissance"
  },
  {
    "Code": "JUV016110",
    "Literal": "JUVENILE FICTION / Historical / United States / General"
  },
  {
    "Code": "JUV016120",
    "Literal": "JUVENILE FICTION / Historical / United States / Colonial & Revolutionary Periods"
  },
  {
    "Code": "JUV016140",
    "Literal": "JUVENILE FICTION / Historical / United States / 19th Century"
  },
  {
    "Code": "JUV016200",
    "Literal": "JUVENILE FICTION / Historical / United States / Civil War Period (1850-1877)"
  },
  {
    "Code": "JUV016150",
    "Literal": "JUVENILE FICTION / Historical / United States / 20th Century"
  },
  {
    "Code": "JUV016190",
    "Literal": "JUVENILE FICTION / Historical / United States / 21st Century"
  },
  {
    "Code": "JUV017000",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / General (see also Religious / Christian / Holidays & Celebrations)"
  },
  {
    "Code": "JUV017100",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Birthdays"
  },
  {
    "Code": "JUV017010",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Christmas & Advent"
  },
  {
    "Code": "JUV017020",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Easter & Lent"
  },
  {
    "Code": "JUV017140",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Father's Day"
  },
  {
    "Code": "JUV017030",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Halloween"
  },
  {
    "Code": "JUV017110",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Hanukkah"
  },
  {
    "Code": "JUV017050",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Kwanzaa"
  },
  {
    "Code": "JUV017150",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Mother's Day"
  },
  {
    "Code": "JUV017120",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Passover"
  },
  {
    "Code": "JUV017130",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Patriotic Holidays"
  },
  {
    "Code": "JUV017060",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Thanksgiving"
  },
  {
    "Code": "JUV017070",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Valentine's Day"
  },
  {
    "Code": "JUV017080",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Other, Non-Religious"
  },
  {
    "Code": "JUV017090",
    "Literal": "JUVENILE FICTION / Holidays & Celebrations / Other, Religious"
  },
  {
    "Code": "JUV018000",
    "Literal": "JUVENILE FICTION / Horror"
  },
  {
    "Code": "JUV019000",
    "Literal": "JUVENILE FICTION / Humorous Stories"
  },
  {
    "Code": "JUV051000",
    "Literal": "JUVENILE FICTION / Imagination & Play"
  },
  {
    "Code": "JUV020000",
    "Literal": "JUVENILE FICTION / Interactive Adventures"
  },
  {
    "Code": "JUV021000",
    "Literal": "JUVENILE FICTION / Law & Crime"
  },
  {
    "Code": "JUV022000",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / General"
  },
  {
    "Code": "JUV012050",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / African"
  },
  {
    "Code": "JUV022010",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / Arthurian"
  },
  {
    "Code": "JUV012060",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / Asian"
  },
  {
    "Code": "JUV012070",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / Caribbean & Latin American"
  },
  {
    "Code": "JUV022020",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / Greek & Roman"
  },
  {
    "Code": "JUV012080",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / Native American"
  },
  {
    "Code": "JUV022030",
    "Literal": "JUVENILE FICTION / Legends, Myths, Fables / Norse"
  },
  {
    "Code": "JUV060000",
    "Literal": "JUVENILE FICTION / LGBT"
  },
  {
    "Code": "JUV023000",
    "Literal": "JUVENILE FICTION / Lifestyles / City & Town Life"
  },
  {
    "Code": "JUV024000",
    "Literal": "JUVENILE FICTION / Lifestyles / Country Life"
  },
  {
    "Code": "JUV025000",
    "Literal": "JUVENILE FICTION / Lifestyles / Farm & Ranch Life"
  },
  {
    "Code": "JUV026000",
    "Literal": "JUVENILE FICTION / Love & Romance"
  },
  {
    "Code": "JUV072000",
    "Literal": "JUVENILE FICTION / Mathematics"
  },
  {
    "Code": "JUV027000",
    "Literal": "JUVENILE FICTION / Media Tie-In"
  },
  {
    "Code": "JUV066000",
    "Literal": "JUVENILE FICTION / Mermaids"
  },
  {
    "Code": "JUV052000",
    "Literal": "JUVENILE FICTION / Monsters"
  },
  {
    "Code": "JUV028000",
    "Literal": "JUVENILE FICTION / Mysteries & Detective Stories"
  },
  {
    "Code": "JUV055000",
    "Literal": "JUVENILE FICTION / Nursery Rhymes"
  },
  {
    "Code": "JUV058000",
    "Literal": "JUVENILE FICTION / Paranormal, Occult & Supernatural"
  },
  {
    "Code": "JUV030000",
    "Literal": "JUVENILE FICTION / People & Places / General"
  },
  {
    "Code": "JUV030010",
    "Literal": "JUVENILE FICTION / People & Places / Africa"
  },
  {
    "Code": "JUV030020",
    "Literal": "JUVENILE FICTION / People & Places / Asia"
  },
  {
    "Code": "JUV030080",
    "Literal": "JUVENILE FICTION / People & Places / Australia & Oceania"
  },
  {
    "Code": "JUV030030",
    "Literal": "JUVENILE FICTION / People & Places / Canada / General"
  },
  {
    "Code": "JUV030090",
    "Literal": "JUVENILE FICTION / People & Places / Canada / Native Canadian"
  },
  {
    "Code": "JUV030040",
    "Literal": "JUVENILE FICTION / People & Places / Caribbean & Latin America"
  },
  {
    "Code": "JUV030050",
    "Literal": "JUVENILE FICTION / People & Places / Europe"
  },
  {
    "Code": "JUV030100",
    "Literal": "JUVENILE FICTION / People & Places / Mexico"
  },
  {
    "Code": "JUV030110",
    "Literal": "JUVENILE FICTION / People & Places / Middle East"
  },
  {
    "Code": "JUV030120",
    "Literal": "JUVENILE FICTION / People & Places / Polar Regions"
  },
  {
    "Code": "JUV030060",
    "Literal": "JUVENILE FICTION / People & Places / United States / General"
  },
  {
    "Code": "JUV011010",
    "Literal": "JUVENILE FICTION / People & Places / United States / African American"
  },
  {
    "Code": "JUV011020",
    "Literal": "JUVENILE FICTION / People & Places / United States / Asian American"
  },
  {
    "Code": "JUV011030",
    "Literal": "JUVENILE FICTION / People & Places / United States / Hispanic & Latino"
  },
  {
    "Code": "JUV011040",
    "Literal": "JUVENILE FICTION / People & Places / United States / Native American"
  },
  {
    "Code": "JUV031000",
    "Literal": "JUVENILE FICTION / Performing Arts / General"
  },
  {
    "Code": "JUV031010",
    "Literal": "JUVENILE FICTION / Performing Arts / Circus"
  },
  {
    "Code": "JUV031020",
    "Literal": "JUVENILE FICTION / Performing Arts / Dance"
  },
  {
    "Code": "JUV031030",
    "Literal": "JUVENILE FICTION / Performing Arts / Film"
  },
  {
    "Code": "JUV031040",
    "Literal": "JUVENILE FICTION / Performing Arts / Music"
  },
  {
    "Code": "JUV031050",
    "Literal": "JUVENILE FICTION / Performing Arts / Television & Radio"
  },
  {
    "Code": "JUV031060",
    "Literal": "JUVENILE FICTION / Performing Arts / Theater & Musicals"
  },
  {
    "Code": "JUV070000",
    "Literal": "JUVENILE FICTION / Poetry (see also Stories in Verse)"
  },
  {
    "Code": "JUV061000",
    "Literal": "JUVENILE FICTION / Politics & Government"
  },
  {
    "Code": "JUV043000",
    "Literal": "JUVENILE FICTION / Readers / Beginner"
  },
  {
    "Code": "JUV044000",
    "Literal": "JUVENILE FICTION / Readers / Intermediate"
  },
  {
    "Code": "JUV045000",
    "Literal": "JUVENILE FICTION / Readers / Chapter Books"
  },
  {
    "Code": "JUV063000",
    "Literal": "JUVENILE FICTION / Recycling & Green Living"
  },
  {
    "Code": "JUV033000",
    "Literal": "JUVENILE FICTION / Religious / General"
  },
  {
    "Code": "JUV033250",
    "Literal": "JUVENILE FICTION / Religious / Buddhist"
  },
  {
    "Code": "JUV033010",
    "Literal": "JUVENILE FICTION / Religious / Christian / General"
  },
  {
    "Code": "JUV033040",
    "Literal": "JUVENILE FICTION / Religious / Christian / Action & Adventure"
  },
  {
    "Code": "JUV033050",
    "Literal": "JUVENILE FICTION / Religious / Christian / Animals"
  },
  {
    "Code": "JUV033060",
    "Literal": "JUVENILE FICTION / Religious / Christian / Bedtime & Dreams"
  },
  {
    "Code": "JUV033070",
    "Literal": "JUVENILE FICTION / Religious / Christian / Comics & Graphic Novels"
  },
  {
    "Code": "JUV033080",
    "Literal": "JUVENILE FICTION / Religious / Christian / Early Readers"
  },
  {
    "Code": "JUV033090",
    "Literal": "JUVENILE FICTION / Religious / Christian / Emotions & Feelings"
  },
  {
    "Code": "JUV033100",
    "Literal": "JUVENILE FICTION / Religious / Christian / Family"
  },
  {
    "Code": "JUV033110",
    "Literal": "JUVENILE FICTION / Religious / Christian / Fantasy & Science Fiction"
  },
  {
    "Code": "JUV033120",
    "Literal": "JUVENILE FICTION / Religious / Christian / Friendship"
  },
  {
    "Code": "JUV033140",
    "Literal": "JUVENILE FICTION / Religious / Christian / Historical"
  },
  {
    "Code": "JUV033150",
    "Literal": "JUVENILE FICTION / Religious / Christian / Holidays & Celebrations"
  },
  {
    "Code": "JUV033160",
    "Literal": "JUVENILE FICTION / Religious / Christian / Humorous"
  },
  {
    "Code": "JUV033280",
    "Literal": "JUVENILE FICTION / Religious / Christian / Inspirational"
  },
  {
    "Code": "JUV033170",
    "Literal": "JUVENILE FICTION / Religious / Christian / Learning Concepts"
  },
  {
    "Code": "JUV033180",
    "Literal": "JUVENILE FICTION / Religious / Christian / Mysteries & Detective Stories"
  },
  {
    "Code": "JUV033190",
    "Literal": "JUVENILE FICTION / Religious / Christian / People & Places"
  },
  {
    "Code": "JUV033200",
    "Literal": "JUVENILE FICTION / Religious / Christian / Relationships"
  },
  {
    "Code": "JUV033220",
    "Literal": "JUVENILE FICTION / Religious / Christian / Social Issues"
  },
  {
    "Code": "JUV033230",
    "Literal": "JUVENILE FICTION / Religious / Christian / Sports & Recreation"
  },
  {
    "Code": "JUV033240",
    "Literal": "JUVENILE FICTION / Religious / Christian / Values & Virtues"
  },
  {
    "Code": "JUV033260",
    "Literal": "JUVENILE FICTION / Religious / Hindu"
  },
  {
    "Code": "JUV033020",
    "Literal": "JUVENILE FICTION / Religious / Jewish"
  },
  {
    "Code": "JUV033270",
    "Literal": "JUVENILE FICTION / Religious / Muslim"
  },
  {
    "Code": "JUV056000",
    "Literal": "JUVENILE FICTION / Robots"
  },
  {
    "Code": "JUV034000",
    "Literal": "JUVENILE FICTION / Royalty"
  },
  {
    "Code": "JUV035000",
    "Literal": "JUVENILE FICTION / School & Education"
  },
  {
    "Code": "JUV029000",
    "Literal": "JUVENILE FICTION / Science & Nature / General"
  },
  {
    "Code": "JUV029030",
    "Literal": "JUVENILE FICTION / Science & Nature / Disasters"
  },
  {
    "Code": "JUV029010",
    "Literal": "JUVENILE FICTION / Science & Nature / Environment"
  },
  {
    "Code": "JUV029020",
    "Literal": "JUVENILE FICTION / Science & Nature / Weather"
  },
  {
    "Code": "JUV053000",
    "Literal": "JUVENILE FICTION / Science Fiction / General"
  },
  {
    "Code": "JUV053010",
    "Literal": "JUVENILE FICTION / Science Fiction / Alien Contact"
  },
  {
    "Code": "JUV053020",
    "Literal": "JUVENILE FICTION / Science Fiction / Space Exploration"
  },
  {
    "Code": "JUV064000",
    "Literal": "JUVENILE FICTION / Science Fiction / Time Travel"
  },
  {
    "Code": "JUV038000",
    "Literal": "JUVENILE FICTION / Short Stories"
  },
  {
    "Code": "JUV039000",
    "Literal": "JUVENILE FICTION / Social Themes / General (see also headings under Family)"
  },
  {
    "Code": "JUV039020",
    "Literal": "JUVENILE FICTION / Social Themes / Adolescence & Coming of Age"
  },
  {
    "Code": "JUV039230",
    "Literal": "JUVENILE FICTION / Social Themes / Bullying"
  },
  {
    "Code": "JUV039190",
    "Literal": "JUVENILE FICTION / Social Themes / Dating & Relationships"
  },
  {
    "Code": "JUV039030",
    "Literal": "JUVENILE FICTION / Social Themes / Death, Grief, Bereavement"
  },
  {
    "Code": "JUV039240",
    "Literal": "JUVENILE FICTION / Social Themes / Depression & Mental Illness"
  },
  {
    "Code": "JUV039040",
    "Literal": "JUVENILE FICTION / Social Themes / Drugs, Alcohol, Substance Abuse"
  },
  {
    "Code": "JUV039250",
    "Literal": "JUVENILE FICTION / Social Themes / Emigration & Immigration"
  },
  {
    "Code": "JUV039050",
    "Literal": "JUVENILE FICTION / Social Themes / Emotions & Feelings"
  },
  {
    "Code": "JUV039060",
    "Literal": "JUVENILE FICTION / Social Themes / Friendship"
  },
  {
    "Code": "JUV039070",
    "Literal": "JUVENILE FICTION / Social Themes / Homelessness & Poverty"
  },
  {
    "Code": "JUV039200",
    "Literal": "JUVENILE FICTION / Social Themes / Manners & Etiquette"
  },
  {
    "Code": "JUV039090",
    "Literal": "JUVENILE FICTION / Social Themes / New Experience"
  },
  {
    "Code": "JUV039100",
    "Literal": "JUVENILE FICTION / Social Themes / Peer Pressure"
  },
  {
    "Code": "JUV039010",
    "Literal": "JUVENILE FICTION / Social Themes / Physical & Emotional Abuse (see also Social Themes / Sexual Abuse)"
  },
  {
    "Code": "JUV039120",
    "Literal": "JUVENILE FICTION / Social Themes / Prejudice & Racism"
  },
  {
    "Code": "JUV039280",
    "Literal": "JUVENILE FICTION / Social Themes / Religion & Faith"
  },
  {
    "Code": "JUV039130",
    "Literal": "JUVENILE FICTION / Social Themes / Runaways"
  },
  {
    "Code": "JUV039140",
    "Literal": "JUVENILE FICTION / Social Themes / Self-Esteem & Self-Reliance"
  },
  {
    "Code": "JUV039210",
    "Literal": "JUVENILE FICTION / Social Themes / Sexual Abuse"
  },
  {
    "Code": "JUV039270",
    "Literal": "JUVENILE FICTION / Social Themes / Strangers"
  },
  {
    "Code": "JUV039220",
    "Literal": "JUVENILE FICTION / Social Themes / Values & Virtues"
  },
  {
    "Code": "JUV039180",
    "Literal": "JUVENILE FICTION / Social Themes / Violence"
  },
  {
    "Code": "JUV032000",
    "Literal": "JUVENILE FICTION / Sports & Recreation / General"
  },
  {
    "Code": "JUV032010",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Baseball & Softball"
  },
  {
    "Code": "JUV032020",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Basketball"
  },
  {
    "Code": "JUV032170",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Camping & Outdoor Activities"
  },
  {
    "Code": "JUV032180",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Cycling"
  },
  {
    "Code": "JUV032090",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Equestrian"
  },
  {
    "Code": "JUV032100",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Extreme Sports"
  },
  {
    "Code": "JUV032030",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Football"
  },
  {
    "Code": "JUV032040",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Games"
  },
  {
    "Code": "JUV032190",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Golf"
  },
  {
    "Code": "JUV032200",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Gymnastics"
  },
  {
    "Code": "JUV032110",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Hockey"
  },
  {
    "Code": "JUV032120",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Ice Skating"
  },
  {
    "Code": "JUV032070",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Martial Arts"
  },
  {
    "Code": "JUV032140",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Skateboarding"
  },
  {
    "Code": "JUV032150",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Soccer"
  },
  {
    "Code": "JUV032210",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Track & Field"
  },
  {
    "Code": "JUV032060",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Water Sports"
  },
  {
    "Code": "JUV032080",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Winter Sports"
  },
  {
    "Code": "JUV032160",
    "Literal": "JUVENILE FICTION / Sports & Recreation / Wrestling"
  },
  {
    "Code": "JUV062000",
    "Literal": "JUVENILE FICTION / Steampunk"
  },
  {
    "Code": "JUV057000",
    "Literal": "JUVENILE FICTION / Stories in Verse (see also Poetry)"
  },
  {
    "Code": "JUV071000",
    "Literal": "JUVENILE FICTION / Superheroes"
  },
  {
    "Code": "JUV036000",
    "Literal": "JUVENILE FICTION / Technology / General"
  },
  {
    "Code": "JUV036010",
    "Literal": "JUVENILE FICTION / Technology / Astronauts & Space"
  },
  {
    "Code": "JUV036020",
    "Literal": "JUVENILE FICTION / Technology / Inventions"
  },
  {
    "Code": "JUV067000",
    "Literal": "JUVENILE FICTION / Thrillers & Suspense"
  },
  {
    "Code": "JUV040000",
    "Literal": "JUVENILE FICTION / Toys, Dolls & Puppets"
  },
  {
    "Code": "JUV041000",
    "Literal": "JUVENILE FICTION / Transportation / General"
  },
  {
    "Code": "JUV041010",
    "Literal": "JUVENILE FICTION / Transportation / Aviation"
  },
  {
    "Code": "JUV041020",
    "Literal": "JUVENILE FICTION / Transportation / Boats, Ships & Underwater Craft"
  },
  {
    "Code": "JUV041030",
    "Literal": "JUVENILE FICTION / Transportation / Cars & Trucks"
  },
  {
    "Code": "JUV041050",
    "Literal": "JUVENILE FICTION / Transportation / Railroads & Trains"
  },
  {
    "Code": "JUV068000",
    "Literal": "JUVENILE FICTION / Travel"
  },
  {
    "Code": "JUV046000",
    "Literal": "JUVENILE FICTION / Visionary & Metaphysical"
  },
  {
    "Code": "JUV042000",
    "Literal": "JUVENILE FICTION / Westerns"
  },
  {
    "Code": "JNF000000",
    "Literal": "JUVENILE NONFICTION / General"
  },
  {
    "Code": "JNF001000",
    "Literal": "JUVENILE NONFICTION / Activity Books / General"
  },
  {
    "Code": "JNF001010",
    "Literal": "JUVENILE NONFICTION / Activity Books / Coloring"
  },
  {
    "Code": "JNF001020",
    "Literal": "JUVENILE NONFICTION / Activity Books / Sticker"
  },
  {
    "Code": "JNF002000",
    "Literal": "JUVENILE NONFICTION / Adventure & Adventurers"
  },
  {
    "Code": "JNF003000",
    "Literal": "JUVENILE NONFICTION / Animals / General"
  },
  {
    "Code": "JNF003220",
    "Literal": "JUVENILE NONFICTION / Animals / Animal Welfare"
  },
  {
    "Code": "JNF003010",
    "Literal": "JUVENILE NONFICTION / Animals / Apes, Monkeys, etc."
  },
  {
    "Code": "JNF003330",
    "Literal": "JUVENILE NONFICTION / Animals / Baby Animals"
  },
  {
    "Code": "JNF003020",
    "Literal": "JUVENILE NONFICTION / Animals / Bears"
  },
  {
    "Code": "JNF003030",
    "Literal": "JUVENILE NONFICTION / Animals / Birds"
  },
  {
    "Code": "JNF003250",
    "Literal": "JUVENILE NONFICTION / Animals / Butterflies, Moths & Caterpillars"
  },
  {
    "Code": "JNF003040",
    "Literal": "JUVENILE NONFICTION / Animals / Cats"
  },
  {
    "Code": "JNF003260",
    "Literal": "JUVENILE NONFICTION / Animals / Cows"
  },
  {
    "Code": "JNF003230",
    "Literal": "JUVENILE NONFICTION / Animals / Deer, Moose & Caribou"
  },
  {
    "Code": "JNF003050",
    "Literal": "JUVENILE NONFICTION / Animals / Dinosaurs & Prehistoric Creatures"
  },
  {
    "Code": "JNF003060",
    "Literal": "JUVENILE NONFICTION / Animals / Dogs"
  },
  {
    "Code": "JNF003210",
    "Literal": "JUVENILE NONFICTION / Animals / Ducks, Geese, etc."
  },
  {
    "Code": "JNF003070",
    "Literal": "JUVENILE NONFICTION / Animals / Elephants"
  },
  {
    "Code": "JNF003270",
    "Literal": "JUVENILE NONFICTION / Animals / Endangered"
  },
  {
    "Code": "JNF003080",
    "Literal": "JUVENILE NONFICTION / Animals / Farm Animals"
  },
  {
    "Code": "JNF003090",
    "Literal": "JUVENILE NONFICTION / Animals / Fish"
  },
  {
    "Code": "JNF003100",
    "Literal": "JUVENILE NONFICTION / Animals / Foxes"
  },
  {
    "Code": "JNF003340",
    "Literal": "JUVENILE NONFICTION / Animals / Frogs & Toads"
  },
  {
    "Code": "JNF003280",
    "Literal": "JUVENILE NONFICTION / Animals / Giraffes"
  },
  {
    "Code": "JNF003290",
    "Literal": "JUVENILE NONFICTION / Animals / Hippos & Rhinos"
  },
  {
    "Code": "JNF003110",
    "Literal": "JUVENILE NONFICTION / Animals / Horses"
  },
  {
    "Code": "JNF003120",
    "Literal": "JUVENILE NONFICTION / Animals / Insects, Spiders, etc."
  },
  {
    "Code": "JNF003300",
    "Literal": "JUVENILE NONFICTION / Animals / Jungle Animals"
  },
  {
    "Code": "JNF003310",
    "Literal": "JUVENILE NONFICTION / Animals / Kangaroos"
  },
  {
    "Code": "JNF003130",
    "Literal": "JUVENILE NONFICTION / Animals / Lions, Tigers, Leopards, etc."
  },
  {
    "Code": "JNF003140",
    "Literal": "JUVENILE NONFICTION / Animals / Mammals"
  },
  {
    "Code": "JNF003150",
    "Literal": "JUVENILE NONFICTION / Animals / Marine Life"
  },
  {
    "Code": "JNF003160",
    "Literal": "JUVENILE NONFICTION / Animals / Mice, Hamsters, Guinea Pigs, Squirrels, etc."
  },
  {
    "Code": "JNF003320",
    "Literal": "JUVENILE NONFICTION / Animals / Nocturnal"
  },
  {
    "Code": "JNF003350",
    "Literal": "JUVENILE NONFICTION / Animals / Penguins"
  },
  {
    "Code": "JNF003170",
    "Literal": "JUVENILE NONFICTION / Animals / Pets"
  },
  {
    "Code": "JNF003180",
    "Literal": "JUVENILE NONFICTION / Animals / Rabbits"
  },
  {
    "Code": "JNF003190",
    "Literal": "JUVENILE NONFICTION / Animals / Reptiles & Amphibians"
  },
  {
    "Code": "JNF003360",
    "Literal": "JUVENILE NONFICTION / Animals / Turtles"
  },
  {
    "Code": "JNF003240",
    "Literal": "JUVENILE NONFICTION / Animals / Wolves, Coyotes & Wild Dogs"
  },
  {
    "Code": "JNF003200",
    "Literal": "JUVENILE NONFICTION / Animals / Zoos"
  },
  {
    "Code": "JNF004000",
    "Literal": "JUVENILE NONFICTION / Antiques & Collectibles"
  },
  {
    "Code": "JNF005000",
    "Literal": "JUVENILE NONFICTION / Architecture"
  },
  {
    "Code": "JNF006000",
    "Literal": "JUVENILE NONFICTION / Art / General"
  },
  {
    "Code": "JNF006010",
    "Literal": "JUVENILE NONFICTION / Art / Cartooning"
  },
  {
    "Code": "JNF006020",
    "Literal": "JUVENILE NONFICTION / Art / Drawing"
  },
  {
    "Code": "JNF006030",
    "Literal": "JUVENILE NONFICTION / Art / Fashion"
  },
  {
    "Code": "JNF006040",
    "Literal": "JUVENILE NONFICTION / Art / History"
  },
  {
    "Code": "JNF006050",
    "Literal": "JUVENILE NONFICTION / Art / Painting"
  },
  {
    "Code": "JNF006060",
    "Literal": "JUVENILE NONFICTION / Art / Sculpture"
  },
  {
    "Code": "JNF006070",
    "Literal": "JUVENILE NONFICTION / Art / Techniques"
  },
  {
    "Code": "JNF067000",
    "Literal": "JUVENILE NONFICTION / Bedtime & Dreams"
  },
  {
    "Code": "JNF007000",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / General"
  },
  {
    "Code": "JNF007010",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Art"
  },
  {
    "Code": "JNF007050",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Cultural, Ethnic & Regional"
  },
  {
    "Code": "JNF007020",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Historical"
  },
  {
    "Code": "JNF007150",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / LGBT"
  },
  {
    "Code": "JNF007030",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Literary"
  },
  {
    "Code": "JNF007040",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Music"
  },
  {
    "Code": "JNF007060",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Performing Arts"
  },
  {
    "Code": "JNF007070",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Political"
  },
  {
    "Code": "JNF007130",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Presidents & First Families (U.S.)"
  },
  {
    "Code": "JNF007080",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Religious (see also Religious / Christian / Biography & Autobiography)"
  },
  {
    "Code": "JNF007140",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Royalty"
  },
  {
    "Code": "JNF007090",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Science & Technology"
  },
  {
    "Code": "JNF007110",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Social Activists"
  },
  {
    "Code": "JNF007100",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Sports & Recreation"
  },
  {
    "Code": "JNF007120",
    "Literal": "JUVENILE NONFICTION / Biography & Autobiography / Women"
  },
  {
    "Code": "JNF063000",
    "Literal": "JUVENILE NONFICTION / Books & Libraries"
  },
  {
    "Code": "JNF009000",
    "Literal": "JUVENILE NONFICTION / Boys & Men"
  },
  {
    "Code": "JNF010000",
    "Literal": "JUVENILE NONFICTION / Business & Economics"
  },
  {
    "Code": "JNF011000",
    "Literal": "JUVENILE NONFICTION / Careers"
  },
  {
    "Code": "JNF059000",
    "Literal": "JUVENILE NONFICTION / Clothing & Dress"
  },
  {
    "Code": "JNF062000",
    "Literal": "JUVENILE NONFICTION / Comics & Graphic Novels / General"
  },
  {
    "Code": "JNF062010",
    "Literal": "JUVENILE NONFICTION / Comics & Graphic Novels / Biography"
  },
  {
    "Code": "JNF062020",
    "Literal": "JUVENILE NONFICTION / Comics & Graphic Novels / History"
  },
  {
    "Code": "JNF062030",
    "Literal": "JUVENILE NONFICTION / Comics & Graphic Novels / Science & Nature"
  },
  {
    "Code": "JNF062040",
    "Literal": "JUVENILE NONFICTION / Comics & Graphic Novels / Social Topics"
  },
  {
    "Code": "JNF012000",
    "Literal": "JUVENILE NONFICTION / Computers / General"
  },
  {
    "Code": "JNF012040",
    "Literal": "JUVENILE NONFICTION / Computers / Coding & Programming"
  },
  {
    "Code": "JNF012010",
    "Literal": "JUVENILE NONFICTION / Computers / Entertainment & Games"
  },
  {
    "Code": "JNF012030",
    "Literal": "JUVENILE NONFICTION / Computers / Internet"
  },
  {
    "Code": "JNF012050",
    "Literal": "JUVENILE NONFICTION / Computers / Software"
  },
  {
    "Code": "JNF013000",
    "Literal": "JUVENILE NONFICTION / Concepts / General"
  },
  {
    "Code": "JNF013010",
    "Literal": "JUVENILE NONFICTION / Concepts / Alphabet"
  },
  {
    "Code": "JNF013110",
    "Literal": "JUVENILE NONFICTION / Concepts / Body"
  },
  {
    "Code": "JNF013020",
    "Literal": "JUVENILE NONFICTION / Concepts / Colors"
  },
  {
    "Code": "JNF013030",
    "Literal": "JUVENILE NONFICTION / Concepts / Counting & Numbers"
  },
  {
    "Code": "JNF013080",
    "Literal": "JUVENILE NONFICTION / Concepts / Date & Time"
  },
  {
    "Code": "JNF013040",
    "Literal": "JUVENILE NONFICTION / Concepts / Money"
  },
  {
    "Code": "JNF013050",
    "Literal": "JUVENILE NONFICTION / Concepts / Opposites"
  },
  {
    "Code": "JNF013090",
    "Literal": "JUVENILE NONFICTION / Concepts / Seasons"
  },
  {
    "Code": "JNF013060",
    "Literal": "JUVENILE NONFICTION / Concepts / Senses & Sensation"
  },
  {
    "Code": "JNF013070",
    "Literal": "JUVENILE NONFICTION / Concepts / Size & Shape"
  },
  {
    "Code": "JNF013100",
    "Literal": "JUVENILE NONFICTION / Concepts / Sounds"
  },
  {
    "Code": "JNF013120",
    "Literal": "JUVENILE NONFICTION / Concepts / Words (see also headings under Language Arts)"
  },
  {
    "Code": "JNF014000",
    "Literal": "JUVENILE NONFICTION / Cooking & Food"
  },
  {
    "Code": "JNF015000",
    "Literal": "JUVENILE NONFICTION / Crafts & Hobbies"
  },
  {
    "Code": "JNF016000",
    "Literal": "JUVENILE NONFICTION / Curiosities & Wonders"
  },
  {
    "Code": "JNF053180",
    "Literal": "JUVENILE NONFICTION / Disabilities & Special Needs"
  },
  {
    "Code": "JNF069000",
    "Literal": "JUVENILE NONFICTION / Diversity & Multicultural"
  },
  {
    "Code": "JNF017000",
    "Literal": "JUVENILE NONFICTION / Drama"
  },
  {
    "Code": "JNF019000",
    "Literal": "JUVENILE NONFICTION / Family / General (see also headings under Social Topics)"
  },
  {
    "Code": "JNF019010",
    "Literal": "JUVENILE NONFICTION / Family / Adoption"
  },
  {
    "Code": "JNF019090",
    "Literal": "JUVENILE NONFICTION / Family / Alternative Family"
  },
  {
    "Code": "JNF019080",
    "Literal": "JUVENILE NONFICTION / Family / Blended Families"
  },
  {
    "Code": "JNF019020",
    "Literal": "JUVENILE NONFICTION / Family / Marriage & Divorce"
  },
  {
    "Code": "JNF019030",
    "Literal": "JUVENILE NONFICTION / Family / Multigenerational"
  },
  {
    "Code": "JNF019040",
    "Literal": "JUVENILE NONFICTION / Family / New Baby"
  },
  {
    "Code": "JNF019050",
    "Literal": "JUVENILE NONFICTION / Family / Orphans & Foster Homes"
  },
  {
    "Code": "JNF019060",
    "Literal": "JUVENILE NONFICTION / Family / Parents"
  },
  {
    "Code": "JNF019070",
    "Literal": "JUVENILE NONFICTION / Family / Siblings"
  },
  {
    "Code": "JNF020000",
    "Literal": "JUVENILE NONFICTION / Foreign Language Study / General"
  },
  {
    "Code": "JNF020010",
    "Literal": "JUVENILE NONFICTION / Foreign Language Study / English as a Second Language"
  },
  {
    "Code": "JNF020020",
    "Literal": "JUVENILE NONFICTION / Foreign Language Study / French"
  },
  {
    "Code": "JNF020030",
    "Literal": "JUVENILE NONFICTION / Foreign Language Study / Spanish"
  },
  {
    "Code": "JNF021000",
    "Literal": "JUVENILE NONFICTION / Games & Activities / General"
  },
  {
    "Code": "JNF021010",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Board Games"
  },
  {
    "Code": "JNF021020",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Card Games"
  },
  {
    "Code": "JNF021030",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Magic"
  },
  {
    "Code": "JNF021040",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Puzzles"
  },
  {
    "Code": "JNF021050",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Questions & Answers"
  },
  {
    "Code": "JNF021060",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Video & Electronic Games"
  },
  {
    "Code": "JNF021070",
    "Literal": "JUVENILE NONFICTION / Games & Activities / Word Games"
  },
  {
    "Code": "JNF022000",
    "Literal": "JUVENILE NONFICTION / Gardening"
  },
  {
    "Code": "JNF023000",
    "Literal": "JUVENILE NONFICTION / Girls & Women"
  },
  {
    "Code": "JNF024000",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / General"
  },
  {
    "Code": "JNF024120",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Daily Activities"
  },
  {
    "Code": "JNF024010",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Diet & Nutrition"
  },
  {
    "Code": "JNF024020",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Diseases, Illnesses & Injuries"
  },
  {
    "Code": "JNF024100",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Drugs, Alcohol, Substance Abuse"
  },
  {
    "Code": "JNF024030",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / First Aid"
  },
  {
    "Code": "JNF024040",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Fitness & Exercise"
  },
  {
    "Code": "JNF024050",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Maturing"
  },
  {
    "Code": "JNF024130",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Mindfulness & Meditation"
  },
  {
    "Code": "JNF024060",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Personal Hygiene"
  },
  {
    "Code": "JNF024070",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Physical Impairments"
  },
  {
    "Code": "JNF024080",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Safety"
  },
  {
    "Code": "JNF024090",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Sexuality & Pregnancy"
  },
  {
    "Code": "JNF024110",
    "Literal": "JUVENILE NONFICTION / Health & Daily Living / Toilet Training"
  },
  {
    "Code": "JNF025000",
    "Literal": "JUVENILE NONFICTION / History / General"
  },
  {
    "Code": "JNF025010",
    "Literal": "JUVENILE NONFICTION / History / Africa"
  },
  {
    "Code": "JNF025020",
    "Literal": "JUVENILE NONFICTION / History / Ancient"
  },
  {
    "Code": "JNF025030",
    "Literal": "JUVENILE NONFICTION / History / Asia"
  },
  {
    "Code": "JNF025040",
    "Literal": "JUVENILE NONFICTION / History / Australia & Oceania"
  },
  {
    "Code": "JNF025050",
    "Literal": "JUVENILE NONFICTION / History / Canada / General"
  },
  {
    "Code": "JNF025230",
    "Literal": "JUVENILE NONFICTION / History / Canada / Pre-Confederation (to 1867)"
  },
  {
    "Code": "JNF025240",
    "Literal": "JUVENILE NONFICTION / History / Canada / Post-Confederation (1867-)"
  },
  {
    "Code": "JNF025060",
    "Literal": "JUVENILE NONFICTION / History / Central & South America"
  },
  {
    "Code": "JNF025070",
    "Literal": "JUVENILE NONFICTION / History / Europe"
  },
  {
    "Code": "JNF025080",
    "Literal": "JUVENILE NONFICTION / History / Exploration & Discovery"
  },
  {
    "Code": "JNF025090",
    "Literal": "JUVENILE NONFICTION / History / Holocaust"
  },
  {
    "Code": "JNF025100",
    "Literal": "JUVENILE NONFICTION / History / Medieval"
  },
  {
    "Code": "JNF025110",
    "Literal": "JUVENILE NONFICTION / History / Mexico"
  },
  {
    "Code": "JNF025120",
    "Literal": "JUVENILE NONFICTION / History / Middle East"
  },
  {
    "Code": "JNF025130",
    "Literal": "JUVENILE NONFICTION / History / Military & Wars"
  },
  {
    "Code": "JNF025140",
    "Literal": "JUVENILE NONFICTION / History / Modern"
  },
  {
    "Code": "JNF025150",
    "Literal": "JUVENILE NONFICTION / History / Prehistoric"
  },
  {
    "Code": "JNF025160",
    "Literal": "JUVENILE NONFICTION / History / Renaissance"
  },
  {
    "Code": "JNF025260",
    "Literal": "JUVENILE NONFICTION / History / Symbols, Monuments, National Parks, etc."
  },
  {
    "Code": "JNF025170",
    "Literal": "JUVENILE NONFICTION / History / United States / General"
  },
  {
    "Code": "JNF025180",
    "Literal": "JUVENILE NONFICTION / History / United States / State & Local"
  },
  {
    "Code": "JNF025190",
    "Literal": "JUVENILE NONFICTION / History / United States / Colonial & Revolutionary Periods"
  },
  {
    "Code": "JNF025200",
    "Literal": "JUVENILE NONFICTION / History / United States / 19th Century"
  },
  {
    "Code": "JNF025270",
    "Literal": "JUVENILE NONFICTION / History / United States / Civil War Period (1850-1877)"
  },
  {
    "Code": "JNF025210",
    "Literal": "JUVENILE NONFICTION / History / United States / 20th Century"
  },
  {
    "Code": "JNF025250",
    "Literal": "JUVENILE NONFICTION / History / United States / 21st Century"
  },
  {
    "Code": "JNF026000",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / General (see also Religious / Christian / Holidays & Celebrations)"
  },
  {
    "Code": "JNF026100",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Birthdays"
  },
  {
    "Code": "JNF026010",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Christmas & Advent"
  },
  {
    "Code": "JNF026020",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Easter & Lent"
  },
  {
    "Code": "JNF026030",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Halloween"
  },
  {
    "Code": "JNF026110",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Hanukkah"
  },
  {
    "Code": "JNF026050",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Kwanzaa"
  },
  {
    "Code": "JNF026120",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Passover"
  },
  {
    "Code": "JNF026130",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Patriotic Holidays"
  },
  {
    "Code": "JNF026060",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Thanksgiving"
  },
  {
    "Code": "JNF026070",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Valentine's Day"
  },
  {
    "Code": "JNF026080",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Other, Non-Religious"
  },
  {
    "Code": "JNF026090",
    "Literal": "JUVENILE NONFICTION / Holidays & Celebrations / Other, Religious"
  },
  {
    "Code": "JNF027000",
    "Literal": "JUVENILE NONFICTION / House & Home"
  },
  {
    "Code": "JNF028000",
    "Literal": "JUVENILE NONFICTION / Humor / General"
  },
  {
    "Code": "JNF028010",
    "Literal": "JUVENILE NONFICTION / Humor / Comic Strips & Cartoons"
  },
  {
    "Code": "JNF028020",
    "Literal": "JUVENILE NONFICTION / Humor / Jokes & Riddles"
  },
  {
    "Code": "JNF070000",
    "Literal": "JUVENILE NONFICTION / Inspirational & Personal Growth"
  },
  {
    "Code": "JNF029000",
    "Literal": "JUVENILE NONFICTION / Language Arts / General"
  },
  {
    "Code": "JNF029010",
    "Literal": "JUVENILE NONFICTION / Language Arts / Composition & Creative Writing"
  },
  {
    "Code": "JNF029020",
    "Literal": "JUVENILE NONFICTION / Language Arts / Grammar"
  },
  {
    "Code": "JNF029030",
    "Literal": "JUVENILE NONFICTION / Language Arts / Handwriting"
  },
  {
    "Code": "JNF029060",
    "Literal": "JUVENILE NONFICTION / Language Arts / Journal Writing"
  },
  {
    "Code": "JNF029050",
    "Literal": "JUVENILE NONFICTION / Language Arts / Sign Language"
  },
  {
    "Code": "JNF029040",
    "Literal": "JUVENILE NONFICTION / Language Arts / Vocabulary & Spelling"
  },
  {
    "Code": "JNF030000",
    "Literal": "JUVENILE NONFICTION / Law & Crime"
  },
  {
    "Code": "JNF053080",
    "Literal": "JUVENILE NONFICTION / LGBT"
  },
  {
    "Code": "JNF031000",
    "Literal": "JUVENILE NONFICTION / Lifestyles / City & Town Life"
  },
  {
    "Code": "JNF032000",
    "Literal": "JUVENILE NONFICTION / Lifestyles / Country Life"
  },
  {
    "Code": "JNF033000",
    "Literal": "JUVENILE NONFICTION / Lifestyles / Farm & Ranch Life"
  },
  {
    "Code": "JNF034000",
    "Literal": "JUVENILE NONFICTION / Literary Criticism & Collections"
  },
  {
    "Code": "JNF035000",
    "Literal": "JUVENILE NONFICTION / Mathematics / General"
  },
  {
    "Code": "JNF035020",
    "Literal": "JUVENILE NONFICTION / Mathematics / Algebra"
  },
  {
    "Code": "JNF035030",
    "Literal": "JUVENILE NONFICTION / Mathematics / Arithmetic"
  },
  {
    "Code": "JNF035040",
    "Literal": "JUVENILE NONFICTION / Mathematics / Fractions"
  },
  {
    "Code": "JNF035050",
    "Literal": "JUVENILE NONFICTION / Mathematics / Geometry"
  },
  {
    "Code": "JNF060000",
    "Literal": "JUVENILE NONFICTION / Media Studies"
  },
  {
    "Code": "JNF064000",
    "Literal": "JUVENILE NONFICTION / Media Tie-In"
  },
  {
    "Code": "JNF036000",
    "Literal": "JUVENILE NONFICTION / Music / General"
  },
  {
    "Code": "JNF036010",
    "Literal": "JUVENILE NONFICTION / Music / Classical"
  },
  {
    "Code": "JNF036020",
    "Literal": "JUVENILE NONFICTION / Music / History"
  },
  {
    "Code": "JNF036030",
    "Literal": "JUVENILE NONFICTION / Music / Instruction & Study"
  },
  {
    "Code": "JNF036090",
    "Literal": "JUVENILE NONFICTION / Music / Instruments"
  },
  {
    "Code": "JNF036040",
    "Literal": "JUVENILE NONFICTION / Music / Jazz"
  },
  {
    "Code": "JNF036050",
    "Literal": "JUVENILE NONFICTION / Music / Popular"
  },
  {
    "Code": "JNF036060",
    "Literal": "JUVENILE NONFICTION / Music / Rap & Hip Hop"
  },
  {
    "Code": "JNF036070",
    "Literal": "JUVENILE NONFICTION / Music / Rock"
  },
  {
    "Code": "JNF036080",
    "Literal": "JUVENILE NONFICTION / Music / Songbooks"
  },
  {
    "Code": "JNF008000",
    "Literal": "JUVENILE NONFICTION / Paranormal & Supernatural"
  },
  {
    "Code": "JNF038000",
    "Literal": "JUVENILE NONFICTION / People & Places / General"
  },
  {
    "Code": "JNF038010",
    "Literal": "JUVENILE NONFICTION / People & Places / Africa"
  },
  {
    "Code": "JNF038020",
    "Literal": "JUVENILE NONFICTION / People & Places / Asia"
  },
  {
    "Code": "JNF038030",
    "Literal": "JUVENILE NONFICTION / People & Places / Australia & Oceania"
  },
  {
    "Code": "JNF038040",
    "Literal": "JUVENILE NONFICTION / People & Places / Canada / General"
  },
  {
    "Code": "JNF038120",
    "Literal": "JUVENILE NONFICTION / People & Places / Canada / Native Canadian"
  },
  {
    "Code": "JNF038050",
    "Literal": "JUVENILE NONFICTION / People & Places / Caribbean & Latin America"
  },
  {
    "Code": "JNF038060",
    "Literal": "JUVENILE NONFICTION / People & Places / Europe"
  },
  {
    "Code": "JNF038070",
    "Literal": "JUVENILE NONFICTION / People & Places / Mexico"
  },
  {
    "Code": "JNF038080",
    "Literal": "JUVENILE NONFICTION / People & Places / Middle East"
  },
  {
    "Code": "JNF038090",
    "Literal": "JUVENILE NONFICTION / People & Places / Polar Regions"
  },
  {
    "Code": "JNF038100",
    "Literal": "JUVENILE NONFICTION / People & Places / United States / General"
  },
  {
    "Code": "JNF018010",
    "Literal": "JUVENILE NONFICTION / People & Places / United States / African American"
  },
  {
    "Code": "JNF018020",
    "Literal": "JUVENILE NONFICTION / People & Places / United States / Asian American"
  },
  {
    "Code": "JNF018030",
    "Literal": "JUVENILE NONFICTION / People & Places / United States / Hispanic & Latino"
  },
  {
    "Code": "JNF018040",
    "Literal": "JUVENILE NONFICTION / People & Places / United States / Native American"
  },
  {
    "Code": "JNF039000",
    "Literal": "JUVENILE NONFICTION / Performing Arts / General"
  },
  {
    "Code": "JNF039010",
    "Literal": "JUVENILE NONFICTION / Performing Arts / Circus"
  },
  {
    "Code": "JNF039020",
    "Literal": "JUVENILE NONFICTION / Performing Arts / Dance"
  },
  {
    "Code": "JNF039030",
    "Literal": "JUVENILE NONFICTION / Performing Arts / Film"
  },
  {
    "Code": "JNF039040",
    "Literal": "JUVENILE NONFICTION / Performing Arts / Television & Radio"
  },
  {
    "Code": "JNF039050",
    "Literal": "JUVENILE NONFICTION / Performing Arts / Theater & Musicals"
  },
  {
    "Code": "JNF040000",
    "Literal": "JUVENILE NONFICTION / Philosophy"
  },
  {
    "Code": "JNF041000",
    "Literal": "JUVENILE NONFICTION / Photography"
  },
  {
    "Code": "JNF066000",
    "Literal": "JUVENILE NONFICTION / Pirates"
  },
  {
    "Code": "JNF042000",
    "Literal": "JUVENILE NONFICTION / Poetry / General"
  },
  {
    "Code": "JNF042010",
    "Literal": "JUVENILE NONFICTION / Poetry / Humorous"
  },
  {
    "Code": "JNF045000",
    "Literal": "JUVENILE NONFICTION / Readers / Beginner"
  },
  {
    "Code": "JNF046000",
    "Literal": "JUVENILE NONFICTION / Readers / Intermediate"
  },
  {
    "Code": "JNF047000",
    "Literal": "JUVENILE NONFICTION / Readers / Chapter Books"
  },
  {
    "Code": "JNF065000",
    "Literal": "JUVENILE NONFICTION / Recycling & Green Living"
  },
  {
    "Code": "JNF048000",
    "Literal": "JUVENILE NONFICTION / Reference / General"
  },
  {
    "Code": "JNF048010",
    "Literal": "JUVENILE NONFICTION / Reference / Almanacs"
  },
  {
    "Code": "JNF048020",
    "Literal": "JUVENILE NONFICTION / Reference / Atlases"
  },
  {
    "Code": "JNF048030",
    "Literal": "JUVENILE NONFICTION / Reference / Dictionaries"
  },
  {
    "Code": "JNF048040",
    "Literal": "JUVENILE NONFICTION / Reference / Encyclopedias"
  },
  {
    "Code": "JNF048050",
    "Literal": "JUVENILE NONFICTION / Reference / Thesauri"
  },
  {
    "Code": "JNF049000",
    "Literal": "JUVENILE NONFICTION / Religion / General"
  },
  {
    "Code": "JNF049040",
    "Literal": "JUVENILE NONFICTION / Religion / Bible Stories / General"
  },
  {
    "Code": "JNF049140",
    "Literal": "JUVENILE NONFICTION / Religion / Bible Stories / Old Testament"
  },
  {
    "Code": "JNF049150",
    "Literal": "JUVENILE NONFICTION / Religion / Bible Stories / New Testament"
  },
  {
    "Code": "JNF049020",
    "Literal": "JUVENILE NONFICTION / Religion / Biblical Biography"
  },
  {
    "Code": "JNF049170",
    "Literal": "JUVENILE NONFICTION / Religion / Biblical Reference"
  },
  {
    "Code": "JNF049010",
    "Literal": "JUVENILE NONFICTION / Religion / Biblical Studies"
  },
  {
    "Code": "JNF049320",
    "Literal": "JUVENILE NONFICTION / Religion / Buddhism"
  },
  {
    "Code": "JNF049080",
    "Literal": "JUVENILE NONFICTION / Religion / Christianity"
  },
  {
    "Code": "JNF049090",
    "Literal": "JUVENILE NONFICTION / Religion / Eastern"
  },
  {
    "Code": "JNF049330",
    "Literal": "JUVENILE NONFICTION / Religion / Hinduism"
  },
  {
    "Code": "JNF049100",
    "Literal": "JUVENILE NONFICTION / Religion / Islam"
  },
  {
    "Code": "JNF049110",
    "Literal": "JUVENILE NONFICTION / Religion / Judaism"
  },
  {
    "Code": "JNF049130",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / General"
  },
  {
    "Code": "JNF049180",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Biography & Autobiography (see also Biography & Autobiography / Religious)"
  },
  {
    "Code": "JNF049190",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Comics & Graphic Novels"
  },
  {
    "Code": "JNF049120",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Devotional & Prayer"
  },
  {
    "Code": "JNF049200",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Early Readers"
  },
  {
    "Code": "JNF049210",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Family & Relationships"
  },
  {
    "Code": "JNF049220",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Games & Activities"
  },
  {
    "Code": "JNF049240",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Holidays & Celebrations"
  },
  {
    "Code": "JNF049250",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Inspirational"
  },
  {
    "Code": "JNF049260",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Learning Concepts"
  },
  {
    "Code": "JNF049280",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Science & Nature"
  },
  {
    "Code": "JNF049290",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Social Topics"
  },
  {
    "Code": "JNF049310",
    "Literal": "JUVENILE NONFICTION / Religious / Christian / Values & Virtues"
  },
  {
    "Code": "JNF050000",
    "Literal": "JUVENILE NONFICTION / School & Education"
  },
  {
    "Code": "JNF051000",
    "Literal": "JUVENILE NONFICTION / Science & Nature / General (see also headings under Animals or Technology)"
  },
  {
    "Code": "JNF051030",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Anatomy & Physiology"
  },
  {
    "Code": "JNF051040",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Astronomy"
  },
  {
    "Code": "JNF051050",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Biology"
  },
  {
    "Code": "JNF051070",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Chemistry"
  },
  {
    "Code": "JNF051160",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Disasters"
  },
  {
    "Code": "JNF051170",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Discoveries"
  },
  {
    "Code": "JNF051080",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / General"
  },
  {
    "Code": "JNF037010",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / Earthquakes & Volcanoes"
  },
  {
    "Code": "JNF051180",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / Geography"
  },
  {
    "Code": "JNF037060",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / Rocks & Minerals"
  },
  {
    "Code": "JNF037070",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / Water (Oceans, Lakes, etc.)"
  },
  {
    "Code": "JNF037080",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Earth Sciences / Weather"
  },
  {
    "Code": "JNF037020",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Environmental Conservation & Protection"
  },
  {
    "Code": "JNF051100",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Environmental Science & Ecosystems"
  },
  {
    "Code": "JNF051110",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Experiments & Projects"
  },
  {
    "Code": "JNF037030",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Flowers & Plants"
  },
  {
    "Code": "JNF037050",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Fossils"
  },
  {
    "Code": "JNF051190",
    "Literal": "JUVENILE NONFICTION / Science & Nature / History of Science"
  },
  {
    "Code": "JNF051140",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Physics"
  },
  {
    "Code": "JNF037040",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Trees & Forests"
  },
  {
    "Code": "JNF051200",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Weights & Measures"
  },
  {
    "Code": "JNF051150",
    "Literal": "JUVENILE NONFICTION / Science & Nature / Zoology"
  },
  {
    "Code": "JNF071000",
    "Literal": "JUVENILE NONFICTION / Social Activism & Volunteering"
  },
  {
    "Code": "JNF052000",
    "Literal": "JUVENILE NONFICTION / Social Science / General"
  },
  {
    "Code": "JNF052010",
    "Literal": "JUVENILE NONFICTION / Social Science / Archaeology"
  },
  {
    "Code": "JNF052020",
    "Literal": "JUVENILE NONFICTION / Social Science / Customs, Traditions, Anthropology"
  },
  {
    "Code": "JNF052030",
    "Literal": "JUVENILE NONFICTION / Social Science / Folklore & Mythology"
  },
  {
    "Code": "JNF043000",
    "Literal": "JUVENILE NONFICTION / Social Science / Politics & Government"
  },
  {
    "Code": "JNF044000",
    "Literal": "JUVENILE NONFICTION / Social Science / Psychology"
  },
  {
    "Code": "JNF052040",
    "Literal": "JUVENILE NONFICTION / Social Science / Sociology"
  },
  {
    "Code": "JNF053000",
    "Literal": "JUVENILE NONFICTION / Social Topics / General (see also headings under Family)"
  },
  {
    "Code": "JNF053010",
    "Literal": "JUVENILE NONFICTION / Social Topics / Adolescence"
  },
  {
    "Code": "JNF053220",
    "Literal": "JUVENILE NONFICTION / Social Topics / Bullying"
  },
  {
    "Code": "JNF053020",
    "Literal": "JUVENILE NONFICTION / Social Topics / Dating & Relationships"
  },
  {
    "Code": "JNF053030",
    "Literal": "JUVENILE NONFICTION / Social Topics / Death, Grief, Bereavement"
  },
  {
    "Code": "JNF053230",
    "Literal": "JUVENILE NONFICTION / Social Topics / Depression & Mental Illness"
  },
  {
    "Code": "JNF053040",
    "Literal": "JUVENILE NONFICTION / Social Topics / Drugs, Alcohol, Substance Abuse"
  },
  {
    "Code": "JNF053240",
    "Literal": "JUVENILE NONFICTION / Social Topics / Emigration & Immigration"
  },
  {
    "Code": "JNF053050",
    "Literal": "JUVENILE NONFICTION / Social Topics / Emotions & Feelings"
  },
  {
    "Code": "JNF053060",
    "Literal": "JUVENILE NONFICTION / Social Topics / Friendship"
  },
  {
    "Code": "JNF053070",
    "Literal": "JUVENILE NONFICTION / Social Topics / Homelessness & Poverty"
  },
  {
    "Code": "JNF053090",
    "Literal": "JUVENILE NONFICTION / Social Topics / Manners & Etiquette"
  },
  {
    "Code": "JNF053100",
    "Literal": "JUVENILE NONFICTION / Social Topics / New Experience"
  },
  {
    "Code": "JNF053110",
    "Literal": "JUVENILE NONFICTION / Social Topics / Peer Pressure"
  },
  {
    "Code": "JNF053120",
    "Literal": "JUVENILE NONFICTION / Social Topics / Physical & Emotional Abuse (see also Social Topics / Sexual Abuse)"
  },
  {
    "Code": "JNF053140",
    "Literal": "JUVENILE NONFICTION / Social Topics / Prejudice & Racism"
  },
  {
    "Code": "JNF053160",
    "Literal": "JUVENILE NONFICTION / Social Topics / Self-Esteem & Self-Reliance"
  },
  {
    "Code": "JNF053170",
    "Literal": "JUVENILE NONFICTION / Social Topics / Sexual Abuse"
  },
  {
    "Code": "JNF053200",
    "Literal": "JUVENILE NONFICTION / Social Topics / Values & Virtues"
  },
  {
    "Code": "JNF053210",
    "Literal": "JUVENILE NONFICTION / Social Topics / Violence"
  },
  {
    "Code": "JNF068000",
    "Literal": "JUVENILE NONFICTION / Spies & Spying"
  },
  {
    "Code": "JNF054000",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / General"
  },
  {
    "Code": "JNF054010",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Baseball & Softball"
  },
  {
    "Code": "JNF054020",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Basketball"
  },
  {
    "Code": "JNF054030",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Camping & Outdoor Activities"
  },
  {
    "Code": "JNF054040",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Cycling"
  },
  {
    "Code": "JNF054170",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Equestrian"
  },
  {
    "Code": "JNF054180",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Extreme Sports"
  },
  {
    "Code": "JNF054050",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Football"
  },
  {
    "Code": "JNF054230",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Golf"
  },
  {
    "Code": "JNF054060",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Gymnastics"
  },
  {
    "Code": "JNF054070",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Hockey"
  },
  {
    "Code": "JNF054190",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Ice Skating"
  },
  {
    "Code": "JNF054080",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Martial Arts"
  },
  {
    "Code": "JNF054100",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Motor Sports"
  },
  {
    "Code": "JNF054110",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Olympics"
  },
  {
    "Code": "JNF054120",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Racket Sports"
  },
  {
    "Code": "JNF054200",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Roller & In-Line Skating"
  },
  {
    "Code": "JNF054210",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Skateboarding"
  },
  {
    "Code": "JNF054130",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Soccer"
  },
  {
    "Code": "JNF054140",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Track & Field"
  },
  {
    "Code": "JNF054150",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Water Sports"
  },
  {
    "Code": "JNF054160",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Winter Sports"
  },
  {
    "Code": "JNF054220",
    "Literal": "JUVENILE NONFICTION / Sports & Recreation / Wrestling"
  },
  {
    "Code": "JNF055000",
    "Literal": "JUVENILE NONFICTION / Study Aids / General"
  },
  {
    "Code": "JNF055010",
    "Literal": "JUVENILE NONFICTION / Study Aids / Book Notes (see also STUDY AIDS / Book Notes)"
  },
  {
    "Code": "JNF055030",
    "Literal": "JUVENILE NONFICTION / Study Aids / Test Preparation"
  },
  {
    "Code": "JNF061000",
    "Literal": "JUVENILE NONFICTION / Technology / General"
  },
  {
    "Code": "JNF051010",
    "Literal": "JUVENILE NONFICTION / Technology / Aeronautics, Astronautics & Space Science"
  },
  {
    "Code": "JNF051020",
    "Literal": "JUVENILE NONFICTION / Technology / Agriculture"
  },
  {
    "Code": "JNF051090",
    "Literal": "JUVENILE NONFICTION / Technology / Electricity & Electronics"
  },
  {
    "Code": "JNF051120",
    "Literal": "JUVENILE NONFICTION / Technology / How Things Work-Are Made"
  },
  {
    "Code": "JNF061010",
    "Literal": "JUVENILE NONFICTION / Technology / Inventions"
  },
  {
    "Code": "JNF051130",
    "Literal": "JUVENILE NONFICTION / Technology / Machinery & Tools"
  },
  {
    "Code": "JNF061020",
    "Literal": "JUVENILE NONFICTION / Technology / Robotics"
  },
  {
    "Code": "JNF056000",
    "Literal": "JUVENILE NONFICTION / Toys, Dolls & Puppets"
  },
  {
    "Code": "JNF057000",
    "Literal": "JUVENILE NONFICTION / Transportation / General"
  },
  {
    "Code": "JNF057010",
    "Literal": "JUVENILE NONFICTION / Transportation / Aviation"
  },
  {
    "Code": "JNF057020",
    "Literal": "JUVENILE NONFICTION / Transportation / Boats, Ships & Underwater Craft"
  },
  {
    "Code": "JNF057030",
    "Literal": "JUVENILE NONFICTION / Transportation / Cars & Trucks"
  },
  {
    "Code": "JNF057040",
    "Literal": "JUVENILE NONFICTION / Transportation / Motorcycles"
  },
  {
    "Code": "JNF057050",
    "Literal": "JUVENILE NONFICTION / Transportation / Railroads & Trains"
  },
  {
    "Code": "JNF058000",
    "Literal": "JUVENILE NONFICTION / Travel"
  },
  {
    "Code": "LAN000000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / General"
  },
  {
    "Code": "LAN001000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Alphabets & Writing Systems"
  },
  {
    "Code": "LAN004000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Communication Studies"
  },
  {
    "Code": "LAN022000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Editing & Proofreading"
  },
  {
    "Code": "LAN006000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Grammar & Punctuation"
  },
  {
    "Code": "LAN007000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Handwriting"
  },
  {
    "Code": "LAN008000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Journalism"
  },
  {
    "Code": "LAN029000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Lexicography"
  },
  {
    "Code": "LAN025000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / General"
  },
  {
    "Code": "LAN025010",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / Administration & Management"
  },
  {
    "Code": "LAN025020",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / Archives & Special Libraries"
  },
  {
    "Code": "LAN025030",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / Cataloging & Classification"
  },
  {
    "Code": "LAN025040",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / Collection Development"
  },
  {
    "Code": "LAN025060",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / Digital & Online Resources"
  },
  {
    "Code": "LAN025050",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Library & Information Science / School Media"
  },
  {
    "Code": "LAN009000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / General"
  },
  {
    "Code": "LAN024000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Etymology"
  },
  {
    "Code": "LAN009010",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Historical & Comparative"
  },
  {
    "Code": "LAN009020",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Morphology"
  },
  {
    "Code": "LAN011000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Phonetics & Phonology"
  },
  {
    "Code": "LAN009030",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Pragmatics"
  },
  {
    "Code": "LAN009040",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Psycholinguistics"
  },
  {
    "Code": "LAN016000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Semantics"
  },
  {
    "Code": "LAN009050",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Sociolinguistics"
  },
  {
    "Code": "LAN009060",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Linguistics / Syntax"
  },
  {
    "Code": "LAN010000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Literacy"
  },
  {
    "Code": "LAN026000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Public Speaking & Speech Writing"
  },
  {
    "Code": "LAN027000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Publishers & Publishing Industry"
  },
  {
    "Code": "LAN012000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Readers"
  },
  {
    "Code": "LAN013000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Reading Skills"
  },
  {
    "Code": "LAN015000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Rhetoric"
  },
  {
    "Code": "LAN017000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Sign Language"
  },
  {
    "Code": "LAN018000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Speech & Pronunciation"
  },
  {
    "Code": "LAN021000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Spelling & Vocabulary"
  },
  {
    "Code": "LAN020000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Study & Teaching"
  },
  {
    "Code": "LAN028000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Style Manuals"
  },
  {
    "Code": "LAN023000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Translating & Interpreting"
  },
  {
    "Code": "LAN005000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / General"
  },
  {
    "Code": "LAN005010",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Academic & Scholarly"
  },
  {
    "Code": "LAN002000",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Authorship"
  },
  {
    "Code": "LAN005020",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Business Aspects"
  },
  {
    "Code": "LAN005030",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Children's & Young Adult"
  },
  {
    "Code": "LAN005040",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Composition"
  },
  {
    "Code": "LAN005050",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Fiction Writing"
  },
  {
    "Code": "LAN005060",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Nonfiction (incl. Memoirs)"
  },
  {
    "Code": "LAN005070",
    "Literal": "LANGUAGE ARTS & DISCIPLINES / Writing / Poetry"
  },
  {
    "Code": "LAW000000",
    "Literal": "LAW / General"
  },
  {
    "Code": "LAW001000",
    "Literal": "LAW / Administrative Law & Regulatory Practice"
  },
  {
    "Code": "LAW102000",
    "Literal": "LAW / Agricultural"
  },
  {
    "Code": "LAW002000",
    "Literal": "LAW / Air & Space"
  },
  {
    "Code": "LAW003000",
    "Literal": "LAW / Alternative Dispute Resolution"
  },
  {
    "Code": "LAW004000",
    "Literal": "LAW / Annotations & Citations"
  },
  {
    "Code": "LAW005000",
    "Literal": "LAW / Antitrust"
  },
  {
    "Code": "LAW006000",
    "Literal": "LAW / Arbitration, Negotiation, Mediation"
  },
  {
    "Code": "LAW007000",
    "Literal": "LAW / Banking"
  },
  {
    "Code": "LAW008000",
    "Literal": "LAW / Bankruptcy & Insolvency"
  },
  {
    "Code": "LAW009000",
    "Literal": "LAW / Business & Financial"
  },
  {
    "Code": "LAW010000",
    "Literal": "LAW / Child Advocacy"
  },
  {
    "Code": "LAW011000",
    "Literal": "LAW / Civil Law"
  },
  {
    "Code": "LAW012000",
    "Literal": "LAW / Civil Procedure"
  },
  {
    "Code": "LAW013000",
    "Literal": "LAW / Civil Rights"
  },
  {
    "Code": "LAW014000",
    "Literal": "LAW / Commercial / General"
  },
  {
    "Code": "LAW014010",
    "Literal": "LAW / Commercial / International Trade"
  },
  {
    "Code": "LAW103000",
    "Literal": "LAW / Common"
  },
  {
    "Code": "LAW015000",
    "Literal": "LAW / Communications"
  },
  {
    "Code": "LAW016000",
    "Literal": "LAW / Comparative"
  },
  {
    "Code": "LAW104000",
    "Literal": "LAW / Computer & Internet"
  },
  {
    "Code": "LAW017000",
    "Literal": "LAW / Conflict of Laws"
  },
  {
    "Code": "LAW018000",
    "Literal": "LAW / Constitutional"
  },
  {
    "Code": "LAW019000",
    "Literal": "LAW / Construction"
  },
  {
    "Code": "LAW020000",
    "Literal": "LAW / Consumer"
  },
  {
    "Code": "LAW021000",
    "Literal": "LAW / Contracts"
  },
  {
    "Code": "LAW022000",
    "Literal": "LAW / Corporate"
  },
  {
    "Code": "LAW023000",
    "Literal": "LAW / Court Records"
  },
  {
    "Code": "LAW024000",
    "Literal": "LAW / Court Rules"
  },
  {
    "Code": "LAW025000",
    "Literal": "LAW / Courts"
  },
  {
    "Code": "LAW026000",
    "Literal": "LAW / Criminal Law / General"
  },
  {
    "Code": "LAW026010",
    "Literal": "LAW / Criminal Law / Juvenile Offenders"
  },
  {
    "Code": "LAW026020",
    "Literal": "LAW / Criminal Law / Sentencing"
  },
  {
    "Code": "LAW027000",
    "Literal": "LAW / Criminal Procedure"
  },
  {
    "Code": "LAW028000",
    "Literal": "LAW / Customary"
  },
  {
    "Code": "LAW106000",
    "Literal": "LAW / Defamation"
  },
  {
    "Code": "LAW029000",
    "Literal": "LAW / Depositions"
  },
  {
    "Code": "LAW030000",
    "Literal": "LAW / Dictionaries & Terminology"
  },
  {
    "Code": "LAW031000",
    "Literal": "LAW / Disability"
  },
  {
    "Code": "LAW094000",
    "Literal": "LAW / Discrimination"
  },
  {
    "Code": "LAW118000",
    "Literal": "LAW / Drugs & the Law"
  },
  {
    "Code": "LAW092000",
    "Literal": "LAW / Educational Law & Legislation"
  },
  {
    "Code": "LAW107000",
    "Literal": "LAW / Elder Law"
  },
  {
    "Code": "LAW108000",
    "Literal": "LAW / Election Law"
  },
  {
    "Code": "LAW032000",
    "Literal": "LAW / Emigration & Immigration"
  },
  {
    "Code": "LAW033000",
    "Literal": "LAW / Entertainment"
  },
  {
    "Code": "LAW034000",
    "Literal": "LAW / Environmental"
  },
  {
    "Code": "LAW101000",
    "Literal": "LAW / Essays"
  },
  {
    "Code": "LAW035000",
    "Literal": "LAW / Estates & Trusts"
  },
  {
    "Code": "LAW036000",
    "Literal": "LAW / Ethics & Professional Responsibility"
  },
  {
    "Code": "LAW037000",
    "Literal": "LAW / Evidence"
  },
  {
    "Code": "LAW038000",
    "Literal": "LAW / Family Law / General"
  },
  {
    "Code": "LAW038010",
    "Literal": "LAW / Family Law / Children"
  },
  {
    "Code": "LAW038020",
    "Literal": "LAW / Family Law / Divorce & Separation"
  },
  {
    "Code": "LAW038030",
    "Literal": "LAW / Family Law / Marriage"
  },
  {
    "Code": "LAW041000",
    "Literal": "LAW / Forensic Science"
  },
  {
    "Code": "LAW043000",
    "Literal": "LAW / Gender & the Law"
  },
  {
    "Code": "LAW044000",
    "Literal": "LAW / General Practice"
  },
  {
    "Code": "LAW109000",
    "Literal": "LAW / Government / General"
  },
  {
    "Code": "LAW039000",
    "Literal": "LAW / Government / Federal"
  },
  {
    "Code": "LAW089000",
    "Literal": "LAW / Government / State, Provincial & Municipal"
  },
  {
    "Code": "LAW046000",
    "Literal": "LAW / Health"
  },
  {
    "Code": "LAW047000",
    "Literal": "LAW / Housing & Urban Development"
  },
  {
    "Code": "LAW110000",
    "Literal": "LAW / Indigenous Peoples"
  },
  {
    "Code": "LAW049000",
    "Literal": "LAW / Insurance"
  },
  {
    "Code": "LAW050000",
    "Literal": "LAW / Intellectual Property / General"
  },
  {
    "Code": "LAW050010",
    "Literal": "LAW / Intellectual Property / Copyright"
  },
  {
    "Code": "LAW050020",
    "Literal": "LAW / Intellectual Property / Patent"
  },
  {
    "Code": "LAW050030",
    "Literal": "LAW / Intellectual Property / Trademark"
  },
  {
    "Code": "LAW051000",
    "Literal": "LAW / International"
  },
  {
    "Code": "LAW111000",
    "Literal": "LAW / Judicial Power"
  },
  {
    "Code": "LAW052000",
    "Literal": "LAW / Jurisprudence"
  },
  {
    "Code": "LAW053000",
    "Literal": "LAW / Jury"
  },
  {
    "Code": "LAW054000",
    "Literal": "LAW / Labor & Employment"
  },
  {
    "Code": "LAW055000",
    "Literal": "LAW / Land Use"
  },
  {
    "Code": "LAW112000",
    "Literal": "LAW / Landlord & Tenant"
  },
  {
    "Code": "LAW056000",
    "Literal": "LAW / Law Office Management"
  },
  {
    "Code": "LAW059000",
    "Literal": "LAW / Legal Education"
  },
  {
    "Code": "LAW060000",
    "Literal": "LAW / Legal History"
  },
  {
    "Code": "LAW061000",
    "Literal": "LAW / Legal Profession"
  },
  {
    "Code": "LAW062000",
    "Literal": "LAW / Legal Services"
  },
  {
    "Code": "LAW063000",
    "Literal": "LAW / Legal Writing"
  },
  {
    "Code": "LAW113000",
    "Literal": "LAW / Liability"
  },
  {
    "Code": "LAW064000",
    "Literal": "LAW / Litigation"
  },
  {
    "Code": "LAW100000",
    "Literal": "LAW / Living Trusts"
  },
  {
    "Code": "LAW095000",
    "Literal": "LAW / Malpractice"
  },
  {
    "Code": "LAW066000",
    "Literal": "LAW / Maritime"
  },
  {
    "Code": "LAW096000",
    "Literal": "LAW / Media & the Law"
  },
  {
    "Code": "LAW093000",
    "Literal": "LAW / Medical Law & Legislation"
  },
  {
    "Code": "LAW067000",
    "Literal": "LAW / Mental Health"
  },
  {
    "Code": "LAW114000",
    "Literal": "LAW / Mergers & Acquisitions"
  },
  {
    "Code": "LAW068000",
    "Literal": "LAW / Military"
  },
  {
    "Code": "LAW069000",
    "Literal": "LAW / Natural Law"
  },
  {
    "Code": "LAW070000",
    "Literal": "LAW / Natural Resources"
  },
  {
    "Code": "LAW071000",
    "Literal": "LAW / Paralegals & Paralegalism"
  },
  {
    "Code": "LAW115000",
    "Literal": "LAW / Pension Law"
  },
  {
    "Code": "LAW097000",
    "Literal": "LAW / Personal Injury"
  },
  {
    "Code": "LAW098000",
    "Literal": "LAW / Practical Guides"
  },
  {
    "Code": "LAW116000",
    "Literal": "LAW / Privacy"
  },
  {
    "Code": "LAW074000",
    "Literal": "LAW / Property"
  },
  {
    "Code": "LAW075000",
    "Literal": "LAW / Public"
  },
  {
    "Code": "LAW076000",
    "Literal": "LAW / Public Contract"
  },
  {
    "Code": "LAW077000",
    "Literal": "LAW / Public Utilities"
  },
  {
    "Code": "LAW078000",
    "Literal": "LAW / Real Estate"
  },
  {
    "Code": "LAW079000",
    "Literal": "LAW / Reference"
  },
  {
    "Code": "LAW080000",
    "Literal": "LAW / Remedies & Damages"
  },
  {
    "Code": "LAW081000",
    "Literal": "LAW / Research"
  },
  {
    "Code": "LAW082000",
    "Literal": "LAW / Right to Die"
  },
  {
    "Code": "LAW099000",
    "Literal": "LAW / Science & Technology"
  },
  {
    "Code": "LAW083000",
    "Literal": "LAW / Securities"
  },
  {
    "Code": "LAW084000",
    "Literal": "LAW / Sports"
  },
  {
    "Code": "LAW086000",
    "Literal": "LAW / Taxation"
  },
  {
    "Code": "LAW087000",
    "Literal": "LAW / Torts"
  },
  {
    "Code": "LAW117000",
    "Literal": "LAW / Transportation"
  },
  {
    "Code": "LAW088000",
    "Literal": "LAW / Trial Practice"
  },
  {
    "Code": "LAW090000",
    "Literal": "LAW / Wills"
  },
  {
    "Code": "LAW091000",
    "Literal": "LAW / Witnesses"
  },
  {
    "Code": "LCO000000",
    "Literal": "LITERARY COLLECTIONS / General"
  },
  {
    "Code": "LCO001000",
    "Literal": "LITERARY COLLECTIONS / African"
  },
  {
    "Code": "LCO002000",
    "Literal": "LITERARY COLLECTIONS / American / General"
  },
  {
    "Code": "LCO002010",
    "Literal": "LITERARY COLLECTIONS / American / African American"
  },
  {
    "Code": "LCO003000",
    "Literal": "LITERARY COLLECTIONS / Ancient & Classical"
  },
  {
    "Code": "LCO004000",
    "Literal": "LITERARY COLLECTIONS / Asian / General"
  },
  {
    "Code": "LCO004010",
    "Literal": "LITERARY COLLECTIONS / Asian / Chinese"
  },
  {
    "Code": "LCO004020",
    "Literal": "LITERARY COLLECTIONS / Asian / Indic"
  },
  {
    "Code": "LCO004030",
    "Literal": "LITERARY COLLECTIONS / Asian / Japanese"
  },
  {
    "Code": "LCO005000",
    "Literal": "LITERARY COLLECTIONS / Australian & Oceanian"
  },
  {
    "Code": "LCO006000",
    "Literal": "LITERARY COLLECTIONS / Canadian"
  },
  {
    "Code": "LCO007000",
    "Literal": "LITERARY COLLECTIONS / Caribbean & Latin American"
  },
  {
    "Code": "LCO015000",
    "Literal": "LITERARY COLLECTIONS / Diaries & Journals"
  },
  {
    "Code": "LCO008000",
    "Literal": "LITERARY COLLECTIONS / European / General"
  },
  {
    "Code": "LCO008010",
    "Literal": "LITERARY COLLECTIONS / European / Eastern (see also Russian & Former Soviet Union)"
  },
  {
    "Code": "LCO009000",
    "Literal": "LITERARY COLLECTIONS / European / English, Irish, Scottish, Welsh"
  },
  {
    "Code": "LCO008020",
    "Literal": "LITERARY COLLECTIONS / European / French"
  },
  {
    "Code": "LCO008030",
    "Literal": "LITERARY COLLECTIONS / European / German"
  },
  {
    "Code": "LCO008040",
    "Literal": "LITERARY COLLECTIONS / European / Italian"
  },
  {
    "Code": "LCO008050",
    "Literal": "LITERARY COLLECTIONS / European / Scandinavian"
  },
  {
    "Code": "LCO008060",
    "Literal": "LITERARY COLLECTIONS / European / Spanish & Portuguese"
  },
  {
    "Code": "LCO010000",
    "Literal": "LITERARY COLLECTIONS / Essays"
  },
  {
    "Code": "LCO020000",
    "Literal": "LITERARY COLLECTIONS / Interviews"
  },
  {
    "Code": "LCO011000",
    "Literal": "LITERARY COLLECTIONS / Letters"
  },
  {
    "Code": "LCO016000",
    "Literal": "LITERARY COLLECTIONS / LGBT"
  },
  {
    "Code": "LCO017000",
    "Literal": "LITERARY COLLECTIONS / Medieval"
  },
  {
    "Code": "LCO012000",
    "Literal": "LITERARY COLLECTIONS / Middle Eastern"
  },
  {
    "Code": "LCO013000",
    "Literal": "LITERARY COLLECTIONS / Native American"
  },
  {
    "Code": "LCO014000",
    "Literal": "LITERARY COLLECTIONS / Russian & Former Soviet Union"
  },
  {
    "Code": "LCO018000",
    "Literal": "LITERARY COLLECTIONS / Speeches"
  },
  {
    "Code": "LCO019000",
    "Literal": "LITERARY COLLECTIONS / Women Authors"
  },
  {
    "Code": "LIT000000",
    "Literal": "LITERARY CRITICISM / General"
  },
  {
    "Code": "LIT004010",
    "Literal": "LITERARY CRITICISM / African"
  },
  {
    "Code": "LIT004020",
    "Literal": "LITERARY CRITICISM / American / General"
  },
  {
    "Code": "LIT004040",
    "Literal": "LITERARY CRITICISM / American / African American"
  },
  {
    "Code": "LIT004030",
    "Literal": "LITERARY CRITICISM / American / Asian American"
  },
  {
    "Code": "LIT004050",
    "Literal": "LITERARY CRITICISM / American / Hispanic American"
  },
  {
    "Code": "LIT023000",
    "Literal": "LITERARY CRITICISM / American / Regional"
  },
  {
    "Code": "LIT004190",
    "Literal": "LITERARY CRITICISM / Ancient & Classical"
  },
  {
    "Code": "LIT008000",
    "Literal": "LITERARY CRITICISM / Asian / General"
  },
  {
    "Code": "LIT008010",
    "Literal": "LITERARY CRITICISM / Asian / Chinese"
  },
  {
    "Code": "LIT008020",
    "Literal": "LITERARY CRITICISM / Asian / Indic"
  },
  {
    "Code": "LIT008030",
    "Literal": "LITERARY CRITICISM / Asian / Japanese"
  },
  {
    "Code": "LIT004070",
    "Literal": "LITERARY CRITICISM / Australian & Oceanian"
  },
  {
    "Code": "LIT007000",
    "Literal": "LITERARY CRITICISM / Books & Reading"
  },
  {
    "Code": "LIT004080",
    "Literal": "LITERARY CRITICISM / Canadian"
  },
  {
    "Code": "LIT004100",
    "Literal": "LITERARY CRITICISM / Caribbean & Latin American"
  },
  {
    "Code": "LIT009000",
    "Literal": "LITERARY CRITICISM / Children's & Young Adult Literature"
  },
  {
    "Code": "LIT017000",
    "Literal": "LITERARY CRITICISM / Comics & Graphic Novels"
  },
  {
    "Code": "LIT020000",
    "Literal": "LITERARY CRITICISM / Comparative Literature"
  },
  {
    "Code": "LIT013000",
    "Literal": "LITERARY CRITICISM / Drama"
  },
  {
    "Code": "LIT004130",
    "Literal": "LITERARY CRITICISM / European / General"
  },
  {
    "Code": "LIT004110",
    "Literal": "LITERARY CRITICISM / European / Eastern (see also Russian & Former Soviet Union)"
  },
  {
    "Code": "LIT004120",
    "Literal": "LITERARY CRITICISM / European / English, Irish, Scottish, Welsh"
  },
  {
    "Code": "LIT004150",
    "Literal": "LITERARY CRITICISM / European / French"
  },
  {
    "Code": "LIT004170",
    "Literal": "LITERARY CRITICISM / European / German"
  },
  {
    "Code": "LIT004200",
    "Literal": "LITERARY CRITICISM / European / Italian"
  },
  {
    "Code": "LIT004250",
    "Literal": "LITERARY CRITICISM / European / Scandinavian"
  },
  {
    "Code": "LIT004280",
    "Literal": "LITERARY CRITICISM / European / Spanish & Portuguese"
  },
  {
    "Code": "LIT022000",
    "Literal": "LITERARY CRITICISM / Fairy Tales, Folk Tales, Legends & Mythology"
  },
  {
    "Code": "LIT003000",
    "Literal": "LITERARY CRITICISM / Feminist"
  },
  {
    "Code": "LIT004180",
    "Literal": "LITERARY CRITICISM / Gothic & Romance"
  },
  {
    "Code": "LIT021000",
    "Literal": "LITERARY CRITICISM / Horror & Supernatural"
  },
  {
    "Code": "LIT016000",
    "Literal": "LITERARY CRITICISM / Humor"
  },
  {
    "Code": "LIT004210",
    "Literal": "LITERARY CRITICISM / Jewish"
  },
  {
    "Code": "LIT004160",
    "Literal": "LITERARY CRITICISM / LGBT"
  },
  {
    "Code": "LIT011000",
    "Literal": "LITERARY CRITICISM / Medieval"
  },
  {
    "Code": "LIT004220",
    "Literal": "LITERARY CRITICISM / Middle Eastern"
  },
  {
    "Code": "LIT024000",
    "Literal": "LITERARY CRITICISM / Modern / General"
  },
  {
    "Code": "LIT024010",
    "Literal": "LITERARY CRITICISM / Modern / 16th Century"
  },
  {
    "Code": "LIT024020",
    "Literal": "LITERARY CRITICISM / Modern / 17th Century"
  },
  {
    "Code": "LIT024030",
    "Literal": "LITERARY CRITICISM / Modern / 18th Century"
  },
  {
    "Code": "LIT024040",
    "Literal": "LITERARY CRITICISM / Modern / 19th Century"
  },
  {
    "Code": "LIT024050",
    "Literal": "LITERARY CRITICISM / Modern / 20th Century"
  },
  {
    "Code": "LIT024060",
    "Literal": "LITERARY CRITICISM / Modern / 21st Century"
  },
  {
    "Code": "LIT004230",
    "Literal": "LITERARY CRITICISM / Mystery & Detective"
  },
  {
    "Code": "LIT004060",
    "Literal": "LITERARY CRITICISM / Native American"
  },
  {
    "Code": "LIT014000",
    "Literal": "LITERARY CRITICISM / Poetry"
  },
  {
    "Code": "LIT012000",
    "Literal": "LITERARY CRITICISM / Reference"
  },
  {
    "Code": "LIT019000",
    "Literal": "LITERARY CRITICISM / Renaissance"
  },
  {
    "Code": "LIT004240",
    "Literal": "LITERARY CRITICISM / Russian & Former Soviet Union"
  },
  {
    "Code": "LIT004260",
    "Literal": "LITERARY CRITICISM / Science Fiction & Fantasy"
  },
  {
    "Code": "LIT006000",
    "Literal": "LITERARY CRITICISM / Semiotics & Theory"
  },
  {
    "Code": "LIT015000",
    "Literal": "LITERARY CRITICISM / Shakespeare"
  },
  {
    "Code": "LIT018000",
    "Literal": "LITERARY CRITICISM / Short Stories"
  },
  {
    "Code": "LIT025000",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / General"
  },
  {
    "Code": "LIT025010",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Historical Events"
  },
  {
    "Code": "LIT025020",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Nature"
  },
  {
    "Code": "LIT025030",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Politics"
  },
  {
    "Code": "LIT025040",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Religion"
  },
  {
    "Code": "LIT025050",
    "Literal": "LITERARY CRITICISM / Subjects & Themes / Women"
  },
  {
    "Code": "LIT004290",
    "Literal": "LITERARY CRITICISM / Women Authors"
  },
  {
    "Code": "MAT000000",
    "Literal": "MATHEMATICS / General"
  },
  {
    "Code": "MAT002000",
    "Literal": "MATHEMATICS / Algebra / General"
  },
  {
    "Code": "MAT002010",
    "Literal": "MATHEMATICS / Algebra / Abstract"
  },
  {
    "Code": "MAT002030",
    "Literal": "MATHEMATICS / Algebra / Elementary"
  },
  {
    "Code": "MAT002040",
    "Literal": "MATHEMATICS / Algebra / Intermediate"
  },
  {
    "Code": "MAT002050",
    "Literal": "MATHEMATICS / Algebra / Linear"
  },
  {
    "Code": "MAT003000",
    "Literal": "MATHEMATICS / Applied"
  },
  {
    "Code": "MAT004000",
    "Literal": "MATHEMATICS / Arithmetic"
  },
  {
    "Code": "MAT005000",
    "Literal": "MATHEMATICS / Calculus"
  },
  {
    "Code": "MAT036000",
    "Literal": "MATHEMATICS / Combinatorics"
  },
  {
    "Code": "MAT040000",
    "Literal": "MATHEMATICS / Complex Analysis"
  },
  {
    "Code": "MAT006000",
    "Literal": "MATHEMATICS / Counting & Numeration"
  },
  {
    "Code": "MAT007000",
    "Literal": "MATHEMATICS / Differential Equations / General"
  },
  {
    "Code": "MAT007010",
    "Literal": "MATHEMATICS / Differential Equations / Ordinary"
  },
  {
    "Code": "MAT007020",
    "Literal": "MATHEMATICS / Differential Equations / Partial"
  },
  {
    "Code": "MAT008000",
    "Literal": "MATHEMATICS / Discrete Mathematics"
  },
  {
    "Code": "MAT039000",
    "Literal": "MATHEMATICS / Essays"
  },
  {
    "Code": "MAT009000",
    "Literal": "MATHEMATICS / Finite Mathematics"
  },
  {
    "Code": "MAT037000",
    "Literal": "MATHEMATICS / Functional Analysis"
  },
  {
    "Code": "MAT011000",
    "Literal": "MATHEMATICS / Game Theory"
  },
  {
    "Code": "MAT012000",
    "Literal": "MATHEMATICS / Geometry / General"
  },
  {
    "Code": "MAT012010",
    "Literal": "MATHEMATICS / Geometry / Algebraic"
  },
  {
    "Code": "MAT012020",
    "Literal": "MATHEMATICS / Geometry / Analytic"
  },
  {
    "Code": "MAT012030",
    "Literal": "MATHEMATICS / Geometry / Differential"
  },
  {
    "Code": "MAT012040",
    "Literal": "MATHEMATICS / Geometry / Non-Euclidean"
  },
  {
    "Code": "MAT013000",
    "Literal": "MATHEMATICS / Graphic Methods"
  },
  {
    "Code": "MAT014000",
    "Literal": "MATHEMATICS / Group Theory"
  },
  {
    "Code": "MAT015000",
    "Literal": "MATHEMATICS / History & Philosophy"
  },
  {
    "Code": "MAT016000",
    "Literal": "MATHEMATICS / Infinity"
  },
  {
    "Code": "MAT017000",
    "Literal": "MATHEMATICS / Linear & Nonlinear Programming"
  },
  {
    "Code": "MAT018000",
    "Literal": "MATHEMATICS / Logic"
  },
  {
    "Code": "MAT034000",
    "Literal": "MATHEMATICS / Mathematical Analysis"
  },
  {
    "Code": "MAT019000",
    "Literal": "MATHEMATICS / Matrices"
  },
  {
    "Code": "MAT020000",
    "Literal": "MATHEMATICS / Measurement"
  },
  {
    "Code": "MAT021000",
    "Literal": "MATHEMATICS / Number Systems"
  },
  {
    "Code": "MAT022000",
    "Literal": "MATHEMATICS / Number Theory"
  },
  {
    "Code": "MAT041000",
    "Literal": "MATHEMATICS / Numerical Analysis"
  },
  {
    "Code": "MAT042000",
    "Literal": "MATHEMATICS / Optimization"
  },
  {
    "Code": "MAT023000",
    "Literal": "MATHEMATICS / Pre-Calculus"
  },
  {
    "Code": "MAT029000",
    "Literal": "MATHEMATICS / Probability & Statistics / General"
  },
  {
    "Code": "MAT029010",
    "Literal": "MATHEMATICS / Probability & Statistics / Bayesian Analysis"
  },
  {
    "Code": "MAT029020",
    "Literal": "MATHEMATICS / Probability & Statistics / Multivariate Analysis"
  },
  {
    "Code": "MAT029030",
    "Literal": "MATHEMATICS / Probability & Statistics / Regression Analysis"
  },
  {
    "Code": "MAT029040",
    "Literal": "MATHEMATICS / Probability & Statistics / Stochastic Processes"
  },
  {
    "Code": "MAT029050",
    "Literal": "MATHEMATICS / Probability & Statistics / Time Series"
  },
  {
    "Code": "MAT025000",
    "Literal": "MATHEMATICS / Recreations & Games"
  },
  {
    "Code": "MAT026000",
    "Literal": "MATHEMATICS / Reference"
  },
  {
    "Code": "MAT027000",
    "Literal": "MATHEMATICS / Research"
  },
  {
    "Code": "MAT028000",
    "Literal": "MATHEMATICS / Set Theory"
  },
  {
    "Code": "MAT030000",
    "Literal": "MATHEMATICS / Study & Teaching"
  },
  {
    "Code": "MAT038000",
    "Literal": "MATHEMATICS / Topology"
  },
  {
    "Code": "MAT031000",
    "Literal": "MATHEMATICS / Transformations"
  },
  {
    "Code": "MAT032000",
    "Literal": "MATHEMATICS / Trigonometry"
  },
  {
    "Code": "MAT033000",
    "Literal": "MATHEMATICS / Vector Analysis"
  },
  {
    "Code": "MED000000",
    "Literal": "MEDICAL / General"
  },
  {
    "Code": "MED001000",
    "Literal": "MEDICAL / Acupuncture"
  },
  {
    "Code": "MED002000",
    "Literal": "MEDICAL / Administration"
  },
  {
    "Code": "MED022020",
    "Literal": "MEDICAL / AIDS & HIV"
  },
  {
    "Code": "MED003000",
    "Literal": "MEDICAL / Allied Health Services / General"
  },
  {
    "Code": "MED003010",
    "Literal": "MEDICAL / Allied Health Services / Emergency Medical Services"
  },
  {
    "Code": "MED003020",
    "Literal": "MEDICAL / Allied Health Services / Hypnotherapy"
  },
  {
    "Code": "MED003070",
    "Literal": "MEDICAL / Allied Health Services / Imaging Technologies"
  },
  {
    "Code": "MED003090",
    "Literal": "MEDICAL / Allied Health Services / Massage Therapy"
  },
  {
    "Code": "MED003030",
    "Literal": "MEDICAL / Allied Health Services / Medical Assistants"
  },
  {
    "Code": "MED003100",
    "Literal": "MEDICAL / Allied Health Services / Medical Coding & Billing"
  },
  {
    "Code": "MED003040",
    "Literal": "MEDICAL / Allied Health Services / Medical Technology"
  },
  {
    "Code": "MED003050",
    "Literal": "MEDICAL / Allied Health Services / Occupational Therapy"
  },
  {
    "Code": "MED003060",
    "Literal": "MEDICAL / Allied Health Services / Physical Therapy"
  },
  {
    "Code": "MED003080",
    "Literal": "MEDICAL / Allied Health Services / Respiratory Therapy"
  },
  {
    "Code": "MED004000",
    "Literal": "MEDICAL / Alternative & Complementary Medicine"
  },
  {
    "Code": "MED005000",
    "Literal": "MEDICAL / Anatomy"
  },
  {
    "Code": "MED006000",
    "Literal": "MEDICAL / Anesthesiology"
  },
  {
    "Code": "MED101000",
    "Literal": "MEDICAL / Atlases"
  },
  {
    "Code": "MED007000",
    "Literal": "MEDICAL / Audiology & Speech Pathology"
  },
  {
    "Code": "MED111000",
    "Literal": "MEDICAL / Bariatrics"
  },
  {
    "Code": "MED008000",
    "Literal": "MEDICAL / Biochemistry"
  },
  {
    "Code": "MED090000",
    "Literal": "MEDICAL / Biostatistics"
  },
  {
    "Code": "MED009000",
    "Literal": "MEDICAL / Biotechnology"
  },
  {
    "Code": "MED010000",
    "Literal": "MEDICAL / Cardiology"
  },
  {
    "Code": "MED011000",
    "Literal": "MEDICAL / Caregiving"
  },
  {
    "Code": "MED012000",
    "Literal": "MEDICAL / Chemotherapy"
  },
  {
    "Code": "MED013000",
    "Literal": "MEDICAL / Chiropractic"
  },
  {
    "Code": "MED014000",
    "Literal": "MEDICAL / Clinical Medicine"
  },
  {
    "Code": "MED015000",
    "Literal": "MEDICAL / Critical Care"
  },
  {
    "Code": "MED016000",
    "Literal": "MEDICAL / Dentistry / General"
  },
  {
    "Code": "MED016010",
    "Literal": "MEDICAL / Dentistry / Dental Assisting"
  },
  {
    "Code": "MED016020",
    "Literal": "MEDICAL / Dentistry / Dental Hygiene"
  },
  {
    "Code": "MED016080",
    "Literal": "MEDICAL / Dentistry / Dental Implants"
  },
  {
    "Code": "MED016060",
    "Literal": "MEDICAL / Dentistry / Endodontics"
  },
  {
    "Code": "MED016050",
    "Literal": "MEDICAL / Dentistry / Oral Surgery"
  },
  {
    "Code": "MED016030",
    "Literal": "MEDICAL / Dentistry / Orthodontics"
  },
  {
    "Code": "MED016040",
    "Literal": "MEDICAL / Dentistry / Periodontics"
  },
  {
    "Code": "MED016090",
    "Literal": "MEDICAL / Dentistry / Practice Management"
  },
  {
    "Code": "MED016070",
    "Literal": "MEDICAL / Dentistry / Prosthodontics"
  },
  {
    "Code": "MED017000",
    "Literal": "MEDICAL / Dermatology"
  },
  {
    "Code": "MED018000",
    "Literal": "MEDICAL / Diagnosis"
  },
  {
    "Code": "MED019000",
    "Literal": "MEDICAL / Diagnostic Imaging / General"
  },
  {
    "Code": "MED019010",
    "Literal": "MEDICAL / Diagnostic Imaging / Radiography"
  },
  {
    "Code": "MED098000",
    "Literal": "MEDICAL / Diagnostic Imaging / Ultrasonography"
  },
  {
    "Code": "MED020000",
    "Literal": "MEDICAL / Dictionaries & Terminology"
  },
  {
    "Code": "MED021000",
    "Literal": "MEDICAL / Diet Therapy"
  },
  {
    "Code": "MED022000",
    "Literal": "MEDICAL / Diseases"
  },
  {
    "Code": "MED023000",
    "Literal": "MEDICAL / Drug Guides"
  },
  {
    "Code": "MED024000",
    "Literal": "MEDICAL / Education & Training"
  },
  {
    "Code": "MED025000",
    "Literal": "MEDICAL / Embryology"
  },
  {
    "Code": "MED026000",
    "Literal": "MEDICAL / Emergency Medicine"
  },
  {
    "Code": "MED027000",
    "Literal": "MEDICAL / Endocrinology & Metabolism"
  },
  {
    "Code": "MED116000",
    "Literal": "MEDICAL / Environmental Health"
  },
  {
    "Code": "MED028000",
    "Literal": "MEDICAL / Epidemiology"
  },
  {
    "Code": "MED109000",
    "Literal": "MEDICAL / Essays"
  },
  {
    "Code": "MED050000",
    "Literal": "MEDICAL / Ethics"
  },
  {
    "Code": "MED112000",
    "Literal": "MEDICAL / Evidence-Based Medicine"
  },
  {
    "Code": "MED029000",
    "Literal": "MEDICAL / Family & General Practice"
  },
  {
    "Code": "MED030000",
    "Literal": "MEDICAL / Forensic Medicine"
  },
  {
    "Code": "MED031000",
    "Literal": "MEDICAL / Gastroenterology"
  },
  {
    "Code": "MED107000",
    "Literal": "MEDICAL / Genetics"
  },
  {
    "Code": "MED032000",
    "Literal": "MEDICAL / Geriatrics"
  },
  {
    "Code": "MED033000",
    "Literal": "MEDICAL / Gynecology & Obstetrics"
  },
  {
    "Code": "MED034000",
    "Literal": "MEDICAL / Healing"
  },
  {
    "Code": "MED035000",
    "Literal": "MEDICAL / Health Care Delivery"
  },
  {
    "Code": "MED036000",
    "Literal": "MEDICAL / Health Policy"
  },
  {
    "Code": "MED037000",
    "Literal": "MEDICAL / Health Risk Assessment"
  },
  {
    "Code": "MED038000",
    "Literal": "MEDICAL / Hematology"
  },
  {
    "Code": "MED114000",
    "Literal": "MEDICAL / Hepatology"
  },
  {
    "Code": "MED110000",
    "Literal": "MEDICAL / Histology"
  },
  {
    "Code": "MED039000",
    "Literal": "MEDICAL / History"
  },
  {
    "Code": "MED040000",
    "Literal": "MEDICAL / Holistic Medicine"
  },
  {
    "Code": "MED041000",
    "Literal": "MEDICAL / Home Care"
  },
  {
    "Code": "MED043000",
    "Literal": "MEDICAL / Hospital Administration & Care"
  },
  {
    "Code": "MED044000",
    "Literal": "MEDICAL / Immunology"
  },
  {
    "Code": "MED115000",
    "Literal": "MEDICAL / Infection Control"
  },
  {
    "Code": "MED022090",
    "Literal": "MEDICAL / Infectious Diseases"
  },
  {
    "Code": "MED117000",
    "Literal": "MEDICAL / Informatics"
  },
  {
    "Code": "MED108000",
    "Literal": "MEDICAL / Instruments & Supplies"
  },
  {
    "Code": "MED045000",
    "Literal": "MEDICAL / Internal Medicine"
  },
  {
    "Code": "MED047000",
    "Literal": "MEDICAL / Laboratory Medicine"
  },
  {
    "Code": "MED048000",
    "Literal": "MEDICAL / Lasers in Medicine"
  },
  {
    "Code": "MED113000",
    "Literal": "MEDICAL / Long-Term Care"
  },
  {
    "Code": "MED049000",
    "Literal": "MEDICAL / Medicaid & Medicare"
  },
  {
    "Code": "MED051000",
    "Literal": "MEDICAL / Medical History & Records"
  },
  {
    "Code": "MED102000",
    "Literal": "MEDICAL / Mental Health"
  },
  {
    "Code": "MED052000",
    "Literal": "MEDICAL / Microbiology"
  },
  {
    "Code": "MED118000",
    "Literal": "MEDICAL / Military Medicine"
  },
  {
    "Code": "MED055000",
    "Literal": "MEDICAL / Nephrology"
  },
  {
    "Code": "MED056000",
    "Literal": "MEDICAL / Neurology"
  },
  {
    "Code": "MED057000",
    "Literal": "MEDICAL / Neuroscience"
  },
  {
    "Code": "MED091000",
    "Literal": "MEDICAL / Nosology"
  },
  {
    "Code": "MED058000",
    "Literal": "MEDICAL / Nursing / General"
  },
  {
    "Code": "MED058010",
    "Literal": "MEDICAL / Nursing / Anesthesia"
  },
  {
    "Code": "MED058020",
    "Literal": "MEDICAL / Nursing / Assessment & Diagnosis"
  },
  {
    "Code": "MED058240",
    "Literal": "MEDICAL / Nursing / Care Plans"
  },
  {
    "Code": "MED058030",
    "Literal": "MEDICAL / Nursing / Critical & Intensive Care"
  },
  {
    "Code": "MED058040",
    "Literal": "MEDICAL / Nursing / Emergency"
  },
  {
    "Code": "MED058050",
    "Literal": "MEDICAL / Nursing / Fundamentals & Skills"
  },
  {
    "Code": "MED058060",
    "Literal": "MEDICAL / Nursing / Gerontology"
  },
  {
    "Code": "MED058070",
    "Literal": "MEDICAL / Nursing / Home & Community Care"
  },
  {
    "Code": "MED058100",
    "Literal": "MEDICAL / Nursing / LPN & LVN"
  },
  {
    "Code": "MED058110",
    "Literal": "MEDICAL / Nursing / Management & Leadership"
  },
  {
    "Code": "MED058120",
    "Literal": "MEDICAL / Nursing / Maternity, Perinatal, Women's Health"
  },
  {
    "Code": "MED058220",
    "Literal": "MEDICAL / Nursing / Medical & Surgical"
  },
  {
    "Code": "MED058140",
    "Literal": "MEDICAL / Nursing / Nurse & Patient"
  },
  {
    "Code": "MED058150",
    "Literal": "MEDICAL / Nursing / Nutrition"
  },
  {
    "Code": "MED058160",
    "Literal": "MEDICAL / Nursing / Oncology & Cancer"
  },
  {
    "Code": "MED058230",
    "Literal": "MEDICAL / Nursing / Palliative Care"
  },
  {
    "Code": "MED058080",
    "Literal": "MEDICAL / Nursing / Pediatric & Neonatal"
  },
  {
    "Code": "MED058170",
    "Literal": "MEDICAL / Nursing / Pharmacology"
  },
  {
    "Code": "MED058180",
    "Literal": "MEDICAL / Nursing / Psychiatric & Mental Health"
  },
  {
    "Code": "MED058190",
    "Literal": "MEDICAL / Nursing / Reference"
  },
  {
    "Code": "MED058200",
    "Literal": "MEDICAL / Nursing / Research & Theory"
  },
  {
    "Code": "MED058090",
    "Literal": "MEDICAL / Nursing / Social, Ethical & Legal Issues"
  },
  {
    "Code": "MED058210",
    "Literal": "MEDICAL / Nursing / Test Preparation & Review (see also STUDY AIDS / NCLEX)"
  },
  {
    "Code": "MED059000",
    "Literal": "MEDICAL / Nursing Home Care"
  },
  {
    "Code": "MED060000",
    "Literal": "MEDICAL / Nutrition"
  },
  {
    "Code": "MED061000",
    "Literal": "MEDICAL / Occupational & Industrial Medicine"
  },
  {
    "Code": "MED062000",
    "Literal": "MEDICAL / Oncology / General"
  },
  {
    "Code": "MED062010",
    "Literal": "MEDICAL / Oncology / Breast Cancer"
  },
  {
    "Code": "MED062020",
    "Literal": "MEDICAL / Oncology / Leukemia & Lymphoma"
  },
  {
    "Code": "MED062030",
    "Literal": "MEDICAL / Oncology / Lung Cancer"
  },
  {
    "Code": "MED062040",
    "Literal": "MEDICAL / Oncology / Pediatric"
  },
  {
    "Code": "MED062050",
    "Literal": "MEDICAL / Oncology / Prostate Cancer"
  },
  {
    "Code": "MED062060",
    "Literal": "MEDICAL / Oncology / Skin Cancer"
  },
  {
    "Code": "MED063000",
    "Literal": "MEDICAL / Ophthalmology"
  },
  {
    "Code": "MED064000",
    "Literal": "MEDICAL / Optometry"
  },
  {
    "Code": "MED065000",
    "Literal": "MEDICAL / Orthopedics"
  },
  {
    "Code": "MED092000",
    "Literal": "MEDICAL / Osteopathy"
  },
  {
    "Code": "MED066000",
    "Literal": "MEDICAL / Otolaryngology"
  },
  {
    "Code": "MED093000",
    "Literal": "MEDICAL / Pain Medicine"
  },
  {
    "Code": "MED103000",
    "Literal": "MEDICAL / Parasitology"
  },
  {
    "Code": "MED067000",
    "Literal": "MEDICAL / Pathology"
  },
  {
    "Code": "MED068000",
    "Literal": "MEDICAL / Pathophysiology"
  },
  {
    "Code": "MED094000",
    "Literal": "MEDICAL / Pediatric Emergencies"
  },
  {
    "Code": "MED069000",
    "Literal": "MEDICAL / Pediatrics"
  },
  {
    "Code": "MED070000",
    "Literal": "MEDICAL / Perinatology & Neonatology"
  },
  {
    "Code": "MED071000",
    "Literal": "MEDICAL / Pharmacology"
  },
  {
    "Code": "MED072000",
    "Literal": "MEDICAL / Pharmacy"
  },
  {
    "Code": "MED073000",
    "Literal": "MEDICAL / Physical Medicine & Rehabilitation"
  },
  {
    "Code": "MED074000",
    "Literal": "MEDICAL / Physician & Patient"
  },
  {
    "Code": "MED104000",
    "Literal": "MEDICAL / Physicians"
  },
  {
    "Code": "MED075000",
    "Literal": "MEDICAL / Physiology"
  },
  {
    "Code": "MED100000",
    "Literal": "MEDICAL / Podiatry"
  },
  {
    "Code": "MED095000",
    "Literal": "MEDICAL / Practice Management & Reimbursement"
  },
  {
    "Code": "MED076000",
    "Literal": "MEDICAL / Preventive Medicine"
  },
  {
    "Code": "MED077000",
    "Literal": "MEDICAL / Prosthesis"
  },
  {
    "Code": "MED105000",
    "Literal": "MEDICAL / Psychiatry / General"
  },
  {
    "Code": "MED105010",
    "Literal": "MEDICAL / Psychiatry / Child & Adolescent"
  },
  {
    "Code": "MED105020",
    "Literal": "MEDICAL / Psychiatry / Psychopharmacology"
  },
  {
    "Code": "MED078000",
    "Literal": "MEDICAL / Public Health"
  },
  {
    "Code": "MED079000",
    "Literal": "MEDICAL / Pulmonary & Thoracic Medicine"
  },
  {
    "Code": "MED080000",
    "Literal": "MEDICAL / Radiology, Radiotherapy & Nuclear Medicine"
  },
  {
    "Code": "MED081000",
    "Literal": "MEDICAL / Reference"
  },
  {
    "Code": "MED082000",
    "Literal": "MEDICAL / Reproductive Medicine & Technology"
  },
  {
    "Code": "MED106000",
    "Literal": "MEDICAL / Research"
  },
  {
    "Code": "MED083000",
    "Literal": "MEDICAL / Rheumatology"
  },
  {
    "Code": "MED119000",
    "Literal": "MEDICAL / Sleep Medicine"
  },
  {
    "Code": "MED084000",
    "Literal": "MEDICAL / Sports Medicine"
  },
  {
    "Code": "MED085000",
    "Literal": "MEDICAL / Surgery / General"
  },
  {
    "Code": "MED085090",
    "Literal": "MEDICAL / Surgery / Abdominal"
  },
  {
    "Code": "MED085040",
    "Literal": "MEDICAL / Surgery / Cardiothoracic"
  },
  {
    "Code": "MED085060",
    "Literal": "MEDICAL / Surgery / Colon & Rectal"
  },
  {
    "Code": "MED085030",
    "Literal": "MEDICAL / Surgery / Cosmetic & Reconstructive"
  },
  {
    "Code": "MED085100",
    "Literal": "MEDICAL / Surgery / Eye"
  },
  {
    "Code": "MED085080",
    "Literal": "MEDICAL / Surgery / Laparoscopic & Robotic"
  },
  {
    "Code": "MED085010",
    "Literal": "MEDICAL / Surgery / Neurosurgery"
  },
  {
    "Code": "MED085110",
    "Literal": "MEDICAL / Surgery / Oncology"
  },
  {
    "Code": "MED085020",
    "Literal": "MEDICAL / Surgery / Oral & Maxillofacial"
  },
  {
    "Code": "MED085120",
    "Literal": "MEDICAL / Surgery / Orthopedic"
  },
  {
    "Code": "MED085130",
    "Literal": "MEDICAL / Surgery / Otolaryngology"
  },
  {
    "Code": "MED085140",
    "Literal": "MEDICAL / Surgery / Pediatric"
  },
  {
    "Code": "MED085150",
    "Literal": "MEDICAL / Surgery / Reproductive & Urological"
  },
  {
    "Code": "MED085070",
    "Literal": "MEDICAL / Surgery / Transplant"
  },
  {
    "Code": "MED085050",
    "Literal": "MEDICAL / Surgery / Vascular"
  },
  {
    "Code": "MED042000",
    "Literal": "MEDICAL / Terminal Care"
  },
  {
    "Code": "MED086000",
    "Literal": "MEDICAL / Test Preparation & Review"
  },
  {
    "Code": "MED096000",
    "Literal": "MEDICAL / Toxicology"
  },
  {
    "Code": "MED087000",
    "Literal": "MEDICAL / Transportation"
  },
  {
    "Code": "MED097000",
    "Literal": "MEDICAL / Tropical Medicine"
  },
  {
    "Code": "MED088000",
    "Literal": "MEDICAL / Urology"
  },
  {
    "Code": "MED089000",
    "Literal": "MEDICAL / Veterinary Medicine / General"
  },
  {
    "Code": "MED089010",
    "Literal": "MEDICAL / Veterinary Medicine / Equine"
  },
  {
    "Code": "MED089020",
    "Literal": "MEDICAL / Veterinary Medicine / Food Animal"
  },
  {
    "Code": "MED089030",
    "Literal": "MEDICAL / Veterinary Medicine / Small Animal"
  },
  {
    "Code": "MUS000000",
    "Literal": "MUSIC / General"
  },
  {
    "Code": "MUS004000",
    "Literal": "MUSIC / Business Aspects"
  },
  {
    "Code": "MUS012000",
    "Literal": "MUSIC / Discography & Buyer's Guides"
  },
  {
    "Code": "MUS055000",
    "Literal": "MUSIC / Essays"
  },
  {
    "Code": "MUS014000",
    "Literal": "MUSIC / Ethnic"
  },
  {
    "Code": "MUS015000",
    "Literal": "MUSIC / Ethnomusicology"
  },
  {
    "Code": "MUS049000",
    "Literal": "MUSIC / Genres & Styles / General"
  },
  {
    "Code": "MUS002000",
    "Literal": "MUSIC / Genres & Styles / Ballet"
  },
  {
    "Code": "MUS053000",
    "Literal": "MUSIC / Genres & Styles / Big Band & Swing"
  },
  {
    "Code": "MUS003000",
    "Literal": "MUSIC / Genres & Styles / Blues"
  },
  {
    "Code": "MUS005000",
    "Literal": "MUSIC / Genres & Styles / Chamber"
  },
  {
    "Code": "MUS026000",
    "Literal": "MUSIC / Genres & Styles / Children's"
  },
  {
    "Code": "MUS051000",
    "Literal": "MUSIC / Genres & Styles / Choral"
  },
  {
    "Code": "MUS006000",
    "Literal": "MUSIC / Genres & Styles / Classical"
  },
  {
    "Code": "MUS010000",
    "Literal": "MUSIC / Genres & Styles / Country & Bluegrass"
  },
  {
    "Code": "MUS011000",
    "Literal": "MUSIC / Genres & Styles / Dance"
  },
  {
    "Code": "MUS013000",
    "Literal": "MUSIC / Genres & Styles / Electronic"
  },
  {
    "Code": "MUS017000",
    "Literal": "MUSIC / Genres & Styles / Folk & Traditional"
  },
  {
    "Code": "MUS019000",
    "Literal": "MUSIC / Genres & Styles / Heavy Metal"
  },
  {
    "Code": "MUS024000",
    "Literal": "MUSIC / Genres & Styles / International"
  },
  {
    "Code": "MUS025000",
    "Literal": "MUSIC / Genres & Styles / Jazz"
  },
  {
    "Code": "MUS036000",
    "Literal": "MUSIC / Genres & Styles / Latin"
  },
  {
    "Code": "MUS045000",
    "Literal": "MUSIC / Genres & Styles / Military & Marches"
  },
  {
    "Code": "MUS046000",
    "Literal": "MUSIC / Genres & Styles / Musicals"
  },
  {
    "Code": "MUS027000",
    "Literal": "MUSIC / Genres & Styles / New Age"
  },
  {
    "Code": "MUS028000",
    "Literal": "MUSIC / Genres & Styles / Opera"
  },
  {
    "Code": "MUS029000",
    "Literal": "MUSIC / Genres & Styles / Pop Vocal"
  },
  {
    "Code": "MUS030000",
    "Literal": "MUSIC / Genres & Styles / Punk"
  },
  {
    "Code": "MUS031000",
    "Literal": "MUSIC / Genres & Styles / Rap & Hip Hop"
  },
  {
    "Code": "MUS047000",
    "Literal": "MUSIC / Genres & Styles / Reggae"
  },
  {
    "Code": "MUS035000",
    "Literal": "MUSIC / Genres & Styles / Rock"
  },
  {
    "Code": "MUS039000",
    "Literal": "MUSIC / Genres & Styles / Soul & R 'n B"
  },
  {
    "Code": "MUS020000",
    "Literal": "MUSIC / History & Criticism"
  },
  {
    "Code": "MUS050000",
    "Literal": "MUSIC / Individual Composer & Musician"
  },
  {
    "Code": "MUS022000",
    "Literal": "MUSIC / Instruction & Study / General"
  },
  {
    "Code": "MUS001000",
    "Literal": "MUSIC / Instruction & Study / Appreciation"
  },
  {
    "Code": "MUS007000",
    "Literal": "MUSIC / Instruction & Study / Composition"
  },
  {
    "Code": "MUS008000",
    "Literal": "MUSIC / Instruction & Study / Conducting"
  },
  {
    "Code": "MUS016000",
    "Literal": "MUSIC / Instruction & Study / Exercises"
  },
  {
    "Code": "MUS038000",
    "Literal": "MUSIC / Instruction & Study / Songwriting"
  },
  {
    "Code": "MUS040000",
    "Literal": "MUSIC / Instruction & Study / Techniques"
  },
  {
    "Code": "MUS041000",
    "Literal": "MUSIC / Instruction & Study / Theory"
  },
  {
    "Code": "MUS042000",
    "Literal": "MUSIC / Instruction & Study / Voice"
  },
  {
    "Code": "MUS052000",
    "Literal": "MUSIC / Lyrics"
  },
  {
    "Code": "MUS023000",
    "Literal": "MUSIC / Musical Instruments / General"
  },
  {
    "Code": "MUS023010",
    "Literal": "MUSIC / Musical Instruments / Brass"
  },
  {
    "Code": "MUS023060",
    "Literal": "MUSIC / Musical Instruments / Guitar"
  },
  {
    "Code": "MUS023020",
    "Literal": "MUSIC / Musical Instruments / Percussion"
  },
  {
    "Code": "MUS023030",
    "Literal": "MUSIC / Musical Instruments / Piano & Keyboard"
  },
  {
    "Code": "MUS023040",
    "Literal": "MUSIC / Musical Instruments / Strings"
  },
  {
    "Code": "MUS023050",
    "Literal": "MUSIC / Musical Instruments / Woodwinds"
  },
  {
    "Code": "MUS054000",
    "Literal": "MUSIC / Philosophy & Social Aspects"
  },
  {
    "Code": "MUS037000",
    "Literal": "MUSIC / Printed Music / General"
  },
  {
    "Code": "MUS037010",
    "Literal": "MUSIC / Printed Music / Artist Specific"
  },
  {
    "Code": "MUS037020",
    "Literal": "MUSIC / Printed Music / Band & Orchestra"
  },
  {
    "Code": "MUS037120",
    "Literal": "MUSIC / Printed Music / Brass"
  },
  {
    "Code": "MUS037030",
    "Literal": "MUSIC / Printed Music / Choral"
  },
  {
    "Code": "MUS037040",
    "Literal": "MUSIC / Printed Music / Guitar & Fretted Instruments"
  },
  {
    "Code": "MUS037050",
    "Literal": "MUSIC / Printed Music / Mixed Collections"
  },
  {
    "Code": "MUS037060",
    "Literal": "MUSIC / Printed Music / Musicals, Film & TV"
  },
  {
    "Code": "MUS037070",
    "Literal": "MUSIC / Printed Music / Opera & Classical Scores"
  },
  {
    "Code": "MUS037080",
    "Literal": "MUSIC / Printed Music / Percussion"
  },
  {
    "Code": "MUS037090",
    "Literal": "MUSIC / Printed Music / Piano & Keyboard Repertoire"
  },
  {
    "Code": "MUS037100",
    "Literal": "MUSIC / Printed Music / Piano-Vocal-Guitar"
  },
  {
    "Code": "MUS037130",
    "Literal": "MUSIC / Printed Music / Strings"
  },
  {
    "Code": "MUS037110",
    "Literal": "MUSIC / Printed Music / Vocal"
  },
  {
    "Code": "MUS037140",
    "Literal": "MUSIC / Printed Music / Woodwinds"
  },
  {
    "Code": "MUS032000",
    "Literal": "MUSIC / Recording & Reproduction"
  },
  {
    "Code": "MUS033000",
    "Literal": "MUSIC / Reference"
  },
  {
    "Code": "MUS048000",
    "Literal": "MUSIC / Religious / General"
  },
  {
    "Code": "MUS048010",
    "Literal": "MUSIC / Religious / Christian"
  },
  {
    "Code": "MUS009000",
    "Literal": "MUSIC / Religious / Contemporary Christian"
  },
  {
    "Code": "MUS018000",
    "Literal": "MUSIC / Religious / Gospel"
  },
  {
    "Code": "MUS021000",
    "Literal": "MUSIC / Religious / Hymns"
  },
  {
    "Code": "MUS048020",
    "Literal": "MUSIC / Religious / Jewish"
  },
  {
    "Code": "MUS048030",
    "Literal": "MUSIC / Religious / Muslim"
  },
  {
    "Code": "NAT000000",
    "Literal": "NATURE / General"
  },
  {
    "Code": "NAT039000",
    "Literal": "NATURE / Animal Rights"
  },
  {
    "Code": "NAT001000",
    "Literal": "NATURE / Animals / General"
  },
  {
    "Code": "NAT003000",
    "Literal": "NATURE / Animals / Bears"
  },
  {
    "Code": "NAT042000",
    "Literal": "NATURE / Animals / Big Cats"
  },
  {
    "Code": "NAT043000",
    "Literal": "NATURE / Animals / Birds"
  },
  {
    "Code": "NAT005000",
    "Literal": "NATURE / Animals / Butterflies & Moths"
  },
  {
    "Code": "NAT007000",
    "Literal": "NATURE / Animals / Dinosaurs & Prehistoric Creatures"
  },
  {
    "Code": "NAT012000",
    "Literal": "NATURE / Animals / Fish"
  },
  {
    "Code": "NAT016000",
    "Literal": "NATURE / Animals / Horses"
  },
  {
    "Code": "NAT017000",
    "Literal": "NATURE / Animals / Insects & Spiders"
  },
  {
    "Code": "NAT019000",
    "Literal": "NATURE / Animals / Mammals"
  },
  {
    "Code": "NAT020000",
    "Literal": "NATURE / Animals / Marine Life"
  },
  {
    "Code": "NAT002000",
    "Literal": "NATURE / Animals / Primates"
  },
  {
    "Code": "NAT028000",
    "Literal": "NATURE / Animals / Reptiles & Amphibians"
  },
  {
    "Code": "NAT037000",
    "Literal": "NATURE / Animals / Wildlife"
  },
  {
    "Code": "NAT044000",
    "Literal": "NATURE / Animals / Wolves"
  },
  {
    "Code": "NAT004000",
    "Literal": "NATURE / Birdwatching Guides"
  },
  {
    "Code": "NAT009000",
    "Literal": "NATURE / Earthquakes & Volcanoes"
  },
  {
    "Code": "NAT010000",
    "Literal": "NATURE / Ecology"
  },
  {
    "Code": "NAT045000",
    "Literal": "NATURE / Ecosystems & Habitats / General"
  },
  {
    "Code": "NAT045050",
    "Literal": "NATURE / Ecosystems & Habitats / Coastal Regions & Shorelines"
  },
  {
    "Code": "NAT045010",
    "Literal": "NATURE / Ecosystems & Habitats / Deserts"
  },
  {
    "Code": "NAT014000",
    "Literal": "NATURE / Ecosystems & Habitats / Forests & Rainforests"
  },
  {
    "Code": "NAT018000",
    "Literal": "NATURE / Ecosystems & Habitats / Lakes, Ponds & Swamps"
  },
  {
    "Code": "NAT041000",
    "Literal": "NATURE / Ecosystems & Habitats / Mountains"
  },
  {
    "Code": "NAT025000",
    "Literal": "NATURE / Ecosystems & Habitats / Oceans & Seas"
  },
  {
    "Code": "NAT045020",
    "Literal": "NATURE / Ecosystems & Habitats / Plains & Prairies"
  },
  {
    "Code": "NAT045030",
    "Literal": "NATURE / Ecosystems & Habitats / Polar Regions"
  },
  {
    "Code": "NAT029000",
    "Literal": "NATURE / Ecosystems & Habitats / Rivers"
  },
  {
    "Code": "NAT045040",
    "Literal": "NATURE / Ecosystems & Habitats / Wilderness"
  },
  {
    "Code": "NAT046000",
    "Literal": "NATURE / Endangered Species"
  },
  {
    "Code": "NAT011000",
    "Literal": "NATURE / Environmental Conservation & Protection"
  },
  {
    "Code": "NAT024000",
    "Literal": "NATURE / Essays"
  },
  {
    "Code": "NAT015000",
    "Literal": "NATURE / Fossils"
  },
  {
    "Code": "NAT023000",
    "Literal": "NATURE / Natural Disasters"
  },
  {
    "Code": "NAT038000",
    "Literal": "NATURE / Natural Resources"
  },
  {
    "Code": "NAT026000",
    "Literal": "NATURE / Plants / General"
  },
  {
    "Code": "NAT047000",
    "Literal": "NATURE / Plants / Aquatic"
  },
  {
    "Code": "NAT048000",
    "Literal": "NATURE / Plants / Cacti & Succulents"
  },
  {
    "Code": "NAT013000",
    "Literal": "NATURE / Plants / Flowers"
  },
  {
    "Code": "NAT022000",
    "Literal": "NATURE / Plants / Mushrooms"
  },
  {
    "Code": "NAT034000",
    "Literal": "NATURE / Plants / Trees"
  },
  {
    "Code": "NAT027000",
    "Literal": "NATURE / Reference"
  },
  {
    "Code": "NAT049000",
    "Literal": "NATURE / Regional"
  },
  {
    "Code": "NAT030000",
    "Literal": "NATURE / Rocks & Minerals"
  },
  {
    "Code": "NAT031000",
    "Literal": "NATURE / Seashells"
  },
  {
    "Code": "NAT032000",
    "Literal": "NATURE / Seasons"
  },
  {
    "Code": "NAT033000",
    "Literal": "NATURE / Sky Observation"
  },
  {
    "Code": "NAT036000",
    "Literal": "NATURE / Weather"
  },
  {
    "Code": "PER000000",
    "Literal": "PERFORMING ARTS / General"
  },
  {
    "Code": "PER001000",
    "Literal": "PERFORMING ARTS / Acting & Auditioning"
  },
  {
    "Code": "PER017000",
    "Literal": "PERFORMING ARTS / Animation (see also Film / Genres / Animated)"
  },
  {
    "Code": "PER014000",
    "Literal": "PERFORMING ARTS / Business Aspects"
  },
  {
    "Code": "PER002000",
    "Literal": "PERFORMING ARTS / Circus"
  },
  {
    "Code": "PER015000",
    "Literal": "PERFORMING ARTS / Comedy"
  },
  {
    "Code": "PER003000",
    "Literal": "PERFORMING ARTS / Dance / General"
  },
  {
    "Code": "PER003090",
    "Literal": "PERFORMING ARTS / Dance / Ballroom"
  },
  {
    "Code": "PER003050",
    "Literal": "PERFORMING ARTS / Dance / Choreography & Dance Notation"
  },
  {
    "Code": "PER003010",
    "Literal": "PERFORMING ARTS / Dance / Classical & Ballet"
  },
  {
    "Code": "PER003020",
    "Literal": "PERFORMING ARTS / Dance / Folk"
  },
  {
    "Code": "PER003100",
    "Literal": "PERFORMING ARTS / Dance / History & Criticism"
  },
  {
    "Code": "PER003030",
    "Literal": "PERFORMING ARTS / Dance / Jazz"
  },
  {
    "Code": "PER003040",
    "Literal": "PERFORMING ARTS / Dance / Modern"
  },
  {
    "Code": "PER003060",
    "Literal": "PERFORMING ARTS / Dance / Popular"
  },
  {
    "Code": "PER003070",
    "Literal": "PERFORMING ARTS / Dance / Reference"
  },
  {
    "Code": "PER021000",
    "Literal": "PERFORMING ARTS / Dance / Regional & Ethnic"
  },
  {
    "Code": "PER003080",
    "Literal": "PERFORMING ARTS / Dance / Tap"
  },
  {
    "Code": "PER004000",
    "Literal": "PERFORMING ARTS / Film / General"
  },
  {
    "Code": "PER004010",
    "Literal": "PERFORMING ARTS / Film / Direction & Production"
  },
  {
    "Code": "PER004060",
    "Literal": "PERFORMING ARTS / Film / Genres / General"
  },
  {
    "Code": "PER004070",
    "Literal": "PERFORMING ARTS / Film / Genres / Action & Adventure"
  },
  {
    "Code": "PER004080",
    "Literal": "PERFORMING ARTS / Film / Genres / Animated"
  },
  {
    "Code": "PER004090",
    "Literal": "PERFORMING ARTS / Film / Genres / Comedy"
  },
  {
    "Code": "PER004100",
    "Literal": "PERFORMING ARTS / Film / Genres / Crime"
  },
  {
    "Code": "PER004110",
    "Literal": "PERFORMING ARTS / Film / Genres / Documentary"
  },
  {
    "Code": "PER004120",
    "Literal": "PERFORMING ARTS / Film / Genres / Historical"
  },
  {
    "Code": "PER004130",
    "Literal": "PERFORMING ARTS / Film / Genres / Horror"
  },
  {
    "Code": "PER004140",
    "Literal": "PERFORMING ARTS / Film / Genres / Science Fiction & Fantasy"
  },
  {
    "Code": "PER004150",
    "Literal": "PERFORMING ARTS / Film / Genres / Westerns"
  },
  {
    "Code": "PER004020",
    "Literal": "PERFORMING ARTS / Film / Guides & Reviews"
  },
  {
    "Code": "PER004030",
    "Literal": "PERFORMING ARTS / Film / History & Criticism"
  },
  {
    "Code": "PER004040",
    "Literal": "PERFORMING ARTS / Film / Reference"
  },
  {
    "Code": "PER004050",
    "Literal": "PERFORMING ARTS / Film / Screenwriting"
  },
  {
    "Code": "PER022000",
    "Literal": "PERFORMING ARTS / Improvisation"
  },
  {
    "Code": "PER018000",
    "Literal": "PERFORMING ARTS / Individual Director (see also BIOGRAPHY & AUTOBIOGRAPHY / Entertainment & Performing Arts)"
  },
  {
    "Code": "PER020000",
    "Literal": "PERFORMING ARTS / Monologues & Scenes"
  },
  {
    "Code": "PER007000",
    "Literal": "PERFORMING ARTS / Puppets & Puppetry"
  },
  {
    "Code": "PER008000",
    "Literal": "PERFORMING ARTS / Radio / General"
  },
  {
    "Code": "PER008010",
    "Literal": "PERFORMING ARTS / Radio / History & Criticism"
  },
  {
    "Code": "PER008020",
    "Literal": "PERFORMING ARTS / Radio / Reference"
  },
  {
    "Code": "PER009000",
    "Literal": "PERFORMING ARTS / Reference"
  },
  {
    "Code": "PER016000",
    "Literal": "PERFORMING ARTS / Screenplays"
  },
  {
    "Code": "PER019000",
    "Literal": "PERFORMING ARTS / Storytelling"
  },
  {
    "Code": "PER010000",
    "Literal": "PERFORMING ARTS / Television / General"
  },
  {
    "Code": "PER010010",
    "Literal": "PERFORMING ARTS / Television / Direction & Production"
  },
  {
    "Code": "PER010060",
    "Literal": "PERFORMING ARTS / Television / Genres / General"
  },
  {
    "Code": "PER010070",
    "Literal": "PERFORMING ARTS / Television / Genres / Children's"
  },
  {
    "Code": "PER010080",
    "Literal": "PERFORMING ARTS / Television / Genres / Comedy"
  },
  {
    "Code": "PER010090",
    "Literal": "PERFORMING ARTS / Television / Genres / Drama"
  },
  {
    "Code": "PER010100",
    "Literal": "PERFORMING ARTS / Television / Genres / Reality, Game Shows & Talk Shows"
  },
  {
    "Code": "PER010110",
    "Literal": "PERFORMING ARTS / Television / Genres / Science Fiction, Fantasy & Horror"
  },
  {
    "Code": "PER010020",
    "Literal": "PERFORMING ARTS / Television / Guides & Reviews"
  },
  {
    "Code": "PER010030",
    "Literal": "PERFORMING ARTS / Television / History & Criticism"
  },
  {
    "Code": "PER010040",
    "Literal": "PERFORMING ARTS / Television / Reference"
  },
  {
    "Code": "PER010050",
    "Literal": "PERFORMING ARTS / Television / Screenwriting"
  },
  {
    "Code": "PER011000",
    "Literal": "PERFORMING ARTS / Theater / General"
  },
  {
    "Code": "PER013000",
    "Literal": "PERFORMING ARTS / Theater / Broadway & Musicals"
  },
  {
    "Code": "PER011010",
    "Literal": "PERFORMING ARTS / Theater / Direction & Production"
  },
  {
    "Code": "PER011020",
    "Literal": "PERFORMING ARTS / Theater / History & Criticism"
  },
  {
    "Code": "PER006000",
    "Literal": "PERFORMING ARTS / Theater / Miming"
  },
  {
    "Code": "PER011030",
    "Literal": "PERFORMING ARTS / Theater / Playwriting"
  },
  {
    "Code": "PER011040",
    "Literal": "PERFORMING ARTS / Theater / Stagecraft & Scenography"
  },
  {
    "Code": "PET000000",
    "Literal": "PETS / General"
  },
  {
    "Code": "PET002000",
    "Literal": "PETS / Birds"
  },
  {
    "Code": "PET003000",
    "Literal": "PETS / Cats / General"
  },
  {
    "Code": "PET003010",
    "Literal": "PETS / Cats / Breeds"
  },
  {
    "Code": "PET004000",
    "Literal": "PETS / Dogs / General"
  },
  {
    "Code": "PET004010",
    "Literal": "PETS / Dogs / Breeds"
  },
  {
    "Code": "PET004020",
    "Literal": "PETS / Dogs / Training"
  },
  {
    "Code": "PET010000",
    "Literal": "PETS / Essays & Narratives"
  },
  {
    "Code": "PET005000",
    "Literal": "PETS / Fish & Aquariums"
  },
  {
    "Code": "PET012000",
    "Literal": "PETS / Food & Nutrition"
  },
  {
    "Code": "PET006000",
    "Literal": "PETS / Horses"
  },
  {
    "Code": "PET013000",
    "Literal": "PETS / Insects & Spiders"
  },
  {
    "Code": "PET011000",
    "Literal": "PETS / Rabbits, Mice, Hamsters, Guinea Pigs, etc."
  },
  {
    "Code": "PET008000",
    "Literal": "PETS / Reference"
  },
  {
    "Code": "PET009000",
    "Literal": "PETS / Reptiles, Amphibians & Terrariums"
  },
  {
    "Code": "PHI000000",
    "Literal": "PHILOSOPHY / General"
  },
  {
    "Code": "PHI001000",
    "Literal": "PHILOSOPHY / Aesthetics"
  },
  {
    "Code": "PHI028000",
    "Literal": "PHILOSOPHY / Buddhist"
  },
  {
    "Code": "PHI026000",
    "Literal": "PHILOSOPHY / Criticism"
  },
  {
    "Code": "PHI003000",
    "Literal": "PHILOSOPHY / Eastern"
  },
  {
    "Code": "PHI004000",
    "Literal": "PHILOSOPHY / Epistemology"
  },
  {
    "Code": "PHI035000",
    "Literal": "PHILOSOPHY / Essays"
  },
  {
    "Code": "PHI005000",
    "Literal": "PHILOSOPHY / Ethics & Moral Philosophy"
  },
  {
    "Code": "PHI007000",
    "Literal": "PHILOSOPHY / Free Will & Determinism"
  },
  {
    "Code": "PHI008000",
    "Literal": "PHILOSOPHY / Good & Evil"
  },
  {
    "Code": "PHI036000",
    "Literal": "PHILOSOPHY / Hermeneutics"
  },
  {
    "Code": "PHI033000",
    "Literal": "PHILOSOPHY / Hindu"
  },
  {
    "Code": "PHI009000",
    "Literal": "PHILOSOPHY / History & Surveys / General"
  },
  {
    "Code": "PHI002000",
    "Literal": "PHILOSOPHY / History & Surveys / Ancient & Classical"
  },
  {
    "Code": "PHI012000",
    "Literal": "PHILOSOPHY / History & Surveys / Medieval"
  },
  {
    "Code": "PHI037000",
    "Literal": "PHILOSOPHY / History & Surveys / Renaissance"
  },
  {
    "Code": "PHI016000",
    "Literal": "PHILOSOPHY / History & Surveys / Modern"
  },
  {
    "Code": "PHI046000",
    "Literal": "PHILOSOPHY / Individual Philosophers"
  },
  {
    "Code": "PHI038000",
    "Literal": "PHILOSOPHY / Language"
  },
  {
    "Code": "PHI011000",
    "Literal": "PHILOSOPHY / Logic"
  },
  {
    "Code": "PHI013000",
    "Literal": "PHILOSOPHY / Metaphysics"
  },
  {
    "Code": "PHI014000",
    "Literal": "PHILOSOPHY / Methodology"
  },
  {
    "Code": "PHI015000",
    "Literal": "PHILOSOPHY / Mind & Body"
  },
  {
    "Code": "PHI031000",
    "Literal": "PHILOSOPHY / Movements / General"
  },
  {
    "Code": "PHI039000",
    "Literal": "PHILOSOPHY / Movements / Analytic"
  },
  {
    "Code": "PHI040000",
    "Literal": "PHILOSOPHY / Movements / Critical Theory"
  },
  {
    "Code": "PHI027000",
    "Literal": "PHILOSOPHY / Movements / Deconstruction"
  },
  {
    "Code": "PHI041000",
    "Literal": "PHILOSOPHY / Movements / Empiricism"
  },
  {
    "Code": "PHI006000",
    "Literal": "PHILOSOPHY / Movements / Existentialism"
  },
  {
    "Code": "PHI010000",
    "Literal": "PHILOSOPHY / Movements / Humanism"
  },
  {
    "Code": "PHI042000",
    "Literal": "PHILOSOPHY / Movements / Idealism"
  },
  {
    "Code": "PHI018000",
    "Literal": "PHILOSOPHY / Movements / Phenomenology"
  },
  {
    "Code": "PHI043000",
    "Literal": "PHILOSOPHY / Movements / Post-Structuralism"
  },
  {
    "Code": "PHI020000",
    "Literal": "PHILOSOPHY / Movements / Pragmatism"
  },
  {
    "Code": "PHI032000",
    "Literal": "PHILOSOPHY / Movements / Rationalism"
  },
  {
    "Code": "PHI044000",
    "Literal": "PHILOSOPHY / Movements / Realism"
  },
  {
    "Code": "PHI029000",
    "Literal": "PHILOSOPHY / Movements / Structuralism"
  },
  {
    "Code": "PHI045000",
    "Literal": "PHILOSOPHY / Movements / Transcendentalism"
  },
  {
    "Code": "PHI030000",
    "Literal": "PHILOSOPHY / Movements / Utilitarianism"
  },
  {
    "Code": "PHI019000",
    "Literal": "PHILOSOPHY / Political"
  },
  {
    "Code": "PHI021000",
    "Literal": "PHILOSOPHY / Reference"
  },
  {
    "Code": "PHI022000",
    "Literal": "PHILOSOPHY / Religious"
  },
  {
    "Code": "PHI034000",
    "Literal": "PHILOSOPHY / Social"
  },
  {
    "Code": "PHI023000",
    "Literal": "PHILOSOPHY / Taoist"
  },
  {
    "Code": "PHI025000",
    "Literal": "PHILOSOPHY / Zen"
  },
  {
    "Code": "PHO000000",
    "Literal": "PHOTOGRAPHY / General"
  },
  {
    "Code": "PHO025000",
    "Literal": "PHOTOGRAPHY / Annuals"
  },
  {
    "Code": "PHO026000",
    "Literal": "PHOTOGRAPHY / Astrophotography"
  },
  {
    "Code": "PHO003000",
    "Literal": "PHOTOGRAPHY / Business Aspects"
  },
  {
    "Code": "PHO004000",
    "Literal": "PHOTOGRAPHY / Collections, Catalogs, Exhibitions / General"
  },
  {
    "Code": "PHO004010",
    "Literal": "PHOTOGRAPHY / Collections, Catalogs, Exhibitions / Group Shows"
  },
  {
    "Code": "PHO004020",
    "Literal": "PHOTOGRAPHY / Collections, Catalogs, Exhibitions / Permanent Collections"
  },
  {
    "Code": "PHO021000",
    "Literal": "PHOTOGRAPHY / Commercial"
  },
  {
    "Code": "PHO005000",
    "Literal": "PHOTOGRAPHY / Criticism"
  },
  {
    "Code": "PHO027000",
    "Literal": "PHOTOGRAPHY / Forensic"
  },
  {
    "Code": "PHO010000",
    "Literal": "PHOTOGRAPHY / History"
  },
  {
    "Code": "PHO011000",
    "Literal": "PHOTOGRAPHY / Individual Photographers / General"
  },
  {
    "Code": "PHO011010",
    "Literal": "PHOTOGRAPHY / Individual Photographers / Artists' Books"
  },
  {
    "Code": "PHO011020",
    "Literal": "PHOTOGRAPHY / Individual Photographers / Essays"
  },
  {
    "Code": "PHO011030",
    "Literal": "PHOTOGRAPHY / Individual Photographers / Monographs"
  },
  {
    "Code": "PHO014000",
    "Literal": "PHOTOGRAPHY / Photoessays & Documentaries"
  },
  {
    "Code": "PHO015000",
    "Literal": "PHOTOGRAPHY / Photojournalism"
  },
  {
    "Code": "PHO017000",
    "Literal": "PHOTOGRAPHY / Reference"
  },
  {
    "Code": "PHO023000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / General"
  },
  {
    "Code": "PHO023010",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Aerial"
  },
  {
    "Code": "PHO001000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Architectural & Industrial"
  },
  {
    "Code": "PHO023070",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Celebrations & Events"
  },
  {
    "Code": "PHO023080",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Celebrity"
  },
  {
    "Code": "PHO023020",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Children"
  },
  {
    "Code": "PHO023030",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Erotica"
  },
  {
    "Code": "PHO009000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Fashion"
  },
  {
    "Code": "PHO023110",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Food"
  },
  {
    "Code": "PHO023100",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Historical"
  },
  {
    "Code": "PHO023040",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Landscapes"
  },
  {
    "Code": "PHO023090",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Lifestyles"
  },
  {
    "Code": "PHO023050",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Nudes"
  },
  {
    "Code": "PHO013000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Plants & Animals"
  },
  {
    "Code": "PHO016000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Portraits & Selfies"
  },
  {
    "Code": "PHO019000",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Regional (see also TRAVEL / Pictorials)"
  },
  {
    "Code": "PHO023060",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Sports"
  },
  {
    "Code": "PHO023120",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Street Photography"
  },
  {
    "Code": "PHO023130",
    "Literal": "PHOTOGRAPHY / Subjects & Themes / Underwater"
  },
  {
    "Code": "PHO018000",
    "Literal": "PHOTOGRAPHY / Techniques / General"
  },
  {
    "Code": "PHO022000",
    "Literal": "PHOTOGRAPHY / Techniques / Cinematography & Videography"
  },
  {
    "Code": "PHO020000",
    "Literal": "PHOTOGRAPHY / Techniques / Color"
  },
  {
    "Code": "PHO006000",
    "Literal": "PHOTOGRAPHY / Techniques / Darkroom"
  },
  {
    "Code": "PHO024000",
    "Literal": "PHOTOGRAPHY / Techniques / Digital (see also COMPUTERS / Digital Media / Photography)"
  },
  {
    "Code": "PHO007000",
    "Literal": "PHOTOGRAPHY / Techniques / Equipment"
  },
  {
    "Code": "PHO012000",
    "Literal": "PHOTOGRAPHY / Techniques / Lighting"
  },
  {
    "Code": "POE000000",
    "Literal": "POETRY / General"
  },
  {
    "Code": "POE007000",
    "Literal": "POETRY / African"
  },
  {
    "Code": "POE005010",
    "Literal": "POETRY / American / General"
  },
  {
    "Code": "POE005050",
    "Literal": "POETRY / American / African American"
  },
  {
    "Code": "POE005060",
    "Literal": "POETRY / American / Asian American"
  },
  {
    "Code": "POE005070",
    "Literal": "POETRY / American / Hispanic American"
  },
  {
    "Code": "POE008000",
    "Literal": "POETRY / Ancient & Classical"
  },
  {
    "Code": "POE001000",
    "Literal": "POETRY / Anthologies (multiple authors)"
  },
  {
    "Code": "POE009000",
    "Literal": "POETRY / Asian / General"
  },
  {
    "Code": "POE009010",
    "Literal": "POETRY / Asian / Chinese"
  },
  {
    "Code": "POE009020",
    "Literal": "POETRY / Asian / Japanese"
  },
  {
    "Code": "POE010000",
    "Literal": "POETRY / Australian & Oceanian"
  },
  {
    "Code": "POE011000",
    "Literal": "POETRY / Canadian"
  },
  {
    "Code": "POE012000",
    "Literal": "POETRY / Caribbean & Latin American"
  },
  {
    "Code": "POE005030",
    "Literal": "POETRY / European / General"
  },
  {
    "Code": "POE005020",
    "Literal": "POETRY / European / English, Irish, Scottish, Welsh"
  },
  {
    "Code": "POE017000",
    "Literal": "POETRY / European / French"
  },
  {
    "Code": "POE018000",
    "Literal": "POETRY / European / German"
  },
  {
    "Code": "POE019000",
    "Literal": "POETRY / European / Italian"
  },
  {
    "Code": "POE020000",
    "Literal": "POETRY / European / Spanish & Portuguese"
  },
  {
    "Code": "POE014000",
    "Literal": "POETRY / Epic"
  },
  {
    "Code": "POE025000",
    "Literal": "POETRY / Haiku"
  },
  {
    "Code": "POE021000",
    "Literal": "POETRY / LGBT"
  },
  {
    "Code": "POE022000",
    "Literal": "POETRY / Medieval"
  },
  {
    "Code": "POE013000",
    "Literal": "POETRY / Middle Eastern"
  },
  {
    "Code": "POE015000",
    "Literal": "POETRY / Native American"
  },
  {
    "Code": "POE016000",
    "Literal": "POETRY / Russian & Former Soviet Union"
  },
  {
    "Code": "POE023000",
    "Literal": "POETRY / Subjects & Themes / General"
  },
  {
    "Code": "POE023010",
    "Literal": "POETRY / Subjects & Themes / Death, Grief, Loss"
  },
  {
    "Code": "POE023050",
    "Literal": "POETRY / Subjects & Themes / Family"
  },
  {
    "Code": "POE003000",
    "Literal": "POETRY / Subjects & Themes / Inspirational & Religious"
  },
  {
    "Code": "POE023020",
    "Literal": "POETRY / Subjects & Themes / Love & Erotica"
  },
  {
    "Code": "POE023030",
    "Literal": "POETRY / Subjects & Themes / Nature"
  },
  {
    "Code": "POE023040",
    "Literal": "POETRY / Subjects & Themes / Places"
  },
  {
    "Code": "POE024000",
    "Literal": "POETRY / Women Authors"
  },
  {
    "Code": "POL000000",
    "Literal": "POLITICAL SCIENCE / General"
  },
  {
    "Code": "POL040000",
    "Literal": "POLITICAL SCIENCE / American Government / General"
  },
  {
    "Code": "POL040010",
    "Literal": "POLITICAL SCIENCE / American Government / Executive Branch"
  },
  {
    "Code": "POL040030",
    "Literal": "POLITICAL SCIENCE / American Government / Judicial Branch"
  },
  {
    "Code": "POL006000",
    "Literal": "POLITICAL SCIENCE / American Government / Legislative Branch"
  },
  {
    "Code": "POL040040",
    "Literal": "POLITICAL SCIENCE / American Government / Local"
  },
  {
    "Code": "POL030000",
    "Literal": "POLITICAL SCIENCE / American Government / National"
  },
  {
    "Code": "POL020000",
    "Literal": "POLITICAL SCIENCE / American Government / State"
  },
  {
    "Code": "POL039000",
    "Literal": "POLITICAL SCIENCE / Censorship"
  },
  {
    "Code": "POL003000",
    "Literal": "POLITICAL SCIENCE / Civics & Citizenship"
  },
  {
    "Code": "POL004000",
    "Literal": "POLITICAL SCIENCE / Civil Rights"
  },
  {
    "Code": "POL045000",
    "Literal": "POLITICAL SCIENCE / Colonialism & Post-Colonialism"
  },
  {
    "Code": "POL046000",
    "Literal": "POLITICAL SCIENCE / Commentary & Opinion"
  },
  {
    "Code": "POL009000",
    "Literal": "POLITICAL SCIENCE / Comparative Politics"
  },
  {
    "Code": "POL022000",
    "Literal": "POLITICAL SCIENCE / Constitutions"
  },
  {
    "Code": "POL064000",
    "Literal": "POLITICAL SCIENCE / Corruption & Misconduct"
  },
  {
    "Code": "POL032000",
    "Literal": "POLITICAL SCIENCE / Essays"
  },
  {
    "Code": "POL061000",
    "Literal": "POLITICAL SCIENCE / Genocide & War Crimes"
  },
  {
    "Code": "POL062000",
    "Literal": "POLITICAL SCIENCE / Geopolitics"
  },
  {
    "Code": "POL033000",
    "Literal": "POLITICAL SCIENCE / Globalization"
  },
  {
    "Code": "POL010000",
    "Literal": "POLITICAL SCIENCE / History & Theory"
  },
  {
    "Code": "POL035010",
    "Literal": "POLITICAL SCIENCE / Human Rights"
  },
  {
    "Code": "POL047000",
    "Literal": "POLITICAL SCIENCE / Imperialism"
  },
  {
    "Code": "POL036000",
    "Literal": "POLITICAL SCIENCE / Intelligence & Espionage"
  },
  {
    "Code": "POL048000",
    "Literal": "POLITICAL SCIENCE / Intergovernmental Organizations"
  },
  {
    "Code": "POL011000",
    "Literal": "POLITICAL SCIENCE / International Relations / General"
  },
  {
    "Code": "POL001000",
    "Literal": "POLITICAL SCIENCE / International Relations / Arms Control"
  },
  {
    "Code": "POL011010",
    "Literal": "POLITICAL SCIENCE / International Relations / Diplomacy"
  },
  {
    "Code": "POL011020",
    "Literal": "POLITICAL SCIENCE / International Relations / Trade & Tariffs"
  },
  {
    "Code": "POL021000",
    "Literal": "POLITICAL SCIENCE / International Relations / Treaties"
  },
  {
    "Code": "POL013000",
    "Literal": "POLITICAL SCIENCE / Labor & Industrial Relations"
  },
  {
    "Code": "POL014000",
    "Literal": "POLITICAL SCIENCE / Law Enforcement"
  },
  {
    "Code": "POL041000",
    "Literal": "POLITICAL SCIENCE / NGOs (Non-Governmental Organizations)"
  },
  {
    "Code": "POL034000",
    "Literal": "POLITICAL SCIENCE / Peace"
  },
  {
    "Code": "POL023000",
    "Literal": "POLITICAL SCIENCE / Political Economy"
  },
  {
    "Code": "POL035000",
    "Literal": "POLITICAL SCIENCE / Political Freedom"
  },
  {
    "Code": "POL042000",
    "Literal": "POLITICAL SCIENCE / Political Ideologies / General"
  },
  {
    "Code": "POL042010",
    "Literal": "POLITICAL SCIENCE / Political Ideologies / Anarchism"
  },
  {
    "Code": "POL042060",
    "Literal": "POLITICAL SCIENCE / Political Ideologies / Capitalism"
  },
  {
    "Code": "POL005000",
    "Literal": "POLITICAL SCIENCE / Political Ideologies / Communism, Post-Communism & Socialism"
  },
  {
    "Code": "POL042020",
    "Literal": "POLITICAL SCIENCE / Political Ideologies / Conservatism & Liberalism"
  },
  {
    "Code": "POL007000",
    "Literal": "POLITICAL SCIENCE / Political Ideologies / Democracy"
  },
  {
    "Code": "POL042030",
    "Literal": "POLITICAL SCIENCE / Political Ideologies / Fascism & Totalitarianism"
  },
  {
    "Code": "POL042050",
    "Literal": "POLITICAL SCIENCE / Political Ideologies / Libertarianism"
  },
  {
    "Code": "POL031000",
    "Literal": "POLITICAL SCIENCE / Political Ideologies / Nationalism & Patriotism"
  },
  {
    "Code": "POL042040",
    "Literal": "POLITICAL SCIENCE / Political Ideologies / Radicalism"
  },
  {
    "Code": "POL016000",
    "Literal": "POLITICAL SCIENCE / Political Process / General"
  },
  {
    "Code": "POL008000",
    "Literal": "POLITICAL SCIENCE / Political Process / Campaigns & Elections"
  },
  {
    "Code": "POL065000",
    "Literal": "POLITICAL SCIENCE / Political Process / Media & Internet"
  },
  {
    "Code": "POL043000",
    "Literal": "POLITICAL SCIENCE / Political Process / Political Advocacy"
  },
  {
    "Code": "POL015000",
    "Literal": "POLITICAL SCIENCE / Political Process / Political Parties"
  },
  {
    "Code": "POL066000",
    "Literal": "POLITICAL SCIENCE / Privacy & Surveillance (see also SOCIAL SCIENCE / Privacy & Surveillance)"
  },
  {
    "Code": "POL049000",
    "Literal": "POLITICAL SCIENCE / Propaganda"
  },
  {
    "Code": "POL017000",
    "Literal": "POLITICAL SCIENCE / Public Affairs & Administration"
  },
  {
    "Code": "POL028000",
    "Literal": "POLITICAL SCIENCE / Public Policy / General"
  },
  {
    "Code": "POL067000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Agriculture & Food Policy (see also SOCIAL SCIENCE / Agriculture & Food)"
  },
  {
    "Code": "POL002000",
    "Literal": "POLITICAL SCIENCE / Public Policy / City Planning & Urban Development"
  },
  {
    "Code": "POL050000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Communication Policy"
  },
  {
    "Code": "POL038000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Cultural Policy"
  },
  {
    "Code": "POL024000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Economic Policy"
  },
  {
    "Code": "POL068000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Energy Policy"
  },
  {
    "Code": "POL044000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Environmental Policy"
  },
  {
    "Code": "POL070000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Immigration"
  },
  {
    "Code": "POL069000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Military Policy"
  },
  {
    "Code": "POL026000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Regional Planning"
  },
  {
    "Code": "POL063000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Science & Technology Policy"
  },
  {
    "Code": "POL029000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Social Policy"
  },
  {
    "Code": "POL027000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Social Security"
  },
  {
    "Code": "POL019000",
    "Literal": "POLITICAL SCIENCE / Public Policy / Social Services & Welfare"
  },
  {
    "Code": "POL018000",
    "Literal": "POLITICAL SCIENCE / Reference"
  },
  {
    "Code": "POL012000",
    "Literal": "POLITICAL SCIENCE / Security (National & International)"
  },
  {
    "Code": "POL037000",
    "Literal": "POLITICAL SCIENCE / Terrorism"
  },
  {
    "Code": "POL051000",
    "Literal": "POLITICAL SCIENCE / Utopias"
  },
  {
    "Code": "POL052000",
    "Literal": "POLITICAL SCIENCE / Women in Politics"
  },
  {
    "Code": "POL040020",
    "Literal": "POLITICAL SCIENCE / World / General"
  },
  {
    "Code": "POL053000",
    "Literal": "POLITICAL SCIENCE / World / African"
  },
  {
    "Code": "POL054000",
    "Literal": "POLITICAL SCIENCE / World / Asian"
  },
  {
    "Code": "POL055000",
    "Literal": "POLITICAL SCIENCE / World / Australian & Oceanian"
  },
  {
    "Code": "POL056000",
    "Literal": "POLITICAL SCIENCE / World / Canadian"
  },
  {
    "Code": "POL057000",
    "Literal": "POLITICAL SCIENCE / World / Caribbean & Latin American"
  },
  {
    "Code": "POL058000",
    "Literal": "POLITICAL SCIENCE / World / European"
  },
  {
    "Code": "POL059000",
    "Literal": "POLITICAL SCIENCE / World / Middle Eastern"
  },
  {
    "Code": "POL060000",
    "Literal": "POLITICAL SCIENCE / World / Russian & Former Soviet Union"
  },
  {
    "Code": "PSY000000",
    "Literal": "PSYCHOLOGY / General"
  },
  {
    "Code": "PSY054000",
    "Literal": "PSYCHOLOGY / Animal & Comparative Psychology"
  },
  {
    "Code": "PSY003000",
    "Literal": "PSYCHOLOGY / Applied Psychology"
  },
  {
    "Code": "PSY042000",
    "Literal": "PSYCHOLOGY / Assessment, Testing & Measurement"
  },
  {
    "Code": "PSY007000",
    "Literal": "PSYCHOLOGY / Clinical Psychology"
  },
  {
    "Code": "PSY051000",
    "Literal": "PSYCHOLOGY / Cognitive Neuroscience & Cognitive Neuropsychology"
  },
  {
    "Code": "PSY008000",
    "Literal": "PSYCHOLOGY / Cognitive Psychology & Cognition"
  },
  {
    "Code": "PSY034000",
    "Literal": "PSYCHOLOGY / Creative Ability"
  },
  {
    "Code": "PSY039000",
    "Literal": "PSYCHOLOGY / Developmental / General"
  },
  {
    "Code": "PSY002000",
    "Literal": "PSYCHOLOGY / Developmental / Adolescent"
  },
  {
    "Code": "PSY043000",
    "Literal": "PSYCHOLOGY / Developmental / Adulthood & Aging"
  },
  {
    "Code": "PSY004000",
    "Literal": "PSYCHOLOGY / Developmental / Child"
  },
  {
    "Code": "PSY044000",
    "Literal": "PSYCHOLOGY / Developmental / Lifespan Development"
  },
  {
    "Code": "PSY012000",
    "Literal": "PSYCHOLOGY / Education & Training"
  },
  {
    "Code": "PSY013000",
    "Literal": "PSYCHOLOGY / Emotions"
  },
  {
    "Code": "PSY055000",
    "Literal": "PSYCHOLOGY / Essays"
  },
  {
    "Code": "PSY050000",
    "Literal": "PSYCHOLOGY / Ethnopsychology"
  },
  {
    "Code": "PSY053000",
    "Literal": "PSYCHOLOGY / Evolutionary Psychology"
  },
  {
    "Code": "PSY040000",
    "Literal": "PSYCHOLOGY / Experimental Psychology"
  },
  {
    "Code": "PSY014000",
    "Literal": "PSYCHOLOGY / Forensic Psychology"
  },
  {
    "Code": "PSY052000",
    "Literal": "PSYCHOLOGY / Grief & Loss"
  },
  {
    "Code": "PSY015000",
    "Literal": "PSYCHOLOGY / History"
  },
  {
    "Code": "PSY016000",
    "Literal": "PSYCHOLOGY / Human Sexuality (see also SOCIAL SCIENCE / Human Sexuality)"
  },
  {
    "Code": "PSY035000",
    "Literal": "PSYCHOLOGY / Hypnotism"
  },
  {
    "Code": "PSY021000",
    "Literal": "PSYCHOLOGY / Industrial & Organizational Psychology"
  },
  {
    "Code": "PSY017000",
    "Literal": "PSYCHOLOGY / Interpersonal Relations"
  },
  {
    "Code": "PSY036000",
    "Literal": "PSYCHOLOGY / Mental Health"
  },
  {
    "Code": "PSY045000",
    "Literal": "PSYCHOLOGY / Movements / General"
  },
  {
    "Code": "PSY045010",
    "Literal": "PSYCHOLOGY / Movements / Behaviorism"
  },
  {
    "Code": "PSY045070",
    "Literal": "PSYCHOLOGY / Movements / Cognitive Behavioral Therapy (CBT)"
  },
  {
    "Code": "PSY045040",
    "Literal": "PSYCHOLOGY / Movements / Existential"
  },
  {
    "Code": "PSY045050",
    "Literal": "PSYCHOLOGY / Movements / Gestalt"
  },
  {
    "Code": "PSY045020",
    "Literal": "PSYCHOLOGY / Movements / Humanistic"
  },
  {
    "Code": "PSY045060",
    "Literal": "PSYCHOLOGY / Movements / Jungian"
  },
  {
    "Code": "PSY026000",
    "Literal": "PSYCHOLOGY / Movements / Psychoanalysis"
  },
  {
    "Code": "PSY045030",
    "Literal": "PSYCHOLOGY / Movements / Transpersonal"
  },
  {
    "Code": "PSY020000",
    "Literal": "PSYCHOLOGY / Neuropsychology"
  },
  {
    "Code": "PSY023000",
    "Literal": "PSYCHOLOGY / Personality"
  },
  {
    "Code": "PSY024000",
    "Literal": "PSYCHOLOGY / Physiological Psychology"
  },
  {
    "Code": "PSY046000",
    "Literal": "PSYCHOLOGY / Practice Management"
  },
  {
    "Code": "PSY022000",
    "Literal": "PSYCHOLOGY / Psychopathology / General"
  },
  {
    "Code": "PSY038000",
    "Literal": "PSYCHOLOGY / Psychopathology / Addiction"
  },
  {
    "Code": "PSY022060",
    "Literal": "PSYCHOLOGY / Psychopathology / Anxieties & Phobias"
  },
  {
    "Code": "PSY022010",
    "Literal": "PSYCHOLOGY / Psychopathology / Attention-Deficit Disorder (ADD-ADHD)"
  },
  {
    "Code": "PSY022020",
    "Literal": "PSYCHOLOGY / Psychopathology / Autism Spectrum Disorders"
  },
  {
    "Code": "PSY022030",
    "Literal": "PSYCHOLOGY / Psychopathology / Bipolar Disorder"
  },
  {
    "Code": "PSY009000",
    "Literal": "PSYCHOLOGY / Psychopathology / Compulsive Behavior"
  },
  {
    "Code": "PSY049000",
    "Literal": "PSYCHOLOGY / Psychopathology / Depression"
  },
  {
    "Code": "PSY022070",
    "Literal": "PSYCHOLOGY / Psychopathology / Dissociative Identity Disorder"
  },
  {
    "Code": "PSY011000",
    "Literal": "PSYCHOLOGY / Psychopathology / Eating Disorders"
  },
  {
    "Code": "PSY022090",
    "Literal": "PSYCHOLOGY / Psychopathology / Memory Disorders"
  },
  {
    "Code": "PSY022080",
    "Literal": "PSYCHOLOGY / Psychopathology / Personality Disorders"
  },
  {
    "Code": "PSY022040",
    "Literal": "PSYCHOLOGY / Psychopathology / Post-Traumatic Stress Disorder (PTSD)"
  },
  {
    "Code": "PSY022050",
    "Literal": "PSYCHOLOGY / Psychopathology / Schizophrenia"
  },
  {
    "Code": "PSY028000",
    "Literal": "PSYCHOLOGY / Psychotherapy / General"
  },
  {
    "Code": "PSY006000",
    "Literal": "PSYCHOLOGY / Psychotherapy / Child & Adolescent"
  },
  {
    "Code": "PSY010000",
    "Literal": "PSYCHOLOGY / Psychotherapy / Counseling"
  },
  {
    "Code": "PSY041000",
    "Literal": "PSYCHOLOGY / Psychotherapy / Couples & Family"
  },
  {
    "Code": "PSY048000",
    "Literal": "PSYCHOLOGY / Psychotherapy / Group"
  },
  {
    "Code": "PSY029000",
    "Literal": "PSYCHOLOGY / Reference"
  },
  {
    "Code": "PSY030000",
    "Literal": "PSYCHOLOGY / Research & Methodology"
  },
  {
    "Code": "PSY031000",
    "Literal": "PSYCHOLOGY / Social Psychology"
  },
  {
    "Code": "PSY032000",
    "Literal": "PSYCHOLOGY / Statistics"
  },
  {
    "Code": "PSY037000",
    "Literal": "PSYCHOLOGY / Suicide"
  },
  {
    "Code": "REF000000",
    "Literal": "REFERENCE / General"
  },
  {
    "Code": "REF001000",
    "Literal": "REFERENCE / Almanacs"
  },
  {
    "Code": "REF002000",
    "Literal": "REFERENCE / Atlases, Gazetteers & Maps (see also TRAVEL / Maps & Road Atlases)"
  },
  {
    "Code": "REF004000",
    "Literal": "REFERENCE / Bibliographies & Indexes"
  },
  {
    "Code": "REF030000",
    "Literal": "REFERENCE / Consumer Guides"
  },
  {
    "Code": "REF007000",
    "Literal": "REFERENCE / Curiosities & Wonders"
  },
  {
    "Code": "REF008000",
    "Literal": "REFERENCE / Dictionaries"
  },
  {
    "Code": "REF009000",
    "Literal": "REFERENCE / Directories"
  },
  {
    "Code": "REF010000",
    "Literal": "REFERENCE / Encyclopedias"
  },
  {
    "Code": "REF011000",
    "Literal": "REFERENCE / Etiquette"
  },
  {
    "Code": "REF032000",
    "Literal": "REFERENCE / Event Planning"
  },
  {
    "Code": "REF013000",
    "Literal": "REFERENCE / Genealogy & Heraldry"
  },
  {
    "Code": "REF015000",
    "Literal": "REFERENCE / Personal & Practical Guides"
  },
  {
    "Code": "REF033000",
    "Literal": "REFERENCE / Personal & Private Investigations"
  },
  {
    "Code": "REF018000",
    "Literal": "REFERENCE / Questions & Answers"
  },
  {
    "Code": "REF019000",
    "Literal": "REFERENCE / Quotations"
  },
  {
    "Code": "REF020000",
    "Literal": "REFERENCE / Research"
  },
  {
    "Code": "REF034000",
    "Literal": "REFERENCE / Signs & Symbols"
  },
  {
    "Code": "REF031000",
    "Literal": "REFERENCE / Survival & Emergency Preparedness"
  },
  {
    "Code": "REF022000",
    "Literal": "REFERENCE / Thesauri"
  },
  {
    "Code": "REF023000",
    "Literal": "REFERENCE / Trivia"
  },
  {
    "Code": "REF024000",
    "Literal": "REFERENCE / Weddings"
  },
  {
    "Code": "REF025000",
    "Literal": "REFERENCE / Word Lists"
  },
  {
    "Code": "REF027000",
    "Literal": "REFERENCE / Yearbooks & Annuals"
  },
  {
    "Code": "REL000000",
    "Literal": "RELIGION / General"
  },
  {
    "Code": "REL001000",
    "Literal": "RELIGION / Agnosticism"
  },
  {
    "Code": "REL114000",
    "Literal": "RELIGION / Ancient"
  },
  {
    "Code": "REL072000",
    "Literal": "RELIGION / Antiquities & Archaeology"
  },
  {
    "Code": "REL004000",
    "Literal": "RELIGION / Atheism"
  },
  {
    "Code": "REL005000",
    "Literal": "RELIGION / Baha'i"
  },
  {
    "Code": "REL006020",
    "Literal": "RELIGION / Biblical Biography / General"
  },
  {
    "Code": "REL006030",
    "Literal": "RELIGION / Biblical Biography / Old Testament"
  },
  {
    "Code": "REL006040",
    "Literal": "RELIGION / Biblical Biography / New Testament"
  },
  {
    "Code": "REL006050",
    "Literal": "RELIGION / Biblical Commentary / General"
  },
  {
    "Code": "REL006060",
    "Literal": "RELIGION / Biblical Commentary / Old Testament / General"
  },
  {
    "Code": "REL006750",
    "Literal": "RELIGION / Biblical Commentary / Old Testament / Pentateuch"
  },
  {
    "Code": "REL006760",
    "Literal": "RELIGION / Biblical Commentary / Old Testament / Historical Books"
  },
  {
    "Code": "REL006770",
    "Literal": "RELIGION / Biblical Commentary / Old Testament / Poetry & Wisdom Literature"
  },
  {
    "Code": "REL006780",
    "Literal": "RELIGION / Biblical Commentary / Old Testament / Prophets"
  },
  {
    "Code": "REL006790",
    "Literal": "RELIGION / Biblical Commentary / Old Testament / Apocrypha & Deuterocanonical"
  },
  {
    "Code": "REL006070",
    "Literal": "RELIGION / Biblical Commentary / New Testament / General"
  },
  {
    "Code": "REL006800",
    "Literal": "RELIGION / Biblical Commentary / New Testament / Jesus, the Gospels & Acts"
  },
  {
    "Code": "REL006810",
    "Literal": "RELIGION / Biblical Commentary / New Testament / Paul's Letters"
  },
  {
    "Code": "REL006820",
    "Literal": "RELIGION / Biblical Commentary / New Testament / General Epistles"
  },
  {
    "Code": "REL006830",
    "Literal": "RELIGION / Biblical Commentary / New Testament / Revelation"
  },
  {
    "Code": "REL006080",
    "Literal": "RELIGION / Biblical Criticism & Interpretation / General"
  },
  {
    "Code": "REL006090",
    "Literal": "RELIGION / Biblical Criticism & Interpretation / Old Testament"
  },
  {
    "Code": "REL006100",
    "Literal": "RELIGION / Biblical Criticism & Interpretation / New Testament"
  },
  {
    "Code": "REL006110",
    "Literal": "RELIGION / Biblical Meditations / General"
  },
  {
    "Code": "REL006120",
    "Literal": "RELIGION / Biblical Meditations / Old Testament"
  },
  {
    "Code": "REL006130",
    "Literal": "RELIGION / Biblical Meditations / New Testament"
  },
  {
    "Code": "REL006160",
    "Literal": "RELIGION / Biblical Reference / General"
  },
  {
    "Code": "REL006650",
    "Literal": "RELIGION / Biblical Reference / Atlases"
  },
  {
    "Code": "REL006660",
    "Literal": "RELIGION / Biblical Reference / Concordances"
  },
  {
    "Code": "REL006670",
    "Literal": "RELIGION / Biblical Reference / Dictionaries & Encyclopedias"
  },
  {
    "Code": "REL006680",
    "Literal": "RELIGION / Biblical Reference / Handbooks"
  },
  {
    "Code": "REL006410",
    "Literal": "RELIGION / Biblical Reference / Language Study"
  },
  {
    "Code": "REL006150",
    "Literal": "RELIGION / Biblical Reference / Quotations"
  },
  {
    "Code": "REL006000",
    "Literal": "RELIGION / Biblical Studies / General"
  },
  {
    "Code": "REL006700",
    "Literal": "RELIGION / Biblical Studies / Bible Study Guides"
  },
  {
    "Code": "REL006400",
    "Literal": "RELIGION / Biblical Studies / Exegesis & Hermeneutics"
  },
  {
    "Code": "REL006630",
    "Literal": "RELIGION / Biblical Studies / History & Culture"
  },
  {
    "Code": "REL006210",
    "Literal": "RELIGION / Biblical Studies / Old Testament / General"
  },
  {
    "Code": "REL006840",
    "Literal": "RELIGION / Biblical Studies / Old Testament / Pentateuch"
  },
  {
    "Code": "REL006850",
    "Literal": "RELIGION / Biblical Studies / Old Testament / Historical Books"
  },
  {
    "Code": "REL006740",
    "Literal": "RELIGION / Biblical Studies / Old Testament / Poetry & Wisdom Literature"
  },
  {
    "Code": "REL006730",
    "Literal": "RELIGION / Biblical Studies / Old Testament / Prophets"
  },
  {
    "Code": "REL006220",
    "Literal": "RELIGION / Biblical Studies / New Testament / General"
  },
  {
    "Code": "REL006710",
    "Literal": "RELIGION / Biblical Studies / New Testament / Jesus, the Gospels & Acts"
  },
  {
    "Code": "REL006720",
    "Literal": "RELIGION / Biblical Studies / New Testament / Paul's Letters"
  },
  {
    "Code": "REL006860",
    "Literal": "RELIGION / Biblical Studies / New Testament / General Epistles"
  },
  {
    "Code": "REL006870",
    "Literal": "RELIGION / Biblical Studies / New Testament / Revelation"
  },
  {
    "Code": "REL006140",
    "Literal": "RELIGION / Biblical Studies / Prophecy"
  },
  {
    "Code": "REL115000",
    "Literal": "RELIGION / Blasphemy, Heresy & Apostasy"
  },
  {
    "Code": "REL007000",
    "Literal": "RELIGION / Buddhism / General (see also PHILOSOPHY / Buddhist)"
  },
  {
    "Code": "REL007010",
    "Literal": "RELIGION / Buddhism / History"
  },
  {
    "Code": "REL007020",
    "Literal": "RELIGION / Buddhism / Rituals & Practice"
  },
  {
    "Code": "REL007030",
    "Literal": "RELIGION / Buddhism / Sacred Writings"
  },
  {
    "Code": "REL007040",
    "Literal": "RELIGION / Buddhism / Theravada"
  },
  {
    "Code": "REL007050",
    "Literal": "RELIGION / Buddhism / Tibetan"
  },
  {
    "Code": "REL092000",
    "Literal": "RELIGION / Buddhism / Zen (see also PHILOSOPHY / Zen)"
  },
  {
    "Code": "REL108000",
    "Literal": "RELIGION / Christian Church / General"
  },
  {
    "Code": "REL014000",
    "Literal": "RELIGION / Christian Church / Administration"
  },
  {
    "Code": "REL008000",
    "Literal": "RELIGION / Christian Church / Canon & Ecclesiastical Law"
  },
  {
    "Code": "REL108010",
    "Literal": "RELIGION / Christian Church / Growth"
  },
  {
    "Code": "REL108020",
    "Literal": "RELIGION / Christian Church / History"
  },
  {
    "Code": "REL011000",
    "Literal": "RELIGION / Christian Education / General"
  },
  {
    "Code": "REL095000",
    "Literal": "RELIGION / Christian Education / Adult"
  },
  {
    "Code": "REL091000",
    "Literal": "RELIGION / Christian Education / Children & Youth"
  },
  {
    "Code": "REL012000",
    "Literal": "RELIGION / Christian Living / General"
  },
  {
    "Code": "REL012140",
    "Literal": "RELIGION / Christian Living / Calling & Vocation"
  },
  {
    "Code": "REL012010",
    "Literal": "RELIGION / Christian Living / Death, Grief, Bereavement"
  },
  {
    "Code": "REL012020",
    "Literal": "RELIGION / Christian Living / Devotional"
  },
  {
    "Code": "REL012150",
    "Literal": "RELIGION / Christian Living / Devotional Journal"
  },
  {
    "Code": "REL012030",
    "Literal": "RELIGION / Christian Living / Family & Relationships"
  },
  {
    "Code": "REL012040",
    "Literal": "RELIGION / Christian Living / Inspirational"
  },
  {
    "Code": "REL108030",
    "Literal": "RELIGION / Christian Living / Leadership & Mentoring"
  },
  {
    "Code": "REL012050",
    "Literal": "RELIGION / Christian Living / Love & Marriage"
  },
  {
    "Code": "REL012060",
    "Literal": "RELIGION / Christian Living / Men's Interests"
  },
  {
    "Code": "REL012160",
    "Literal": "RELIGION / Christian Living / Parenting"
  },
  {
    "Code": "REL012070",
    "Literal": "RELIGION / Christian Living / Personal Growth"
  },
  {
    "Code": "REL012170",
    "Literal": "RELIGION / Christian Living / Personal Memoirs"
  },
  {
    "Code": "REL012080",
    "Literal": "RELIGION / Christian Living / Prayer"
  },
  {
    "Code": "REL012090",
    "Literal": "RELIGION / Christian Living / Professional Growth"
  },
  {
    "Code": "REL012110",
    "Literal": "RELIGION / Christian Living / Social Issues"
  },
  {
    "Code": "REL012120",
    "Literal": "RELIGION / Christian Living / Spiritual Growth"
  },
  {
    "Code": "REL099000",
    "Literal": "RELIGION / Christian Living / Spiritual Warfare"
  },
  {
    "Code": "REL063000",
    "Literal": "RELIGION / Christian Living / Stewardship & Giving"
  },
  {
    "Code": "REL012130",
    "Literal": "RELIGION / Christian Living / Women's Interests"
  },
  {
    "Code": "REL109000",
    "Literal": "RELIGION / Christian Ministry / General"
  },
  {
    "Code": "REL109010",
    "Literal": "RELIGION / Christian Ministry / Adult"
  },
  {
    "Code": "REL109020",
    "Literal": "RELIGION / Christian Ministry / Children"
  },
  {
    "Code": "REL050000",
    "Literal": "RELIGION / Christian Ministry / Counseling & Recovery"
  },
  {
    "Code": "REL023000",
    "Literal": "RELIGION / Christian Ministry / Discipleship"
  },
  {
    "Code": "REL030000",
    "Literal": "RELIGION / Christian Ministry / Evangelism"
  },
  {
    "Code": "REL045000",
    "Literal": "RELIGION / Christian Ministry / Missions"
  },
  {
    "Code": "REL074000",
    "Literal": "RELIGION / Christian Ministry / Pastoral Resources"
  },
  {
    "Code": "REL080000",
    "Literal": "RELIGION / Christian Ministry / Preaching"
  },
  {
    "Code": "REL109030",
    "Literal": "RELIGION / Christian Ministry / Youth"
  },
  {
    "Code": "REL055000",
    "Literal": "RELIGION / Christian Rituals & Practice / General"
  },
  {
    "Code": "REL055010",
    "Literal": "RELIGION / Christian Rituals & Practice / Sacraments"
  },
  {
    "Code": "REL055020",
    "Literal": "RELIGION / Christian Rituals & Practice / Worship & Liturgy"
  },
  {
    "Code": "REL067000",
    "Literal": "RELIGION / Christian Theology / General"
  },
  {
    "Code": "REL067010",
    "Literal": "RELIGION / Christian Theology / Angelology & Demonology"
  },
  {
    "Code": "REL067020",
    "Literal": "RELIGION / Christian Theology / Anthropology"
  },
  {
    "Code": "REL067030",
    "Literal": "RELIGION / Christian Theology / Apologetics"
  },
  {
    "Code": "REL067040",
    "Literal": "RELIGION / Christian Theology / Christology"
  },
  {
    "Code": "REL067050",
    "Literal": "RELIGION / Christian Theology / Ecclesiology"
  },
  {
    "Code": "REL067060",
    "Literal": "RELIGION / Christian Theology / Eschatology"
  },
  {
    "Code": "REL067070",
    "Literal": "RELIGION / Christian Theology / Ethics"
  },
  {
    "Code": "REL067080",
    "Literal": "RELIGION / Christian Theology / History"
  },
  {
    "Code": "REL067120",
    "Literal": "RELIGION / Christian Theology / Liberation"
  },
  {
    "Code": "REL104000",
    "Literal": "RELIGION / Christian Theology / Mariology"
  },
  {
    "Code": "REL067090",
    "Literal": "RELIGION / Christian Theology / Pneumatology"
  },
  {
    "Code": "REL067130",
    "Literal": "RELIGION / Christian Theology / Process"
  },
  {
    "Code": "REL067100",
    "Literal": "RELIGION / Christian Theology / Soteriology"
  },
  {
    "Code": "REL067110",
    "Literal": "RELIGION / Christian Theology / Systematic"
  },
  {
    "Code": "REL070000",
    "Literal": "RELIGION / Christianity / General"
  },
  {
    "Code": "REL002000",
    "Literal": "RELIGION / Christianity / Amish"
  },
  {
    "Code": "REL003000",
    "Literal": "RELIGION / Christianity / Anglican"
  },
  {
    "Code": "REL073000",
    "Literal": "RELIGION / Christianity / Baptist"
  },
  {
    "Code": "REL093000",
    "Literal": "RELIGION / Christianity / Calvinist"
  },
  {
    "Code": "REL009000",
    "Literal": "RELIGION / Christianity / Catechisms"
  },
  {
    "Code": "REL010000",
    "Literal": "RELIGION / Christianity / Catholic"
  },
  {
    "Code": "REL083000",
    "Literal": "RELIGION / Christianity / Christian Science"
  },
  {
    "Code": "REL046000",
    "Literal": "RELIGION / Christianity / Church of Jesus Christ of Latter-day Saints (Mormon)"
  },
  {
    "Code": "REL094000",
    "Literal": "RELIGION / Christianity / Denominations"
  },
  {
    "Code": "REL027000",
    "Literal": "RELIGION / Christianity / Episcopalian"
  },
  {
    "Code": "REL015000",
    "Literal": "RELIGION / Christianity / History"
  },
  {
    "Code": "REL096000",
    "Literal": "RELIGION / Christianity / Jehovah's Witnesses"
  },
  {
    "Code": "REL013000",
    "Literal": "RELIGION / Christianity / Literature & the Arts"
  },
  {
    "Code": "REL082000",
    "Literal": "RELIGION / Christianity / Lutheran"
  },
  {
    "Code": "REL043000",
    "Literal": "RELIGION / Christianity / Mennonite"
  },
  {
    "Code": "REL044000",
    "Literal": "RELIGION / Christianity / Methodist"
  },
  {
    "Code": "REL049000",
    "Literal": "RELIGION / Christianity / Orthodox"
  },
  {
    "Code": "REL079000",
    "Literal": "RELIGION / Christianity / Pentecostal & Charismatic"
  },
  {
    "Code": "REL097000",
    "Literal": "RELIGION / Christianity / Presbyterian"
  },
  {
    "Code": "REL053000",
    "Literal": "RELIGION / Christianity / Protestant"
  },
  {
    "Code": "REL088000",
    "Literal": "RELIGION / Christianity / Quaker"
  },
  {
    "Code": "REL110000",
    "Literal": "RELIGION / Christianity / Saints & Sainthood"
  },
  {
    "Code": "REL098000",
    "Literal": "RELIGION / Christianity / Seventh-Day Adventist"
  },
  {
    "Code": "REL059000",
    "Literal": "RELIGION / Christianity / Shaker"
  },
  {
    "Code": "REL111000",
    "Literal": "RELIGION / Christianity / United Church of Christ"
  },
  {
    "Code": "REL081000",
    "Literal": "RELIGION / Clergy"
  },
  {
    "Code": "REL017000",
    "Literal": "RELIGION / Comparative Religion"
  },
  {
    "Code": "REL018000",
    "Literal": "RELIGION / Confucianism"
  },
  {
    "Code": "REL019000",
    "Literal": "RELIGION / Counseling"
  },
  {
    "Code": "REL020000",
    "Literal": "RELIGION / Cults"
  },
  {
    "Code": "REL021000",
    "Literal": "RELIGION / Deism"
  },
  {
    "Code": "REL100000",
    "Literal": "RELIGION / Demonology & Satanism"
  },
  {
    "Code": "REL022000",
    "Literal": "RELIGION / Devotional"
  },
  {
    "Code": "REL024000",
    "Literal": "RELIGION / Eastern"
  },
  {
    "Code": "REL107000",
    "Literal": "RELIGION / Eckankar"
  },
  {
    "Code": "REL025000",
    "Literal": "RELIGION / Ecumenism & Interfaith"
  },
  {
    "Code": "REL026000",
    "Literal": "RELIGION / Education"
  },
  {
    "Code": "REL085000",
    "Literal": "RELIGION / Eschatology"
  },
  {
    "Code": "REL113000",
    "Literal": "RELIGION / Essays"
  },
  {
    "Code": "REL028000",
    "Literal": "RELIGION / Ethics"
  },
  {
    "Code": "REL029000",
    "Literal": "RELIGION / Ethnic & Tribal"
  },
  {
    "Code": "REL077000",
    "Literal": "RELIGION / Faith"
  },
  {
    "Code": "REL078000",
    "Literal": "RELIGION / Fundamentalism"
  },
  {
    "Code": "REL112000",
    "Literal": "RELIGION / Gnosticism"
  },
  {
    "Code": "REL032000",
    "Literal": "RELIGION / Hinduism / General"
  },
  {
    "Code": "REL032010",
    "Literal": "RELIGION / Hinduism / History"
  },
  {
    "Code": "REL032020",
    "Literal": "RELIGION / Hinduism / Rituals & Practice"
  },
  {
    "Code": "REL032030",
    "Literal": "RELIGION / Hinduism / Sacred Writings"
  },
  {
    "Code": "REL032040",
    "Literal": "RELIGION / Hinduism / Theology"
  },
  {
    "Code": "REL033000",
    "Literal": "RELIGION / History"
  },
  {
    "Code": "REL034000",
    "Literal": "RELIGION / Holidays / General"
  },
  {
    "Code": "REL034010",
    "Literal": "RELIGION / Holidays / Christian"
  },
  {
    "Code": "REL034020",
    "Literal": "RELIGION / Holidays / Christmas & Advent"
  },
  {
    "Code": "REL034030",
    "Literal": "RELIGION / Holidays / Easter & Lent"
  },
  {
    "Code": "REL034040",
    "Literal": "RELIGION / Holidays / Jewish"
  },
  {
    "Code": "REL034050",
    "Literal": "RELIGION / Holidays / Other"
  },
  {
    "Code": "REL036000",
    "Literal": "RELIGION / Inspirational"
  },
  {
    "Code": "REL016000",
    "Literal": "RELIGION / Institutions & Organizations"
  },
  {
    "Code": "REL037000",
    "Literal": "RELIGION / Islam / General"
  },
  {
    "Code": "REL037010",
    "Literal": "RELIGION / Islam / History"
  },
  {
    "Code": "REL041000",
    "Literal": "RELIGION / Islam / Koran & Sacred Writings"
  },
  {
    "Code": "REL037020",
    "Literal": "RELIGION / Islam / Law"
  },
  {
    "Code": "REL037030",
    "Literal": "RELIGION / Islam / Rituals & Practice"
  },
  {
    "Code": "REL037040",
    "Literal": "RELIGION / Islam / Shi'a"
  },
  {
    "Code": "REL090000",
    "Literal": "RELIGION / Islam / Sufi"
  },
  {
    "Code": "REL037050",
    "Literal": "RELIGION / Islam / Sunni"
  },
  {
    "Code": "REL037060",
    "Literal": "RELIGION / Islam / Theology"
  },
  {
    "Code": "REL038000",
    "Literal": "RELIGION / Jainism"
  },
  {
    "Code": "REL040000",
    "Literal": "RELIGION / Judaism / General"
  },
  {
    "Code": "REL040050",
    "Literal": "RELIGION / Judaism / Conservative"
  },
  {
    "Code": "REL040030",
    "Literal": "RELIGION / Judaism / History"
  },
  {
    "Code": "REL040060",
    "Literal": "RELIGION / Judaism / Kabbalah & Mysticism"
  },
  {
    "Code": "REL040070",
    "Literal": "RELIGION / Judaism / Orthodox"
  },
  {
    "Code": "REL040080",
    "Literal": "RELIGION / Judaism / Reform"
  },
  {
    "Code": "REL040010",
    "Literal": "RELIGION / Judaism / Rituals & Practice"
  },
  {
    "Code": "REL040040",
    "Literal": "RELIGION / Judaism / Sacred Writings"
  },
  {
    "Code": "REL064000",
    "Literal": "RELIGION / Judaism / Talmud"
  },
  {
    "Code": "REL040090",
    "Literal": "RELIGION / Judaism / Theology"
  },
  {
    "Code": "REL071000",
    "Literal": "RELIGION / Leadership"
  },
  {
    "Code": "REL042000",
    "Literal": "RELIGION / Meditations"
  },
  {
    "Code": "REL101000",
    "Literal": "RELIGION / Messianic Judaism"
  },
  {
    "Code": "REL086000",
    "Literal": "RELIGION / Monasticism"
  },
  {
    "Code": "REL047000",
    "Literal": "RELIGION / Mysticism"
  },
  {
    "Code": "REL117000",
    "Literal": "RELIGION / Paganism & Neo-Paganism"
  },
  {
    "Code": "REL051000",
    "Literal": "RELIGION / Philosophy"
  },
  {
    "Code": "REL087000",
    "Literal": "RELIGION / Prayer"
  },
  {
    "Code": "REL052000",
    "Literal": "RELIGION / Prayerbooks / General"
  },
  {
    "Code": "REL052010",
    "Literal": "RELIGION / Prayerbooks / Christian"
  },
  {
    "Code": "REL052030",
    "Literal": "RELIGION / Prayerbooks / Islamic"
  },
  {
    "Code": "REL052020",
    "Literal": "RELIGION / Prayerbooks / Jewish"
  },
  {
    "Code": "REL075000",
    "Literal": "RELIGION / Psychology of Religion"
  },
  {
    "Code": "REL054000",
    "Literal": "RELIGION / Reference"
  },
  {
    "Code": "REL106000",
    "Literal": "RELIGION / Religion & Science"
  },
  {
    "Code": "REL084000",
    "Literal": "RELIGION / Religion, Politics & State"
  },
  {
    "Code": "REL116000",
    "Literal": "RELIGION / Religious Intolerance, Persecution & Conflict"
  },
  {
    "Code": "REL089000",
    "Literal": "RELIGION / Scientology"
  },
  {
    "Code": "REL058000",
    "Literal": "RELIGION / Sermons / General"
  },
  {
    "Code": "REL058010",
    "Literal": "RELIGION / Sermons / Christian"
  },
  {
    "Code": "REL058020",
    "Literal": "RELIGION / Sermons / Jewish"
  },
  {
    "Code": "REL105000",
    "Literal": "RELIGION / Sexuality & Gender Studies"
  },
  {
    "Code": "REL060000",
    "Literal": "RELIGION / Shintoism"
  },
  {
    "Code": "REL061000",
    "Literal": "RELIGION / Sikhism"
  },
  {
    "Code": "REL062000",
    "Literal": "RELIGION / Spirituality"
  },
  {
    "Code": "REL065000",
    "Literal": "RELIGION / Taoism (see also PHILOSOPHY / Taoist)"
  },
  {
    "Code": "REL066000",
    "Literal": "RELIGION / Theism"
  },
  {
    "Code": "REL102000",
    "Literal": "RELIGION / Theology"
  },
  {
    "Code": "REL068000",
    "Literal": "RELIGION / Theosophy"
  },
  {
    "Code": "REL103000",
    "Literal": "RELIGION / Unitarian Universalism"
  },
  {
    "Code": "REL118000",
    "Literal": "RELIGION / Wicca (see also BODY, MIND & SPIRIT / Witchcraft)"
  },
  {
    "Code": "REL069000",
    "Literal": "RELIGION / Zoroastrianism"
  },
  {
    "Code": "SCI000000",
    "Literal": "SCIENCE / General"
  },
  {
    "Code": "SCI001000",
    "Literal": "SCIENCE / Acoustics & Sound"
  },
  {
    "Code": "SCI003000",
    "Literal": "SCIENCE / Applied Sciences"
  },
  {
    "Code": "SCI004000",
    "Literal": "SCIENCE / Astronomy"
  },
  {
    "Code": "SCI010000",
    "Literal": "SCIENCE / Biotechnology"
  },
  {
    "Code": "SCI012000",
    "Literal": "SCIENCE / Chaotic Behavior in Systems"
  },
  {
    "Code": "SCI013000",
    "Literal": "SCIENCE / Chemistry / General"
  },
  {
    "Code": "SCI013010",
    "Literal": "SCIENCE / Chemistry / Analytic"
  },
  {
    "Code": "SCI013020",
    "Literal": "SCIENCE / Chemistry / Clinical"
  },
  {
    "Code": "SCI013070",
    "Literal": "SCIENCE / Chemistry / Computational & Molecular Modeling"
  },
  {
    "Code": "SCI013100",
    "Literal": "SCIENCE / Chemistry / Electrochemistry"
  },
  {
    "Code": "SCI013080",
    "Literal": "SCIENCE / Chemistry / Environmental (see also Environmental Science)"
  },
  {
    "Code": "SCI013060",
    "Literal": "SCIENCE / Chemistry / Industrial & Technical"
  },
  {
    "Code": "SCI013030",
    "Literal": "SCIENCE / Chemistry / Inorganic"
  },
  {
    "Code": "SCI013040",
    "Literal": "SCIENCE / Chemistry / Organic"
  },
  {
    "Code": "SCI013050",
    "Literal": "SCIENCE / Chemistry / Physical & Theoretical"
  },
  {
    "Code": "SCI013090",
    "Literal": "SCIENCE / Chemistry / Toxicology"
  },
  {
    "Code": "SCI090000",
    "Literal": "SCIENCE / Cognitive Science"
  },
  {
    "Code": "SCI015000",
    "Literal": "SCIENCE / Cosmology"
  },
  {
    "Code": "SCI019000",
    "Literal": "SCIENCE / Earth Sciences / General"
  },
  {
    "Code": "SCI030000",
    "Literal": "SCIENCE / Earth Sciences / Geography"
  },
  {
    "Code": "SCI031000",
    "Literal": "SCIENCE / Earth Sciences / Geology"
  },
  {
    "Code": "SCI081000",
    "Literal": "SCIENCE / Earth Sciences / Hydrology"
  },
  {
    "Code": "SCI083000",
    "Literal": "SCIENCE / Earth Sciences / Limnology"
  },
  {
    "Code": "SCI042000",
    "Literal": "SCIENCE / Earth Sciences / Meteorology & Climatology"
  },
  {
    "Code": "SCI048000",
    "Literal": "SCIENCE / Earth Sciences / Mineralogy"
  },
  {
    "Code": "SCI052000",
    "Literal": "SCIENCE / Earth Sciences / Oceanography"
  },
  {
    "Code": "SCI091000",
    "Literal": "SCIENCE / Earth Sciences / Sedimentology & Stratigraphy"
  },
  {
    "Code": "SCI082000",
    "Literal": "SCIENCE / Earth Sciences / Seismology & Volcanism"
  },
  {
    "Code": "SCI023000",
    "Literal": "SCIENCE / Electron Microscopes & Microscopy"
  },
  {
    "Code": "SCI024000",
    "Literal": "SCIENCE / Energy"
  },
  {
    "Code": "SCI026000",
    "Literal": "SCIENCE / Environmental Science (see also Chemistry / Environmental)"
  },
  {
    "Code": "SCI080000",
    "Literal": "SCIENCE / Essays"
  },
  {
    "Code": "SCI101000",
    "Literal": "SCIENCE / Ethics"
  },
  {
    "Code": "SCI028000",
    "Literal": "SCIENCE / Experiments & Projects"
  },
  {
    "Code": "SCI092000",
    "Literal": "SCIENCE / Global Warming & Climate Change"
  },
  {
    "Code": "SCI033000",
    "Literal": "SCIENCE / Gravity"
  },
  {
    "Code": "SCI034000",
    "Literal": "SCIENCE / History"
  },
  {
    "Code": "SCI093000",
    "Literal": "SCIENCE / Laboratory Techniques"
  },
  {
    "Code": "SCI086000",
    "Literal": "SCIENCE / Life Sciences / General"
  },
  {
    "Code": "SCI056000",
    "Literal": "SCIENCE / Life Sciences / Anatomy & Physiology (see also Life Sciences / Human Anatomy & Physiology)"
  },
  {
    "Code": "SCI006000",
    "Literal": "SCIENCE / Life Sciences / Bacteriology"
  },
  {
    "Code": "SCI007000",
    "Literal": "SCIENCE / Life Sciences / Biochemistry"
  },
  {
    "Code": "SCI088000",
    "Literal": "SCIENCE / Life Sciences / Biological Diversity"
  },
  {
    "Code": "SCI008000",
    "Literal": "SCIENCE / Life Sciences / Biology"
  },
  {
    "Code": "SCI009000",
    "Literal": "SCIENCE / Life Sciences / Biophysics"
  },
  {
    "Code": "SCI011000",
    "Literal": "SCIENCE / Life Sciences / Botany"
  },
  {
    "Code": "SCI017000",
    "Literal": "SCIENCE / Life Sciences / Cell Biology"
  },
  {
    "Code": "SCI072000",
    "Literal": "SCIENCE / Life Sciences / Developmental Biology"
  },
  {
    "Code": "SCI020000",
    "Literal": "SCIENCE / Life Sciences / Ecology"
  },
  {
    "Code": "SCI027000",
    "Literal": "SCIENCE / Life Sciences / Evolution"
  },
  {
    "Code": "SCI029000",
    "Literal": "SCIENCE / Life Sciences / Genetics & Genomics"
  },
  {
    "Code": "SCI073000",
    "Literal": "SCIENCE / Life Sciences / Horticulture"
  },
  {
    "Code": "SCI036000",
    "Literal": "SCIENCE / Life Sciences / Human Anatomy & Physiology"
  },
  {
    "Code": "SCI039000",
    "Literal": "SCIENCE / Life Sciences / Marine Biology"
  },
  {
    "Code": "SCI045000",
    "Literal": "SCIENCE / Life Sciences / Microbiology"
  },
  {
    "Code": "SCI049000",
    "Literal": "SCIENCE / Life Sciences / Molecular Biology"
  },
  {
    "Code": "SCI094000",
    "Literal": "SCIENCE / Life Sciences / Mycology"
  },
  {
    "Code": "SCI089000",
    "Literal": "SCIENCE / Life Sciences / Neuroscience"
  },
  {
    "Code": "SCI087000",
    "Literal": "SCIENCE / Life Sciences / Taxonomy"
  },
  {
    "Code": "SCI099000",
    "Literal": "SCIENCE / Life Sciences / Virology"
  },
  {
    "Code": "SCI070000",
    "Literal": "SCIENCE / Life Sciences / Zoology / General"
  },
  {
    "Code": "SCI025000",
    "Literal": "SCIENCE / Life Sciences / Zoology / Entomology"
  },
  {
    "Code": "SCI070060",
    "Literal": "SCIENCE / Life Sciences / Zoology / Ethology (Animal Behavior)"
  },
  {
    "Code": "SCI070010",
    "Literal": "SCIENCE / Life Sciences / Zoology / Ichthyology & Herpetology"
  },
  {
    "Code": "SCI070020",
    "Literal": "SCIENCE / Life Sciences / Zoology / Invertebrates"
  },
  {
    "Code": "SCI070030",
    "Literal": "SCIENCE / Life Sciences / Zoology / Mammals"
  },
  {
    "Code": "SCI070040",
    "Literal": "SCIENCE / Life Sciences / Zoology / Ornithology"
  },
  {
    "Code": "SCI070050",
    "Literal": "SCIENCE / Life Sciences / Zoology / Primatology"
  },
  {
    "Code": "SCI041000",
    "Literal": "SCIENCE / Mechanics / General"
  },
  {
    "Code": "SCI084000",
    "Literal": "SCIENCE / Mechanics / Aerodynamics"
  },
  {
    "Code": "SCI018000",
    "Literal": "SCIENCE / Mechanics / Dynamics"
  },
  {
    "Code": "SCI085000",
    "Literal": "SCIENCE / Mechanics / Fluids"
  },
  {
    "Code": "SCI095000",
    "Literal": "SCIENCE / Mechanics / Hydrodynamics"
  },
  {
    "Code": "SCI096000",
    "Literal": "SCIENCE / Mechanics / Solids"
  },
  {
    "Code": "SCI079000",
    "Literal": "SCIENCE / Mechanics / Statics"
  },
  {
    "Code": "SCI065000",
    "Literal": "SCIENCE / Mechanics / Thermodynamics"
  },
  {
    "Code": "SCI047000",
    "Literal": "SCIENCE / Microscopes & Microscopy"
  },
  {
    "Code": "SCI050000",
    "Literal": "SCIENCE / Nanoscience"
  },
  {
    "Code": "SCI100000",
    "Literal": "SCIENCE / Natural History"
  },
  {
    "Code": "SCI054000",
    "Literal": "SCIENCE / Paleontology"
  },
  {
    "Code": "SCI075000",
    "Literal": "SCIENCE / Philosophy & Social Aspects"
  },
  {
    "Code": "SCI055000",
    "Literal": "SCIENCE / Physics / General"
  },
  {
    "Code": "SCI005000",
    "Literal": "SCIENCE / Physics / Astrophysics"
  },
  {
    "Code": "SCI074000",
    "Literal": "SCIENCE / Physics / Atomic & Molecular"
  },
  {
    "Code": "SCI077000",
    "Literal": "SCIENCE / Physics / Condensed Matter"
  },
  {
    "Code": "SCI016000",
    "Literal": "SCIENCE / Physics / Crystallography"
  },
  {
    "Code": "SCI021000",
    "Literal": "SCIENCE / Physics / Electricity"
  },
  {
    "Code": "SCI022000",
    "Literal": "SCIENCE / Physics / Electromagnetism"
  },
  {
    "Code": "SCI032000",
    "Literal": "SCIENCE / Physics / Geophysics"
  },
  {
    "Code": "SCI038000",
    "Literal": "SCIENCE / Physics / Magnetism"
  },
  {
    "Code": "SCI040000",
    "Literal": "SCIENCE / Physics / Mathematical & Computational"
  },
  {
    "Code": "SCI051000",
    "Literal": "SCIENCE / Physics / Nuclear"
  },
  {
    "Code": "SCI053000",
    "Literal": "SCIENCE / Physics / Optics & Light"
  },
  {
    "Code": "SCI097000",
    "Literal": "SCIENCE / Physics / Polymer"
  },
  {
    "Code": "SCI057000",
    "Literal": "SCIENCE / Physics / Quantum Theory"
  },
  {
    "Code": "SCI061000",
    "Literal": "SCIENCE / Physics / Relativity"
  },
  {
    "Code": "SCI058000",
    "Literal": "SCIENCE / Radiation"
  },
  {
    "Code": "SCI059000",
    "Literal": "SCIENCE / Radiography"
  },
  {
    "Code": "SCI060000",
    "Literal": "SCIENCE / Reference"
  },
  {
    "Code": "SCI043000",
    "Literal": "SCIENCE / Research & Methodology"
  },
  {
    "Code": "SCI076000",
    "Literal": "SCIENCE / Scientific Instruments"
  },
  {
    "Code": "SCI098000",
    "Literal": "SCIENCE / Space Science"
  },
  {
    "Code": "SCI078000",
    "Literal": "SCIENCE / Spectroscopy & Spectrum Analysis"
  },
  {
    "Code": "SCI063000",
    "Literal": "SCIENCE / Study & Teaching"
  },
  {
    "Code": "SCI064000",
    "Literal": "SCIENCE / System Theory"
  },
  {
    "Code": "SCI066000",
    "Literal": "SCIENCE / Time"
  },
  {
    "Code": "SCI067000",
    "Literal": "SCIENCE / Waves & Wave Mechanics"
  },
  {
    "Code": "SCI068000",
    "Literal": "SCIENCE / Weights & Measures"
  },
  {
    "Code": "SEL000000",
    "Literal": "SELF-HELP / General"
  },
  {
    "Code": "SEL001000",
    "Literal": "SELF-HELP / Abuse"
  },
  {
    "Code": "SEL003000",
    "Literal": "SELF-HELP / Adult Children of Substance Abusers"
  },
  {
    "Code": "SEL004000",
    "Literal": "SELF-HELP / Affirmations"
  },
  {
    "Code": "SEL005000",
    "Literal": "SELF-HELP / Aging"
  },
  {
    "Code": "SEL036000",
    "Literal": "SELF-HELP / Anxieties & Phobias"
  },
  {
    "Code": "SEL008000",
    "Literal": "SELF-HELP / Codependency"
  },
  {
    "Code": "SEL040000",
    "Literal": "SELF-HELP / Communication & Social Skills"
  },
  {
    "Code": "SEL041000",
    "Literal": "SELF-HELP / Compulsive Behavior / General"
  },
  {
    "Code": "SEL041010",
    "Literal": "SELF-HELP / Compulsive Behavior / Gambling"
  },
  {
    "Code": "SEL041020",
    "Literal": "SELF-HELP / Compulsive Behavior / Hoarding"
  },
  {
    "Code": "SEL041030",
    "Literal": "SELF-HELP / Compulsive Behavior / Obsessive Compulsive Disorder (OCD)"
  },
  {
    "Code": "SEL041040",
    "Literal": "SELF-HELP / Compulsive Behavior / Sex & Pornography Addiction"
  },
  {
    "Code": "SEL009000",
    "Literal": "SELF-HELP / Creativity"
  },
  {
    "Code": "SEL010000",
    "Literal": "SELF-HELP / Death, Grief, Bereavement"
  },
  {
    "Code": "SEL012000",
    "Literal": "SELF-HELP / Dreams"
  },
  {
    "Code": "SEL014000",
    "Literal": "SELF-HELP / Eating Disorders & Body Image"
  },
  {
    "Code": "SEL042000",
    "Literal": "SELF-HELP / Emotions"
  },
  {
    "Code": "SEL038000",
    "Literal": "SELF-HELP / Fashion & Style"
  },
  {
    "Code": "SEL046000",
    "Literal": "SELF-HELP / Gender & Sexuality"
  },
  {
    "Code": "SEL039000",
    "Literal": "SELF-HELP / Green Lifestyle"
  },
  {
    "Code": "SEL015000",
    "Literal": "SELF-HELP / Handwriting Analysis"
  },
  {
    "Code": "SEL045000",
    "Literal": "SELF-HELP / Journaling"
  },
  {
    "Code": "SEL019000",
    "Literal": "SELF-HELP / Meditations"
  },
  {
    "Code": "SEL020000",
    "Literal": "SELF-HELP / Mood Disorders / General"
  },
  {
    "Code": "SEL020010",
    "Literal": "SELF-HELP / Mood Disorders / Bipolar Disorder"
  },
  {
    "Code": "SEL011000",
    "Literal": "SELF-HELP / Mood Disorders / Depression"
  },
  {
    "Code": "SEL021000",
    "Literal": "SELF-HELP / Motivational & Inspirational"
  },
  {
    "Code": "SEL037000",
    "Literal": "SELF-HELP / Neuro-Linguistic Programming (NLP)"
  },
  {
    "Code": "SEL031000",
    "Literal": "SELF-HELP / Personal Growth / General"
  },
  {
    "Code": "SEL016000",
    "Literal": "SELF-HELP / Personal Growth / Happiness"
  },
  {
    "Code": "SEL030000",
    "Literal": "SELF-HELP / Personal Growth / Memory Improvement"
  },
  {
    "Code": "SEL023000",
    "Literal": "SELF-HELP / Personal Growth / Self-Esteem"
  },
  {
    "Code": "SEL027000",
    "Literal": "SELF-HELP / Personal Growth / Success"
  },
  {
    "Code": "SEL043000",
    "Literal": "SELF-HELP / Post-Traumatic Stress Disorder (PTSD)"
  },
  {
    "Code": "SEL017000",
    "Literal": "SELF-HELP / Self-Hypnosis"
  },
  {
    "Code": "SEL044000",
    "Literal": "SELF-HELP / Self-Management / General"
  },
  {
    "Code": "SEL033000",
    "Literal": "SELF-HELP / Self-Management / Anger Management (see also FAMILY & RELATIONSHIPS / Anger)"
  },
  {
    "Code": "SEL024000",
    "Literal": "SELF-HELP / Self-Management / Stress Management"
  },
  {
    "Code": "SEL035000",
    "Literal": "SELF-HELP / Self-Management / Time Management"
  },
  {
    "Code": "SEL034000",
    "Literal": "SELF-HELP / Sexual Instruction"
  },
  {
    "Code": "SEL032000",
    "Literal": "SELF-HELP / Spiritual"
  },
  {
    "Code": "SEL026000",
    "Literal": "SELF-HELP / Substance Abuse & Addictions / General"
  },
  {
    "Code": "SEL006000",
    "Literal": "SELF-HELP / Substance Abuse & Addictions / Alcohol"
  },
  {
    "Code": "SEL013000",
    "Literal": "SELF-HELP / Substance Abuse & Addictions / Drugs"
  },
  {
    "Code": "SEL026010",
    "Literal": "SELF-HELP / Substance Abuse & Addictions / Tobacco"
  },
  {
    "Code": "SEL029000",
    "Literal": "SELF-HELP / Twelve-Step Programs"
  },
  {
    "Code": "SOC000000",
    "Literal": "SOCIAL SCIENCE / General"
  },
  {
    "Code": "SOC046000",
    "Literal": "SOCIAL SCIENCE / Abortion & Birth Control"
  },
  {
    "Code": "SOC055000",
    "Literal": "SOCIAL SCIENCE / Agriculture & Food (see also POLITICAL SCIENCE / Public Policy / Agriculture & Food Policy)"
  },
  {
    "Code": "SOC002000",
    "Literal": "SOCIAL SCIENCE / Anthropology / General"
  },
  {
    "Code": "SOC002010",
    "Literal": "SOCIAL SCIENCE / Anthropology / Cultural & Social"
  },
  {
    "Code": "SOC002020",
    "Literal": "SOCIAL SCIENCE / Anthropology / Physical"
  },
  {
    "Code": "SOC003000",
    "Literal": "SOCIAL SCIENCE / Archaeology"
  },
  {
    "Code": "SOC056000",
    "Literal": "SOCIAL SCIENCE / Black Studies (Global)"
  },
  {
    "Code": "SOC061000",
    "Literal": "SOCIAL SCIENCE / Body Language & Nonverbal Communication"
  },
  {
    "Code": "SOC067000",
    "Literal": "SOCIAL SCIENCE / Capital Punishment"
  },
  {
    "Code": "SOC047000",
    "Literal": "SOCIAL SCIENCE / Children's Studies"
  },
  {
    "Code": "SOC058000",
    "Literal": "SOCIAL SCIENCE / Conspiracy Theories"
  },
  {
    "Code": "SOC004000",
    "Literal": "SOCIAL SCIENCE / Criminology"
  },
  {
    "Code": "SOC005000",
    "Literal": "SOCIAL SCIENCE / Customs & Traditions"
  },
  {
    "Code": "SOC036000",
    "Literal": "SOCIAL SCIENCE / Death & Dying"
  },
  {
    "Code": "SOC006000",
    "Literal": "SOCIAL SCIENCE / Demography"
  },
  {
    "Code": "SOC042000",
    "Literal": "SOCIAL SCIENCE / Developing & Emerging Countries"
  },
  {
    "Code": "SOC040000",
    "Literal": "SOCIAL SCIENCE / Disasters & Disaster Relief"
  },
  {
    "Code": "SOC031000",
    "Literal": "SOCIAL SCIENCE / Discrimination & Race Relations"
  },
  {
    "Code": "SOC057000",
    "Literal": "SOCIAL SCIENCE / Disease & Health Issues"
  },
  {
    "Code": "SOC007000",
    "Literal": "SOCIAL SCIENCE / Emigration & Immigration"
  },
  {
    "Code": "SOC041000",
    "Literal": "SOCIAL SCIENCE / Essays"
  },
  {
    "Code": "SOC008000",
    "Literal": "SOCIAL SCIENCE / Ethnic Studies / General"
  },
  {
    "Code": "SOC001000",
    "Literal": "SOCIAL SCIENCE / Ethnic Studies / African American Studies"
  },
  {
    "Code": "SOC043000",
    "Literal": "SOCIAL SCIENCE / Ethnic Studies / Asian American Studies"
  },
  {
    "Code": "SOC044000",
    "Literal": "SOCIAL SCIENCE / Ethnic Studies / Hispanic American Studies"
  },
  {
    "Code": "SOC021000",
    "Literal": "SOCIAL SCIENCE / Ethnic Studies / Native American Studies"
  },
  {
    "Code": "SOC010000",
    "Literal": "SOCIAL SCIENCE / Feminism & Feminist Theory"
  },
  {
    "Code": "SOC011000",
    "Literal": "SOCIAL SCIENCE / Folklore & Mythology"
  },
  {
    "Code": "SOC038000",
    "Literal": "SOCIAL SCIENCE / Freemasonry & Secret Societies"
  },
  {
    "Code": "SOC037000",
    "Literal": "SOCIAL SCIENCE / Future Studies"
  },
  {
    "Code": "SOC032000",
    "Literal": "SOCIAL SCIENCE / Gender Studies"
  },
  {
    "Code": "SOC013000",
    "Literal": "SOCIAL SCIENCE / Gerontology"
  },
  {
    "Code": "SOC014000",
    "Literal": "SOCIAL SCIENCE / Holidays (non-religious)"
  },
  {
    "Code": "SOC015000",
    "Literal": "SOCIAL SCIENCE / Human Geography"
  },
  {
    "Code": "SOC016000",
    "Literal": "SOCIAL SCIENCE / Human Services"
  },
  {
    "Code": "SOC065000",
    "Literal": "SOCIAL SCIENCE / Human Sexuality (see also PSYCHOLOGY / Human Sexuality)"
  },
  {
    "Code": "SOC062000",
    "Literal": "SOCIAL SCIENCE / Indigenous Studies"
  },
  {
    "Code": "SOC048000",
    "Literal": "SOCIAL SCIENCE / Islamic Studies"
  },
  {
    "Code": "SOC049000",
    "Literal": "SOCIAL SCIENCE / Jewish Studies"
  },
  {
    "Code": "SOC064000",
    "Literal": "SOCIAL SCIENCE / LGBT Studies / General"
  },
  {
    "Code": "SOC064010",
    "Literal": "SOCIAL SCIENCE / LGBT Studies / Bisexual Studies"
  },
  {
    "Code": "SOC012000",
    "Literal": "SOCIAL SCIENCE / LGBT Studies / Gay Studies"
  },
  {
    "Code": "SOC017000",
    "Literal": "SOCIAL SCIENCE / LGBT Studies / Lesbian Studies"
  },
  {
    "Code": "SOC064020",
    "Literal": "SOCIAL SCIENCE / LGBT Studies / Transgender Studies"
  },
  {
    "Code": "SOC052000",
    "Literal": "SOCIAL SCIENCE / Media Studies"
  },
  {
    "Code": "SOC018000",
    "Literal": "SOCIAL SCIENCE / Men's Studies"
  },
  {
    "Code": "SOC019000",
    "Literal": "SOCIAL SCIENCE / Methodology"
  },
  {
    "Code": "SOC020000",
    "Literal": "SOCIAL SCIENCE / Minority Studies"
  },
  {
    "Code": "SOC030000",
    "Literal": "SOCIAL SCIENCE / Penology"
  },
  {
    "Code": "SOC029000",
    "Literal": "SOCIAL SCIENCE / People with Disabilities"
  },
  {
    "Code": "SOC033000",
    "Literal": "SOCIAL SCIENCE / Philanthropy & Charity"
  },
  {
    "Code": "SOC022000",
    "Literal": "SOCIAL SCIENCE / Popular Culture"
  },
  {
    "Code": "SOC034000",
    "Literal": "SOCIAL SCIENCE / Pornography"
  },
  {
    "Code": "SOC045000",
    "Literal": "SOCIAL SCIENCE / Poverty & Homelessness"
  },
  {
    "Code": "SOC063000",
    "Literal": "SOCIAL SCIENCE / Privacy & Surveillance (see also POLITICAL SCIENCE / Privacy & Surveillance)"
  },
  {
    "Code": "SOC059000",
    "Literal": "SOCIAL SCIENCE / Prostitution & Sex Trade"
  },
  {
    "Code": "SOC023000",
    "Literal": "SOCIAL SCIENCE / Reference"
  },
  {
    "Code": "SOC066000",
    "Literal": "SOCIAL SCIENCE / Refugees"
  },
  {
    "Code": "SOC053000",
    "Literal": "SOCIAL SCIENCE / Regional Studies"
  },
  {
    "Code": "SOC024000",
    "Literal": "SOCIAL SCIENCE / Research"
  },
  {
    "Code": "SOC060000",
    "Literal": "SOCIAL SCIENCE / Sexual Abuse & Harassment"
  },
  {
    "Code": "SOC054000",
    "Literal": "SOCIAL SCIENCE / Slavery"
  },
  {
    "Code": "SOC050000",
    "Literal": "SOCIAL SCIENCE / Social Classes & Economic Disparity"
  },
  {
    "Code": "SOC025000",
    "Literal": "SOCIAL SCIENCE / Social Work"
  },
  {
    "Code": "SOC026000",
    "Literal": "SOCIAL SCIENCE / Sociology / General"
  },
  {
    "Code": "SOC026010",
    "Literal": "SOCIAL SCIENCE / Sociology / Marriage & Family"
  },
  {
    "Code": "SOC026020",
    "Literal": "SOCIAL SCIENCE / Sociology / Rural"
  },
  {
    "Code": "SOC026040",
    "Literal": "SOCIAL SCIENCE / Sociology / Social Theory"
  },
  {
    "Code": "SOC026030",
    "Literal": "SOCIAL SCIENCE / Sociology / Urban"
  },
  {
    "Code": "SOC039000",
    "Literal": "SOCIAL SCIENCE / Sociology of Religion"
  },
  {
    "Code": "SOC027000",
    "Literal": "SOCIAL SCIENCE / Statistics"
  },
  {
    "Code": "SOC051000",
    "Literal": "SOCIAL SCIENCE / Violence in Society"
  },
  {
    "Code": "SOC035000",
    "Literal": "SOCIAL SCIENCE / Volunteer Work"
  },
  {
    "Code": "SOC028000",
    "Literal": "SOCIAL SCIENCE / Women's Studies"
  },
  {
    "Code": "SPO000000",
    "Literal": "SPORTS & RECREATION / General"
  },
  {
    "Code": "SPO001000",
    "Literal": "SPORTS & RECREATION / Air Sports"
  },
  {
    "Code": "SPO002000",
    "Literal": "SPORTS & RECREATION / Archery"
  },
  {
    "Code": "SPO003000",
    "Literal": "SPORTS & RECREATION / Baseball / General"
  },
  {
    "Code": "SPO003020",
    "Literal": "SPORTS & RECREATION / Baseball / Essays & Writings"
  },
  {
    "Code": "SPO003030",
    "Literal": "SPORTS & RECREATION / Baseball / History"
  },
  {
    "Code": "SPO003040",
    "Literal": "SPORTS & RECREATION / Baseball / Statistics"
  },
  {
    "Code": "SPO004000",
    "Literal": "SPORTS & RECREATION / Basketball"
  },
  {
    "Code": "SPO005000",
    "Literal": "SPORTS & RECREATION / Boating"
  },
  {
    "Code": "SPO006000",
    "Literal": "SPORTS & RECREATION / Bodybuilding & Weight Training"
  },
  {
    "Code": "SPO007000",
    "Literal": "SPORTS & RECREATION / Bowling"
  },
  {
    "Code": "SPO008000",
    "Literal": "SPORTS & RECREATION / Boxing"
  },
  {
    "Code": "SPO068000",
    "Literal": "SPORTS & RECREATION / Business Aspects"
  },
  {
    "Code": "SPO009000",
    "Literal": "SPORTS & RECREATION / Camping"
  },
  {
    "Code": "SPO010000",
    "Literal": "SPORTS & RECREATION / Canoeing"
  },
  {
    "Code": "SPO074000",
    "Literal": "SPORTS & RECREATION / Caving"
  },
  {
    "Code": "SPO070000",
    "Literal": "SPORTS & RECREATION / Cheerleading"
  },
  {
    "Code": "SPO077000",
    "Literal": "SPORTS & RECREATION / Children's & Youth Sports"
  },
  {
    "Code": "SPO061000",
    "Literal": "SPORTS & RECREATION / Coaching / General"
  },
  {
    "Code": "SPO003010",
    "Literal": "SPORTS & RECREATION / Coaching / Baseball"
  },
  {
    "Code": "SPO061010",
    "Literal": "SPORTS & RECREATION / Coaching / Basketball"
  },
  {
    "Code": "SPO061020",
    "Literal": "SPORTS & RECREATION / Coaching / Football"
  },
  {
    "Code": "SPO061030",
    "Literal": "SPORTS & RECREATION / Coaching / Soccer"
  },
  {
    "Code": "SPO054000",
    "Literal": "SPORTS & RECREATION / Cricket"
  },
  {
    "Code": "SPO011000",
    "Literal": "SPORTS & RECREATION / Cycling"
  },
  {
    "Code": "SPO076000",
    "Literal": "SPORTS & RECREATION / Disability Sports"
  },
  {
    "Code": "SPO062000",
    "Literal": "SPORTS & RECREATION / Dog Racing"
  },
  {
    "Code": "SPO057000",
    "Literal": "SPORTS & RECREATION / Equestrian"
  },
  {
    "Code": "SPO063000",
    "Literal": "SPORTS & RECREATION / Equipment & Supplies"
  },
  {
    "Code": "SPO012000",
    "Literal": "SPORTS & RECREATION / Essays"
  },
  {
    "Code": "SPO064000",
    "Literal": "SPORTS & RECREATION / Extreme Sports"
  },
  {
    "Code": "SPO071000",
    "Literal": "SPORTS & RECREATION / Fencing"
  },
  {
    "Code": "SPO073000",
    "Literal": "SPORTS & RECREATION / Field Hockey"
  },
  {
    "Code": "SPO014000",
    "Literal": "SPORTS & RECREATION / Fishing"
  },
  {
    "Code": "SPO015000",
    "Literal": "SPORTS & RECREATION / Football"
  },
  {
    "Code": "SPO016000",
    "Literal": "SPORTS & RECREATION / Golf"
  },
  {
    "Code": "SPO017000",
    "Literal": "SPORTS & RECREATION / Gymnastics"
  },
  {
    "Code": "SPO075000",
    "Literal": "SPORTS & RECREATION / Health & Safety"
  },
  {
    "Code": "SPO018000",
    "Literal": "SPORTS & RECREATION / Hiking"
  },
  {
    "Code": "SPO019000",
    "Literal": "SPORTS & RECREATION / History"
  },
  {
    "Code": "SPO020000",
    "Literal": "SPORTS & RECREATION / Hockey"
  },
  {
    "Code": "SPO021000",
    "Literal": "SPORTS & RECREATION / Horse Racing"
  },
  {
    "Code": "SPO022000",
    "Literal": "SPORTS & RECREATION / Hunting"
  },
  {
    "Code": "SPO023000",
    "Literal": "SPORTS & RECREATION / Ice & Figure Skating"
  },
  {
    "Code": "SPO024000",
    "Literal": "SPORTS & RECREATION / Juggling"
  },
  {
    "Code": "SPO025000",
    "Literal": "SPORTS & RECREATION / Kayaking"
  },
  {
    "Code": "SPO026000",
    "Literal": "SPORTS & RECREATION / Lacrosse"
  },
  {
    "Code": "SPO027000",
    "Literal": "SPORTS & RECREATION / Martial Arts & Self-Defense"
  },
  {
    "Code": "SPO028000",
    "Literal": "SPORTS & RECREATION / Motor Sports"
  },
  {
    "Code": "SPO029000",
    "Literal": "SPORTS & RECREATION / Mountaineering"
  },
  {
    "Code": "SPO058000",
    "Literal": "SPORTS & RECREATION / Olympics & Paralympics"
  },
  {
    "Code": "SPO030000",
    "Literal": "SPORTS & RECREATION / Outdoor Skills"
  },
  {
    "Code": "SPO055000",
    "Literal": "SPORTS & RECREATION / Polo"
  },
  {
    "Code": "SPO060000",
    "Literal": "SPORTS & RECREATION / Pool, Billiards, Snooker"
  },
  {
    "Code": "SPO031000",
    "Literal": "SPORTS & RECREATION / Racket Sports"
  },
  {
    "Code": "SPO032000",
    "Literal": "SPORTS & RECREATION / Racquetball"
  },
  {
    "Code": "SPO033000",
    "Literal": "SPORTS & RECREATION / Reference"
  },
  {
    "Code": "SPO065000",
    "Literal": "SPORTS & RECREATION / Rodeos"
  },
  {
    "Code": "SPO034000",
    "Literal": "SPORTS & RECREATION / Roller & In-Line Skating"
  },
  {
    "Code": "SPO056000",
    "Literal": "SPORTS & RECREATION / Rugby"
  },
  {
    "Code": "SPO035000",
    "Literal": "SPORTS & RECREATION / Running & Jogging"
  },
  {
    "Code": "SPO036000",
    "Literal": "SPORTS & RECREATION / Sailing"
  },
  {
    "Code": "SPO059000",
    "Literal": "SPORTS & RECREATION / Scuba & Snorkeling"
  },
  {
    "Code": "SPO037000",
    "Literal": "SPORTS & RECREATION / Shooting"
  },
  {
    "Code": "SPO038000",
    "Literal": "SPORTS & RECREATION / Skateboarding"
  },
  {
    "Code": "SPO039000",
    "Literal": "SPORTS & RECREATION / Skiing"
  },
  {
    "Code": "SPO072000",
    "Literal": "SPORTS & RECREATION / Snowboarding"
  },
  {
    "Code": "SPO040000",
    "Literal": "SPORTS & RECREATION / Soccer"
  },
  {
    "Code": "SPO066000",
    "Literal": "SPORTS & RECREATION / Sociology of Sports"
  },
  {
    "Code": "SPO067000",
    "Literal": "SPORTS & RECREATION / Softball"
  },
  {
    "Code": "SPO041000",
    "Literal": "SPORTS & RECREATION / Sports Psychology"
  },
  {
    "Code": "SPO042000",
    "Literal": "SPORTS & RECREATION / Squash"
  },
  {
    "Code": "SPO069000",
    "Literal": "SPORTS & RECREATION / Surfing"
  },
  {
    "Code": "SPO043000",
    "Literal": "SPORTS & RECREATION / Swimming & Diving"
  },
  {
    "Code": "SPO044000",
    "Literal": "SPORTS & RECREATION / Table Tennis"
  },
  {
    "Code": "SPO045000",
    "Literal": "SPORTS & RECREATION / Tennis"
  },
  {
    "Code": "SPO046000",
    "Literal": "SPORTS & RECREATION / Track & Field"
  },
  {
    "Code": "SPO047000",
    "Literal": "SPORTS & RECREATION / Training"
  },
  {
    "Code": "SPO048000",
    "Literal": "SPORTS & RECREATION / Triathlon"
  },
  {
    "Code": "SPO049000",
    "Literal": "SPORTS & RECREATION / Volleyball"
  },
  {
    "Code": "SPO050000",
    "Literal": "SPORTS & RECREATION / Walking"
  },
  {
    "Code": "SPO051000",
    "Literal": "SPORTS & RECREATION / Water Sports"
  },
  {
    "Code": "SPO052000",
    "Literal": "SPORTS & RECREATION / Winter Sports"
  },
  {
    "Code": "SPO053000",
    "Literal": "SPORTS & RECREATION / Wrestling"
  },
  {
    "Code": "STU000000",
    "Literal": "STUDY AIDS / General"
  },
  {
    "Code": "STU001000",
    "Literal": "STUDY AIDS / ACT"
  },
  {
    "Code": "STU002000",
    "Literal": "STUDY AIDS / Advanced Placement"
  },
  {
    "Code": "STU003000",
    "Literal": "STUDY AIDS / Armed Forces (incl. ASVAB)"
  },
  {
    "Code": "STU034000",
    "Literal": "STUDY AIDS / Bar Exam"
  },
  {
    "Code": "STU004000",
    "Literal": "STUDY AIDS / Book Notes (see also JUVENILE NONFICTION / Study Aids / Book Notes or YOUNG ADULT NONFICTION / Study Aids / Book Notes)"
  },
  {
    "Code": "STU006000",
    "Literal": "STUDY AIDS / Citizenship"
  },
  {
    "Code": "STU007000",
    "Literal": "STUDY AIDS / Civil Service"
  },
  {
    "Code": "STU008000",
    "Literal": "STUDY AIDS / CLEP (College-Level Examination Program)"
  },
  {
    "Code": "STU009000",
    "Literal": "STUDY AIDS / College Entrance"
  },
  {
    "Code": "STU010000",
    "Literal": "STUDY AIDS / College Guides"
  },
  {
    "Code": "STU011000",
    "Literal": "STUDY AIDS / CPA (Certified Public Accountant)"
  },
  {
    "Code": "STU028000",
    "Literal": "STUDY AIDS / English Proficiency (incl. TOEFL & IELTS)"
  },
  {
    "Code": "STU031000",
    "Literal": "STUDY AIDS / Financial Aid"
  },
  {
    "Code": "STU013000",
    "Literal": "STUDY AIDS / GMAT (Graduate Management Admission Test)"
  },
  {
    "Code": "STU015000",
    "Literal": "STUDY AIDS / Graduate School Guides"
  },
  {
    "Code": "STU016000",
    "Literal": "STUDY AIDS / GRE (Graduate Record Examination)"
  },
  {
    "Code": "STU025000",
    "Literal": "STUDY AIDS / High School Entrance"
  },
  {
    "Code": "STU012000",
    "Literal": "STUDY AIDS / High School Equivalency (incl. GED & TASC)"
  },
  {
    "Code": "STU017000",
    "Literal": "STUDY AIDS / LSAT (Law School Admission Test)"
  },
  {
    "Code": "STU018000",
    "Literal": "STUDY AIDS / MAT (Miller Analogies Test)"
  },
  {
    "Code": "STU032000",
    "Literal": "STUDY AIDS / MCAT (Medical College Admission Test)"
  },
  {
    "Code": "STU035000",
    "Literal": "STUDY AIDS / NCLEX"
  },
  {
    "Code": "STU021000",
    "Literal": "STUDY AIDS / Professional"
  },
  {
    "Code": "STU033000",
    "Literal": "STUDY AIDS / PSAT & NMSQT (National Merit Scholarship Qualifying Test)"
  },
  {
    "Code": "STU022000",
    "Literal": "STUDY AIDS / Regents"
  },
  {
    "Code": "STU024000",
    "Literal": "STUDY AIDS / SAT"
  },
  {
    "Code": "STU036000",
    "Literal": "STUDY AIDS / Study & Test-Taking Skills"
  },
  {
    "Code": "STU026000",
    "Literal": "STUDY AIDS / Study Guides"
  },
  {
    "Code": "STU019000",
    "Literal": "STUDY AIDS / Teacher Certification (incl. Praxis)"
  },
  {
    "Code": "STU027000",
    "Literal": "STUDY AIDS / Tests (Other)"
  },
  {
    "Code": "STU037000",
    "Literal": "STUDY AIDS / USMLE (United States Medical Licensure Examination)"
  },
  {
    "Code": "STU029000",
    "Literal": "STUDY AIDS / Vocational"
  },
  {
    "Code": "TEC000000",
    "Literal": "TECHNOLOGY & ENGINEERING / General"
  },
  {
    "Code": "TEC001000",
    "Literal": "TECHNOLOGY & ENGINEERING / Acoustics & Sound"
  },
  {
    "Code": "TEC002000",
    "Literal": "TECHNOLOGY & ENGINEERING / Aeronautics & Astronautics"
  },
  {
    "Code": "TEC003000",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / General"
  },
  {
    "Code": "TEC003080",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / Agronomy / General"
  },
  {
    "Code": "TEC003030",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / Agronomy / Crop Science"
  },
  {
    "Code": "TEC003060",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / Agronomy / Soil Science"
  },
  {
    "Code": "TEC003020",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / Animal Husbandry"
  },
  {
    "Code": "TEC003100",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / Beekeeping"
  },
  {
    "Code": "TEC003110",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / Enology & Viticulture"
  },
  {
    "Code": "TEC003040",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / Forestry"
  },
  {
    "Code": "TEC003050",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / Irrigation"
  },
  {
    "Code": "TEC003090",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / Organic"
  },
  {
    "Code": "TEC003070",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / Sustainable Agriculture"
  },
  {
    "Code": "TEC003010",
    "Literal": "TECHNOLOGY & ENGINEERING / Agriculture / Tropical Agriculture"
  },
  {
    "Code": "TEC004000",
    "Literal": "TECHNOLOGY & ENGINEERING / Automation"
  },
  {
    "Code": "TEC009090",
    "Literal": "TECHNOLOGY & ENGINEERING / Automotive"
  },
  {
    "Code": "TEC059000",
    "Literal": "TECHNOLOGY & ENGINEERING / Biomedical"
  },
  {
    "Code": "TEC048000",
    "Literal": "TECHNOLOGY & ENGINEERING / Cartography"
  },
  {
    "Code": "TEC009010",
    "Literal": "TECHNOLOGY & ENGINEERING / Chemical & Biochemical"
  },
  {
    "Code": "TEC009020",
    "Literal": "TECHNOLOGY & ENGINEERING / Civil / General"
  },
  {
    "Code": "TEC009100",
    "Literal": "TECHNOLOGY & ENGINEERING / Civil / Bridges"
  },
  {
    "Code": "TEC009110",
    "Literal": "TECHNOLOGY & ENGINEERING / Civil / Dams & Reservoirs"
  },
  {
    "Code": "TEC009120",
    "Literal": "TECHNOLOGY & ENGINEERING / Civil / Earthquake"
  },
  {
    "Code": "TEC009130",
    "Literal": "TECHNOLOGY & ENGINEERING / Civil / Flood Control"
  },
  {
    "Code": "TEC009140",
    "Literal": "TECHNOLOGY & ENGINEERING / Civil / Highway & Traffic"
  },
  {
    "Code": "TEC009150",
    "Literal": "TECHNOLOGY & ENGINEERING / Civil / Soil & Rock"
  },
  {
    "Code": "TEC009160",
    "Literal": "TECHNOLOGY & ENGINEERING / Civil / Transportation"
  },
  {
    "Code": "TEC005000",
    "Literal": "TECHNOLOGY & ENGINEERING / Construction / General"
  },
  {
    "Code": "TEC005010",
    "Literal": "TECHNOLOGY & ENGINEERING / Construction / Carpentry"
  },
  {
    "Code": "TEC005020",
    "Literal": "TECHNOLOGY & ENGINEERING / Construction / Contracting"
  },
  {
    "Code": "TEC005030",
    "Literal": "TECHNOLOGY & ENGINEERING / Construction / Electrical"
  },
  {
    "Code": "TEC005040",
    "Literal": "TECHNOLOGY & ENGINEERING / Construction / Estimating"
  },
  {
    "Code": "TEC005050",
    "Literal": "TECHNOLOGY & ENGINEERING / Construction / Heating, Ventilation & Air Conditioning"
  },
  {
    "Code": "TEC005060",
    "Literal": "TECHNOLOGY & ENGINEERING / Construction / Masonry"
  },
  {
    "Code": "TEC005070",
    "Literal": "TECHNOLOGY & ENGINEERING / Construction / Plumbing"
  },
  {
    "Code": "TEC005080",
    "Literal": "TECHNOLOGY & ENGINEERING / Construction / Roofing"
  },
  {
    "Code": "TEC006000",
    "Literal": "TECHNOLOGY & ENGINEERING / Drafting & Mechanical Drawing"
  },
  {
    "Code": "TEC007000",
    "Literal": "TECHNOLOGY & ENGINEERING / Electrical"
  },
  {
    "Code": "TEC008000",
    "Literal": "TECHNOLOGY & ENGINEERING / Electronics / General"
  },
  {
    "Code": "TEC008010",
    "Literal": "TECHNOLOGY & ENGINEERING / Electronics / Circuits / General"
  },
  {
    "Code": "TEC008020",
    "Literal": "TECHNOLOGY & ENGINEERING / Electronics / Circuits / Integrated"
  },
  {
    "Code": "TEC008030",
    "Literal": "TECHNOLOGY & ENGINEERING / Electronics / Circuits / Logic"
  },
  {
    "Code": "TEC008050",
    "Literal": "TECHNOLOGY & ENGINEERING / Electronics / Circuits / VLSI & ULSI"
  },
  {
    "Code": "TEC008060",
    "Literal": "TECHNOLOGY & ENGINEERING / Electronics / Digital"
  },
  {
    "Code": "TEC008070",
    "Literal": "TECHNOLOGY & ENGINEERING / Electronics / Microelectronics"
  },
  {
    "Code": "TEC008080",
    "Literal": "TECHNOLOGY & ENGINEERING / Electronics / Optoelectronics"
  },
  {
    "Code": "TEC008090",
    "Literal": "TECHNOLOGY & ENGINEERING / Electronics / Semiconductors"
  },
  {
    "Code": "TEC008100",
    "Literal": "TECHNOLOGY & ENGINEERING / Electronics / Solid State"
  },
  {
    "Code": "TEC008110",
    "Literal": "TECHNOLOGY & ENGINEERING / Electronics / Transistors"
  },
  {
    "Code": "TEC065000",
    "Literal": "TECHNOLOGY & ENGINEERING / Emergency Management"
  },
  {
    "Code": "TEC009000",
    "Literal": "TECHNOLOGY & ENGINEERING / Engineering (General)"
  },
  {
    "Code": "TEC010000",
    "Literal": "TECHNOLOGY & ENGINEERING / Environmental / General"
  },
  {
    "Code": "TEC010010",
    "Literal": "TECHNOLOGY & ENGINEERING / Environmental / Pollution Control"
  },
  {
    "Code": "TEC010020",
    "Literal": "TECHNOLOGY & ENGINEERING / Environmental / Waste Management"
  },
  {
    "Code": "TEC010030",
    "Literal": "TECHNOLOGY & ENGINEERING / Environmental / Water Supply"
  },
  {
    "Code": "TEC011000",
    "Literal": "TECHNOLOGY & ENGINEERING / Fiber Optics"
  },
  {
    "Code": "TEC045000",
    "Literal": "TECHNOLOGY & ENGINEERING / Fire Science"
  },
  {
    "Code": "TEC049000",
    "Literal": "TECHNOLOGY & ENGINEERING / Fisheries & Aquaculture"
  },
  {
    "Code": "TEC012000",
    "Literal": "TECHNOLOGY & ENGINEERING / Food Science / General"
  },
  {
    "Code": "TEC012010",
    "Literal": "TECHNOLOGY & ENGINEERING / Food Science / Chemistry & Biotechnology"
  },
  {
    "Code": "TEC012020",
    "Literal": "TECHNOLOGY & ENGINEERING / Food Science / Food Packaging & Processing"
  },
  {
    "Code": "TEC012030",
    "Literal": "TECHNOLOGY & ENGINEERING / Food Science / Food Safety & Security"
  },
  {
    "Code": "TEC012040",
    "Literal": "TECHNOLOGY & ENGINEERING / Food Science / Food Types"
  },
  {
    "Code": "TEC013000",
    "Literal": "TECHNOLOGY & ENGINEERING / Fracture Mechanics"
  },
  {
    "Code": "TEC056000",
    "Literal": "TECHNOLOGY & ENGINEERING / History"
  },
  {
    "Code": "TEC050000",
    "Literal": "TECHNOLOGY & ENGINEERING / Holography"
  },
  {
    "Code": "TEC014000",
    "Literal": "TECHNOLOGY & ENGINEERING / Hydraulics"
  },
  {
    "Code": "TEC015000",
    "Literal": "TECHNOLOGY & ENGINEERING / Imaging Systems"
  },
  {
    "Code": "TEC016000",
    "Literal": "TECHNOLOGY & ENGINEERING / Industrial Design / General"
  },
  {
    "Code": "TEC016010",
    "Literal": "TECHNOLOGY & ENGINEERING / Industrial Design / Packaging"
  },
  {
    "Code": "TEC016020",
    "Literal": "TECHNOLOGY & ENGINEERING / Industrial Design / Product"
  },
  {
    "Code": "TEC009060",
    "Literal": "TECHNOLOGY & ENGINEERING / Industrial Engineering"
  },
  {
    "Code": "TEC017000",
    "Literal": "TECHNOLOGY & ENGINEERING / Industrial Health & Safety"
  },
  {
    "Code": "TEC018000",
    "Literal": "TECHNOLOGY & ENGINEERING / Industrial Technology"
  },
  {
    "Code": "TEC057000",
    "Literal": "TECHNOLOGY & ENGINEERING / Inventions"
  },
  {
    "Code": "TEC019000",
    "Literal": "TECHNOLOGY & ENGINEERING / Lasers & Photonics"
  },
  {
    "Code": "TEC046000",
    "Literal": "TECHNOLOGY & ENGINEERING / Machinery"
  },
  {
    "Code": "TEC020000",
    "Literal": "TECHNOLOGY & ENGINEERING / Manufacturing"
  },
  {
    "Code": "TEC060000",
    "Literal": "TECHNOLOGY & ENGINEERING / Marine & Naval"
  },
  {
    "Code": "TEC021000",
    "Literal": "TECHNOLOGY & ENGINEERING / Materials Science / General"
  },
  {
    "Code": "TEC021010",
    "Literal": "TECHNOLOGY & ENGINEERING / Materials Science / Ceramics"
  },
  {
    "Code": "TEC021020",
    "Literal": "TECHNOLOGY & ENGINEERING / Materials Science / Electronic Materials"
  },
  {
    "Code": "TEC021030",
    "Literal": "TECHNOLOGY & ENGINEERING / Materials Science / Metals & Alloys"
  },
  {
    "Code": "TEC021040",
    "Literal": "TECHNOLOGY & ENGINEERING / Materials Science / Thin Films, Surfaces & Interfaces"
  },
  {
    "Code": "TEC022000",
    "Literal": "TECHNOLOGY & ENGINEERING / Measurement"
  },
  {
    "Code": "TEC009070",
    "Literal": "TECHNOLOGY & ENGINEERING / Mechanical"
  },
  {
    "Code": "TEC023000",
    "Literal": "TECHNOLOGY & ENGINEERING / Metallurgy"
  },
  {
    "Code": "TEC024000",
    "Literal": "TECHNOLOGY & ENGINEERING / Microwaves"
  },
  {
    "Code": "TEC025000",
    "Literal": "TECHNOLOGY & ENGINEERING / Military Science"
  },
  {
    "Code": "TEC026000",
    "Literal": "TECHNOLOGY & ENGINEERING / Mining"
  },
  {
    "Code": "TEC061000",
    "Literal": "TECHNOLOGY & ENGINEERING / Mobile & Wireless Communications"
  },
  {
    "Code": "TEC027000",
    "Literal": "TECHNOLOGY & ENGINEERING / Nanotechnology & MEMS"
  },
  {
    "Code": "TEC029000",
    "Literal": "TECHNOLOGY & ENGINEERING / Operations Research"
  },
  {
    "Code": "TEC030000",
    "Literal": "TECHNOLOGY & ENGINEERING / Optics"
  },
  {
    "Code": "TEC058000",
    "Literal": "TECHNOLOGY & ENGINEERING / Pest Control"
  },
  {
    "Code": "TEC047000",
    "Literal": "TECHNOLOGY & ENGINEERING / Petroleum"
  },
  {
    "Code": "TEC031000",
    "Literal": "TECHNOLOGY & ENGINEERING / Power Resources / General"
  },
  {
    "Code": "TEC031010",
    "Literal": "TECHNOLOGY & ENGINEERING / Power Resources / Alternative & Renewable"
  },
  {
    "Code": "TEC031020",
    "Literal": "TECHNOLOGY & ENGINEERING / Power Resources / Electrical"
  },
  {
    "Code": "TEC031030",
    "Literal": "TECHNOLOGY & ENGINEERING / Power Resources / Fossil Fuels"
  },
  {
    "Code": "TEC028000",
    "Literal": "TECHNOLOGY & ENGINEERING / Power Resources / Nuclear"
  },
  {
    "Code": "TEC062000",
    "Literal": "TECHNOLOGY & ENGINEERING / Project Management"
  },
  {
    "Code": "TEC032000",
    "Literal": "TECHNOLOGY & ENGINEERING / Quality Control"
  },
  {
    "Code": "TEC033000",
    "Literal": "TECHNOLOGY & ENGINEERING / Radar"
  },
  {
    "Code": "TEC034000",
    "Literal": "TECHNOLOGY & ENGINEERING / Radio"
  },
  {
    "Code": "TEC035000",
    "Literal": "TECHNOLOGY & ENGINEERING / Reference"
  },
  {
    "Code": "TEC036000",
    "Literal": "TECHNOLOGY & ENGINEERING / Remote Sensing & Geographic Information Systems"
  },
  {
    "Code": "TEC066000",
    "Literal": "TECHNOLOGY & ENGINEERING / Research"
  },
  {
    "Code": "TEC037000",
    "Literal": "TECHNOLOGY & ENGINEERING / Robotics"
  },
  {
    "Code": "TEC064000",
    "Literal": "TECHNOLOGY & ENGINEERING / Sensors"
  },
  {
    "Code": "TEC067000",
    "Literal": "TECHNOLOGY & ENGINEERING / Signals & Signal Processing"
  },
  {
    "Code": "TEC052000",
    "Literal": "TECHNOLOGY & ENGINEERING / Social Aspects"
  },
  {
    "Code": "TEC063000",
    "Literal": "TECHNOLOGY & ENGINEERING / Structural"
  },
  {
    "Code": "TEC039000",
    "Literal": "TECHNOLOGY & ENGINEERING / Superconductors & Superconductivity"
  },
  {
    "Code": "TEC054000",
    "Literal": "TECHNOLOGY & ENGINEERING / Surveying"
  },
  {
    "Code": "TEC040000",
    "Literal": "TECHNOLOGY & ENGINEERING / Technical & Manufacturing Industries & Trades"
  },
  {
    "Code": "TEC044000",
    "Literal": "TECHNOLOGY & ENGINEERING / Technical Writing"
  },
  {
    "Code": "TEC041000",
    "Literal": "TECHNOLOGY & ENGINEERING / Telecommunications"
  },
  {
    "Code": "TEC043000",
    "Literal": "TECHNOLOGY & ENGINEERING / Television & Video"
  },
  {
    "Code": "TEC055000",
    "Literal": "TECHNOLOGY & ENGINEERING / Textiles & Polymers"
  },
  {
    "Code": "TEC070000",
    "Literal": "TECHNOLOGY & ENGINEERING / 3D Printing"
  },
  {
    "Code": "TEC068000",
    "Literal": "TECHNOLOGY & ENGINEERING / Tribology"
  },
  {
    "Code": "TEC069000",
    "Literal": "TECHNOLOGY & ENGINEERING / Welding"
  },
  {
    "Code": "TRA000000",
    "Literal": "TRANSPORTATION / General"
  },
  {
    "Code": "TRA001000",
    "Literal": "TRANSPORTATION / Automotive / General"
  },
  {
    "Code": "TRA001010",
    "Literal": "TRANSPORTATION / Automotive / Antique & Classic"
  },
  {
    "Code": "TRA001020",
    "Literal": "TRANSPORTATION / Automotive / Buyer's Guides"
  },
  {
    "Code": "TRA001030",
    "Literal": "TRANSPORTATION / Automotive / Customizing"
  },
  {
    "Code": "TRA001080",
    "Literal": "TRANSPORTATION / Automotive / Driver Education"
  },
  {
    "Code": "TRA001050",
    "Literal": "TRANSPORTATION / Automotive / History"
  },
  {
    "Code": "TRA001060",
    "Literal": "TRANSPORTATION / Automotive / Pictorial"
  },
  {
    "Code": "TRA001140",
    "Literal": "TRANSPORTATION / Automotive / Repair & Maintenance"
  },
  {
    "Code": "TRA001150",
    "Literal": "TRANSPORTATION / Automotive / Trucks"
  },
  {
    "Code": "TRA002000",
    "Literal": "TRANSPORTATION / Aviation / General"
  },
  {
    "Code": "TRA002040",
    "Literal": "TRANSPORTATION / Aviation / Commercial"
  },
  {
    "Code": "TRA002010",
    "Literal": "TRANSPORTATION / Aviation / History"
  },
  {
    "Code": "TRA002050",
    "Literal": "TRANSPORTATION / Aviation / Piloting & Flight Instruction"
  },
  {
    "Code": "TRA002030",
    "Literal": "TRANSPORTATION / Aviation / Repair & Maintenance"
  },
  {
    "Code": "TRA010000",
    "Literal": "TRANSPORTATION / Bicycles"
  },
  {
    "Code": "TRA003000",
    "Literal": "TRANSPORTATION / Motorcycles / General"
  },
  {
    "Code": "TRA003010",
    "Literal": "TRANSPORTATION / Motorcycles / History"
  },
  {
    "Code": "TRA003020",
    "Literal": "TRANSPORTATION / Motorcycles / Pictorial"
  },
  {
    "Code": "TRA003030",
    "Literal": "TRANSPORTATION / Motorcycles / Repair & Maintenance"
  },
  {
    "Code": "TRA008000",
    "Literal": "TRANSPORTATION / Navigation"
  },
  {
    "Code": "TRA009000",
    "Literal": "TRANSPORTATION / Public Transportation"
  },
  {
    "Code": "TRA004000",
    "Literal": "TRANSPORTATION / Railroads / General"
  },
  {
    "Code": "TRA004010",
    "Literal": "TRANSPORTATION / Railroads / History"
  },
  {
    "Code": "TRA004020",
    "Literal": "TRANSPORTATION / Railroads / Pictorial"
  },
  {
    "Code": "TRA006000",
    "Literal": "TRANSPORTATION / Ships & Shipbuilding / General"
  },
  {
    "Code": "TRA006010",
    "Literal": "TRANSPORTATION / Ships & Shipbuilding / History"
  },
  {
    "Code": "TRA006020",
    "Literal": "TRANSPORTATION / Ships & Shipbuilding / Pictorial"
  },
  {
    "Code": "TRA006030",
    "Literal": "TRANSPORTATION / Ships & Shipbuilding / Repair & Maintenance"
  },
  {
    "Code": "TRA006040",
    "Literal": "TRANSPORTATION / Ships & Shipbuilding / Submarines"
  },
  {
    "Code": "TRV000000",
    "Literal": "TRAVEL / General"
  },
  {
    "Code": "TRV002000",
    "Literal": "TRAVEL / Africa / General"
  },
  {
    "Code": "TRV002010",
    "Literal": "TRAVEL / Africa / Central"
  },
  {
    "Code": "TRV002020",
    "Literal": "TRAVEL / Africa / East"
  },
  {
    "Code": "TRV002030",
    "Literal": "TRAVEL / Africa / Kenya"
  },
  {
    "Code": "TRV002040",
    "Literal": "TRAVEL / Africa / Morocco"
  },
  {
    "Code": "TRV002050",
    "Literal": "TRAVEL / Africa / North"
  },
  {
    "Code": "TRV002060",
    "Literal": "TRAVEL / Africa / Republic of South Africa"
  },
  {
    "Code": "TRV002070",
    "Literal": "TRAVEL / Africa / South"
  },
  {
    "Code": "TRV002080",
    "Literal": "TRAVEL / Africa / West"
  },
  {
    "Code": "TRV003000",
    "Literal": "TRAVEL / Asia / General"
  },
  {
    "Code": "TRV003010",
    "Literal": "TRAVEL / Asia / Central"
  },
  {
    "Code": "TRV003020",
    "Literal": "TRAVEL / Asia / China"
  },
  {
    "Code": "TRV003030",
    "Literal": "TRAVEL / Asia / Far East"
  },
  {
    "Code": "TRV003040",
    "Literal": "TRAVEL / Asia / India & South Asia"
  },
  {
    "Code": "TRV003050",
    "Literal": "TRAVEL / Asia / Japan"
  },
  {
    "Code": "TRV003060",
    "Literal": "TRAVEL / Asia / Southeast"
  },
  {
    "Code": "TRV003070",
    "Literal": "TRAVEL / Asia / Southwest"
  },
  {
    "Code": "TRV004000",
    "Literal": "TRAVEL / Australia & Oceania"
  },
  {
    "Code": "TRV006000",
    "Literal": "TRAVEL / Canada / General"
  },
  {
    "Code": "TRV006010",
    "Literal": "TRAVEL / Canada / Atlantic Provinces (NB, NL, NS, PE)"
  },
  {
    "Code": "TRV006020",
    "Literal": "TRAVEL / Canada / Ontario (ON)"
  },
  {
    "Code": "TRV006030",
    "Literal": "TRAVEL / Canada / Prairie Provinces (MB, SK)"
  },
  {
    "Code": "TRV006060",
    "Literal": "TRAVEL / Canada / Quebec (QC)"
  },
  {
    "Code": "TRV006040",
    "Literal": "TRAVEL / Canada / Territories & Nunavut (NT, NU, YT)"
  },
  {
    "Code": "TRV006050",
    "Literal": "TRAVEL / Canada / Western Provinces (AB, BC)"
  },
  {
    "Code": "TRV007000",
    "Literal": "TRAVEL / Caribbean & West Indies"
  },
  {
    "Code": "TRV008000",
    "Literal": "TRAVEL / Central America"
  },
  {
    "Code": "TRV010000",
    "Literal": "TRAVEL / Essays & Travelogues"
  },
  {
    "Code": "TRV009000",
    "Literal": "TRAVEL / Europe / General"
  },
  {
    "Code": "TRV009010",
    "Literal": "TRAVEL / Europe / Austria"
  },
  {
    "Code": "TRV009020",
    "Literal": "TRAVEL / Europe / Benelux Countries (Belgium, Netherlands, Luxembourg)"
  },
  {
    "Code": "TRV009160",
    "Literal": "TRAVEL / Europe / Cyprus"
  },
  {
    "Code": "TRV009030",
    "Literal": "TRAVEL / Europe / Denmark"
  },
  {
    "Code": "TRV009040",
    "Literal": "TRAVEL / Europe / Eastern"
  },
  {
    "Code": "TRV009050",
    "Literal": "TRAVEL / Europe / France"
  },
  {
    "Code": "TRV009060",
    "Literal": "TRAVEL / Europe / Germany"
  },
  {
    "Code": "TRV009070",
    "Literal": "TRAVEL / Europe / Great Britain"
  },
  {
    "Code": "TRV009080",
    "Literal": "TRAVEL / Europe / Greece"
  },
  {
    "Code": "TRV009090",
    "Literal": "TRAVEL / Europe / Iceland & Greenland"
  },
  {
    "Code": "TRV009100",
    "Literal": "TRAVEL / Europe / Ireland"
  },
  {
    "Code": "TRV009110",
    "Literal": "TRAVEL / Europe / Italy"
  },
  {
    "Code": "TRV009120",
    "Literal": "TRAVEL / Europe / Scandinavia (Finland, Norway, Sweden)"
  },
  {
    "Code": "TRV009130",
    "Literal": "TRAVEL / Europe / Spain & Portugal"
  },
  {
    "Code": "TRV009140",
    "Literal": "TRAVEL / Europe / Switzerland"
  },
  {
    "Code": "TRV009150",
    "Literal": "TRAVEL / Europe / Western"
  },
  {
    "Code": "TRV036000",
    "Literal": "TRAVEL / Food, Lodging & Transportation / General"
  },
  {
    "Code": "TRV005000",
    "Literal": "TRAVEL / Food, Lodging & Transportation / Bed & Breakfast"
  },
  {
    "Code": "TRV028000",
    "Literal": "TRAVEL / Food, Lodging & Transportation / Cruises"
  },
  {
    "Code": "TRV013000",
    "Literal": "TRAVEL / Food, Lodging & Transportation / Hotels, Inns & Hostels"
  },
  {
    "Code": "TRV035000",
    "Literal": "TRAVEL / Food, Lodging & Transportation / Rail Travel"
  },
  {
    "Code": "TRV030000",
    "Literal": "TRAVEL / Food, Lodging & Transportation / Resorts & Spas"
  },
  {
    "Code": "TRV022000",
    "Literal": "TRAVEL / Food, Lodging & Transportation / Restaurants"
  },
  {
    "Code": "TRV031000",
    "Literal": "TRAVEL / Food, Lodging & Transportation / Road Travel"
  },
  {
    "Code": "TRV012000",
    "Literal": "TRAVEL / Former Soviet Republics"
  },
  {
    "Code": "TRV027000",
    "Literal": "TRAVEL / Maps & Road Atlases (see also REFERENCE / Atlases, Gazetteers & Maps)"
  },
  {
    "Code": "TRV014000",
    "Literal": "TRAVEL / Mexico"
  },
  {
    "Code": "TRV015000",
    "Literal": "TRAVEL / Middle East / General"
  },
  {
    "Code": "TRV015010",
    "Literal": "TRAVEL / Middle East / Egypt"
  },
  {
    "Code": "TRV015020",
    "Literal": "TRAVEL / Middle East / Israel"
  },
  {
    "Code": "TRV015030",
    "Literal": "TRAVEL / Middle East / Turkey"
  },
  {
    "Code": "TRV016000",
    "Literal": "TRAVEL / Museums, Tours, Points of Interest"
  },
  {
    "Code": "TRV018000",
    "Literal": "TRAVEL / Parks & Campgrounds"
  },
  {
    "Code": "TRV019000",
    "Literal": "TRAVEL / Pictorials (see also PHOTOGRAPHY / Subjects & Themes / Regional)"
  },
  {
    "Code": "TRV020000",
    "Literal": "TRAVEL / Polar Regions"
  },
  {
    "Code": "TRV021000",
    "Literal": "TRAVEL / Reference"
  },
  {
    "Code": "TRV023000",
    "Literal": "TRAVEL / Russia"
  },
  {
    "Code": "TRV024000",
    "Literal": "TRAVEL / South America / General"
  },
  {
    "Code": "TRV024010",
    "Literal": "TRAVEL / South America / Argentina"
  },
  {
    "Code": "TRV024020",
    "Literal": "TRAVEL / South America / Brazil"
  },
  {
    "Code": "TRV024030",
    "Literal": "TRAVEL / South America / Chile & Easter Island"
  },
  {
    "Code": "TRV024040",
    "Literal": "TRAVEL / South America / Ecuador & Galapagos Islands"
  },
  {
    "Code": "TRV024050",
    "Literal": "TRAVEL / South America / Peru"
  },
  {
    "Code": "TRV026000",
    "Literal": "TRAVEL / Special Interest / General"
  },
  {
    "Code": "TRV001000",
    "Literal": "TRAVEL / Special Interest / Adventure"
  },
  {
    "Code": "TRV029000",
    "Literal": "TRAVEL / Special Interest / Amusement & Theme Parks"
  },
  {
    "Code": "TRV026100",
    "Literal": "TRAVEL / Special Interest / Bicycling"
  },
  {
    "Code": "TRV033000",
    "Literal": "TRAVEL / Special Interest / Budget"
  },
  {
    "Code": "TRV026010",
    "Literal": "TRAVEL / Special Interest / Business"
  },
  {
    "Code": "TRV026120",
    "Literal": "TRAVEL / Special Interest / Culinary"
  },
  {
    "Code": "TRV026030",
    "Literal": "TRAVEL / Special Interest / Disabilities & Special Needs"
  },
  {
    "Code": "TRV026020",
    "Literal": "TRAVEL / Special Interest / Ecotourism"
  },
  {
    "Code": "TRV011000",
    "Literal": "TRAVEL / Special Interest / Family"
  },
  {
    "Code": "TRV026130",
    "Literal": "TRAVEL / Special Interest / Haunted & Unexplained"
  },
  {
    "Code": "TRV034000",
    "Literal": "TRAVEL / Special Interest / Hikes & Walks"
  },
  {
    "Code": "TRV026070",
    "Literal": "TRAVEL / Special Interest / LGBT"
  },
  {
    "Code": "TRV026090",
    "Literal": "TRAVEL / Special Interest / Literary"
  },
  {
    "Code": "TRV026110",
    "Literal": "TRAVEL / Special Interest / Military"
  },
  {
    "Code": "TRV026040",
    "Literal": "TRAVEL / Special Interest / Pets"
  },
  {
    "Code": "TRV026060",
    "Literal": "TRAVEL / Special Interest / Religious"
  },
  {
    "Code": "TRV026140",
    "Literal": "TRAVEL / Special Interest / Roadside Attractions"
  },
  {
    "Code": "TRV026050",
    "Literal": "TRAVEL / Special Interest / Senior"
  },
  {
    "Code": "TRV032000",
    "Literal": "TRAVEL / Special Interest / Shopping"
  },
  {
    "Code": "TRV026080",
    "Literal": "TRAVEL / Special Interest / Sports"
  },
  {
    "Code": "TRV025000",
    "Literal": "TRAVEL / United States / General"
  },
  {
    "Code": "TRV025010",
    "Literal": "TRAVEL / United States / Midwest / General"
  },
  {
    "Code": "TRV025020",
    "Literal": "TRAVEL / United States / Midwest / East North Central (IL, IN, MI, OH, WI)"
  },
  {
    "Code": "TRV025030",
    "Literal": "TRAVEL / United States / Midwest / West North Central (IA, KS, MN, MO, ND, NE, SD)"
  },
  {
    "Code": "TRV025040",
    "Literal": "TRAVEL / United States / Northeast / General"
  },
  {
    "Code": "TRV025050",
    "Literal": "TRAVEL / United States / Northeast / Middle Atlantic (NJ, NY, PA)"
  },
  {
    "Code": "TRV025060",
    "Literal": "TRAVEL / United States / Northeast / New England (CT, MA, ME, NH, RI, VT)"
  },
  {
    "Code": "TRV025070",
    "Literal": "TRAVEL / United States / South / General"
  },
  {
    "Code": "TRV025080",
    "Literal": "TRAVEL / United States / South / East South Central (AL, KY, MS, TN)"
  },
  {
    "Code": "TRV025090",
    "Literal": "TRAVEL / United States / South / South Atlantic (DC, DE, FL, GA, MD, NC, SC, VA, WV)"
  },
  {
    "Code": "TRV025100",
    "Literal": "TRAVEL / United States / South / West South Central (AR, LA, OK, TX)"
  },
  {
    "Code": "TRV025110",
    "Literal": "TRAVEL / United States / West / General"
  },
  {
    "Code": "TRV025120",
    "Literal": "TRAVEL / United States / West / Mountain (AZ, CO, ID, MT, NM, NV, UT, WY)"
  },
  {
    "Code": "TRV025130",
    "Literal": "TRAVEL / United States / West / Pacific (AK, CA, HI, OR, WA)"
  },
  {
    "Code": "TRU000000",
    "Literal": "TRUE CRIME / General"
  },
  {
    "Code": "TRU006000",
    "Literal": "TRUE CRIME / Abductions, Kidnappings & Missing Persons"
  },
  {
    "Code": "TRU004000",
    "Literal": "TRUE CRIME / Con Artists, Hoaxes & Deceptions"
  },
  {
    "Code": "TRU001000",
    "Literal": "TRUE CRIME / Espionage"
  },
  {
    "Code": "TRU007000",
    "Literal": "TRUE CRIME / Forensics"
  },
  {
    "Code": "TRU008000",
    "Literal": "TRUE CRIME / Heists & Robberies"
  },
  {
    "Code": "TRU010000",
    "Literal": "TRUE CRIME / Historical"
  },
  {
    "Code": "TRU002000",
    "Literal": "TRUE CRIME / Murder / General"
  },
  {
    "Code": "TRU002020",
    "Literal": "TRUE CRIME / Murder / Mass Murder"
  },
  {
    "Code": "TRU002010",
    "Literal": "TRUE CRIME / Murder / Serial Killers"
  },
  {
    "Code": "TRU003000",
    "Literal": "TRUE CRIME / Organized Crime"
  },
  {
    "Code": "TRU009000",
    "Literal": "TRUE CRIME / Sexual Assault"
  },
  {
    "Code": "TRU005000",
    "Literal": "TRUE CRIME / White Collar Crime"
  },
  {
    "Code": "YAF000000",
    "Literal": "YOUNG ADULT FICTION / General"
  },
  {
    "Code": "YAF001000",
    "Literal": "YOUNG ADULT FICTION / Action & Adventure / General"
  },
  {
    "Code": "YAF001010",
    "Literal": "YOUNG ADULT FICTION / Action & Adventure / Pirates"
  },
  {
    "Code": "YAF001020",
    "Literal": "YOUNG ADULT FICTION / Action & Adventure / Survival Stories"
  },
  {
    "Code": "YAF071000",
    "Literal": "YOUNG ADULT FICTION / Alternative History"
  },
  {
    "Code": "YAF002000",
    "Literal": "YOUNG ADULT FICTION / Animals / General"
  },
  {
    "Code": "YAF002010",
    "Literal": "YOUNG ADULT FICTION / Animals / Horses"
  },
  {
    "Code": "YAF002020",
    "Literal": "YOUNG ADULT FICTION / Animals / Marine Life"
  },
  {
    "Code": "YAF002030",
    "Literal": "YOUNG ADULT FICTION / Animals / Mythical Creatures"
  },
  {
    "Code": "YAF002040",
    "Literal": "YOUNG ADULT FICTION / Animals / Pets"
  },
  {
    "Code": "YAF004000",
    "Literal": "YOUNG ADULT FICTION / Art"
  },
  {
    "Code": "YAF005000",
    "Literal": "YOUNG ADULT FICTION / Biographical"
  },
  {
    "Code": "YAF006000",
    "Literal": "YOUNG ADULT FICTION / Books & Libraries"
  },
  {
    "Code": "YAF007000",
    "Literal": "YOUNG ADULT FICTION / Boys & Men"
  },
  {
    "Code": "YAF008000",
    "Literal": "YOUNG ADULT FICTION / Careers, Occupations, Internships"
  },
  {
    "Code": "YAF009000",
    "Literal": "YOUNG ADULT FICTION / Classics"
  },
  {
    "Code": "YAF010000",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / General"
  },
  {
    "Code": "YAF010050",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Action & Adventure"
  },
  {
    "Code": "YAF010060",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Classic Adaptation"
  },
  {
    "Code": "YAF010070",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Coming of Age"
  },
  {
    "Code": "YAF010080",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Dystopian"
  },
  {
    "Code": "YAF010090",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Fairy Tales, Folklore, Legends & Mythology"
  },
  {
    "Code": "YAF010100",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Fantasy"
  },
  {
    "Code": "YAF010110",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Historical"
  },
  {
    "Code": "YAF010120",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Horror"
  },
  {
    "Code": "YAF010130",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Humorous"
  },
  {
    "Code": "YAF010140",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / LGBT"
  },
  {
    "Code": "YAF010010",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Manga"
  },
  {
    "Code": "YAF010020",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Media Tie-In"
  },
  {
    "Code": "YAF010150",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Mystery & Detective"
  },
  {
    "Code": "YAF010160",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Paranormal"
  },
  {
    "Code": "YAF010170",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Romance"
  },
  {
    "Code": "YAF010030",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Science Fiction"
  },
  {
    "Code": "YAF010040",
    "Literal": "YOUNG ADULT FICTION / Comics & Graphic Novels / Superheroes"
  },
  {
    "Code": "YAF011000",
    "Literal": "YOUNG ADULT FICTION / Coming of Age"
  },
  {
    "Code": "YAF012000",
    "Literal": "YOUNG ADULT FICTION / Computers & Digital Media"
  },
  {
    "Code": "YAF013000",
    "Literal": "YOUNG ADULT FICTION / Cooking & Food"
  },
  {
    "Code": "YAF058070",
    "Literal": "YOUNG ADULT FICTION / Disabilities & Special Needs"
  },
  {
    "Code": "YAF014000",
    "Literal": "YOUNG ADULT FICTION / Diversity & Multicultural"
  },
  {
    "Code": "YAF015000",
    "Literal": "YOUNG ADULT FICTION / Dystopian"
  },
  {
    "Code": "YAF016000",
    "Literal": "YOUNG ADULT FICTION / Epistolary (Letters & Diaries)"
  },
  {
    "Code": "YAF017000",
    "Literal": "YOUNG ADULT FICTION / Fairy Tales & Folklore / General"
  },
  {
    "Code": "YAF017010",
    "Literal": "YOUNG ADULT FICTION / Fairy Tales & Folklore / Adaptations"
  },
  {
    "Code": "YAF017020",
    "Literal": "YOUNG ADULT FICTION / Fairy Tales & Folklore / Anthologies"
  },
  {
    "Code": "YAF017030",
    "Literal": "YOUNG ADULT FICTION / Fairy Tales & Folklore / Country & Ethnic"
  },
  {
    "Code": "YAF018000",
    "Literal": "YOUNG ADULT FICTION / Family / General (see also headings under Social Themes)"
  },
  {
    "Code": "YAF018010",
    "Literal": "YOUNG ADULT FICTION / Family / Adoption"
  },
  {
    "Code": "YAF018020",
    "Literal": "YOUNG ADULT FICTION / Family / Alternative Family"
  },
  {
    "Code": "YAF018080",
    "Literal": "YOUNG ADULT FICTION / Family / Blended Families"
  },
  {
    "Code": "YAF018030",
    "Literal": "YOUNG ADULT FICTION / Family / Marriage & Divorce"
  },
  {
    "Code": "YAF018040",
    "Literal": "YOUNG ADULT FICTION / Family / Multigenerational"
  },
  {
    "Code": "YAF018050",
    "Literal": "YOUNG ADULT FICTION / Family / Orphans & Foster Homes"
  },
  {
    "Code": "YAF018060",
    "Literal": "YOUNG ADULT FICTION / Family / Parents"
  },
  {
    "Code": "YAF018070",
    "Literal": "YOUNG ADULT FICTION / Family / Siblings"
  },
  {
    "Code": "YAF019000",
    "Literal": "YOUNG ADULT FICTION / Fantasy / General"
  },
  {
    "Code": "YAF019010",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Contemporary"
  },
  {
    "Code": "YAF019020",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Dark Fantasy"
  },
  {
    "Code": "YAF019030",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Epic"
  },
  {
    "Code": "YAF019040",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Historical"
  },
  {
    "Code": "YAF019050",
    "Literal": "YOUNG ADULT FICTION / Fantasy / Wizards & Witches"
  },
  {
    "Code": "YAF020000",
    "Literal": "YOUNG ADULT FICTION / Fashion & Beauty"
  },
  {
    "Code": "YAF021000",
    "Literal": "YOUNG ADULT FICTION / Ghost Stories"
  },
  {
    "Code": "YAF022000",
    "Literal": "YOUNG ADULT FICTION / Girls & Women"
  },
  {
    "Code": "YAF023000",
    "Literal": "YOUNG ADULT FICTION / Health & Daily Living / General"
  },
  {
    "Code": "YAF023010",
    "Literal": "YOUNG ADULT FICTION / Health & Daily Living / Diseases, Illnesses & Injuries"
  },
  {
    "Code": "YAF024000",
    "Literal": "YOUNG ADULT FICTION / Historical / General"
  },
  {
    "Code": "YAF024010",
    "Literal": "YOUNG ADULT FICTION / Historical / Africa"
  },
  {
    "Code": "YAF024020",
    "Literal": "YOUNG ADULT FICTION / Historical / Ancient Civilizations"
  },
  {
    "Code": "YAF024030",
    "Literal": "YOUNG ADULT FICTION / Historical / Asia"
  },
  {
    "Code": "YAF024040",
    "Literal": "YOUNG ADULT FICTION / Historical / Canada"
  },
  {
    "Code": "YAF024050",
    "Literal": "YOUNG ADULT FICTION / Historical / Europe"
  },
  {
    "Code": "YAF024060",
    "Literal": "YOUNG ADULT FICTION / Historical / Exploration & Discovery"
  },
  {
    "Code": "YAF024070",
    "Literal": "YOUNG ADULT FICTION / Historical / Holocaust"
  },
  {
    "Code": "YAF024080",
    "Literal": "YOUNG ADULT FICTION / Historical / Medieval"
  },
  {
    "Code": "YAF024090",
    "Literal": "YOUNG ADULT FICTION / Historical / Middle East"
  },
  {
    "Code": "YAF024100",
    "Literal": "YOUNG ADULT FICTION / Historical / Military & Wars"
  },
  {
    "Code": "YAF024110",
    "Literal": "YOUNG ADULT FICTION / Historical / Prehistory"
  },
  {
    "Code": "YAF024120",
    "Literal": "YOUNG ADULT FICTION / Historical / Renaissance"
  },
  {
    "Code": "YAF024130",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / General"
  },
  {
    "Code": "YAF024140",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / Colonial & Revolutionary Periods"
  },
  {
    "Code": "YAF024150",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / 19th Century"
  },
  {
    "Code": "YAF024160",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / Civil War Period (1850-1877)"
  },
  {
    "Code": "YAF024170",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / 20th Century"
  },
  {
    "Code": "YAF024180",
    "Literal": "YOUNG ADULT FICTION / Historical / United States / 21st Century"
  },
  {
    "Code": "YAF025000",
    "Literal": "YOUNG ADULT FICTION / Holidays & Celebrations"
  },
  {
    "Code": "YAF026000",
    "Literal": "YOUNG ADULT FICTION / Horror"
  },
  {
    "Code": "YAF027000",
    "Literal": "YOUNG ADULT FICTION / Humorous / General"
  },
  {
    "Code": "YAF027010",
    "Literal": "YOUNG ADULT FICTION / Humorous / Black Comedy"
  },
  {
    "Code": "YAF028000",
    "Literal": "YOUNG ADULT FICTION / Interactive Adventures"
  },
  {
    "Code": "YAF029000",
    "Literal": "YOUNG ADULT FICTION / Law & Crime"
  },
  {
    "Code": "YAF030000",
    "Literal": "YOUNG ADULT FICTION / Legends, Myths, Fables / General"
  },
  {
    "Code": "YAF030010",
    "Literal": "YOUNG ADULT FICTION / Legends, Myths, Fables / Arthurian"
  },
  {
    "Code": "YAF030020",
    "Literal": "YOUNG ADULT FICTION / Legends, Myths, Fables / Greek & Roman"
  },
  {
    "Code": "YAF031000",
    "Literal": "YOUNG ADULT FICTION / LGBT"
  },
  {
    "Code": "YAF032000",
    "Literal": "YOUNG ADULT FICTION / Lifestyles / City & Town Life"
  },
  {
    "Code": "YAF033000",
    "Literal": "YOUNG ADULT FICTION / Lifestyles / Country Life"
  },
  {
    "Code": "YAF034000",
    "Literal": "YOUNG ADULT FICTION / Lifestyles / Farm & Ranch Life"
  },
  {
    "Code": "YAF035000",
    "Literal": "YOUNG ADULT FICTION / Light Novel (Ranobe)"
  },
  {
    "Code": "YAF036000",
    "Literal": "YOUNG ADULT FICTION / Literary"
  },
  {
    "Code": "YAF037000",
    "Literal": "YOUNG ADULT FICTION / Loners & Outcasts"
  },
  {
    "Code": "YAF038000",
    "Literal": "YOUNG ADULT FICTION / Magical Realism"
  },
  {
    "Code": "YAF039000",
    "Literal": "YOUNG ADULT FICTION / Media Tie-In"
  },
  {
    "Code": "YAF040000",
    "Literal": "YOUNG ADULT FICTION / Mermaids"
  },
  {
    "Code": "YAF041000",
    "Literal": "YOUNG ADULT FICTION / Monsters"
  },
  {
    "Code": "YAF042000",
    "Literal": "YOUNG ADULT FICTION / Mysteries & Detective Stories"
  },
  {
    "Code": "YAF044000",
    "Literal": "YOUNG ADULT FICTION / Novels in Verse"
  },
  {
    "Code": "YAF045000",
    "Literal": "YOUNG ADULT FICTION / Paranormal, Occult & Supernatural"
  },
  {
    "Code": "YAF046000",
    "Literal": "YOUNG ADULT FICTION / People & Places / General"
  },
  {
    "Code": "YAF046010",
    "Literal": "YOUNG ADULT FICTION / People & Places / Aboriginal & Indigenous"
  },
  {
    "Code": "YAF046020",
    "Literal": "YOUNG ADULT FICTION / People & Places / Africa"
  },
  {
    "Code": "YAF046030",
    "Literal": "YOUNG ADULT FICTION / People & Places / Asia"
  },
  {
    "Code": "YAF046040",
    "Literal": "YOUNG ADULT FICTION / People & Places / Australia & Oceania"
  },
  {
    "Code": "YAF046050",
    "Literal": "YOUNG ADULT FICTION / People & Places / Canada"
  },
  {
    "Code": "YAF046060",
    "Literal": "YOUNG ADULT FICTION / People & Places / Caribbean & Latin America"
  },
  {
    "Code": "YAF046070",
    "Literal": "YOUNG ADULT FICTION / People & Places / Europe"
  },
  {
    "Code": "YAF046080",
    "Literal": "YOUNG ADULT FICTION / People & Places / Mexico"
  },
  {
    "Code": "YAF046090",
    "Literal": "YOUNG ADULT FICTION / People & Places / Middle East"
  },
  {
    "Code": "YAF046100",
    "Literal": "YOUNG ADULT FICTION / People & Places / Polar Regions"
  },
  {
    "Code": "YAF046110",
    "Literal": "YOUNG ADULT FICTION / People & Places / United States / General"
  },
  {
    "Code": "YAF046120",
    "Literal": "YOUNG ADULT FICTION / People & Places / United States / African American"
  },
  {
    "Code": "YAF046130",
    "Literal": "YOUNG ADULT FICTION / People & Places / United States / Asian American"
  },
  {
    "Code": "YAF046140",
    "Literal": "YOUNG ADULT FICTION / People & Places / United States / Hispanic & Latino"
  },
  {
    "Code": "YAF046150",
    "Literal": "YOUNG ADULT FICTION / People & Places / United States / Native American"
  },
  {
    "Code": "YAF047000",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / General"
  },
  {
    "Code": "YAF047010",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / Dance"
  },
  {
    "Code": "YAF047020",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / Film"
  },
  {
    "Code": "YAF047030",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / Music"
  },
  {
    "Code": "YAF047040",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / Television & Radio"
  },
  {
    "Code": "YAF047050",
    "Literal": "YOUNG ADULT FICTION / Performing Arts / Theater & Musicals"
  },
  {
    "Code": "YAF048000",
    "Literal": "YOUNG ADULT FICTION / Poetry"
  },
  {
    "Code": "YAF049000",
    "Literal": "YOUNG ADULT FICTION / Politics & Government"
  },
  {
    "Code": "YAF050000",
    "Literal": "YOUNG ADULT FICTION / Recycling & Green Living"
  },
  {
    "Code": "YAF051000",
    "Literal": "YOUNG ADULT FICTION / Religious / General"
  },
  {
    "Code": "YAF051010",
    "Literal": "YOUNG ADULT FICTION / Religious / Agnostic & Atheist"
  },
  {
    "Code": "YAF051020",
    "Literal": "YOUNG ADULT FICTION / Religious / Buddhist"
  },
  {
    "Code": "YAF051030",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / General"
  },
  {
    "Code": "YAF051040",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Action & Adventure"
  },
  {
    "Code": "YAF051050",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Comics & Graphic Novels"
  },
  {
    "Code": "YAF051060",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Fantasy"
  },
  {
    "Code": "YAF051070",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Historical"
  },
  {
    "Code": "YAF051080",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Mysteries & Detective Stories"
  },
  {
    "Code": "YAF051090",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Relationships"
  },
  {
    "Code": "YAF051100",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Science Fiction"
  },
  {
    "Code": "YAF051110",
    "Literal": "YOUNG ADULT FICTION / Religious / Christian / Social Issues"
  },
  {
    "Code": "YAF051120",
    "Literal": "YOUNG ADULT FICTION / Religious / Hindu"
  },
  {
    "Code": "YAF051130",
    "Literal": "YOUNG ADULT FICTION / Religious / Jewish"
  },
  {
    "Code": "YAF051140",
    "Literal": "YOUNG ADULT FICTION / Religious / Muslim"
  },
  {
    "Code": "YAF052000",
    "Literal": "YOUNG ADULT FICTION / Romance / General"
  },
  {
    "Code": "YAF052010",
    "Literal": "YOUNG ADULT FICTION / Romance / Clean & Wholesome"
  },
  {
    "Code": "YAF052020",
    "Literal": "YOUNG ADULT FICTION / Romance / Contemporary"
  },
  {
    "Code": "YAF052030",
    "Literal": "YOUNG ADULT FICTION / Romance / Historical"
  },
  {
    "Code": "YAF052040",
    "Literal": "YOUNG ADULT FICTION / Romance / LGBT"
  },
  {
    "Code": "YAF052070",
    "Literal": "YOUNG ADULT FICTION / Romance / Multicultural & Interracial"
  },
  {
    "Code": "YAF052050",
    "Literal": "YOUNG ADULT FICTION / Romance / Paranormal"
  },
  {
    "Code": "YAF052060",
    "Literal": "YOUNG ADULT FICTION / Romance / Romantic Comedy"
  },
  {
    "Code": "YAF053000",
    "Literal": "YOUNG ADULT FICTION / Royalty"
  },
  {
    "Code": "YAF027020",
    "Literal": "YOUNG ADULT FICTION / Satire"
  },
  {
    "Code": "YAF054000",
    "Literal": "YOUNG ADULT FICTION / School & Education / General"
  },
  {
    "Code": "YAF054010",
    "Literal": "YOUNG ADULT FICTION / School & Education / Boarding School & Prep School"
  },
  {
    "Code": "YAF054020",
    "Literal": "YOUNG ADULT FICTION / School & Education / College & University"
  },
  {
    "Code": "YAF043000",
    "Literal": "YOUNG ADULT FICTION / Science & Nature / General (see also headings under Animals)"
  },
  {
    "Code": "YAF043010",
    "Literal": "YOUNG ADULT FICTION / Science & Nature / Environment"
  },
  {
    "Code": "YAF056000",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / General"
  },
  {
    "Code": "YAF056010",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / Alien Contact"
  },
  {
    "Code": "YAF003000",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / Apocalyptic & Post-Apocalyptic"
  },
  {
    "Code": "YAF056020",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / Space Opera"
  },
  {
    "Code": "YAF063000",
    "Literal": "YOUNG ADULT FICTION / Science Fiction / Time Travel"
  },
  {
    "Code": "YAF057000",
    "Literal": "YOUNG ADULT FICTION / Short Stories"
  },
  {
    "Code": "YAF058000",
    "Literal": "YOUNG ADULT FICTION / Social Themes / General (see also headings under Family)"
  },
  {
    "Code": "YAF058010",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Assimilation"
  },
  {
    "Code": "YAF058020",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Bullying"
  },
  {
    "Code": "YAF058030",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Class Differences"
  },
  {
    "Code": "YAF058040",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Dating & Sex"
  },
  {
    "Code": "YAF058050",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Death, Grief, Bereavement"
  },
  {
    "Code": "YAF058060",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Depression"
  },
  {
    "Code": "YAF058080",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Drugs, Alcohol, Substance Abuse"
  },
  {
    "Code": "YAF058090",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Eating Disorders & Body Image"
  },
  {
    "Code": "YAF058100",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Emigration & Immigration"
  },
  {
    "Code": "YAF058110",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Emotions & Feelings"
  },
  {
    "Code": "YAF058120",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Friendship"
  },
  {
    "Code": "YAF058130",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Homelessness & Poverty"
  },
  {
    "Code": "YAF058140",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Mental Illness"
  },
  {
    "Code": "YAF058150",
    "Literal": "YOUNG ADULT FICTION / Social Themes / New Experience"
  },
  {
    "Code": "YAF058160",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Peer Pressure"
  },
  {
    "Code": "YAF058170",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Physical & Emotional Abuse (see also Social Themes / Sexual Abuse)"
  },
  {
    "Code": "YAF058180",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Pregnancy"
  },
  {
    "Code": "YAF058190",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Prejudice & Racism"
  },
  {
    "Code": "YAF058200",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Religion & Faith"
  },
  {
    "Code": "YAF058210",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Runaways"
  },
  {
    "Code": "YAF058220",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Self-Esteem & Self-Reliance"
  },
  {
    "Code": "YAF058230",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Self-Mutilation"
  },
  {
    "Code": "YAF058240",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Sexual Abuse"
  },
  {
    "Code": "YAF058250",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Suicide"
  },
  {
    "Code": "YAF058260",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Values & Virtues"
  },
  {
    "Code": "YAF058270",
    "Literal": "YOUNG ADULT FICTION / Social Themes / Violence"
  },
  {
    "Code": "YAF059000",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / General"
  },
  {
    "Code": "YAF059010",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Baseball & Softball"
  },
  {
    "Code": "YAF059020",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Basketball"
  },
  {
    "Code": "YAF059030",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Camping & Outdoor Activities"
  },
  {
    "Code": "YAF059040",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Equestrian"
  },
  {
    "Code": "YAF059050",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Extreme Sports"
  },
  {
    "Code": "YAF059060",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Football"
  },
  {
    "Code": "YAF059070",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Gymnastics"
  },
  {
    "Code": "YAF059080",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Hockey"
  },
  {
    "Code": "YAF059090",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Martial Arts"
  },
  {
    "Code": "YAF059100",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Skateboarding"
  },
  {
    "Code": "YAF059110",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Soccer"
  },
  {
    "Code": "YAF059120",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Track & Field"
  },
  {
    "Code": "YAF059130",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Water Sports"
  },
  {
    "Code": "YAF059140",
    "Literal": "YOUNG ADULT FICTION / Sports & Recreation / Winter Sports"
  },
  {
    "Code": "YAF060000",
    "Literal": "YOUNG ADULT FICTION / Steampunk"
  },
  {
    "Code": "YAF061000",
    "Literal": "YOUNG ADULT FICTION / Superheroes"
  },
  {
    "Code": "YAF055000",
    "Literal": "YOUNG ADULT FICTION / Technology"
  },
  {
    "Code": "YAF062000",
    "Literal": "YOUNG ADULT FICTION / Thrillers & Suspense"
  },
  {
    "Code": "YAF064000",
    "Literal": "YOUNG ADULT FICTION / Travel & Transportation / General"
  },
  {
    "Code": "YAF064010",
    "Literal": "YOUNG ADULT FICTION / Travel & Transportation / Car & Road Trips"
  },
  {
    "Code": "YAF065000",
    "Literal": "YOUNG ADULT FICTION / Vampires"
  },
  {
    "Code": "YAF066000",
    "Literal": "YOUNG ADULT FICTION / Visionary & Metaphysical"
  },
  {
    "Code": "YAF067000",
    "Literal": "YOUNG ADULT FICTION / War & Military"
  },
  {
    "Code": "YAF068000",
    "Literal": "YOUNG ADULT FICTION / Werewolves & Shifters"
  },
  {
    "Code": "YAF069000",
    "Literal": "YOUNG ADULT FICTION / Westerns"
  },
  {
    "Code": "YAF070000",
    "Literal": "YOUNG ADULT FICTION / Zombies"
  },
  {
    "Code": "YAN000000",
    "Literal": "YOUNG ADULT NONFICTION / General"
  },
  {
    "Code": "YAN001000",
    "Literal": "YOUNG ADULT NONFICTION / Activity Books"
  },
  {
    "Code": "YAN002000",
    "Literal": "YOUNG ADULT NONFICTION / Adventure & Adventurers"
  },
  {
    "Code": "YAN003000",
    "Literal": "YOUNG ADULT NONFICTION / Animals / General"
  },
  {
    "Code": "YAN003010",
    "Literal": "YOUNG ADULT NONFICTION / Animals / Animal Welfare"
  },
  {
    "Code": "YAN003020",
    "Literal": "YOUNG ADULT NONFICTION / Animals / Birds"
  },
  {
    "Code": "YAN003030",
    "Literal": "YOUNG ADULT NONFICTION / Animals / Marine Life"
  },
  {
    "Code": "YAN004000",
    "Literal": "YOUNG ADULT NONFICTION / Architecture"
  },
  {
    "Code": "YAN005000",
    "Literal": "YOUNG ADULT NONFICTION / Art / General"
  },
  {
    "Code": "YAN005010",
    "Literal": "YOUNG ADULT NONFICTION / Art / Cartooning"
  },
  {
    "Code": "YAN005020",
    "Literal": "YOUNG ADULT NONFICTION / Art / Drawing"
  },
  {
    "Code": "YAN005030",
    "Literal": "YOUNG ADULT NONFICTION / Art / Fashion"
  },
  {
    "Code": "YAN005040",
    "Literal": "YOUNG ADULT NONFICTION / Art / History"
  },
  {
    "Code": "YAN005050",
    "Literal": "YOUNG ADULT NONFICTION / Art / Painting"
  },
  {
    "Code": "YAN005060",
    "Literal": "YOUNG ADULT NONFICTION / Art / Sculpture"
  },
  {
    "Code": "YAN005070",
    "Literal": "YOUNG ADULT NONFICTION / Art / Techniques"
  },
  {
    "Code": "YAN006000",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / General"
  },
  {
    "Code": "YAN006010",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Art"
  },
  {
    "Code": "YAN006020",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Cultural, Ethnic & Regional"
  },
  {
    "Code": "YAN006030",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Historical"
  },
  {
    "Code": "YAN006150",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / LGBT"
  },
  {
    "Code": "YAN006040",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Literary"
  },
  {
    "Code": "YAN006050",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Music"
  },
  {
    "Code": "YAN006060",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Performing Arts"
  },
  {
    "Code": "YAN006070",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Political"
  },
  {
    "Code": "YAN006080",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Presidents & First Families (U.S.)"
  },
  {
    "Code": "YAN006090",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Religious"
  },
  {
    "Code": "YAN006100",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Royalty"
  },
  {
    "Code": "YAN006110",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Science & Technology"
  },
  {
    "Code": "YAN006120",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Social Activists"
  },
  {
    "Code": "YAN006130",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Sports & Recreation"
  },
  {
    "Code": "YAN006140",
    "Literal": "YOUNG ADULT NONFICTION / Biography & Autobiography / Women"
  },
  {
    "Code": "YAN008000",
    "Literal": "YOUNG ADULT NONFICTION / Books & Libraries"
  },
  {
    "Code": "YAN009000",
    "Literal": "YOUNG ADULT NONFICTION / Boys & Men"
  },
  {
    "Code": "YAN010000",
    "Literal": "YOUNG ADULT NONFICTION / Business & Economics"
  },
  {
    "Code": "YAN011000",
    "Literal": "YOUNG ADULT NONFICTION / Careers"
  },
  {
    "Code": "YAN012000",
    "Literal": "YOUNG ADULT NONFICTION / Comics & Graphic Novels / General"
  },
  {
    "Code": "YAN012010",
    "Literal": "YOUNG ADULT NONFICTION / Comics & Graphic Novels / Biography"
  },
  {
    "Code": "YAN012020",
    "Literal": "YOUNG ADULT NONFICTION / Comics & Graphic Novels / History"
  },
  {
    "Code": "YAN012030",
    "Literal": "YOUNG ADULT NONFICTION / Comics & Graphic Novels / Science & Nature"
  },
  {
    "Code": "YAN012040",
    "Literal": "YOUNG ADULT NONFICTION / Comics & Graphic Novels / Social Topics"
  },
  {
    "Code": "YAN013000",
    "Literal": "YOUNG ADULT NONFICTION / Computers / General"
  },
  {
    "Code": "YAN013030",
    "Literal": "YOUNG ADULT NONFICTION / Computers / Coding & Programming"
  },
  {
    "Code": "YAN013010",
    "Literal": "YOUNG ADULT NONFICTION / Computers / Entertainment & Games"
  },
  {
    "Code": "YAN013020",
    "Literal": "YOUNG ADULT NONFICTION / Computers / Internet"
  },
  {
    "Code": "YAN013040",
    "Literal": "YOUNG ADULT NONFICTION / Computers / Software"
  },
  {
    "Code": "YAN014000",
    "Literal": "YOUNG ADULT NONFICTION / Cooking & Food"
  },
  {
    "Code": "YAN015000",
    "Literal": "YOUNG ADULT NONFICTION / Crafts & Hobbies"
  },
  {
    "Code": "YAN016000",
    "Literal": "YOUNG ADULT NONFICTION / Curiosities & Wonders"
  },
  {
    "Code": "YAN051230",
    "Literal": "YOUNG ADULT NONFICTION / Disabilities & Special Needs"
  },
  {
    "Code": "YAN017000",
    "Literal": "YOUNG ADULT NONFICTION / Drama"
  },
  {
    "Code": "YAN018000",
    "Literal": "YOUNG ADULT NONFICTION / Family / General (see also headings under Social Topics)"
  },
  {
    "Code": "YAN018010",
    "Literal": "YOUNG ADULT NONFICTION / Family / Adoption"
  },
  {
    "Code": "YAN018020",
    "Literal": "YOUNG ADULT NONFICTION / Family / Alternative Family"
  },
  {
    "Code": "YAN018080",
    "Literal": "YOUNG ADULT NONFICTION / Family / Blended Families"
  },
  {
    "Code": "YAN018030",
    "Literal": "YOUNG ADULT NONFICTION / Family / Marriage & Divorce"
  },
  {
    "Code": "YAN018040",
    "Literal": "YOUNG ADULT NONFICTION / Family / Multigenerational"
  },
  {
    "Code": "YAN018050",
    "Literal": "YOUNG ADULT NONFICTION / Family / Orphans & Foster Homes"
  },
  {
    "Code": "YAN018060",
    "Literal": "YOUNG ADULT NONFICTION / Family / Parents"
  },
  {
    "Code": "YAN018070",
    "Literal": "YOUNG ADULT NONFICTION / Family / Siblings"
  },
  {
    "Code": "YAN019000",
    "Literal": "YOUNG ADULT NONFICTION / Fashion"
  },
  {
    "Code": "YAN020000",
    "Literal": "YOUNG ADULT NONFICTION / Foreign Language Study / General"
  },
  {
    "Code": "YAN020010",
    "Literal": "YOUNG ADULT NONFICTION / Foreign Language Study / English as a Second Language"
  },
  {
    "Code": "YAN020020",
    "Literal": "YOUNG ADULT NONFICTION / Foreign Language Study / French"
  },
  {
    "Code": "YAN020030",
    "Literal": "YOUNG ADULT NONFICTION / Foreign Language Study / Spanish"
  },
  {
    "Code": "YAN021000",
    "Literal": "YOUNG ADULT NONFICTION / Games & Activities / General"
  },
  {
    "Code": "YAN021010",
    "Literal": "YOUNG ADULT NONFICTION / Games & Activities / Puzzles & Word Games"
  },
  {
    "Code": "YAN021020",
    "Literal": "YOUNG ADULT NONFICTION / Games & Activities / Questions & Answers"
  },
  {
    "Code": "YAN022000",
    "Literal": "YOUNG ADULT NONFICTION / Gardening"
  },
  {
    "Code": "YAN023000",
    "Literal": "YOUNG ADULT NONFICTION / Girls & Women"
  },
  {
    "Code": "YAN024000",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / General"
  },
  {
    "Code": "YAN024010",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Beauty & Grooming"
  },
  {
    "Code": "YAN024020",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Diet & Nutrition"
  },
  {
    "Code": "YAN024030",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Diseases, Illnesses & Injuries"
  },
  {
    "Code": "YAN024040",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Fitness & Exercise"
  },
  {
    "Code": "YAN024050",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Maturing"
  },
  {
    "Code": "YAN024090",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Mindfulness & Meditation"
  },
  {
    "Code": "YAN024060",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Physical Impairments"
  },
  {
    "Code": "YAN024070",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Safety"
  },
  {
    "Code": "YAN024080",
    "Literal": "YOUNG ADULT NONFICTION / Health & Daily Living / Sexuality & Pregnancy"
  },
  {
    "Code": "YAN025000",
    "Literal": "YOUNG ADULT NONFICTION / History / General"
  },
  {
    "Code": "YAN025010",
    "Literal": "YOUNG ADULT NONFICTION / History / Africa"
  },
  {
    "Code": "YAN025020",
    "Literal": "YOUNG ADULT NONFICTION / History / Ancient"
  },
  {
    "Code": "YAN025030",
    "Literal": "YOUNG ADULT NONFICTION / History / Asia"
  },
  {
    "Code": "YAN025040",
    "Literal": "YOUNG ADULT NONFICTION / History / Australia & Oceania"
  },
  {
    "Code": "YAN025050",
    "Literal": "YOUNG ADULT NONFICTION / History / Canada"
  },
  {
    "Code": "YAN025060",
    "Literal": "YOUNG ADULT NONFICTION / History / Central & South America"
  },
  {
    "Code": "YAN025070",
    "Literal": "YOUNG ADULT NONFICTION / History / Europe"
  },
  {
    "Code": "YAN025080",
    "Literal": "YOUNG ADULT NONFICTION / History / Exploration & Discovery"
  },
  {
    "Code": "YAN025090",
    "Literal": "YOUNG ADULT NONFICTION / History / Holocaust"
  },
  {
    "Code": "YAN025100",
    "Literal": "YOUNG ADULT NONFICTION / History / Medieval"
  },
  {
    "Code": "YAN025110",
    "Literal": "YOUNG ADULT NONFICTION / History / Mexico"
  },
  {
    "Code": "YAN025120",
    "Literal": "YOUNG ADULT NONFICTION / History / Middle East"
  },
  {
    "Code": "YAN025130",
    "Literal": "YOUNG ADULT NONFICTION / History / Military & Wars"
  },
  {
    "Code": "YAN025140",
    "Literal": "YOUNG ADULT NONFICTION / History / Modern"
  },
  {
    "Code": "YAN025150",
    "Literal": "YOUNG ADULT NONFICTION / History / Prehistoric"
  },
  {
    "Code": "YAN025160",
    "Literal": "YOUNG ADULT NONFICTION / History / Renaissance"
  },
  {
    "Code": "YAN025170",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / General"
  },
  {
    "Code": "YAN025180",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / State & Local"
  },
  {
    "Code": "YAN025190",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / Colonial & Revolutionary Periods"
  },
  {
    "Code": "YAN025200",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / 19th Century"
  },
  {
    "Code": "YAN025210",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / Civil War Period (1850-1877)"
  },
  {
    "Code": "YAN025220",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / 20th Century"
  },
  {
    "Code": "YAN025230",
    "Literal": "YOUNG ADULT NONFICTION / History / United States / 21st Century"
  },
  {
    "Code": "YAN026000",
    "Literal": "YOUNG ADULT NONFICTION / Holidays & Celebrations"
  },
  {
    "Code": "YAN027000",
    "Literal": "YOUNG ADULT NONFICTION / House & Home"
  },
  {
    "Code": "YAN028000",
    "Literal": "YOUNG ADULT NONFICTION / Humor"
  },
  {
    "Code": "YAN029000",
    "Literal": "YOUNG ADULT NONFICTION / Inspirational & Personal Growth"
  },
  {
    "Code": "YAN030000",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / General"
  },
  {
    "Code": "YAN030010",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / Communication"
  },
  {
    "Code": "YAN030020",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / Composition & Creative Writing"
  },
  {
    "Code": "YAN030030",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / Grammar"
  },
  {
    "Code": "YAN030040",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / Journal Writing"
  },
  {
    "Code": "YAN030050",
    "Literal": "YOUNG ADULT NONFICTION / Language Arts / Vocabulary & Spelling"
  },
  {
    "Code": "YAN031000",
    "Literal": "YOUNG ADULT NONFICTION / Law & Crime"
  },
  {
    "Code": "YAN032000",
    "Literal": "YOUNG ADULT NONFICTION / LGBT"
  },
  {
    "Code": "YAN033000",
    "Literal": "YOUNG ADULT NONFICTION / Literary Criticism & Collections"
  },
  {
    "Code": "YAN034000",
    "Literal": "YOUNG ADULT NONFICTION / Mathematics / General"
  },
  {
    "Code": "YAN034010",
    "Literal": "YOUNG ADULT NONFICTION / Mathematics / Algebra"
  },
  {
    "Code": "YAN034020",
    "Literal": "YOUNG ADULT NONFICTION / Mathematics / Geometry"
  },
  {
    "Code": "YAN035000",
    "Literal": "YOUNG ADULT NONFICTION / Media Studies"
  },
  {
    "Code": "YAN036000",
    "Literal": "YOUNG ADULT NONFICTION / Media Tie-In"
  },
  {
    "Code": "YAN037000",
    "Literal": "YOUNG ADULT NONFICTION / Music / General"
  },
  {
    "Code": "YAN037010",
    "Literal": "YOUNG ADULT NONFICTION / Music / History"
  },
  {
    "Code": "YAN037020",
    "Literal": "YOUNG ADULT NONFICTION / Music / Instruction & Study"
  },
  {
    "Code": "YAN037030",
    "Literal": "YOUNG ADULT NONFICTION / Music / Popular"
  },
  {
    "Code": "YAN037040",
    "Literal": "YOUNG ADULT NONFICTION / Music / Rap & Hip Hop"
  },
  {
    "Code": "YAN037050",
    "Literal": "YOUNG ADULT NONFICTION / Music / Rock"
  },
  {
    "Code": "YAN007000",
    "Literal": "YOUNG ADULT NONFICTION / Paranormal & Supernatural"
  },
  {
    "Code": "YAN038000",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / General"
  },
  {
    "Code": "YAN038010",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / Aboriginal & Indigenous"
  },
  {
    "Code": "YAN038020",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / Africa"
  },
  {
    "Code": "YAN038030",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / Asia"
  },
  {
    "Code": "YAN038040",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / Australia & Oceania"
  },
  {
    "Code": "YAN038050",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / Canada"
  },
  {
    "Code": "YAN038060",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / Caribbean & Latin America"
  },
  {
    "Code": "YAN038070",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / Europe"
  },
  {
    "Code": "YAN038080",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / Mexico"
  },
  {
    "Code": "YAN038090",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / Middle East"
  },
  {
    "Code": "YAN038100",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / United States / General"
  },
  {
    "Code": "YAN038110",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / United States / African American"
  },
  {
    "Code": "YAN038120",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / United States / Asian American"
  },
  {
    "Code": "YAN038130",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / United States / Hispanic & Latino"
  },
  {
    "Code": "YAN038140",
    "Literal": "YOUNG ADULT NONFICTION / People & Places / United States / Native American"
  },
  {
    "Code": "YAN039000",
    "Literal": "YOUNG ADULT NONFICTION / Performing Arts / General"
  },
  {
    "Code": "YAN039010",
    "Literal": "YOUNG ADULT NONFICTION / Performing Arts / Dance"
  },
  {
    "Code": "YAN039020",
    "Literal": "YOUNG ADULT NONFICTION / Performing Arts / Film"
  },
  {
    "Code": "YAN039030",
    "Literal": "YOUNG ADULT NONFICTION / Performing Arts / Television & Radio"
  },
  {
    "Code": "YAN039040",
    "Literal": "YOUNG ADULT NONFICTION / Performing Arts / Theater & Musicals"
  },
  {
    "Code": "YAN040000",
    "Literal": "YOUNG ADULT NONFICTION / Personal Finance"
  },
  {
    "Code": "YAN041000",
    "Literal": "YOUNG ADULT NONFICTION / Philosophy"
  },
  {
    "Code": "YAN042000",
    "Literal": "YOUNG ADULT NONFICTION / Photography"
  },
  {
    "Code": "YAN043000",
    "Literal": "YOUNG ADULT NONFICTION / Poetry"
  },
  {
    "Code": "YAN044000",
    "Literal": "YOUNG ADULT NONFICTION / Public Speaking & Presentation"
  },
  {
    "Code": "YAN045000",
    "Literal": "YOUNG ADULT NONFICTION / Recycling & Green Living"
  },
  {
    "Code": "YAN046000",
    "Literal": "YOUNG ADULT NONFICTION / Reference"
  },
  {
    "Code": "YAN047000",
    "Literal": "YOUNG ADULT NONFICTION / Religion / General"
  },
  {
    "Code": "YAN047010",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Agnosticism & Atheism"
  },
  {
    "Code": "YAN047020",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Biblical Stories & Studies"
  },
  {
    "Code": "YAN047030",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Buddhism"
  },
  {
    "Code": "YAN047040",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Christianity"
  },
  {
    "Code": "YAN047050",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Eastern"
  },
  {
    "Code": "YAN047060",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Hinduism"
  },
  {
    "Code": "YAN047070",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Islam"
  },
  {
    "Code": "YAN047080",
    "Literal": "YOUNG ADULT NONFICTION / Religion / Judaism"
  },
  {
    "Code": "YAN048000",
    "Literal": "YOUNG ADULT NONFICTION / Religious / Christian / General"
  },
  {
    "Code": "YAN048010",
    "Literal": "YOUNG ADULT NONFICTION / Religious / Christian / Dating"
  },
  {
    "Code": "YAN048020",
    "Literal": "YOUNG ADULT NONFICTION / Religious / Christian / Devotional & Prayer"
  },
  {
    "Code": "YAN048030",
    "Literal": "YOUNG ADULT NONFICTION / Religious / Christian / Family & Relationships"
  },
  {
    "Code": "YAN048040",
    "Literal": "YOUNG ADULT NONFICTION / Religious / Christian / Inspirational"
  },
  {
    "Code": "YAN049000",
    "Literal": "YOUNG ADULT NONFICTION / School & Education"
  },
  {
    "Code": "YAN050000",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / General (see also headings under Animals or Technology)"
  },
  {
    "Code": "YAN050010",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Anatomy & Physiology"
  },
  {
    "Code": "YAN050020",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Astronomy"
  },
  {
    "Code": "YAN050030",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Biology"
  },
  {
    "Code": "YAN050040",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Botany"
  },
  {
    "Code": "YAN050050",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Chemistry"
  },
  {
    "Code": "YAN050060",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Disasters"
  },
  {
    "Code": "YAN050070",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Earth Sciences"
  },
  {
    "Code": "YAN050080",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Environmental Conservation & Protection"
  },
  {
    "Code": "YAN050090",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Environmental Science & Ecosystems"
  },
  {
    "Code": "YAN050100",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Experiments & Projects"
  },
  {
    "Code": "YAN050110",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / History of Science"
  },
  {
    "Code": "YAN050120",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Physics"
  },
  {
    "Code": "YAN050130",
    "Literal": "YOUNG ADULT NONFICTION / Science & Nature / Zoology"
  },
  {
    "Code": "YAN058000",
    "Literal": "YOUNG ADULT NONFICTION / Social Activism & Volunteering"
  },
  {
    "Code": "YAN052000",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / General"
  },
  {
    "Code": "YAN052010",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Archaeology"
  },
  {
    "Code": "YAN052020",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Customs, Traditions, Anthropology"
  },
  {
    "Code": "YAN052030",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Folklore & Mythology"
  },
  {
    "Code": "YAN052040",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Politics & Government"
  },
  {
    "Code": "YAN052050",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Psychology"
  },
  {
    "Code": "YAN052060",
    "Literal": "YOUNG ADULT NONFICTION / Social Science / Sociology"
  },
  {
    "Code": "YAN051000",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / General (see also headings under Family)"
  },
  {
    "Code": "YAN051010",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Assimilation"
  },
  {
    "Code": "YAN051020",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Bullying"
  },
  {
    "Code": "YAN051270",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Civil & Human Rights"
  },
  {
    "Code": "YAN051030",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Class Differences"
  },
  {
    "Code": "YAN051280",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Compulsive Behavior"
  },
  {
    "Code": "YAN051040",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Dating & Sex"
  },
  {
    "Code": "YAN051050",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Death, Grief, Bereavement"
  },
  {
    "Code": "YAN051060",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Depression & Mental Illness"
  },
  {
    "Code": "YAN051070",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Drugs, Alcohol, Substance Abuse"
  },
  {
    "Code": "YAN051080",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Eating Disorders & Body Image"
  },
  {
    "Code": "YAN051090",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Emigration & Immigration"
  },
  {
    "Code": "YAN051100",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Emotions & Feelings"
  },
  {
    "Code": "YAN051110",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Friendship"
  },
  {
    "Code": "YAN051120",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Homelessness & Poverty"
  },
  {
    "Code": "YAN051140",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Manners & Etiquette"
  },
  {
    "Code": "YAN051150",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Peer Pressure"
  },
  {
    "Code": "YAN051160",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Physical & Emotional Abuse (see also Social Topics / Sexual Abuse)"
  },
  {
    "Code": "YAN051170",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Pregnancy"
  },
  {
    "Code": "YAN051180",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Prejudice & Racism"
  },
  {
    "Code": "YAN051190",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Runaways"
  },
  {
    "Code": "YAN051200",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Self-Esteem & Self-Reliance"
  },
  {
    "Code": "YAN051210",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Self-Mutilation"
  },
  {
    "Code": "YAN051220",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Sexual Abuse"
  },
  {
    "Code": "YAN051240",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Suicide"
  },
  {
    "Code": "YAN051250",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Values & Virtues"
  },
  {
    "Code": "YAN051260",
    "Literal": "YOUNG ADULT NONFICTION / Social Topics / Violence"
  },
  {
    "Code": "YAN053000",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / General"
  },
  {
    "Code": "YAN053010",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Baseball & Softball"
  },
  {
    "Code": "YAN053020",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Basketball"
  },
  {
    "Code": "YAN053030",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Camping & Outdoor Activities"
  },
  {
    "Code": "YAN053040",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Extreme Sports"
  },
  {
    "Code": "YAN053050",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Football"
  },
  {
    "Code": "YAN053060",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Hockey"
  },
  {
    "Code": "YAN053070",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Martial Arts"
  },
  {
    "Code": "YAN053080",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Motor Sports"
  },
  {
    "Code": "YAN053090",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Olympics & Paralympics"
  },
  {
    "Code": "YAN053100",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Soccer"
  },
  {
    "Code": "YAN053110",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Track & Field"
  },
  {
    "Code": "YAN053120",
    "Literal": "YOUNG ADULT NONFICTION / Sports & Recreation / Winter Sports"
  },
  {
    "Code": "YAN054000",
    "Literal": "YOUNG ADULT NONFICTION / Study Aids / General"
  },
  {
    "Code": "YAN054010",
    "Literal": "YOUNG ADULT NONFICTION / Study Aids / Book Notes (see also STUDY AIDS / Book Notes)"
  },
  {
    "Code": "YAN054020",
    "Literal": "YOUNG ADULT NONFICTION / Study Aids / Test Preparation"
  },
  {
    "Code": "YAN055000",
    "Literal": "YOUNG ADULT NONFICTION / Technology / General"
  },
  {
    "Code": "YAN055010",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Aeronautics, Astronautics & Space Science"
  },
  {
    "Code": "YAN055020",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Agriculture"
  },
  {
    "Code": "YAN055030",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Electricity & Electronics"
  },
  {
    "Code": "YAN055040",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Inventions"
  },
  {
    "Code": "YAN055050",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Machinery & Tools"
  },
  {
    "Code": "YAN055060",
    "Literal": "YOUNG ADULT NONFICTION / Technology / Robotics"
  },
  {
    "Code": "YAN056000",
    "Literal": "YOUNG ADULT NONFICTION / Transportation / General"
  },
  {
    "Code": "YAN056010",
    "Literal": "YOUNG ADULT NONFICTION / Transportation / Aviation"
  },
  {
    "Code": "YAN056020",
    "Literal": "YOUNG ADULT NONFICTION / Transportation / Boats, Ships & Underwater Craft"
  },
  {
    "Code": "YAN056030",
    "Literal": "YOUNG ADULT NONFICTION / Transportation / Cars & Trucks"
  },
  {
    "Code": "YAN057000",
    "Literal": "YOUNG ADULT NONFICTION / Travel"
  },
  {
    "Code": "NON000000",
    "Literal": "NON-CLASSIFIABLE"
  }
]

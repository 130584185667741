import * as React from "react";
import {ReactNode} from "react";
import {Image, View} from "react-native";
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined';
import Button from "@material-ui/core/Button";
import MuiLink from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import LocalLibraryOutlinedIcon from "@material-ui/icons/LocalLibraryOutlined";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";

// Local
import {userState} from "../store/atoms";
import {hyphenateSearchQuery, unhyphenateSearchQuery} from "../utils";
import {auth} from "../firebase";
import {Strings} from "../constants";
import Footer from "./Footer";

const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: {
      justifyContent: "space-between",
      backgroundColor: "white",
    },
    navBar: {
      display: "flex",
      flexDirection: "row",
      //justifyContent: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      width: "auto",
      height: 56,
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(1/2),
        paddingRight: theme.spacing(1/2),
      },
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.black, 0.065),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.black, 0.115),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "#333",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: theme.spacing(2),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "40ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    navLogoButton: {
      color: theme.palette.primary.main,
      textTransform: "capitalize",
      fontSize: 18,
      fontWeight: "bold",
    },
    navButton: {
      textTransform: "capitalize",
      //fontSize: 17,
      fontWeight: "bold",
      marginLeft: theme.spacing(1.5),
    },
    navContainer: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

interface HeaderLinkProps {
  iconOnly?: boolean;
  href?: string;
  icon?: ReactNode;
  label: string;
  to?: string;
  onClick?: () => void;
  color?: "primary" | "secondary" | "inherit";
  variant?: "text" | "outlined" | "contained";
}

function HeaderLink(props: HeaderLinkProps) {
  const classes = useHeaderStyles();
  const {
    color = "primary",
    href = null,
    icon,
    iconOnly = false,
    label,
    to = null,
    onClick = null,
    variant = "text",
  } = props;
  return (
    <Box>
      {
        iconOnly ? (
          onClick ? (
            <IconButton color={color} onClick={onClick}>
              {icon ?? null}
            </IconButton>
          ) : to ? (
            <IconButton
              color={color}
              component={Link}
              to={to}
              style={{textDecoration: "none"}}
            >
              {icon ?? null}
            </IconButton>
          ) : href ? (
            <IconButton color={color} component={MuiLink} href={href}>
              {icon ?? null}
            </IconButton>
          ) : (
            <IconButton color={color}>{icon ?? null}</IconButton>
          )
        ) : (
          onClick ? (
            <Button
              className={classes.navButton}
              variant={variant}
              color={color}
              onClick={onClick}
              startIcon={icon ?? null}
            >
              {label}
            </Button>
          ) : to ? (
            <Button
              className={classes.navButton}
              variant={variant}
              color={color}
              component={Link}
              to={to}
              startIcon={icon ?? null}
            >
              {label}
            </Button>
          ) : href ? (
            <Button
              className={classes.navButton}
              color={color}
              component={MuiLink}
              href={href}
              startIcon={icon ?? null}
            >
              {label}
            </Button>
          ) : (
            <Button
              className={classes.navButton}
              variant={variant}
              color={color}
              startIcon={icon ?? null}
            >
              {label}
            </Button>
          )
        )
      }
    </Box>
  );
}

function Header(props: { transparentHeaderBg: boolean }) {
  const {transparentHeaderBg} = props;
  const classes = useHeaderStyles();
  // @ts-ignore
  const smDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const {query: queryParam = ""} = useParams();
  const user = useRecoilValue(userState);
  const [searchQuery, setSearchQuery] = React.useState<string>(
    unhyphenateSearchQuery(queryParam)
  );
  return (
    <AppBar
      style={transparentHeaderBg ? {backgroundColor: "transparent"} : {}}
      position={transparentHeaderBg ? "fixed" : "static"}
      elevation={transparentHeaderBg ? 0 : 1}
    >
      <Toolbar
        className={classes.toolbar}
        style={transparentHeaderBg ? {backgroundColor: "transparent"} : {}}
      >
        <Hidden smDown>
          <Button
            className={classes.navLogoButton}
            color="inherit"
            component={Link}
            to="/"
          >
            <Image
              style={{height: 40, width: 180}}
              resizeMode="contain"
              source={require("../assets/logo.png")}/>
          </Button>
        </Hidden>
        {(
          <form
            onSubmit={() => {
              if (searchQuery.length > 0) {
                const query = hyphenateSearchQuery(searchQuery.trim());
                navigate("/search/" + query);
              }
            }}
          >
            <div className={classes.search}>
              <InputBase
                placeholder={Strings.searchPlaceholder}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={searchQuery}
                onChange={({target: {value}}) => {
                  setSearchQuery(value);
                }}
                inputProps={{"aria-label": "search"}}
              />
              <IconButton
                onClick={() => {
                  if (searchQuery.length > 0) {
                    const query = hyphenateSearchQuery(searchQuery.trim());
                    navigate("/search/" + query);
                  }
                }}
              >
                <SearchIcon/>
              </IconButton>
            </div>
          </form>
        )}
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <Hidden smDown>
            {user.status === "USER_NOT_LOGGED_IN" && (
              <HeaderLink
                to="/sign-in"
                label="Sign In"
                variant="outlined"
                icon={<PersonOutlinedIcon/>}
              />
            )}
          </Hidden>
          {user.status === "USER_NOT_LOGGED_IN" && (
            <HeaderLink
              color="secondary"
              to="/sign-up"
              label="Sign Up"
              iconOnly={smDown}
              variant="contained"
              icon={<PersonAddOutlinedIcon/>}
            />
          )}
          {user.status === "USER_LOGGED_IN" && (
            <HeaderLink
              onClick={() => auth.signOut()}
              label="Logout"
              variant="contained"
              icon={<ExitToAppOutlinedIcon/>}
            />
          )}
        </View>
      </Toolbar>
      <Box className={classes.navBar}>
        <Hidden smDown>
          <HeaderLink
            color="inherit"
            to="/browse"
            label="Browse Subjects"
            icon={<ExploreOutlinedIcon/>}
          />
        </Hidden>
        <HeaderLink
          color="inherit"
          to={user.status === "USER_LOGGED_IN" ? "/bookshelf" : "/sign-up/bookshelf"}
          label="Bookshelf"
          icon={<LocalLibraryOutlinedIcon/>}
        />
        <HeaderLink
          color="inherit"
          to="/redeem"
          label="Redeem"
          icon={<RedeemOutlinedIcon/>}
        />
      </Box>
    </AppBar>
  );
}

interface Props {
  fullWidth?: boolean;
  loading?: boolean;
  transparentHeaderBg?: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  bottomNav: {
    width: "100%",
    position: "fixed",
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 10,
  },
});

export default function Page(props: Props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  
  // Make sure to update value when the pathname changes without a tab being pressed
  React.useEffect(() => {
    window.scrollTo(0, 0);
    switch (pathname) {
      case "":
        setValue(0);
        break;
      case "/":
        setValue(0);
        break;
      case "browse":
        setValue(1);
        break;
      case "/browse":
        setValue(1);
        break;
      default:
    }
  }, [pathname]);
  
  const {
    children,
    fullWidth = false,
    loading = false,
    transparentHeaderBg = false,
  } = props;
  return (
    <Box
      style={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Header transparentHeaderBg={transparentHeaderBg}/>
      <View style={{flex: 1}}>
        {loading ? (
          <LinearProgress/>
        ) : (
          <div
            style={{
              width: "100%",
              maxWidth: fullWidth ? "100%" : 1120,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {children}
          </div>
        )}
      </View>
      <Footer/>
      <Hidden mdUp>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            switch (newValue) {
              case 0:
                navigate("/");
                break;
              case 1:
                navigate("/browse");
                break;
              default:
            }
          }}
          showLabels
          className={classes.bottomNav}
        >
          <BottomNavigationAction label="Home" icon={<HomeOutlinedIcon/>}/>
          <BottomNavigationAction
            label="Browse Subjects"
            icon={<ExploreOutlinedIcon/>}
          />
        </BottomNavigation>
      </Hidden>
    </Box>
  );
}

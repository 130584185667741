const Colors = {
  primary: "#063b66",
  accent: "#fe5722",
};

const isDev = false

const FUNCTIONS_URL = isDev ? "http://localhost:5000/sharecaribbean/us-central1" : "https://us-central1-sharecaribbean.cloudfunctions.net"

const EndPoints = {
  findBook: `${FUNCTIONS_URL}/findBook`,
  getVitalSourceBook: `${FUNCTIONS_URL}/getVitalSourceBook`,
  getVitalSourceBookshelfURL: `${FUNCTIONS_URL}/getVitalSourceBookshelfURL`,
  getVitalSourceCredentials: `${FUNCTIONS_URL}/getVitalSourceCredentials`,
  verifyVitalSourceCredentials: `${FUNCTIONS_URL}/verifyVitalSourceCredentials`,
}

const Strings = {
  company: "Caribbean Shared Educational Resources Service",
  url: "https://ebooks.studenteportal.com/",
  apiKeyLive: "6UJ73YVZQ4YNX3WS",
  apiKeyTest: "26RPHRND7J2BS2BA",
  apiKey: "6UJ73YVZQ4YNX3WS",
  apiReference: "SHARE-CARIBBEAN",
  userStorageKey: "@user_Storage_Key",
  cartStorageKey: "@cart_Storage_Key",
  corsProxy3: "https://cors-anywhere.herokuapp.com/",
  corsProxy2: "http://alloworigin.com/get?url=",
  corsProxy: "",
  searchPlaceholder: "Search by Title, Author or ISBN",
  mMoneyAPIKeyTesting:
    "M0gMTO1cyF8a26kLfbWEfPwSI8HxosEsFpdEtPivLpuHmQL3jsQVBJh91uAfLPeU",
  mMoneyAPIKey:
    "f_ckPUEvaRCYmI0Xz5BEawh6hXcr2h-PXxREDNnZn4iK-1E7GxmvvykQCp4LiQO0",
  payPalClientIDSandBox:
    "AfYq4kT6ZPWKXsv9L62YzIJWz0gm_h2QWEX9VTolUIZQcnKPrqtYfIuiE90NRY86CVeGnxQCtHrbPWas",
  payPalClientIDLive:
    "AVHlGpJTqFfogNfeCaurUejigqqpjGTsI2Ur14F7Pk9QsjWfPQC2MxHM6SpW26oEzXvLjOFp5Me181fq",
};

export { Colors, EndPoints, Strings };

import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Local
import AuthenticatingRoute from "../components/AuthenticatingRoute";
import PrivateRoute from "../components/PrivateRoute";
import Home from "../pages/Home";
import SearchResults from "../pages/SearchResults";
import Product from "../pages/Product";
import Browse from "../pages/Browse";
import BrowseSubject from "../pages/BrowseSubject";
import Checkout from "../pages/Checkout";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import Bookshelf from "../pages/Bookshelf";
import About from "../pages/About";
import FAQ from "../pages/FAQ";
import Privacy from "../pages/Privacy";
import Redeem from "../pages/Redeem";
import Terms from "../pages/Terms";
import NotFound from "../pages/NotFound";
import FunctionsTest from "../pages/FunctionsTest";

function Navigator() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search/:query" element={<SearchResults />} />
        <Route path="/product/:vbid" element={<Product />} />
        <Route path="browse" element={<Browse />} />
        <Route path="browse/:shortcode" element={<BrowseSubject />} />
        <Route path="about" element={<About />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="redeem" element={<Redeem />} />
        <Route path="terms" element={<Terms />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="test" element={<FunctionsTest />} />
        <Route path="*" element={<NotFound />} />
        <PrivateRoute
          path="/checkout/:vbid/:variant"
          element={<Checkout />}
          redirect="/sign-up/browse"
        />
        <PrivateRoute
          path="bookshelf"
          element={<Bookshelf />}
          redirect="/sign-up/bookshelf"
        />
        <PrivateRoute
          path="bookshelf/:code"
          element={<Bookshelf />}
          redirect="/sign-up/bookshelf"
        />
        <AuthenticatingRoute path="sign-in" element={<SignIn />} />
        <AuthenticatingRoute path="sign-in/:redirect" element={<SignIn />} />
        <AuthenticatingRoute
          path="sign-in/:redirect/:redirect1"
          element={<SignIn />}
        />
        <AuthenticatingRoute path="sign-up" element={<SignUp />} />
        <AuthenticatingRoute path="sign-up/:redirect" element={<SignUp />} />
        <AuthenticatingRoute
          path="sign-up/:redirect/:redirect1"
          element={<SignUp />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigator;

import React from 'react';

// Local
import Page from "../components/Page";

const About = () => {
  return (
    <Page>
      <div>
        About
      </div>
    </Page>
  );
};

export default About;
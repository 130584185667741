import React from 'react';
import Button from "@material-ui/core/Button";

const FunctionsTest = () => {
  return (
    <div style={{
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: 'center',
      paddingTop: 200,
    }}>
      <Button
        variant="contained"
        color="primary"
        onClick={()=> {
          fetch("http://localhost:5001/sharecaribbean/us-central1/helloWorld", {
            method: "GET",
          })
            .then((response: any) => {
              return response.json();
            })
            .then((result: any) => {
              console.log(result)
            })
            .catch((err: any) => {
              console.log(err)
            });
        }}>
        Test
      </Button>
    </div>
  );
}

export default FunctionsTest;

import React from 'react';
import {Image, View} from "react-native";
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MUILink from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import {Link} from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useRecoilValue} from "recoil";

//
import Copyright from "./Copyright";
import {userState} from "../store/atoms";

const MAX_WIDTH = 1128;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.primary.dark,
      marginTop: 16,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 56
      },
    },
    grid: {
      flexGrow: 1,
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    container: {
      maxWidth: MAX_WIDTH
    },
    item: {
      marginTop: -8,
      marginBottom: -8
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    title: {
      fontSize: 13,
      fontWeight: "bold",
      color: "#e5e5e5",
      marginBottom: theme.spacing(),
      textTransform: "uppercase"
    },
    link: {
      color: "#fff",
      textTransform: "capitalize",
      marginTop: -16,
      marginBottom: -16,
    },
    contactInfo: {
      color: "#fff",
      marginTop: -16,
      marginBottom: -16,
    },
    icons: {
      marginTop: theme.spacing(1)
    },
    icon: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(),
      borderRadius: 4
    },
    button: {
      color: "white",
      textTransform: "capitalize",
      marginTop: -8,
      marginBottom: -8,
    },
    copyrightBar: {
      //flexGrow: 1,
      backgroundColor: "#04345b",
      padding: theme.spacing(2)
    },
    copyrightContent: {
      maxWidth: MAX_WIDTH,
      minWidth: 200,
      margin: "auto",
    },
    payments: {
      [theme.breakpoints.down("sm")]: {
        marginTop: 16,
        marginBottom: 16,
      },},
  }),
);

export default function Footer() {
  const classes = useStyles();
  const user = useRecoilValue(userState);
  // @ts-ignore
  const smDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const quickLinks = [
    {title: "Home", to: "/"},
    {title: "Browse Subjects", to: "/browse"},
    {title: "Bookshelf", to: user.status === "USER_LOGGED_IN" ? "/bookshelf" : "/sign-up/bookshelf"},
  ];
  const infoLinks = [
    {title: "FAQ", to: "/faq"},
    {title: "Redeem", to: "/redeem"},
    {title: "Privacy Policy", to: "/privacy"},
    {title: "Terms & Conditions", to: "/terms"},
  ];
  const contactInfo = [
    {title: "Email: support@csers.info"},
    {title: "Address: PO Box 964E, St Michael Barbados"},
  ];
  const socialLinks = [
    {icon: <FacebookIcon className={classes.icon}/>, url: "http://www.facebook.com/studenteportal"},
    {icon: <InstagramIcon className={classes.icon}/>, url: "http://www.instagram.com/studenteportal"},
    {icon: <YouTubeIcon className={classes.icon}/>, url: "https://www.youtube.com/channel/UCjoyyg7HU3VKe2wTQPx-48w"},
  ];
  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid className={classes.grid} container spacing={4}>
          <Grid item xs={12} md={3}>
            <View
              style={{
                width: 200,
                height: 60,
                backgroundColor: "white",
                borderRadius: 4,
                padding: 8,
                marginTop: 24}}>
              <Image
                style={{width: "100%", height: "100%"}}
                resizeMode="contain"
                source={require("../assets/logo.png")}/>
            </View>
          </Grid>
          <Grid item xs={12} md={3}>
            <List>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    className: classes.title
                  }}
                  primary="Quick Links"/>
              </ListItem>
              {quickLinks.map((link, index) => (
                <ListItem
                  className={classes.link}
                  key={index.toString()}
                  component={Link}
                  to={link.to}>
                  <ListItemText primary={link.title}/>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={3}>
            <List>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    className: classes.title
                  }}
                  primary="Information"/>
              </ListItem>
              {infoLinks.map((link, index) => (
                <ListItem
                  className={classes.link}
                  key={index.toString()}
                  component={Link}
                  to={link.to}>
                  <ListItemText primary={link.title}/>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={3}>
            <List>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    className: classes.title
                  }}
                  primary="Contact Us"/>
              </ListItem>
              {contactInfo.map((link, index) => (
                <ListItem
                  className={classes.contactInfo}
                  key={index.toString()}>
                  <ListItemText primary={link.title}/>
                </ListItem>
              ))}
              <Grid className={classes.icons} container>
                {socialLinks.map((link, index)=> (
                  <IconButton key={index.toString()} component={MUILink} href={link.url}>
                    {link.icon}
                  </IconButton>
                ))}
              </Grid>
            </List>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.copyrightBar}>
        <Grid className={classes.copyrightContent} container>
          <Grid container item xs={12} md={6} justify={smDown ? "center" : "flex-start"} alignItems="center">
            <Copyright dark/>
          </Grid>
          <Grid className={classes.payments} container item xs={12} md={6} justify={smDown ? "center" : "flex-end"} spacing={2}>
            <Grid item>
              <View
                style={{
                  width: 75,
                  height: 30,
                  backgroundColor: "white",
                  borderRadius: 4,
                  padding: 4,
                }}>
              <Image
                style={{width: "100%", height: "100%"}}
                resizeMode="contain"
                source={require("../assets/payment-visa.jpg")}/>
              </View>
            </Grid>
            <Grid item>
              <View
                style={{
                  width: 75,
                  height: 30,
                  backgroundColor: "white",
                  borderRadius: 4,
                  padding: 4,
                }}>
                <Image
                  style={{width: "100%", height: "100%"}}
                  resizeMode="contain"
                  source={require("../assets/payment-mastercard.png")}/>
              </View>
            </Grid>
            <Grid item>
              <View
                style={{
                  width: 75,
                  height: 30,
                  backgroundColor: "white",
                  borderRadius: 4,
                  padding: 4,
                }}>
                <Image
                  style={{width: "100%", height: "100%"}}
                  resizeMode="contain"
                  source={require("../assets/payment-paypal.png")}/>
              </View>
            </Grid>
            <Grid item>
              <View
                style={{
                  width: 75,
                  height: 30,
                  backgroundColor: "white",
                  borderRadius: 4,
                  padding: 4,
                }}>
              <Image
                style={{width: "100%", height: "100%"}}
                resizeMode="contain"
                source={require("../assets/payment-mmoney.png")}/>
              </View>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

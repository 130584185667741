import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";

// Local
import Page from "../components/Page";
import BR from "../components/BR";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 792,
      margin: "auto",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  }),
);

const Terms = () => {
  const classes = useStyles();
  return (
    <Page>
      <div className={classes.root}>
        <Typography variant="h4">Terms and Conditions of Use</Typography>
        <br/>
        <Typography>
        This web site (the "Site") is owned and operated by CSERS, a division of Datalore Inc. Registered Office: #10 Waterhall Terrace #2, St James, Barbados, Registered in Barbados. (“Datalore Inc.,” “we,” “us” or “our”).
        </Typography>
        <BR/>
        <Typography>
        By accessing or using the Site, you agree to be bound by the terms and conditions below (the “Terms and Conditions”). These Terms and Conditions expressly incorporate by reference and include the Site’s <Link to="/privacy">Privacy Policy</Link> and any guidelines, rules or disclaimers that may be posted and updated on specific webpages or on notices that are sent to you. If you do not agree with these Terms and Conditions, please do not use this Site.
        </Typography>
        <BR/>
        <Typography>
        Datalore Inc. reserves the right to change, modify, add or remove portions of these Terms and Conditions in its sole discretion at any time and without prior notice. Please check this page periodically for any modifications. Your continued use of this Site following the posting of any changes will mean that you have accepted the changes.
          </Typography>
        <br/>
        <br/>
        <Typography>
        PRIVACY
          </Typography>
        <br/>
        <Typography>
        Please review our <Link to="/privacy">Privacy Policy</Link>, which also governs your use of the Site, to understand our practices.
        </Typography>
        <br/>
        <br/>
        <Typography>
        COPYRIGHT
        </Typography>
        <br/>
        <Typography>
        All content in or made available through this Site, including site layout, design, images, programs, text, logos, button icons, audio clips, graphics, digital downloads, data compilations and other information (collectively, the “Content”) is the property of Datalore Inc. and its affiliated companies or its licensors and is protected by copyright and other intellectual property laws.
        </Typography>
        <br/>
        <br/>
        <Typography>
        TRADEMARKS
        </Typography>
        <br/>
        <Typography>
        All trademarks reproduced in this Site, which are not the property of, or licensed to the operator, are acknowledged on the Site.
        </Typography>
        <br/>
        <br/>
        <Typography>
        LIMITATIONS ON USE
        </Typography>
        <br/>
        <Typography>
        Subject to your compliance with these Terms and Conditions and your payment of any applicable fees, Datalore Inc. or its content providers grant you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal and non-commercial use of the Site Content. This license does not include any resale or commercial use of any Content.
        </Typography>
        <BR/>
        <Typography>
        All rights not expressly granted to you in these Terms and Conditions are reserved and retained by Datalore Inc. or its licensors, suppliers, publishers, rightsholders, or other content providers.
        </Typography>
        <BR/>
        <Typography>
        You may not copy, display, distribute, modify, publish, reproduce, store, transmit, create derivative works from, or sell or license all or any part of the Content, products or services obtained from this Site in any medium to anyone, except as otherwise expressly permitted under applicable law or as described in these Terms and Conditions or relevant license or subscriber agreement.
          </Typography>
        <BR/>
        <Typography>
        You may not print or download Content from the Site unless expressly provided for by the content provider, and then only for your own personal, non-commercial use and provided that you keep intact all copyright and other proprietary notices. You may not engage in systematic retrieval of Content from the Site to create or compile, directly or indirectly, a collection, compilation, database or directory without prior written permission from Datalore Inc.
        </Typography>
        <BR/>
        <Typography>
        You may not use any robots, spiders, crawlers or other automated downloading programs or devices to: (i) continuously and automatically search, scrape, extract or index any Content, unless authorized by us; (ii) harvest personal information from the Site for purposes of sending unsolicited or unauthorized material; or (iii) cause disruption to the working of the Site. If this Site contains robot exclusion files or robot exclusion headers, you agree to honor them and not use any device, software or routine to bypass them.
        </Typography>
        <BR/>
        <Typography>
        You may not use the services on the Site to publish or distribute any information (including software or other content) which is illegal, which violates or infringes upon the rights of any other person, which is defamatory, abusive, hateful, profane, pornographic, threatening or vulgar, which contains errors, viruses or other harmful components, or which is otherwise actionable at law. Datalore Inc. may at any time exercise editorial control over the Content of the Site.
        </Typography>
        <BR/>
        <Typography>
        You may not, without the approval of Datalore Inc., use the Site to publish or distribute any advertising, promotional material, or solicitation to other users of the Site to use any goods or services. For example (but without limitation to the foregoing), you may not use the Site to conduct any business, to solicit the performance of any activity that is prohibited by law, or to solicit other users to become subscribers of other information services. Similarly, you may not use the Site to download and redistribute public information or shareware or distribute multiple copies of public domain information or shareware.
        </Typography>
        <BR/>
        <Typography>
        You may not misuse the Site. You may use the Site only as permitted by law. The licenses granted by Datalore Inc. terminate if you do not comply with these Terms and Conditions. Unauthorised use of the Site may give rise to a claim for damages and/or be a criminal offence.
        </Typography>
        <br/>
        <br/>
        <Typography>
        YOUR ACCOUNT
          </Typography>
        <br/>
        <Typography>
        You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device, and you agree to accept responsibility for all activities that occur under your account or password. Datalore Inc. does sell products for children, but it sells them to adults, who can purchase with a credit card or other permitted payment method. If you are under 18, you may use this Site only with involvement of a parent or guardian. Datalore Inc. reserves the right to refuse service, terminate accounts, remove or edit content, or cancel orders in its sole discretion.
          </Typography>
            <br/>
            <br/>
        <Typography>
        COMMUNICATIONS AND CONTENT POSTED ON OR THROUGH THE SITE
          </Typography>
            <br/>
            <Typography>
        Users may submit suggestions, ideas, comments, questions, or other information, so long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam."
            </Typography>
        <BR/>
        <Typography>
        We do not claim ownership of any material that you provide to us (including feedback and suggestions) or post, upload, input or submit on or through this Site for review by the general public or by the members of any public or private community (“Submission”) and we are not responsible for its content or accuracy. However, by posting, uploading, inputting, providing or submitting (“Posting”) your Submission you grant us, our affiliated companies and sublicensees permission to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, display and otherwise use your Submission in any format in connection with the operation of our respective businesses (including, without limitation, the Site) throughout the world. You grant Datalore Inc. and sublicensees the right to use the name that you submit in connection with such content, if they choose. No compensation will be paid with respect to the use of your Submission. We are under no obligation to display or otherwise use any Submission you may provide, and we may remove any Submission at any time in our sole discretion. By Posting a Submission, you also warrant and represent that you own or otherwise control all of the rights to your Submission including, without limitation, all the rights necessary for granting the permission specified above.
        </Typography>
        <BR/>
        <Typography>
        You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of your Submission or other content. Datalore Inc. has the right but not the obligation to monitor Submissions to determine compliance with these Terms and Conditions and any operating rules we establish and to satisfy any law, regulation or authorized government request. We shall have the right in our sole discretion to edit, refuse to post, or remove any Submission. Datalore Inc. takes no responsibility and assumes no liability for any content posted by you or any third party.
        </Typography>
        <br/>
        <br/>
        <Typography>
        SYSTEMS RELIABILITY
        </Typography>
        <br/>
        <Typography>
        Datalore Inc. aims to keep the Site available twenty-four (24) hours a day, seven (7) days a week and to maintain saved information. However, due to technical failures, natural phenomena or routine maintenance, availability may be limited and/or information may be lost. Datalore Inc. shall not be liable for lost information or non-availability of the services.
        </Typography>
  <br/>
  <br/>
        <Typography>
        REPLACEMENT POLICY
        </Typography>
        <br/>
        <Typography>
        Due to the nature of our products, it is not our policy to issue returns or refunds; however, in the following situations, and at our sole discretion, Datalore Inc. may replace your purchase subsequent to verification of the issue and/or deactivation of the previously purchased product:
        </Typography>
        <Typography>
          <ol>
            <li>
              A non-functioning or duplicated redemption code has been issued in error;
            </li>
            <BR/>
            <li>
              The product has not been successfully delivered to your account or device due to electrical or other failure or other unforeseen circumstances.
            </li>
          </ol>
        </Typography>
        <br/>
        <Typography>
        The following conditions must also be met for any returns to be processed:
          </Typography>
        <Typography>
          <ul>
            <li> You have 14 calendar days to return an item from the date you purchased it. </li>
        <li>
          You have not viewed or printed, in total, more than twenty percent (20%) of the eTextbook.
        </li>
        <li>
          You have not used any included access codes for any online resource that came with your eTextbook purchase.
        </li>
        <li>
          You have not previously returned the same eBook from a prior purchase.
        </li>
        <li>
          You are not returning an eBook from one of the publishers in our Return Policy Exceptions.
        </li>
        <li>
          You have not returned a total of 3 or more eTextbooks in the past 90 days across all of your Datalore Inc. or VitalSource Accounts.
        </li>
          </ul>
        </Typography>
        <br/>
        <br/>
        <Typography>
        PRICING
        </Typography>
        <br/>
        <Typography>
        Prices are displayed with the applicable Product or Service. Prices and availability of all Products and Services are subject to change at any time without notice before we accept your order. Prices may or may not be inclusive of applicable sales, use, or other taxes; however, such taxes shall be identified, calculated and collected at the point of sale. A confirmation email will be provided after the checkout for each order. We are not responsible for any interruption to or failure in any communication or process, howsoever caused, which results in an order, or in any payment or payment details not being received or processed by us. A payment will be regarded as received when we are able to credit or transfer the amount required into our designated bank account.
        </Typography>
  <br/>
  <br/>
        <Typography>
        DISCLAIMER OF WARRANTIES AND LIABILITY
        </Typography>
        <br/>
        <Typography>
        Neither Datalore Inc., its affiliates, nor any third-party content providers or licensors makes any warranty whatsoever, including without limitation, that the operation of the Site will be uninterrupted or error-free; that defects will be corrected; that this Site, including the server that makes it available, is free of viruses or other harmful components; as to the results that may be obtained from use of the Content or other materials on the Site; or as to the accuracy, completeness, reliability, availability, suitability, quality, non-infringement or operation of any Content, product or service provided on or accessible from the Site.
        </Typography>
        <BR/>
        <Typography>
        THIS SITE AND ALL CONTENT, PRODUCTS AND SERVICES INCLUDED IN OR ACCESSIBLE FROM THIS SITE ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OR REPRESENTATIONS OF ANY KIND (EXPRESS, IMPLIED AND STATUTORY, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF TITLE AND NONINFRINGEMENT AND THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE), ALL OF WHICH DATALORE INC. DISCLAIMS TO THE FULLEST EXTENT PERMITTED BY LAW. YOUR USE OF THE SITE IS AT YOUR SOLE RISK.
        </Typography>
        <BR/>
        <Typography>
        To the extent permissible under applicable laws, no responsibility is assumed for any injury and/or damage to persons, animals or property as a matter of products liability, negligence or otherwise, or from any use or operation of any ideas, instructions, methods, products or procedures contained in the Site or in the Content. No suggested test or procedure should be carried out unless, in the reader's judgment, its risk is justified.
        </Typography>
        <BR/>
        <Typography>
        NEITHER DATLORE INC. NOR ANY OF ITS AFFILIATES OR LICENSORS SHALL BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR INJURY, CAUSED IN WHOLE OR PART BY ITS NEGLIGENCE OR CONTINGENCIES BEYOND ITS CONTROL IN PROCURING, COMPILING, INTERPRETING, REPORTING OR DELIVERING INFORMATION THROUGH THE SITE. IN NO EVENT WILL DATALORE INC., ITS AFFILIATES OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON SUCH INFORMATION. DATALORE INC. AND ITS AFFILIATES AND LICENSORS SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, OR SIMILAR DAMAGES) EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </Typography>
        <BR/>
        <Typography>
        Datalore Inc. neither endorses nor takes responsibility for any products, goods or services offered by outside vendors through our services or advertised on our system.
        </Typography>
  <br/>
  <br/>
        <Typography>
        INDEMNIFICATION
        </Typography>
        <br/>
        <Typography>
        You hereby agree to indemnify, defend and hold Datalore Inc., its directors, officers, shareholders, parents, subsidiaries, affiliates, agents and licensors harmless from and against any and all liability, losses, damages and costs, including, without limitation, reasonable attorneys' fees, arising from your use of the Site or Content.
        </Typography>
  <br/>
  <br/>
        <Typography>
        DISPUTES
        </Typography>
        <br/>
        <Typography>
          <strong>Any dispute or claim relating in any way to your use of any Datalore Inc. service, or to any products or services sold or distributed by Datalore Inc. or through the Site will be resolved by binding arbitration, rather than in court</strong>, except that you may assert claims in the Magistrate’s Court for District ‘A’ in Barbados if your claims qualify.
        </Typography>
        <BR/>
        <Typography>
          <strong>There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow the terms of these Terms and Conditions as a court would.</strong>
        </Typography>
        <BR/>
        <Typography>
          <strong>We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action.</strong> If for any reason a claim proceeds in court rather than in arbitration <strong>we each waive any right to a jury trial</strong>. We also both agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.
        </Typography>
        <br/>
        <br/>
        <Typography>
        GOVERNING LAW
        </Typography>
        <br/>
        <Typography>
        These terms and conditions shall be governed by and construed in accordance with the laws of Barbados, without regard to its conflicts of law principles. You hereby submit to and agree that the sole jurisdiction and venue for any actions that may arise under or in relation to the subject matter hereof shall be the courts located in Barbados.
        </Typography>
        <br/>
        <br/>
        <Typography>
        SITE POLICIES, MODIFICATION, AND SEVERABILITY
        </Typography>
        <br/>
        <Typography>
        Please review our other policies, such as our <Link to="/privacy">Privacy Policy</Link>, posted on this site. These policies also govern your use of this Site. We reserve the right to make changes to our site, policies, and these Terms and Conditions at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition.
        </Typography>
      </div>
    </Page>
  );
};

export default Terms;
import React from "react"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from "@material-ui/core/Typography"
import LinearProgress from "@material-ui/core/LinearProgress"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/Close';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {capitalize} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Link, useParams} from "react-router-dom"

// Local
import Page from "../components/Page"
import {firestore} from "../firebase"
import BookGrid from "../components/BookGrid"
import BisacCodes from "../data/bisac-codes"
import Subjects from "../data/subjects"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    breadcrumbsContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(2)
      }
    },
    breadcrumbText: {
      textTransform: "capitalize",
      fontSize: 18,
    },
    breadcrumbText1: {
      textTransform: "capitalize",
      fontSize: 18,
      fontWeight: "bold"
    },
    sidebar: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2)
    },
    sidebarTitle: {
      marginBottom: theme.spacing(2)
    },
    sidebarSubtitle: {
      marginBottom: theme.spacing(),
      fontWeight: "bold"
    },
    subject: {
      [theme.breakpoints.up("md")]: {
        paddingTop: 2,
        paddingLeft: 4,
        paddingBottom: 2,
        paddingRight: 4,
      },
    },
    selectedSubject: {
      color: theme.palette.primary.main
    },
    childSubject: {
      paddingTop: 2,
      paddingLeft: theme.spacing(2),
      paddingBottom: 2,
      paddingRight: 4,
    },
    subjectText: {
      textTransform: "capitalize",
      fontSize: 15,
      lineHeight: 1.25
    },
    expandSubjectsButton: {
      marginLeft: theme.spacing(),
      marginBottom: theme.spacing(1.5),
      [theme.breakpoints.up("md")]: {
        display: "none"
      },
    }
  }),
)

interface Subject {
  code: string,
  literal: string,
  title: string,
  children: {
    code: string,
    literal: string,
    title: string
  }[]
}

interface SidebarSubjectProps {
  subject: Subject
  onClick: (code: string, name: string) => void
}

function SidebarSubject(props: SidebarSubjectProps) {
  const classes = useStyles()
  const {subject, onClick} = props
  const {code, children, title} = subject
  const [expanded, expand] = React.useState(false)
  const hasChildren = children.length > 0
  const parentTitle = title
  return (
    <div>
      <ListItem
        className={classes.subject}
        button
        onClick={() => {
          if (hasChildren) {
            expand(!expanded)
            return
          }
          onClick(code, title)
        }}>
        <ListItemText
          primaryTypographyProps={{className: classes.subjectText}}
          primary={title}/>
        {hasChildren ? expanded ? <ExpandLess/> : <ExpandMore/> : null}
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map(({code, title}) => (
            <ListItem
              key={code}
              className={classes.childSubject}
              button
              onClick={() => {
                // TODO: Better solution for displaying nexted subjects
                onClick(code, `${parentTitle}/${title}`)
              }}>
              <ListItemText
                primaryTypographyProps={{className: classes.subjectText}}
                primary={title}/>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  )
}

const BOOK_COUNT = 24

const BrowseSubject = () => {
  const classes = useStyles()
  const {shortcode} = useParams()
  const booksRef = firestore.collection("books")
  const initialBookQuery = booksRef
    .orderBy("title")
    .where(
      "subjects",
      "array-contains",
      {code: `${shortcode.toUpperCase()}000000`, schema: "bisac", name: "General"}
    )
    .limit(BOOK_COUNT)
  const [booksQuery, setBooksQuery] = React.useState(initialBookQuery)
  const [orderedBooksRef, setOrderedBooksRef] = React.useState(booksQuery)
  const [books, setBooks] = React.useState({value: null, loading: true, error: null, firstDoc: null, lastDoc: null})
  const {value, loading, error, lastDoc} = books
  const subject = getSubject(shortcode.toUpperCase())
  const isMobile = useMediaQuery('(max-width:600px)')
  const [sidebarExpanded, expandSidebar] = React.useState(!isMobile)
  const [selectedSubject, selectSubject] = React.useState<string | null>(null)
  
  function _resetBookQuery() {
    selectSubject(null)
    expandSidebar(true)
    setBooksQuery(initialBookQuery)
  }
  
  React.useEffect(() => {
    orderedBooksRef.get().then(function (documentSnapshots) {
      const firstDoc = documentSnapshots?.docs[0]
      const lastDoc = documentSnapshots?.docs[documentSnapshots?.docs?.length - 1]
      const newValue = value ? [...value] : []
      documentSnapshots.forEach((childSnapshot) => {
        if (childSnapshot.exists) newValue.push(childSnapshot.data())
      })
      setBooks({
        value: newValue,
        loading: false,
        error: null,
        firstDoc,
        lastDoc
      })
    })
  }, [orderedBooksRef])
  
  React.useEffect(() => {
    setBooks({value: null, loading: true, error: null, firstDoc: null, lastDoc: null})
    setOrderedBooksRef(booksQuery)
  }, [booksQuery])
  
  const subjects = getSubjects(shortcode)
  
  const sidebar = (
    <Paper className={classes.sidebar} variant="outlined">
      <Typography className={classes.sidebarTitle} variant="h6">Subjects</Typography>
      <Grid container alignItems="center">
        <Grid item>
          <Typography className={classes.sidebarSubtitle}>{subject?.name}</Typography>
          {selectedSubject !== null && (
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography className={classes.selectedSubject}>{selectedSubject}</Typography>
              </Grid>
              <Grid item>
                <Button onClick={() => _resetBookQuery()}>
                  <CloseIcon/>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Button className={classes.expandSubjectsButton} onClick={() => expandSidebar(!sidebarExpanded)}>
            {sidebarExpanded ? <ExpandLess/> : <ExpandMore/>}
          </Button>
        </Grid>
      </Grid>
      {sidebarExpanded && (
        <List>
          {subjects.map((subject) => (
            <SidebarSubject
              key={subject.code}
              subject={subject}
              onClick={(code, name) => {
                const ref = booksRef.orderBy("title")
                  .where(
                    "subjects",
                    "array-contains",
                    {code, schema: "bisac", name}
                  )
                  .limit(BOOK_COUNT)
                setBooksQuery(ref)
                expandSidebar(false)
                selectSubject(name)
              }}/>
          ))}
        </List>
      )}
    </Paper>
  )
  
  if (loading) return <LinearProgress/>
  const errorComp = (
    <div style={{marginTop: 40}}>
      <Typography variant="h5" align="center">
        Oops, an error occurred, please reload the page
        {JSON.stringify(error)}
      </Typography>
    </div>
  )
  return (
    <Page>
      {error ? (
        errorComp
      ) : (
        <div>
          <div className={classes.breadcrumbsContainer}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{textDecoration: "none"}} to="/">
                <Typography className={classes.breadcrumbText} color="textPrimary">Home</Typography>
              </Link>
              <Link style={{textDecoration: "none"}} to="/browse">
                <Typography className={classes.breadcrumbText} color="textPrimary">Browse</Typography>
              </Link>
              <Typography className={classes.breadcrumbText1}
                          color="primary">{(subject?.name.toLowerCase() ?? "")}</Typography>
            </Breadcrumbs>
          </div>
          <div style={{minHeight: 600, paddingBottom: 120}}>
            {value && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  {sidebar}
                </Grid>
                <Grid item xs={12} md={9}>
                  <BookGrid
                    docs={value}
                    noItemsMsg={`We don't have any books for "${selectedSubject === null ? capitalize(subject?.name?.toLowerCase() ?? "") : selectedSubject}". Please check back soon.`}
                  />
                  {BOOK_COUNT <= value.length && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setOrderedBooksRef(booksQuery.startAfter(lastDoc).limit(BOOK_COUNT))
                      }}>
                      Load more
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      )}
    </Page>
  )
}

export default BrowseSubject

function getSubjects(shortCode: string) {
  const subjects: Subject[] = []
  BisacCodes.forEach(({Code, Literal}) => {
    if (Code.substr(0, 3) !== shortCode.toUpperCase()) return
    const segments = Literal.split(" / ")
    if (segments.length === 0 || segments.length === 1) return
    if (segments.length > 3) return
    if (segments.length === 2) {
      subjects.push({code: Code, literal: Literal, title: segments[1], children: []})
      return
    }
    
    let itemIndex = -1
    subjects.forEach(({title}, index) => {
      // has this already been added?
      if (title === segments[1]) itemIndex = index
    })
    
    if (itemIndex > -1) {
      // it's been added so do your thing
      subjects[itemIndex].children.push({code: Code, literal: Literal, title: segments[2]})
      return
    }
    
    subjects.push({
      code: Code,
      literal: Literal,
      title: segments[1],
      children: [{code: Code, literal: Literal, title: segments[2]}]
    })
    
  })
  return subjects
}

function getSubject(shortcode: string) {
  for (let index in Subjects){
    const subject = Subjects[index]
    if (subject.shortcode === shortcode) {
      return subject
    }
  }
  return null
}


import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Linking } from "react-native";
import { useRecoilValue } from "recoil";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";

//
import Copyright from "../components/Copyright";
import { FirebaseUserType } from "../types";
import { firestore } from "../firebase";
import {EndPoints, Strings} from "../constants";
import { userState } from "../store/atoms";

function handleBookshelfRedirect(
  user: FirebaseUserType,
  onError: (error: string) => void,
  destination: string
) {
  const userRef = firestore.collection("users").doc(user.uid);
  function redirect(token) {
    const body = JSON.stringify({
      apiKey: Strings.apiKey,
      token,
      destination,
    });
    fetch(EndPoints.getVitalSourceBookshelfURL, {
      method: "POST",
      body,
    })
      .then((response: any) => {
        return response.text();
      })
      .then((result: any) => {
        console.log({url: JSON.parse(result).url});
        Linking.openURL(JSON.parse(result).url).then();
      })
      .catch((err: any) => {
        console.log(err)
      });
  }
  userRef
    .get()
    .then(function (doc) {
      if (doc.exists) {
        const userDoc = doc.data();
        console.log({ userDoc });
        if (userDoc.token) {
          redirect(userDoc.token);
        } else {
          const body = JSON.stringify({
            apiKey: Strings.apiKey,
            uid: user.uid,
            firstname: userDoc.firstname,
            lastname: userDoc.lastname,
          });
          fetch(EndPoints.getVitalSourceCredentials, {
            method: "POST",
            body,
          })
            .then((response: any) => {
              console.log({response});
              return response.text();
            })
            .then((result: any) => {
              console.log({result: JSON.parse(result)});
            })
            .catch((err: any) => {
              console.log(err)
              onError("Error connecting to the bookshelf system.");
            });
        }
      } else {
        console.log("No such document!");
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      onError("Error connecting to the bookshelf system.");
    });
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  error: {
    color: "red",
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}));

export default function Bookshelf() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { code } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [contactingService, setContactingService] = React.useState(true);
  const [error, setError] = React.useState("");
  const user = useRecoilValue(userState);
  React.useEffect(() => {
    if (user.status === "USER_LOGGED_IN")
      handleBookshelfRedirect(
        user,
        (error) => {
          setError(error);
          setContactingService(false);
        },
        code
          ? `https://studenteportal.vitalsource.com/#/books/${code}`
          : `https://studenteportal.vitalsource.com/#/books`
      );
    setLoading(false);
  });
  if (loading) return <div />;
  return (
    <div className={classes.root}>
      {contactingService && <LinearProgress />}
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="sm">
        <div>
          <Button
            aria-label="go-back"
            startIcon={<ArrowBackIcon />}
            color="primary"
            onClick={() => navigate("/")}
          >
            Go Back
          </Button>
          <Typography variant="h2" component="h1" gutterBottom>
            Bookshelf
          </Typography>
        </div>
        <Typography variant="h5" component="h2" gutterBottom>
          {"We're preparing your bookshelf for you."}
        </Typography>
        {error ? (
          <Typography className={classes.error} variant="body1">
            {error}
          </Typography>
        ) : (
          <Typography variant="body1">Please give us a few seconds.</Typography>
        )}
      </Container>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}

import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import {capitalize} from "@material-ui/core";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {useDocumentDataOnce, useDocumentOnce,} from "react-firebase-hooks/firestore";
import {View} from "react-native";
import {PayPalButtons} from "@paypal/react-paypal-js";
//
import Copyright from "../../components/Copyright";
import {Strings, EndPoints} from "../../constants";
import {getUSDPrice, sortVariants} from "../../utils";
import {firestore} from "../../firebase";
import {userState} from "../../store/atoms";
import Review from "./Review";
import {UserType} from "../../types";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fafafa",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#fafafa",
  },
  navLogoButton: {
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: "bold",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const testSKU = "L99972292"

export default function Checkout() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = React.useState({
    paying: false,
    error: "",
    status: "",
  });
  const [orderID, setOrderID] = React.useState<string | null>(null);
  const {variant, vbid} = useParams();
  const orderRef = firestore.collection("orders").doc();
  const [bookSnapshot, loadingBook, bookError] = useDocumentOnce(
    firestore.collection("books").doc(vbid)
  );
  const [book, setBook] = React.useState(null);
  //if (book != null) console.log("book", book);
  const user = useRecoilValue(userState);
  const userRef = firestore.collection("users").doc(user.uid);
  const [userDoc, loading, error] = useDocumentDataOnce<UserType>(userRef);
  const [userBookSnapshot, loadingUserBook, userBookError] = useDocumentOnce(
    userRef.collection("books").doc(vbid)
  );
  
  function _createOrder(data: any, actions: any) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: book.price(),
            },
          },
        ],
      })
      .then((orderID: string) => {
        setOrderID(orderID);
        console.log({data, orderID});
        return orderID;
      });
  }
  
  function _onApprove(data: any, actions: any) {
    console.log("approved")
    return actions.order.capture().then((something: any) => {
      console.log({something, data});
      _onSuccess();
      return something;
    });
  }
  
  function _onClick() {
    setPaymentStatus({
      paying: true,
      error: "",
      status: "",
    });
  }
  
  function _onCancel() {
    setPaymentStatus({
      paying: false,
      error: "",
      status: "Canceled",
    });
  }
  
  function _onError(error?: string) {
    setPaymentStatus({
      paying: false,
      error: error ?? "Oops, something went wrong, please try again later",
      status: "Error",
    });
  }
  
  function _onSuccess() {
    console.log("success")
    setPaymentStatus({
      paying: false,
      error: "",
      status: "OK",
    });
    _getBook();
  }
  
  function _show_mMoney_button(amount: number) {
    let paymentParams = {
      amount: amount, // Cost of item; Must be FLOAT type, eg. 1.99
      currency: "BBD", // Currency
      merchant_order_id: orderRef.id, // Your cart ID or invoice ID; Must be UNIQUE string type
      
      // API Key from mMoney Merchant Panel with ONLY permission to receive payments enabled
      api_key: Strings.mMoneyAPIKey,
      
      onPaid: function (details: any) {
        // Optional function called when payment is completed
        console.log("User paid:", details);
        _onSuccess();
      },
      onCancel: function () {
        // Optional function called when payment window is cancelled
        console.log("User cancelled");
        _onCancel();
      },
      onError: function (err: any) {
        // Optional function called when payment window throws an error
        console.log("User error", err);
        _onError();
      },
    };
    
    // Send payment parameters and show button
    // @ts-ignore
    mMoney?.payment?.button?.render(paymentParams, "#payment-button");
  }
  
  function _getBook() {
    if (userDoc.token) {
      const requestBody = {
        token: userDoc.token,
        apiKey: Strings.apiKey,
        sku: book.sku,
        term: capitalize(book.duration),
        online_term: capitalize(book.duration),
      };
      fetch(EndPoints.getVitalSourceBook, {
        method: "POST",
        body: JSON.stringify(requestBody),
      })
        .then(response => response.text())
        .then((result: any) => {
          const {code, sku} = JSON.parse(result);
          console.log({result})
          if (!!sku) {
            console.log("got a sku")
            // Redirect to bookshelf page
            userRef
              .collection("books")
              .doc(vbid)
              .set({...bookSnapshot.data(), code})
              .then(() => {
                navigate(`/bookshelf/${sku}`);
              })
              .catch((err: any) => {
                console.log({err})
              });
          } else {
            console.log("gots no sku")
          }
        })
        .catch((err: any) => {
          console.log({err})
        });
    } else {
      _onError("You don't have the credentials to access this book")
    }
  }
  
  React.useEffect(() => {
    if (!loading && !loadingBook && !loadingUserBook && book !== null) _show_mMoney_button(Number(book.price() * 2.03));
    //if (!loading && !loadingBook && !loadingUserBook && book !== null) _show_mMoney_button(0.5);
  }, [loading, loadingBook, loadingUserBook, book]);
  
  React.useEffect(() => {
    if (!loading && !loadingBook && !loadingUserBook) {
      const bookVariant = sortVariants(bookSnapshot?.data()?.variants)[variant];
      const book = {
        title: bookSnapshot?.data()?.title ?? "",
        sku: bookVariant?.sku,
        duration: bookVariant?.duration ?? "",
        price: () => {
          return getUSDPrice(bookVariant?.prices ?? []);
        },
      };
      setBook(book)
    }
  }, [loading, loadingBook, loadingUserBook]);
  
  
  if (loading || loadingBook || loadingUserBook || book === null) return <LinearProgress/>;
  if (error)
    return (
      <div style={{padding: 80}}>
        <Typography variant="h6" align="center">
          {error.message}
        </Typography>
      </div>
    );
  if (userBookError)
    return (
      <div style={{padding: 80}}>
        <Typography variant="h6" align="center">
          {userBookError.message}
        </Typography>
      </div>
    );
  if (userBookSnapshot?.exists)
    return (
      <React.Fragment>
        <div className={classes.root}>
          <CssBaseline/>
          <AppBar
            className={classes.appBar}
            position="absolute"
            color="default"
            elevation={0}
          >
            <Toolbar>
              <Button
                className={classes.navLogoButton}
                color="inherit"
                component={Link}
                to="/"
              >
                Share Caribbean
              </Button>
            </Toolbar>
          </AppBar>
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <Typography variant="h4">Checkout</Typography>
              <React.Fragment>
                <Typography
                  style={{marginTop: 40, marginBottom: 8}}
                  variant="h5"
                  gutterBottom
                >
                  Thank you for your patronage.
                </Typography>
                <Typography style={{marginBottom: 40}} variant="subtitle1">
                  You have already purchased &quot;
                  <strong>{userBookSnapshot.data().title}</strong>&quot; and it
                  is available on your bookshelf.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/bookshelf/${vbid}`}
                >
                  Go to bookshelf
                </Button>
                <Button
                  style={{marginLeft: 16}}
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/"
                >
                  Continue Shopping
                </Button>
              </React.Fragment>
            </Paper>
            <Copyright/>
            <div style={{height: 40}}/>
          </main>
        </div>
      </React.Fragment>
    );
  if (userDoc && !userBookSnapshot?.exists)
    return (
      <React.Fragment>
        <div className={classes.root}>
          <CssBaseline/>
          <AppBar
            className={classes.appBar}
            position="absolute"
            color="default"
            elevation={0}
          >
            <Toolbar>
              <Button
                className={classes.navLogoButton}
                color="inherit"
                component={Link}
                to="/"
              >
                Share Caribbean
              </Button>
            </Toolbar>
          </AppBar>
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <Typography component="h1" variant="h4">
                Checkout
              </Typography>
              <div style={{height: 24}}/>
              {paymentStatus.error ? (
                <Typography style={{marginBottom: 40}} variant="subtitle1">
                  {paymentStatus.error}
                </Typography>
              ) : paymentStatus.status === "OK" ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your order.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your order is completed. We have emailed your order
                    confirmation, and will redirect you to your bookshelf.
                  </Typography>
                  <View
                    style={{
                      marginTop: 32,
                      marginBottom: 16,
                    }}
                  >
                    <LinearProgress/>
                  </View>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Review
                    book={{
                      duration: book.duration,
                      price: book.price(),
                      title: book.title,
                    }}
                  />
                  <div style={{height: 24}}/>
                  <Typography variant="h5">Payment methods</Typography>
                  <Typography variant="body1">Visa and Mastercard are accepted through PayPal</Typography>
                  <div style={{height: 16}}/>
                  <div style={{maxWidth: "200px"}}>
                    <PayPalButtons
                      style={{
                        color: "blue",
                        shape: "pill",
                        label: "pay",
                        layout: "horizontal",
                      }}
                      createOrder={_createOrder}
                      onApprove={_onApprove}
                      onClick={_onClick}
                      onCancel={_onCancel}
                      onError={_onError}
                    />
                  </div>
                  <div style={{height: 8}}/>
                  <div id="payment-button"/>
                  {paymentStatus.paying && (
                    <View
                      style={{
                        marginTop: 32,
                        marginBottom: 16,
                      }}
                    >
                      <Typography>Processing payment</Typography>
                      <div style={{height: 4}}/>
                      <LinearProgress/>
                    </View>
                  )}
                </React.Fragment>
              )}
            </Paper>
            <Copyright/>
            <div style={{height: 40}}/>
          </main>
        </div>
      </React.Fragment>
    );
  else
    return (
      <div style={{padding: 80}}>
        <Typography variant="h6" align="center">
          Oops something went wrong
        </Typography>
      </div>
    );
}
